h4, p {
    color: #FFFFFF;
}

a.nav-link {
    padding-left: 0;
}

a.nav-link:hover, a.nav-link:focus {
    color: #F77F00;
}
