body {
    margin: 0;
    min-width: 360px;
}

.divider {
    background: url("./../images/divider.png") no-repeat;
    background-size: contain;
    height: 20px;
    margin: 20px 0;
    width: 100%;
}

.divider.center {
    background-position-x: center;
}

.elementor-column {
    flex-direction: column;
}
