#home {
    margin-top: 66px;

}

@media (min-width: 1024px) {
    #home {
        min-height: 780px;
    }
}

.heroImage {
    position: relative;
    padding: 80px 20px 0;
    display: flex;
    justify-content: center;
}

.heroImage img {
    width: 100%;
    max-width: 1000px;
}
