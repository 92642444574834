.elementor-11 .elementor-element.elementor-element-55de2efd > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-11 .elementor-element.elementor-element-55de2efd:not(.elementor-motion-effects-element-type-background) {
    background-color: #000000;
}

.elementor-11 .elementor-element.elementor-element-55de2efd {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    z-index: 9;
}

.elementor-11 .elementor-element.elementor-element-55de2efd > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-11 .elementor-element.elementor-element-791e67f6 {
    --e-nav-menu-horizontal-menu-item-margin: calc(21px / 2);
}

@media (max-width: 1024px) {
    .elementor-11 .elementor-element.elementor-element-791e67f6 {
        --nav-menu-icon-size: 28px;
    }
}

@media (min-width: 768px) {
    .elementor-11 .elementor-element.elementor-element-9827e7a {
        width: 20%;
    }

    .elementor-11 .elementor-element.elementor-element-3e78d245 {
        width: 54.332%;
    }

    .elementor-11 .elementor-element.elementor-element-5e8218f9 {
        width: 25%;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .elementor-11 .elementor-element.elementor-element-9827e7a {
        width: 25%;
    }

    .elementor-11 .elementor-element.elementor-element-3e78d245 {
        width: 35%;
    }

    .elementor-11 .elementor-element.elementor-element-5e8218f9 {
        width: 40%;
    }
}

@media (max-width: 767px) {
    .elementor-11 .elementor-element.elementor-element-9827e7a {
        width: 80%;
    }

    .elementor-11 .elementor-element.elementor-element-784d8c7d {
        text-align: left;
    }

    .elementor-11 .elementor-element.elementor-element-784d8c7d img {
        width: 80%;
    }

    .elementor-11 .elementor-element.elementor-element-3e78d245 {
        width: 20%;
    }
}
