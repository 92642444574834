.sponsors {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: 1140px;
  padding: 10px;
  flex-direction: column;
}

.sponsors img {
  margin: 20px;
  padding-bottom: 5%;
}

.facebook-logo {
  max-height: 30px;
}

.instagram-logo {
  max-height: 60px;
}

.google-logo {
  max-height: 65px;
}

@media (min-width: 768px) {
  .sponsors {
    flex-direction: row;
  }

  .sponsors img {
    margin: 0;
  }
}
