#services p {
    color: #000000;
}

.elementor-widget-image-box h4 {
    margin: 1.33em 0;
}

.elementor-widget-image-box .elementor-image-box-content {
    width: 100%
}

@media (min-width: 768px) {
    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper, .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
        display: flex
    }

    .elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper {
        text-align: right;
        flex-direction: row-reverse
    }

    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper {
        text-align: left;
        flex-direction: row
    }

    .elementor-widget-image-box.elementor-position-top .elementor-image-box-img {
        margin: auto
    }

    .elementor-widget-image-box.elementor-vertical-align-top .elementor-image-box-wrapper {
        align-items: flex-start
    }

    .elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper {
        align-items: center
    }

    .elementor-widget-image-box.elementor-vertical-align-bottom .elementor-image-box-wrapper {
        align-items: flex-end
    }
}

@media (max-width: 767px) {
    .elementor-widget-image-box .elementor-image-box-img {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 15px
    }
}

.elementor-widget-image-box .elementor-image-box-img {
    display: inline-block
}

.elementor-widget-image-box .elementor-image-box-title a {
    color: inherit
}

.elementor-widget-image-box .elementor-image-box-wrapper {
    text-align: center
}

.elementor-widget-image-box
.elementor-image-box-description {
    margin: 0
}
