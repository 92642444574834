@charset "UTF-8";
.elementor-widget-archive-posts:after, .elementor-widget-posts:after {
    display: none
}

.elementor-posts-container.elementor-posts-masonry {
    align-items: flex-start
}

.elementor-posts-container:not(.elementor-posts-masonry) {
    align-items: stretch
}

.elementor-posts-container .elementor-post {
    padding: 0;
    margin: 0
}

.elementor-posts-container .elementor-post__excerpt {
    flex-grow: var(--read-more-alignment, 0)
}

.elementor-posts-container .elementor-post__thumbnail__link {
    position: relative;
    display: block;
    width: 100%
}

.elementor-posts .elementor-post {
    flex-direction: column;
    transition-property: background, border, box-shadow;
    transition-duration: .25s
}

.elementor-posts .elementor-post__title {
    font-size: 18px;
    margin: 0
}

.elementor-posts .elementor-post__excerpt {
    margin-bottom: 10px
}

.elementor-posts .elementor-post__excerpt p {
    margin: 0;
    line-height: 1.5em;
    font-size: 14px;
    color: #777
}

.elementor-posts .elementor-post__text {
    display: var(--item-display, block);
    flex-direction: column;
    flex-grow: 1
}

.elementor-posts .elementor-post__meta-data {
    line-height: 1.3em;
    font-size: 12px;
    margin-bottom: 13px;
    color: #adadad
}

.elementor-posts .elementor-post__read-more {
    font-size: 12px;
    font-weight: 700;
    align-self: flex-start
}

.elementor-posts--skin-classic .elementor-post {
    overflow: hidden
}

.elementor-posts--align-left .elementor-post {
    text-align: left
}

.elementor-posts--align-right .elementor-post {
    text-align: right
}

.elementor-posts--align-center .elementor-post {
    text-align: center
}

.elementor-posts--thumbnail-top .elementor-post__thumbnail__link {
    margin-bottom: 20px
}

.elementor-posts--thumbnail-top .elementor-post__text {
    width: 100%
}

.elementor-posts--thumbnail-top.elementor-posts--align-left .elementor-post__thumbnail__link {
    margin-right: auto
}

.elementor-posts--thumbnail-top.elementor-posts--align-right .elementor-post__thumbnail__link {
    margin-left: auto
}

.elementor-posts--thumbnail-top.elementor-posts--align-center .elementor-post__thumbnail__link {
    margin-right: auto;
    margin-left: auto
}

.elementor-posts--thumbnail-left .elementor-post, .elementor-posts--thumbnail-right .elementor-post {
    flex-direction: row
}

.elementor-posts--thumbnail-left .elementor-post__thumbnail__link, .elementor-posts--thumbnail-right .elementor-post__thumbnail__link {
    flex-shrink: 0;
    width: 25%
}

.elementor-posts--thumbnail-left .elementor-post__thumbnail__link {
    order: 0;
    margin-right: 20px
}

.elementor-posts--thumbnail-right .elementor-post__thumbnail__link {
    order: 5;
    margin-left: 20px
}

.elementor-posts--thumbnail-none .elementor-posts-container .elementor-post__thumbnail__link {
    display: none
}

.elementor-posts .elementor-post {
    display: flex
}

.elementor-posts .elementor-post__card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    border-radius: 3px;
    border: 0 solid #69727d;
    transition: all .25s
}

.elementor-posts .elementor-post__card .elementor-post__text {
    padding: 0 30px;
    margin-bottom: 0;
    margin-top: 20px
}

.elementor-posts .elementor-post__card .elementor-post__read-more {
    margin-bottom: 20px;
    display: inline-block
}

.elementor-posts .elementor-post__card .elementor-post__meta-data {
    padding: 15px 30px;
    margin-bottom: 0;
    border-top: 1px solid #eaeaea
}

.elementor-posts .elementor-post__card .elementor-post__meta-data span + span:before {
    margin: 0 5px
}

.elementor-posts .elementor-post__card .elementor-post__title {
    font-size: 21px
}

.elementor-posts .elementor-post__card .elementor-post__excerpt {
    line-height: 1.7
}

.elementor-posts .elementor-post__card .elementor-post__excerpt, .elementor-posts .elementor-post__card .elementor-post__title {
    margin-bottom: 25px
}

.elementor-posts .elementor-post__card .elementor-post__badge, .elementor-posts .elementor-post__card .elementor-post__read-more {
    text-transform: uppercase
}

.elementor-posts .elementor-post__badge {
    position: absolute;
    top: 0;
    background: #69727d;
    color: #fff;
    font-size: 12px;
    padding: .6em 1.2em;
    line-height: 1;
    font-weight: 400;
    margin: 20px;
    border-radius: 999px
}

.elementor-posts .elementor-post__avatar {
    position: relative;
    padding: 0 30px;
    width: 100%;
    top: -25px;
    height: 0;
    pointer-events: none
}

.elementor-posts .elementor-post__avatar img {
    border-radius: 50%;
    width: 60px;
    pointer-events: all;
    transform: translateY(-50%)
}

.elementor-posts--thumbnail-top .elementor-posts--skin-cards:not(.elementor-posts-masonry) .elementor-post__meta-data, .elementor-posts .elementor-posts--skin-cards:not(.elementor-posts-masonry) .elementor-post__meta-data {
    margin-top: auto
}

.elementor-posts--thumbnail-none .elementor-post__card .elementor-post__avatar {
    padding-top: 0;
    position: static;
    height: auto
}

.elementor-posts--thumbnail-none .elementor-post__card .elementor-post__avatar img.avatar {
    transform: translateY(0);
    vertical-align: bottom
}

.elementor-posts--show-avatar .elementor-post__thumbnail__link {
    margin-bottom: 25px
}

.elementor-posts__hover-gradient .elementor-post__card .elementor-post__thumbnail__link:after {
    display: block;
    content: "";
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .35), transparent 75%);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 1;
    transition: all .3s ease-out
}

.elementor-posts__hover-gradient .elementor-post__card:hover .elementor-post__thumbnail__link:after {
    opacity: .5
}

.elementor-card-shadow-yes .elementor-post__card {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15)
}

.elementor-card-shadow-yes .elementor-post__card:hover {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15)
}

.elementor-posts--skin-full_content article {
    flex-direction: column;
    overflow: hidden
}

body.elementor-editor-active .elementor-posts--skin-archive_full_content, body.elementor-editor-active .elementor-posts--skin-full_content {
    pointer-events: none
}

body.elementor-editor-active .elementor-posts--skin-archive_full_content .elementor-post__thumbnail__link, body.elementor-editor-active .elementor-posts--skin-full_content .elementor-post__thumbnail__link {
    display: none
}

body.elementor-editor-active .elementor-posts--show-thumbnail .elementor-post__thumbnail__link {
    display: block
}

.elementor-portfolio__filters {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0
}

.elementor-portfolio__filter {
    margin: 0;
    cursor: pointer;
    padding: 8px
}

.elementor-portfolio-item {
    align-self: flex-start;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
    transform-style: preserve-3d;
    overflow: hidden;
    margin: 0
}

.elementor-portfolio-item__title {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 18px;
    color: #fff
}

.elementor-portfolio-item__tags__separator:before {
    content: ", "
}

.elementor-portfolio-item__overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    transition: opacity 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    background-color: rgba(105, 114, 125, .8)
}

.elementor-portfolio-item__overlay:hover {
    opacity: 1;
    transition: opacity .5s
}

.elementor-portfolio-item__img img[src=""] {
    background-color: rgba(0, 0, 0, .1);
    height: 100%
}

.elementor-portfolio.elementor-has-item-ratio {
    transition: height .5s
}

.elementor-portfolio.elementor-has-item-ratio .elementor-post__thumbnail__link {
    padding-bottom: 56.25%
}

.elementor ul li.elementor-portfolio__filter {
    list-style-type: none
}

.e-con-inner > .elementor-widget-portfolio, .e-con > .elementor-widget-portfolio {
    width: var(--container-widget-width);
    --flex-grow: var(--container-widget-flex-grow)
}

.elementor-widget-archive-posts.load-more-align-center .elementor-button-wrapper, .elementor-widget-posts.load-more-align-center .elementor-button-wrapper {
    text-align: center
}

.elementor-widget-archive-posts.load-more-align-start .elementor-button-wrapper, .elementor-widget-posts.load-more-align-start .elementor-button-wrapper {
    text-align: start
}

.elementor-widget-archive-posts.load-more-align-end .elementor-button-wrapper, .elementor-widget-posts.load-more-align-end .elementor-button-wrapper {
    text-align: end
}

.elementor-widget-archive-posts.load-more-align-justify .elementor-button, .elementor-widget-posts.load-more-align-justify .elementor-button {
    width: 100%
}

.elementor-widget-archive-posts.e-load-more-pagination-loading > .elementor-widget-container, .elementor-widget-posts.e-load-more-pagination-loading > .elementor-widget-container {
    cursor: default
}

.elementor-widget-archive-posts.e-load-more-pagination-loading > .elementor-widget-container .e-load-more-spinner, .elementor-widget-posts.e-load-more-pagination-loading > .elementor-widget-container .e-load-more-spinner {
    margin-top: var(--load-moreâ€”spacing, 30px)
}

.elementor-widget-archive-posts.e-load-more-pagination-loading > .elementor-widget-container .e-load-more-spinner i, .elementor-widget-archive-posts.e-load-more-pagination-loading > .elementor-widget-container .e-load-more-spinner svg, .elementor-widget-posts.e-load-more-pagination-loading > .elementor-widget-container .e-load-more-spinner i, .elementor-widget-posts.e-load-more-pagination-loading > .elementor-widget-container .e-load-more-spinner svg {
    display: flex
}

.elementor-widget-archive-posts.e-load-more-pagination-loading > .elementor-widget-container > .elementor-button-wrapper .elementor-button-content-wrapper, .elementor-widget-posts.e-load-more-pagination-loading > .elementor-widget-container > .elementor-button-wrapper .elementor-button-content-wrapper {
    visibility: hidden
}

.elementor-widget-archive-posts.e-load-more-pagination-end > .elementor-widget-container > .elementor-button-wrapper, .elementor-widget-posts.e-load-more-pagination-end > .elementor-widget-container > .elementor-button-wrapper {
    display: none
}

.elementor-widget-archive-posts.e-load-more-pagination-end > .elementor-widget-container > .e-load-more-message, .elementor-widget-posts.e-load-more-pagination-end > .elementor-widget-container > .e-load-more-message {
    display: block
}

.elementor-widget-archive-posts.e-load-more-no-spinner > .elementor-widget-container > .elementor-button-wrapper .elementor-button-content-wrapper, .elementor-widget-posts.e-load-more-no-spinner > .elementor-widget-container > .elementor-button-wrapper .elementor-button-content-wrapper {
    visibility: visible
}

.elementor-widget-archive-posts > .elementor-widget-container .e-load-more-spinner, .elementor-widget-posts > .elementor-widget-container .e-load-more-spinner {
    display: flex
}

.elementor-widget-archive-posts > .elementor-widget-container .e-load-more-spinner i, .elementor-widget-archive-posts > .elementor-widget-container .e-load-more-spinner svg, .elementor-widget-posts > .elementor-widget-container .e-load-more-spinner i, .elementor-widget-posts > .elementor-widget-container .e-load-more-spinner svg {
    margin: 0 auto;
    display: none
}

.elementor-widget-archive-posts > .elementor-widget-container .e-load-more-spinner i, .elementor-widget-posts > .elementor-widget-container .e-load-more-spinner i {
    color: var(--load-more-spinner-color)
}

.elementor-widget-archive-posts > .elementor-widget-container .e-load-more-spinner svg, .elementor-widget-posts > .elementor-widget-container .e-load-more-spinner svg {
    fill: var(--load-more-spinner-color);
    height: 1em;
    width: 1em
}

.elementor-widget-archive-posts > .elementor-widget-container .e-load-more-message, .elementor-widget-posts > .elementor-widget-container .e-load-more-message {
    color: var(--load-more-message-color);
    text-align: var(--load-more-message-alignment, center);
    display: none;
    margin-top: var(--load-moreâ€”spacing, 30px)
}

.elementor-widget-archive-posts > .elementor-widget-container > .elementor-button-wrapper, .elementor-widget-posts > .elementor-widget-container > .elementor-button-wrapper {
    margin-top: var(--load-moreâ€”spacing, 30px)
}

.elementor-widget-archive-posts > .elementor-widget-container > .elementor-button-wrapper .e-load-more-spinner, .elementor-widget-posts > .elementor-widget-container > .elementor-button-wrapper .e-load-more-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: inherit
}

.elementor-widget-archive-posts > .elementor-widget-container > .elementor-button-wrapper .elementor-button, .elementor-widget-posts > .elementor-widget-container > .elementor-button-wrapper .elementor-button {
    cursor: pointer;
    position: relative
}
