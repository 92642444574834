.elementor-14 .elementor-element.elementor-element-4da739e9 iframe {
    height: 400px;
}

.elementor-14 .elementor-element.elementor-element-73979b34 .elementor-icon-list-icon i {
    transition: color 0.3s;
}

.elementor-14 .elementor-element.elementor-element-73979b34 .elementor-icon-list-icon svg {
    transition: fill 0.3s;
}

.elementor-14 .elementor-element.elementor-element-73979b34 {
    --e-icon-list-icon-size: 21px;
    --icon-vertical-offset: 0px;
}

.elementor-14 .elementor-element.elementor-element-73979b34 .elementor-icon-list-icon {
    padding-right: 16px;
}

.elementor-14 .elementor-element.elementor-element-73979b34 .elementor-icon-list-item > a {
    font-size: 21px;
}

.elementor-14 .elementor-element.elementor-element-2f2d3df2:not(.elementor-motion-effects-element-type-background) {
    background-color: #000000;
}

.elementor-14 .elementor-element.elementor-element-2f2d3df2 > .elementor-background-overlay {
    background-image: url("../../images/engine-work-2.jpeg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-14 .elementor-element.elementor-element-2f2d3df2 {
    border-style: solid;
    border-width: 0 0 0.1px 0;
    border-color: #FFFFFF;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 5% 0 5% 0;
}

.elementor-14 .elementor-element.elementor-element-27318cba {
    text-align: left;
}

.elementor-14 .elementor-element.elementor-element-27318cba img {
    width: 65%;
}

.elementor-14 .elementor-element.elementor-element-6f764a27 {
    --grid-template-columns: repeat(0, auto);
    --grid-column-gap: 5px;
    --grid-row-gap: 0px;
}

.elementor-14 .elementor-element.elementor-element-6f764a27 .elementor-widget-container {
    text-align: left;
}

.elementor-14 .elementor-element.elementor-element-6f764a27 .elementor-social-icon {
    background-color: #FFFFFF00;
}

.elementor-14 .elementor-element.elementor-element-6f764a27 .elementor-social-icon i {
    color: #FFFFFF;
}

.elementor-14 .elementor-element.elementor-element-6f764a27 .elementor-social-icon svg {
    fill: #FFFFFF;
}

.elementor-14 .elementor-element.elementor-element-6f764a27 .elementor-social-icon:hover i {
    color: #F77F00;
}

.elementor-14 .elementor-element.elementor-element-6f764a27 .elementor-social-icon:hover svg {
    fill: #F77F00;
}

.elementor-14 .elementor-element.elementor-element-418892f0 > .elementor-element-populated {
    padding: 10% 10% 10% 10%;
}

.elementor-14 .elementor-element.elementor-element-1834f1ac {
    --divider-border-style: parallelogram;
    --divider-color: #F77F00;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
}

.elementor-14 .elementor-element.elementor-element-1834f1ac .elementor-divider-separator {
    width: 100px;
}

.elementor-14 .elementor-element.elementor-element-1834f1ac .elementor-divider {
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-14 .elementor-element.elementor-element-3b1d8fe1 > .elementor-element-populated {
    padding: 10% 10% 10% 10%;
}

.elementor-14 .elementor-element.elementor-element-19514636:not(.elementor-motion-effects-element-type-background) {
    background-color: #000000;
}

.elementor-14 .elementor-element.elementor-element-19514636 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-14 .elementor-element.elementor-element-19514636 > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-14 .elementor-element.elementor-element-21172d53 {
    text-align: center;
    font-size: 14px;
}

@media (max-width: 767px) {
    .elementor-14 .elementor-element.elementor-element-27318cba {
        text-align: center;
    }

    .elementor-14 .elementor-element.elementor-element-4fd2e109 {
        text-align: center;
    }

    .elementor-14 .elementor-element.elementor-element-6f764a27 .elementor-widget-container {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .elementor-14 .elementor-element.elementor-element-36712176 {
        width: 40%;
    }

    .elementor-14 .elementor-element.elementor-element-418892f0 {
        width: 29.664%;
    }

    .elementor-14 .elementor-element.elementor-element-3b1d8fe1 {
        width: 30%;
    }
}
