html, body, #root {
    height: 100%;
    background: #000;
}

html {
    max-width: 1400px;
    margin: 0 auto;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}