/*! elementor-pro - v3.15.0 - 09-08-2023 */
.elementor-skin-carousel .elementor-main-swiper, .elementor-skin-coverflow .elementor-main-swiper {
    height: 230px
}

.elementor-carousel-image {
    background: no-repeat 50%;
    background-size: cover;
    height: 100%;
    position: relative
}

.elementor-testimonial__image {
    flex-shrink: 0
}

.elementor-testimonial__image img {
    border-radius: 999px;
    box-sizing: content-box;
    -o-object-fit: cover;
    object-fit: cover
}

.elementor-testimonial__footer, .elementor-testimonial__header {
    display: flex
}

.elementor-testimonial__footer cite, .elementor-testimonial__header cite {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-size: 14px;
    line-height: 1.5
}

.elementor-testimonial__name {
    font-weight: 700
}

.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
    align-items: center
}

.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
    margin-left: 20px
}

.elementor-testimonial--layout-image_above.elementor-testimonial--align-right .elementor-testimonial__footer, .elementor-testimonial--layout-image_inline.elementor-testimonial--align-right .elementor-testimonial__footer {
    flex-direction: row-reverse
}

.elementor-testimonial--layout-image_above.elementor-testimonial--align-right .elementor-testimonial__image + cite, .elementor-testimonial--layout-image_inline.elementor-testimonial--align-right .elementor-testimonial__image + cite {
    margin-right: 20px;
    margin-left: 0
}

.elementor-testimonial--layout-image_above.elementor-testimonial--align-left .elementor-testimonial__footer, .elementor-testimonial--layout-image_inline.elementor-testimonial--align-left .elementor-testimonial__footer {
    flex-direction: row
}

.elementor-testimonial--layout-image_above.elementor-testimonial--align-left .elementor-testimonial__image + cite, .elementor-testimonial--layout-image_inline.elementor-testimonial--align-left .elementor-testimonial__image + cite {
    margin-right: 0;
    margin-left: 20px
}

.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
    margin-top: 25px
}

.elementor-testimonial--layout-image_above .elementor-testimonial {
    display: flex;
    flex-direction: column-reverse
}

.elementor-testimonial--layout-image_above .elementor-testimonial__footer {
    margin-bottom: 25px
}

.elementor-testimonial--layout-image_stacked .elementor-testimonial__footer {
    margin-top: 25px;
    flex-wrap: wrap
}

.elementor-testimonial--layout-image_stacked .elementor-testimonial__image, .elementor-testimonial--layout-image_stacked .elementor-testimonial cite {
    margin-top: 15px;
    width: 100%
}

.elementor-testimonial--layout-image_left .elementor-testimonial, .elementor-testimonial--layout-image_right .elementor-testimonial {
    display: flex;
    align-items: stretch
}

.elementor-testimonial--layout-image_left .elementor-testimonial__footer, .elementor-testimonial--layout-image_right .elementor-testimonial__footer {
    flex-shrink: 0;
    flex-direction: column;
    text-align: center
}

.elementor-testimonial--layout-image_left .elementor-testimonial__image + cite, .elementor-testimonial--layout-image_right .elementor-testimonial__image + cite {
    margin-top: 15px
}

.elementor-testimonial--layout-image_left.elementor-testimonial--align-center .elementor-testimonial__footer, .elementor-testimonial--layout-image_right.elementor-testimonial--align-center .elementor-testimonial__footer {
    justify-content: flex-start
}

.elementor-testimonial--layout-image_left .elementor-testimonial {
    flex-direction: row-reverse
}

.elementor-testimonial--layout-image_left .elementor-testimonial__footer {
    padding-right: 20px
}

.elementor-testimonial--layout-image_right .elementor-testimonial {
    flex-direction: row
}

.elementor-testimonial--layout-image_right .elementor-testimonial__footer {
    padding-left: 20px
}

.elementor-testimonial--align-right .elementor-testimonial {
    text-align: right
}

.elementor-testimonial--align-right.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
    flex-direction: row-reverse;
    justify-content: end
}

.elementor-testimonial--align-right.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
    margin-right: 20px;
    margin-left: 0
}

.elementor-testimonial--align-left .elementor-testimonial {
    text-align: left
}

.elementor-testimonial--align-left.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
    flex-direction: row;
    justify-content: start
}

.elementor-testimonial--align-left.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
    margin-right: 0;
    margin-left: 20px
}

.elementor-testimonial--align-center .elementor-testimonial {
    text-align: center
}

.elementor-testimonial--align-center.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
    justify-content: center
}

.elementor-testimonial--align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
    text-align: left
}

.elementor-testimonial--skin-default.elementor-testimonial--layout-image_above .elementor-testimonial__cite, .elementor-testimonial--skin-default.elementor-testimonial--layout-image_left .elementor-testimonial__cite, .elementor-testimonial--skin-default.elementor-testimonial--layout-image_right .elementor-testimonial__cite {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25px
}

.elementor-testimonial--skin-bubble .elementor-testimonial__content {
    background-color: #f9fafa;
    padding: 20px;
    position: relative
}

.elementor-testimonial--skin-bubble .elementor-testimonial__content:after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-color: #f9fafa;
    position: absolute;
    transform: scaleX(.75) rotate(45deg);
    border-width: 0;
    box-sizing: content-box
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above .elementor-testimonial__content:after {
    bottom: calc(100% - 7px);
    transform: scaleX(.75) rotate(-135deg)
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above.elementor-testimonial--align-center .elementor-testimonial__content:after {
    left: 50%;
    transform: translateX(-50%) scaleX(.75) rotate(-135deg)
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_inline .elementor-testimonial__content:after, .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_stacked .elementor-testimonial__content:after {
    top: calc(100% - 7px)
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_inline.elementor-testimonial--align-center .elementor-testimonial__content:after, .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-center .elementor-testimonial__content:after {
    left: 50%;
    transform: translateX(-50%) scaleX(.75) rotate(45deg)
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above.elementor-testimonial--align-left .elementor-testimonial__content:after, .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_inline.elementor-testimonial--align-left .elementor-testimonial__content:after, .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-left .elementor-testimonial__content:after {
    left: calc(20px + (50px / 2))
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_above.elementor-testimonial--align-right .elementor-testimonial__content:after, .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_inline.elementor-testimonial--align-right .elementor-testimonial__content:after, .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-right .elementor-testimonial__content:after {
    right: calc(20px + (50px / 2))
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_left .elementor-testimonial__content:after {
    transform: scaleY(.75) rotate(135deg);
    left: -8px
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_right .elementor-testimonial__content:after {
    transform: scaleY(.75) rotate(-45deg);
    right: -8px
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_left .elementor-testimonial__content:after, .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_right .elementor-testimonial__content:after {
    top: calc(20px + (50px / 2))
}

.elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_left .elementor-testimonial__footer, .elementor-testimonial--skin-bubble.elementor-testimonial--layout-image_right .elementor-testimonial__footer {
    padding-top: 20px
}

@media (min-width: -1) {
    .elementor-testimonial--widescreen-align-right .elementor-testimonial {
        text-align: right
    }

    .elementor-testimonial--widescreen-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--widescreen-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end
    }

    .elementor-testimonial--widescreen-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--widescreen-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 20px;
        margin-left: 0
    }

    .elementor-testimonial--widescreen-align-left .elementor-testimonial {
        text-align: left
    }

    .elementor-testimonial--widescreen-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--widescreen-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start
    }

    .elementor-testimonial--widescreen-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--widescreen-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 0;
        margin-left: 20px
    }

    .elementor-testimonial--widescreen-align-center .elementor-testimonial {
        text-align: center
    }

    .elementor-testimonial--widescreen-align-center.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--widescreen-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        justify-content: center
    }

    .elementor-testimonial--widescreen-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        text-align: left
    }
}

@media (max-width: -1) {
    .elementor-testimonial--laptop-align-right .elementor-testimonial {
        text-align: right
    }

    .elementor-testimonial--laptop-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--laptop-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end
    }

    .elementor-testimonial--laptop-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--laptop-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 20px;
        margin-left: 0
    }

    .elementor-testimonial--laptop-align-left .elementor-testimonial {
        text-align: left
    }

    .elementor-testimonial--laptop-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--laptop-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start
    }

    .elementor-testimonial--laptop-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--laptop-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 0;
        margin-left: 20px
    }

    .elementor-testimonial--laptop-align-center .elementor-testimonial {
        text-align: center
    }

    .elementor-testimonial--laptop-align-center.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--laptop-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        justify-content: center
    }

    .elementor-testimonial--laptop-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        text-align: left
    }
}

@media (max-width: -1) {
    .elementor-testimonial--tablet_extra-align-right .elementor-testimonial {
        text-align: right
    }

    .elementor-testimonial--tablet_extra-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--tablet_extra-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end
    }

    .elementor-testimonial--tablet_extra-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--tablet_extra-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 20px;
        margin-left: 0
    }

    .elementor-testimonial--tablet_extra-align-left .elementor-testimonial {
        text-align: left
    }

    .elementor-testimonial--tablet_extra-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--tablet_extra-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start
    }

    .elementor-testimonial--tablet_extra-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--tablet_extra-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 0;
        margin-left: 20px
    }

    .elementor-testimonial--tablet_extra-align-center .elementor-testimonial {
        text-align: center
    }

    .elementor-testimonial--tablet_extra-align-center.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--tablet_extra-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        justify-content: center
    }

    .elementor-testimonial--tablet_extra-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        text-align: left
    }
}

@media (max-width: 1024px) {
    .elementor-testimonial--tablet-align-right .elementor-testimonial {
        text-align: right
    }

    .elementor-testimonial--tablet-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--tablet-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end
    }

    .elementor-testimonial--tablet-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--tablet-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 20px;
        margin-left: 0
    }

    .elementor-testimonial--tablet-align-left .elementor-testimonial {
        text-align: left
    }

    .elementor-testimonial--tablet-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--tablet-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start
    }

    .elementor-testimonial--tablet-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--tablet-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 0;
        margin-left: 20px
    }

    .elementor-testimonial--tablet-align-center .elementor-testimonial {
        text-align: center
    }

    .elementor-testimonial--tablet-align-center.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--tablet-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        justify-content: center
    }

    .elementor-testimonial--tablet-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        text-align: left
    }
}

@media (max-width: -1) {
    .elementor-testimonial--mobile_extra-align-right .elementor-testimonial {
        text-align: right
    }

    .elementor-testimonial--mobile_extra-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--mobile_extra-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end
    }

    .elementor-testimonial--mobile_extra-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--mobile_extra-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 20px;
        margin-left: 0
    }

    .elementor-testimonial--mobile_extra-align-left .elementor-testimonial {
        text-align: left
    }

    .elementor-testimonial--mobile_extra-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--mobile_extra-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start
    }

    .elementor-testimonial--mobile_extra-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--mobile_extra-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 0;
        margin-left: 20px
    }

    .elementor-testimonial--mobile_extra-align-center .elementor-testimonial {
        text-align: center
    }

    .elementor-testimonial--mobile_extra-align-center.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--mobile_extra-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        justify-content: center
    }

    .elementor-testimonial--mobile_extra-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        text-align: left
    }
}

@media (max-width: 767px) {
    .elementor-testimonial--mobile-align-right .elementor-testimonial {
        text-align: right
    }

    .elementor-testimonial--mobile-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--mobile-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row-reverse;
        justify-content: end
    }

    .elementor-testimonial--mobile-align-right.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--mobile-align-right.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 20px;
        margin-left: 0
    }

    .elementor-testimonial--mobile-align-left .elementor-testimonial {
        text-align: left
    }

    .elementor-testimonial--mobile-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--mobile-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        flex-direction: row;
        justify-content: start
    }

    .elementor-testimonial--mobile-align-left.elementor-testimonial--layout-image_above .elementor-testimonial__image + cite, .elementor-testimonial--mobile-align-left.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        margin-right: 0;
        margin-left: 20px
    }

    .elementor-testimonial--mobile-align-center .elementor-testimonial {
        text-align: center
    }

    .elementor-testimonial--mobile-align-center.elementor-testimonial--layout-image_above .elementor-testimonial__footer, .elementor-testimonial--mobile-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__footer {
        justify-content: center
    }

    .elementor-testimonial--mobile-align-center.elementor-testimonial--layout-image_inline .elementor-testimonial__image + cite {
        text-align: left
    }
}

.elementor-widget-testimonial-carousel .swiper-slide {
    padding: 20px
}

.elementor-widget-testimonial-carousel .elementor-testimonial__text {
    font-size: 1.3em;
    font-style: italic;
    line-height: 1.5
}

.elementor-widget-testimonial-carousel .elementor-testimonial__image img {
    width: 50px;
    height: 50px
}

.elementor-widget-reviews .swiper-slide {
    background-color: #fff;
    border: 1px solid #e1e8ed;
    border-radius: 5px;
    padding: 0
}

.elementor-widget-reviews .elementor-testimonial__text {
    font-size: 14px;
    line-height: 20px
}

.elementor-widget-reviews .elementor-testimonial__name {
    color: #1c2022
}

.elementor-widget-reviews .elementor-testimonial__title {
    color: #697882;
    font-size: 12.5px;
    font-weight: 400;
    font-family: inherit
}

.elementor-widget-reviews .elementor-testimonial__image {
    display: flex
}

.elementor-widget-reviews .elementor-testimonial__image img {
    width: 36px;
    height: 36px
}

.elementor-widget-reviews .elementor-testimonial__icon {
    margin-left: auto;
    font-size: 17px
}

.elementor-widget-reviews .elementor-testimonial__icon .fa + .fa {
    margin-left: .15em
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon {
    color: var(--e-testimonial-icon-color)
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-android {
    --e-testimonial-icon-color: #a4c639
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-apple {
    --e-testimonial-icon-color: #999
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-behance {
    --e-testimonial-icon-color: #1769ff
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-bitbucket {
    --e-testimonial-icon-color: #205081
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-codepen {
    --e-testimonial-icon-color: #000
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-delicious {
    --e-testimonial-icon-color: #39f
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-digg {
    --e-testimonial-icon-color: #005be2
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-dribbble {
    --e-testimonial-icon-color: #ea4c89
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-envelope {
    --e-testimonial-icon-color: #ea4335
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-facebook {
    --e-testimonial-icon-color: #3b5998
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-flickr {
    --e-testimonial-icon-color: #0063dc
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-foursquare {
    --e-testimonial-icon-color: #2d5be3
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-github {
    --e-testimonial-icon-color: #333
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-google-plus {
    --e-testimonial-icon-color: #dd4b39
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-houzz {
    --e-testimonial-icon-color: #7ac142
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-instagram {
    --e-testimonial-icon-color: #262626
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-jsfiddle {
    --e-testimonial-icon-color: #487aa2
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-linkedin {
    --e-testimonial-icon-color: #0077b5
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-medium {
    --e-testimonial-icon-color: #00ab6b
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-meetup {
    --e-testimonial-icon-color: #ec1c40
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-mixcloud {
    --e-testimonial-icon-color: #273a4b
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-odnoklassniki {
    --e-testimonial-icon-color: #f4731c
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-pinterest {
    --e-testimonial-icon-color: #bd081c
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-product-hunt {
    --e-testimonial-icon-color: #da552f
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-reddit {
    --e-testimonial-icon-color: #ff4500
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-rss {
    --e-testimonial-icon-color: #f26522
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-shopping-cart {
    --e-testimonial-icon-color: #4caf50
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-skype {
    --e-testimonial-icon-color: #00aff0
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-slideshare {
    --e-testimonial-icon-color: #0077b5
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-snapchat {
    --e-testimonial-icon-color: #fffc00
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-soundcloud {
    --e-testimonial-icon-color: #f80
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-spotify {
    --e-testimonial-icon-color: #2ebd59
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-stack-overflow {
    --e-testimonial-icon-color: #fe7a15
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-steam {
    --e-testimonial-icon-color: #00adee
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-stumbleupon {
    --e-testimonial-icon-color: #eb4924
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-telegram {
    --e-testimonial-icon-color: #2ca5e0
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-thumb-tack, .elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-thumbtack {
    --e-testimonial-icon-color: #1aa1d8
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-tripadvisor {
    --e-testimonial-icon-color: #589442
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-tumblr {
    --e-testimonial-icon-color: #35465c
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-twitch {
    --e-testimonial-icon-color: #6441a5
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-twitter {
    --e-testimonial-icon-color: #1da1f2
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-vimeo {
    --e-testimonial-icon-color: #1ab7ea
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-vk {
    --e-testimonial-icon-color: #45668e
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-weibo {
    --e-testimonial-icon-color: #dd2430
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-weixin {
    --e-testimonial-icon-color: #31a918
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-whatsapp {
    --e-testimonial-icon-color: #25d366
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-wordpress {
    --e-testimonial-icon-color: #21759b
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-xing {
    --e-testimonial-icon-color: #026466
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-yelp {
    --e-testimonial-icon-color: #af0606
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-youtube {
    --e-testimonial-icon-color: #cd201f
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon-500px {
    --e-testimonial-icon-color: #0099e5
}

.elementor-widget-reviews .elementor-testimonial__icon.elementor-icon svg {
    fill: var(--e-testimonial-icon-color)
}

.elementor-widget-reviews .elementor-testimonial__rating {
    background-image: linear-gradient(90deg, #ffc82c var(--rating), #ccc var(--rating));
    font-family: eicons
}

.elementor-widget-reviews .elementor-testimonial__header {
    display: flex;
    padding-top: 15px;
    padding-bottom: 6px
}

.elementor-widget-reviews .elementor-testimonial__image + cite {
    margin-left: 9px
}

.elementor-widget-reviews .elementor-testimonial__separator {
    margin: 0 auto;
    border-top: 0;
    border-bottom: 1px solid #e1e8ed;
    height: 0
}

.elementor-widget-reviews .elementor-testimonial__content {
    padding-top: 6px;
    padding-bottom: 15px
}

.elementor-widget-reviews .elementor-testimonial__content, .elementor-widget-reviews .elementor-testimonial__header {
    padding-left: 15px;
    padding-right: 15px
}

.elementor-widget-reviews.elementor-review--has-separator .elementor-testimonial__header {
    border-bottom: 1px solid #e1e8ed
}

.swiper-pagination-bullets {
    color: #fff;
    cursor: default
}

.swiper-slide {
    will-change: transform;
    border-style: solid;
    border-width: 0;
    transition-duration: .5s;
    transition-property: border, background, transform;
    overflow: hidden
}

.swiper-slide:not(:hover) .e-overlay-animation-fade {
    opacity: 0
}

.swiper-slide:not(:hover) .e-overlay-animation-slide-up {
    transform: translateY(100%)
}

.swiper-slide:not(:hover) .e-overlay-animation-slide-down {
    transform: translateY(-100%)
}

.swiper-slide:not(:hover) .e-overlay-animation-slide-right {
    transform: translateX(-100%)
}

.swiper-slide:not(:hover) .e-overlay-animation-slide-left {
    transform: translateX(100%)
}

.swiper-slide:not(:hover) .e-overlay-animation-zoom-in {
    transform: scale(.5);
    opacity: 0
}

.swiper-slide a {
    display: inline
}

.swiper-slide a.elementor-button {
    display: inline-block
}

.elementor-widget-reviews .elementor-arrows-yes .elementor-main-swiper, .elementor-widget-testimonial-carousel .elementor-arrows-yes .elementor-main-swiper {
    width: calc(95% - 40px)
}

.elementor-widget-reviews .elementor-main-swiper, .elementor-widget-testimonial-carousel .elementor-main-swiper {
    width: 95%
}

.elementor-widget-reviews .swiper-wrapper, .elementor-widget-testimonial-carousel .swiper-wrapper {
    display: flex;
    align-items: stretch
}

.elementor-widget-reviews .swiper-slide, .elementor-widget-testimonial-carousel .swiper-slide {
    height: auto
}

.elementor-widget-reviews.elementor-pagination-type-bullets .swiper, .elementor-widget-reviews.elementor-pagination-type-bullets .swiper-container, .elementor-widget-reviews.elementor-pagination-type-fraction .swiper, .elementor-widget-reviews.elementor-pagination-type-fraction .swiper-container, .elementor-widget-testimonial-carousel.elementor-pagination-type-bullets .swiper, .elementor-widget-testimonial-carousel.elementor-pagination-type-bullets .swiper-container, .elementor-widget-testimonial-carousel.elementor-pagination-type-fraction .swiper, .elementor-widget-testimonial-carousel.elementor-pagination-type-fraction .swiper-container {
    padding-bottom: 40px
}

.elementor-widget-reviews .elementor-swiper-button-prev, .elementor-widget-reviews .swiper-container-rtl .elementor-swiper-button-next, .elementor-widget-reviews .swiper-rtl .elementor-swiper-button-next, .elementor-widget-testimonial-carousel .elementor-swiper-button-prev, .elementor-widget-testimonial-carousel .swiper-container-rtl .elementor-swiper-button-next, .elementor-widget-testimonial-carousel .swiper-rtl .elementor-swiper-button-next {
    left: 0;
    right: unset
}

.elementor-widget-reviews .elementor-swiper-button-next, .elementor-widget-reviews .swiper-container-rtl .elementor-swiper-button-prev, .elementor-widget-reviews .swiper-rtl .elementor-swiper-button-prev, .elementor-widget-testimonial-carousel .elementor-swiper-button-next, .elementor-widget-testimonial-carousel .swiper-container-rtl .elementor-swiper-button-prev, .elementor-widget-testimonial-carousel .swiper-rtl .elementor-swiper-button-prev {
    right: 0;
    left: unset
}

.elementor-widget-reviews.elementor-pagination-type-bullets .elementor-swiper-button-next, .elementor-widget-reviews.elementor-pagination-type-bullets .elementor-swiper-button-prev, .elementor-widget-testimonial-carousel.elementor-pagination-type-bullets .elementor-swiper-button-next, .elementor-widget-testimonial-carousel.elementor-pagination-type-bullets .elementor-swiper-button-prev {
    top: calc(50% - (40px / 2))
}

.elementor-widget-media-carousel .elementor-swiper-button-prev, .elementor-widget-media-carousel .swiper-container-rtl .elementor-swiper-button-next, .elementor-widget-media-carousel .swiper-rtl .elementor-swiper-button-next {
    left: 10px;
    right: unset
}

.elementor-widget-media-carousel .elementor-swiper-button-next, .elementor-widget-media-carousel .swiper-container-rtl .elementor-swiper-button-prev, .elementor-widget-media-carousel .swiper-rtl .elementor-swiper-button-prev {
    right: 10px;
    left: unset
}
