/*! elementor - v3.15.0 - 09-08-2023 */
.elementor-widget.elementor-icon-list--layout-inline .elementor-widget-container {
    overflow: hidden
}

.elementor-widget .elementor-icon-list-item {
    margin: 0;
    padding: 0;
    position: relative
}

.elementor-widget .elementor-icon-list-item:after {
    position: absolute;
    bottom: 0;
    width: 100%
}

.elementor-widget .elementor-icon-list-item, .elementor-widget .elementor-icon-list-item a {
    display: flex;
    font-size: inherit;
    align-items: var(--icon-vertical-align, center)
}

.elementor-widget .elementor-icon-list-icon {
    display: flex;
    position: relative;
    top: var(--icon-vertical-offset, initial)
}

.elementor-widget .elementor-icon-list-icon svg {
    width: var(--e-icon-list-icon-size, 1em);
    height: var(--e-icon-list-icon-size, 1em)
}

.elementor-widget .elementor-icon-list-icon i {
    width: 1.25em;
    font-size: var(--e-icon-list-icon-size)
}

.elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon {
    text-align: var(--e-icon-list-icon-align)
}

.elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon svg {
    margin: var(--e-icon-list-icon-margin, 0 calc(var(--e-icon-list-icon-size, 1em) * .25) 0 0)
}

.elementor-widget.elementor-list-item-link-full_width a {
    width: 100%
}

.elementor-widget.elementor-align-center .elementor-icon-list-item, .elementor-widget.elementor-align-center .elementor-icon-list-item a {
    justify-content: center
}

.elementor-widget.elementor-align-center .elementor-icon-list-item:after {
    margin: auto
}

.elementor-widget.elementor-align-center .elementor-inline-items {
    justify-content: center
}

.elementor-widget.elementor-align-left .elementor-icon-list-item, .elementor-widget.elementor-align-left .elementor-icon-list-item a {
    justify-content: flex-start;
    text-align: left
}

.elementor-widget.elementor-align-left .elementor-inline-items {
    justify-content: flex-start
}

.elementor-widget.elementor-align-right .elementor-icon-list-item, .elementor-widget.elementor-align-right .elementor-icon-list-item a {
    justify-content: flex-end;
    text-align: right
}

.elementor-widget:not(.elementor-align-right) .elementor-icon-list-item:after {
    left: 0
}

.elementor-widget:not(.elementor-align-left) .elementor-icon-list-item:after {
    right: 0
}

@media (min-width: -1) {
    .elementor-widget.elementor-widescreen-align-center .elementor-icon-list-item, .elementor-widget.elementor-widescreen-align-center .elementor-icon-list-item a {
        justify-content: center
    }

    .elementor-widget.elementor-widescreen-align-center .elementor-icon-list-item:after {
        margin: auto
    }

    .elementor-widget.elementor-widescreen-align-center .elementor-inline-items {
        justify-content: center
    }

    .elementor-widget.elementor-widescreen-align-left .elementor-icon-list-item, .elementor-widget.elementor-widescreen-align-left .elementor-icon-list-item a {
        justify-content: flex-start;
        text-align: left
    }

    .elementor-widget.elementor-widescreen-align-left .elementor-inline-items {
        justify-content: flex-start
    }

    .elementor-widget.elementor-widescreen-align-right .elementor-icon-list-item, .elementor-widget.elementor-widescreen-align-right .elementor-icon-list-item a {
        justify-content: flex-end;
        text-align: right
    }

    .elementor-widget:not(.elementor-widescreen-align-right) .elementor-icon-list-item:after {
        left: 0
    }

    .elementor-widget:not(.elementor-widescreen-align-left) .elementor-icon-list-item:after {
        right: 0
    }
}

@media (max-width: 767px) {
    .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item, .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item a {
        justify-content: center
    }

    .elementor-widget.elementor-mobile-align-center .elementor-icon-list-item:after {
        margin: auto
    }

    .elementor-widget.elementor-mobile-align-center .elementor-inline-items {
        justify-content: center
    }

    .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item, .elementor-widget.elementor-mobile-align-left .elementor-icon-list-item a {
        justify-content: flex-start;
        text-align: left
    }

    .elementor-widget.elementor-mobile-align-left .elementor-inline-items {
        justify-content: flex-start
    }

    .elementor-widget.elementor-mobile-align-right .elementor-icon-list-item, .elementor-widget.elementor-mobile-align-right .elementor-icon-list-item a {
        justify-content: flex-end;
        text-align: right
    }

    .elementor-widget:not(.elementor-mobile-align-right) .elementor-icon-list-item:after {
        left: 0
    }

    .elementor-widget:not(.elementor-mobile-align-left) .elementor-icon-list-item:after {
        right: 0
    }
}
