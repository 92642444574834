.navbar-brand {
    align-items: center;
    display: flex;
}

.navbar-brand img {
    margin-right: 10px;
}

.navbar[data-bs-theme="dark"] {
    z-index: 10000;
    background: #000000;
}

.nav-link {
    color: #FFFFFF;
    font-family: "Montserrat", Sans-serif;
    font-size: 14px;
    font-weight: 700;
}
