/*! elementor - v3.15.0 - 09-08-2023 */
.dialog-widget-content {
    background-color: var(--e-a-bg-default);
    position: absolute;
    border-radius: 3px;
    box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.elementor-screen-only,
.screen-reader-text,
.screen-reader-text span,
.ui-helper-hidden-accessible {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.elementor-clearfix:after {
    content: "";
    display: block;
    clear: both;
    width: 0;
    height: 0;
}

.e-logo-wrapper {
    background: var(--e-a-bg-logo);
    display: inline-block;
    padding: 0.75em;
    border-radius: 50%;
    line-height: 1;
}
.e-logo-wrapper i {
    color: var(--e-a-color-logo);
    font-size: 1em;
}

.elementor *, .elementor *:before, .elementor *:after {
    box-sizing: border-box;
}
.elementor a {
    box-shadow: none;
    text-decoration: none;
}
.elementor hr {
    margin: 0;
    background-color: transparent;
}
.elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}
.elementor .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure {
    margin: 0;
}
.elementor embed,
.elementor iframe,
.elementor object,
.elementor video {
    max-width: 100%;
    width: 100%;
    margin: 0;
    line-height: 1;
    border: none;
}
.elementor .elementor-background-video-container,
.elementor .elementor-background-holder,
.elementor .elementor-background {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
}
.elementor .elementor-background-video-container {
    transition: opacity 1s;
    pointer-events: none;
}
.elementor .elementor-background-video-container.elementor-loading {
    opacity: 0;
}
.elementor .elementor-background-video-embed {
    max-width: none;
}
.elementor .elementor-background-video-embed, .elementor .elementor-background-video-hosted {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.elementor .elementor-background-video {
    max-width: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.elementor .elementor-html5-video {
    -o-object-fit: cover;
    object-fit: cover;
}
.elementor .elementor-background-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.elementor .elementor-background-slideshow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
}
.elementor .elementor-background-slideshow__slide__image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}

.elementor-widget-wrap > .elementor-element.elementor-absolute,
.e-con > .elementor-element.elementor-absolute,
.e-con-inner > .elementor-element.elementor-absolute {
    position: absolute;
}
.elementor-widget-wrap > .elementor-element.elementor-fixed,
.e-con > .elementor-element.elementor-fixed,
.e-con-inner > .elementor-element.elementor-fixed {
    position: fixed;
}

.elementor-widget-wrap .elementor-element.elementor-widget__width-auto, .elementor-widget-wrap .elementor-element.elementor-widget__width-initial {
    max-width: 100%;
}
@media (max-width: 1024px) {
    .elementor-widget-wrap .elementor-element.elementor-widget-tablet__width-auto, .elementor-widget-wrap .elementor-element.elementor-widget-tablet__width-initial {
        max-width: 100%;
    }
}
@media (max-width: 767px) {
    .elementor-widget-wrap .elementor-element.elementor-widget-mobile__width-auto, .elementor-widget-wrap .elementor-element.elementor-widget-mobile__width-initial {
        max-width: 100%;
    }
}

.elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self);
}
.elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap);
}

.elementor-invisible {
    visibility: hidden;
}

.elementor-align-center {
    text-align: center;
}
.elementor-align-center .elementor-button {
    width: auto;
}
.elementor-align-right {
    text-align: right;
}
.elementor-align-right .elementor-button {
    width: auto;
}
.elementor-align-left {
    text-align: left;
}
.elementor-align-left .elementor-button {
    width: auto;
}
.elementor-align-justify .elementor-button {
    width: 100%;
}

.elementor-custom-embed-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.elementor-custom-embed-play i {
    font-size: 100px;
    color: #fff;
    text-shadow: 1px 0 6px rgba(0, 0, 0, 0.3);
}
.elementor-custom-embed-play svg {
    height: 100px;
    width: 100px;
    fill: #fff;
    filter: drop-shadow(1px 0 6px rgba(0, 0, 0, 0.3));
}
.elementor-custom-embed-play i,
.elementor-custom-embed-play svg {
    opacity: 0.8;
    transition: all 0.5s;
}
.elementor-custom-embed-play.elementor-playing i {
    font-family: eicons;
}
.elementor-custom-embed-play.elementor-playing i:before {
    content: "\e8fb";
}
.elementor-custom-embed-play.elementor-playing i,
.elementor-custom-embed-play.elementor-playing svg {
    animation: eicon-spin 2s infinite linear;
}

.elementor-tag {
    display: inline-flex;
}

.elementor-ken-burns {
    transition-property: transform;
    transition-duration: 10s;
    transition-timing-function: linear;
}
.elementor-ken-burns--out {
    transform: scale(1.3);
}
.elementor-ken-burns--active {
    transition-duration: 20s;
}
.elementor-ken-burns--active.elementor-ken-burns--out {
    transform: scale(1);
}
.elementor-ken-burns--active.elementor-ken-burns--in {
    transform: scale(1.3);
}

@media (min-width: -1) {
    .elementor-widescreen-align-center {
        text-align: center;
    }
    .elementor-widescreen-align-center .elementor-button {
        width: auto;
    }
    .elementor-widescreen-align-right {
        text-align: right;
    }
    .elementor-widescreen-align-right .elementor-button {
        width: auto;
    }
    .elementor-widescreen-align-left {
        text-align: left;
    }
    .elementor-widescreen-align-left .elementor-button {
        width: auto;
    }
    .elementor-widescreen-align-justify .elementor-button {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .elementor-mobile-align-center {
        text-align: center;
    }
    .elementor-mobile-align-center .elementor-button {
        width: auto;
    }
    .elementor-mobile-align-right {
        text-align: right;
    }
    .elementor-mobile-align-right .elementor-button {
        width: auto;
    }
    .elementor-mobile-align-left {
        text-align: left;
    }
    .elementor-mobile-align-left .elementor-button {
        width: auto;
    }
    .elementor-mobile-align-justify .elementor-button {
        width: 100%;
    }
}
:root {
    --page-title-display: block;
}

.elementor-page-title, h1.entry-title {
    display: var(--page-title-display);
}

@keyframes eicon-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
.eicon-animation-spin {
    animation: eicon-spin 2s infinite linear;
}

.elementor-section {
    position: relative;
}
.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}
@media (max-width: 1024px) {
    .elementor-section .elementor-container {
        flex-wrap: wrap;
    }
}
.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
}
.elementor-section.elementor-section-stretched {
    position: relative;
    width: 100%;
}
.elementor-section.elementor-section-items-top > .elementor-container {
    align-items: flex-start;
}
.elementor-section.elementor-section-items-middle > .elementor-container {
    align-items: center;
}
@media (min-width: 768px) {
    .elementor-section.elementor-section-height-full {
        height: 100vh;
    }
    .elementor-section.elementor-section-height-full > .elementor-container {
        height: 100%;
    }
}

.elementor-bc-flex-widget .elementor-section-content-top > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-items: flex-start;
}
.elementor-bc-flex-widget .elementor-section-content-middle > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-items: center;
}
.elementor-bc-flex-widget .elementor-section-content-bottom > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-items: flex-end;
}

.elementor-row {
    width: 100%;
    display: flex;
}
@media (max-width: 1024px) {
    .elementor-row {
        flex-wrap: wrap;
    }
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
}
.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex;
}
.elementor-widget-wrap > .elementor-element {
    width: 100%;
}
.elementor-widget-wrap.e-swiper-container {
    width: calc(100% - (var(--e-column-margin-left, 0px) + var(--e-column-margin-right, 0px)));
}

.elementor-widget {
    position: relative;
}
.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}
.elementor-widget:not(:last-child).elementor-widget__width-auto, .elementor-widget:not(:last-child).elementor-widget__width-initial, .elementor-widget:not(:last-child).elementor-absolute {
    margin-bottom: 0;
}

.elementor-column {
    position: relative;
    min-height: 1px;
    display: flex;
}

.elementor-column-wrap {
    width: 100%;
    position: relative;
    display: flex;
}

.elementor-column-gap-narrow > .elementor-column > .elementor-element-populated {
    padding: 5px;
}
.elementor-column-gap-default > .elementor-column > .elementor-element-populated {
    padding: 10px;
}
.elementor-column-gap-extended > .elementor-column > .elementor-element-populated {
    padding: 15px;
}
.elementor-column-gap-wide > .elementor-column > .elementor-element-populated {
    padding: 20px;
}
.elementor-column-gap-wider > .elementor-column > .elementor-element-populated {
    padding: 30px;
}

@media (min-width: 768px) {
    .elementor-column.elementor-col-10, .elementor-column[data-col="10"] {
        width: 10%;
    }
    .elementor-column.elementor-col-11, .elementor-column[data-col="11"] {
        width: 11.111%;
    }
    .elementor-column.elementor-col-12, .elementor-column[data-col="12"] {
        width: 12.5%;
    }
    .elementor-column.elementor-col-14, .elementor-column[data-col="14"] {
        width: 14.285%;
    }
    .elementor-column.elementor-col-16, .elementor-column[data-col="16"] {
        width: 16.666%;
    }
    .elementor-column.elementor-col-20, .elementor-column[data-col="20"] {
        width: 20%;
    }
    .elementor-column.elementor-col-25, .elementor-column[data-col="25"] {
        width: 25%;
    }
    .elementor-column.elementor-col-30, .elementor-column[data-col="30"] {
        width: 30%;
    }
    .elementor-column.elementor-col-33, .elementor-column[data-col="33"] {
        width: 33.333%;
    }
    .elementor-column.elementor-col-40, .elementor-column[data-col="40"] {
        width: 40%;
    }
    .elementor-column.elementor-col-50, .elementor-column[data-col="50"] {
        width: 50%;
    }
    .elementor-column.elementor-col-60, .elementor-column[data-col="60"] {
        width: 60%;
    }
    .elementor-column.elementor-col-66, .elementor-column[data-col="66"] {
        width: 66.666%;
    }
    .elementor-column.elementor-col-70, .elementor-column[data-col="70"] {
        width: 70%;
    }
    .elementor-column.elementor-col-75, .elementor-column[data-col="75"] {
        width: 75%;
    }
    .elementor-column.elementor-col-80, .elementor-column[data-col="80"] {
        width: 80%;
    }
    .elementor-column.elementor-col-83, .elementor-column[data-col="83"] {
        width: 83.333%;
    }
    .elementor-column.elementor-col-90, .elementor-column[data-col="90"] {
        width: 90%;
    }
    .elementor-column.elementor-col-100, .elementor-column[data-col="100"] {
        width: 100%;
    }
}
@media (max-width: 479px) {
    .elementor-column.elementor-xs-10 {
        width: 10%;
    }
    .elementor-column.elementor-xs-11 {
        width: 11.111%;
    }
    .elementor-column.elementor-xs-12 {
        width: 12.5%;
    }
    .elementor-column.elementor-xs-14 {
        width: 14.285%;
    }
    .elementor-column.elementor-xs-16 {
        width: 16.666%;
    }
    .elementor-column.elementor-xs-20 {
        width: 20%;
    }
    .elementor-column.elementor-xs-25 {
        width: 25%;
    }
    .elementor-column.elementor-xs-30 {
        width: 30%;
    }
    .elementor-column.elementor-xs-33 {
        width: 33.333%;
    }
    .elementor-column.elementor-xs-40 {
        width: 40%;
    }
    .elementor-column.elementor-xs-50 {
        width: 50%;
    }
    .elementor-column.elementor-xs-60 {
        width: 60%;
    }
    .elementor-column.elementor-xs-66 {
        width: 66.666%;
    }
    .elementor-column.elementor-xs-70 {
        width: 70%;
    }
    .elementor-column.elementor-xs-75 {
        width: 75%;
    }
    .elementor-column.elementor-xs-80 {
        width: 80%;
    }
    .elementor-column.elementor-xs-83 {
        width: 83.333%;
    }
    .elementor-column.elementor-xs-90 {
        width: 90%;
    }
    .elementor-column.elementor-xs-100 {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .elementor-column.elementor-sm-10 {
        width: 10%;
    }
    .elementor-column.elementor-sm-11 {
        width: 11.111%;
    }
    .elementor-column.elementor-sm-12 {
        width: 12.5%;
    }
    .elementor-column.elementor-sm-14 {
        width: 14.285%;
    }
    .elementor-column.elementor-sm-16 {
        width: 16.666%;
    }
    .elementor-column.elementor-sm-20 {
        width: 20%;
    }
    .elementor-column.elementor-sm-25 {
        width: 25%;
    }
    .elementor-column.elementor-sm-30 {
        width: 30%;
    }
    .elementor-column.elementor-sm-33 {
        width: 33.333%;
    }
    .elementor-column.elementor-sm-40 {
        width: 40%;
    }
    .elementor-column.elementor-sm-50 {
        width: 50%;
    }
    .elementor-column.elementor-sm-60 {
        width: 60%;
    }
    .elementor-column.elementor-sm-66 {
        width: 66.666%;
    }
    .elementor-column.elementor-sm-70 {
        width: 70%;
    }
    .elementor-column.elementor-sm-75 {
        width: 75%;
    }
    .elementor-column.elementor-sm-80 {
        width: 80%;
    }
    .elementor-column.elementor-sm-83 {
        width: 83.333%;
    }
    .elementor-column.elementor-sm-90 {
        width: 90%;
    }
    .elementor-column.elementor-sm-100 {
        width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .elementor-column.elementor-md-10 {
        width: 10%;
    }
    .elementor-column.elementor-md-11 {
        width: 11.111%;
    }
    .elementor-column.elementor-md-12 {
        width: 12.5%;
    }
    .elementor-column.elementor-md-14 {
        width: 14.285%;
    }
    .elementor-column.elementor-md-16 {
        width: 16.666%;
    }
    .elementor-column.elementor-md-20 {
        width: 20%;
    }
    .elementor-column.elementor-md-25 {
        width: 25%;
    }
    .elementor-column.elementor-md-30 {
        width: 30%;
    }
    .elementor-column.elementor-md-33 {
        width: 33.333%;
    }
    .elementor-column.elementor-md-40 {
        width: 40%;
    }
    .elementor-column.elementor-md-50 {
        width: 50%;
    }
    .elementor-column.elementor-md-60 {
        width: 60%;
    }
    .elementor-column.elementor-md-66 {
        width: 66.666%;
    }
    .elementor-column.elementor-md-70 {
        width: 70%;
    }
    .elementor-column.elementor-md-75 {
        width: 75%;
    }
    .elementor-column.elementor-md-80 {
        width: 80%;
    }
    .elementor-column.elementor-md-83 {
        width: 83.333%;
    }
    .elementor-column.elementor-md-90 {
        width: 90%;
    }
    .elementor-column.elementor-md-100 {
        width: 100%;
    }
}

@media (min-width: -1) {
    .elementor-reverse-widescreen > .elementor-container > :nth-child(1) {
        order: 10;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(2) {
        order: 9;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(3) {
        order: 8;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(4) {
        order: 7;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(5) {
        order: 6;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(6) {
        order: 5;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(7) {
        order: 4;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(8) {
        order: 3;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(9) {
        order: 2;
    }
    .elementor-reverse-widescreen > .elementor-container > :nth-child(10) {
        order: 1;
    }
}
@media (min-width: 1025px) and (max-width: -1) {
    .elementor-reverse-laptop > .elementor-container > :nth-child(1) {
        order: 10;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(2) {
        order: 9;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(3) {
        order: 8;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(4) {
        order: 7;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(5) {
        order: 6;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(6) {
        order: 5;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(7) {
        order: 4;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(8) {
        order: 3;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(9) {
        order: 2;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
        order: 1;
    }
}
@media (min-width: -1) and (max-width: -1) {
    .elementor-reverse-laptop > .elementor-container > :nth-child(1) {
        order: 10;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(2) {
        order: 9;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(3) {
        order: 8;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(4) {
        order: 7;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(5) {
        order: 6;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(6) {
        order: 5;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(7) {
        order: 4;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(8) {
        order: 3;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(9) {
        order: 2;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
        order: 1;
    }
}
@media (min-width: 1025px) and (max-width: -1) {
    .elementor-reverse-laptop > .elementor-container > :nth-child(1) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(2) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(3) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(4) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(5) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(6) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(7) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(8) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(9) {
        order: initial;
    }
    .elementor-reverse-laptop > .elementor-container > :nth-child(10) {
        order: initial;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(1) {
        order: 10;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(2) {
        order: 9;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(3) {
        order: 8;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(4) {
        order: 7;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(5) {
        order: 6;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(6) {
        order: 5;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(7) {
        order: 4;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(8) {
        order: 3;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(9) {
        order: 2;
    }
    .elementor-reverse-tablet_extra > .elementor-container > :nth-child(10) {
        order: 1;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .elementor-reverse-tablet > .elementor-container > :nth-child(1) {
        order: 10;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(2) {
        order: 9;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(3) {
        order: 8;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(4) {
        order: 7;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(5) {
        order: 6;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(6) {
        order: 5;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(7) {
        order: 4;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(8) {
        order: 3;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(9) {
        order: 2;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
        order: 1;
    }
}
@media (min-width: -1) and (max-width: 1024px) {
    .elementor-reverse-tablet > .elementor-container > :nth-child(1) {
        order: 10;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(2) {
        order: 9;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(3) {
        order: 8;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(4) {
        order: 7;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(5) {
        order: 6;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(6) {
        order: 5;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(7) {
        order: 4;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(8) {
        order: 3;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(9) {
        order: 2;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
        order: 1;
    }
}
@media (min-width: 768px) and (max-width: -1) {
    .elementor-reverse-tablet > .elementor-container > :nth-child(1) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(2) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(3) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(4) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(5) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(6) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(7) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(8) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(9) {
        order: initial;
    }
    .elementor-reverse-tablet > .elementor-container > :nth-child(10) {
        order: initial;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(1) {
        order: 10;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(2) {
        order: 9;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(3) {
        order: 8;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(4) {
        order: 7;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(5) {
        order: 6;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(6) {
        order: 5;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(7) {
        order: 4;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(8) {
        order: 3;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(9) {
        order: 2;
    }
    .elementor-reverse-mobile_extra > .elementor-container > :nth-child(10) {
        order: 1;
    }
}
@media (max-width: 767px) {
    .elementor-reverse-mobile > .elementor-container > :nth-child(1) {
        order: 10;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(2) {
        order: 9;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(3) {
        order: 8;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(4) {
        order: 7;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(5) {
        order: 6;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(6) {
        order: 5;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(7) {
        order: 4;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(8) {
        order: 3;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(9) {
        order: 2;
    }
    .elementor-reverse-mobile > .elementor-container > :nth-child(10) {
        order: 1;
    }
    .elementor-column {
        width: 100%;
    }
}

.elementor-grid {
    display: grid;
    grid-column-gap: var(--grid-column-gap);
    grid-row-gap: var(--grid-row-gap);
}
.elementor-grid .elementor-grid-item {
    min-width: 0;
}
.elementor-grid-0 .elementor-grid {
    display: inline-block;
    width: 100%;
    word-spacing: var(--grid-column-gap);
    margin-bottom: calc(-1 * var(--grid-row-gap));
}
.elementor-grid-0 .elementor-grid .elementor-grid-item {
    display: inline-block;
    margin-bottom: var(--grid-row-gap);
    word-break: break-word;
}
.elementor-grid-1 .elementor-grid {
    grid-template-columns: repeat(1, 1fr);
}
.elementor-grid-2 .elementor-grid {
    grid-template-columns: repeat(2, 1fr);
}
.elementor-grid-3 .elementor-grid {
    grid-template-columns: repeat(3, 1fr);
}
.elementor-grid-4 .elementor-grid {
    grid-template-columns: repeat(4, 1fr);
}
.elementor-grid-5 .elementor-grid {
    grid-template-columns: repeat(5, 1fr);
}
.elementor-grid-6 .elementor-grid {
    grid-template-columns: repeat(6, 1fr);
}
.elementor-grid-7 .elementor-grid {
    grid-template-columns: repeat(7, 1fr);
}
.elementor-grid-8 .elementor-grid {
    grid-template-columns: repeat(8, 1fr);
}
.elementor-grid-9 .elementor-grid {
    grid-template-columns: repeat(9, 1fr);
}
.elementor-grid-10 .elementor-grid {
    grid-template-columns: repeat(10, 1fr);
}
.elementor-grid-11 .elementor-grid {
    grid-template-columns: repeat(11, 1fr);
}
.elementor-grid-12 .elementor-grid {
    grid-template-columns: repeat(12, 1fr);
}
@media (min-width: -1) {
    .elementor-grid-widescreen-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap));
    }
    .elementor-grid-widescreen-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word;
    }
    .elementor-grid-widescreen-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .elementor-grid-widescreen-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .elementor-grid-widescreen-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .elementor-grid-widescreen-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .elementor-grid-widescreen-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr);
    }
    .elementor-grid-widescreen-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr);
    }
    .elementor-grid-widescreen-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr);
    }
    .elementor-grid-widescreen-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr);
    }
    .elementor-grid-widescreen-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr);
    }
    .elementor-grid-widescreen-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr);
    }
    .elementor-grid-widescreen-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr);
    }
    .elementor-grid-widescreen-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (max-width: -1) {
    .elementor-grid-laptop-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap));
    }
    .elementor-grid-laptop-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word;
    }
    .elementor-grid-laptop-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .elementor-grid-laptop-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .elementor-grid-laptop-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .elementor-grid-laptop-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .elementor-grid-laptop-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr);
    }
    .elementor-grid-laptop-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr);
    }
    .elementor-grid-laptop-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr);
    }
    .elementor-grid-laptop-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr);
    }
    .elementor-grid-laptop-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr);
    }
    .elementor-grid-laptop-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr);
    }
    .elementor-grid-laptop-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr);
    }
    .elementor-grid-laptop-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (max-width: -1) {
    .elementor-grid-tablet_extra-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap));
    }
    .elementor-grid-tablet_extra-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word;
    }
    .elementor-grid-tablet_extra-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .elementor-grid-tablet_extra-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .elementor-grid-tablet_extra-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .elementor-grid-tablet_extra-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .elementor-grid-tablet_extra-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr);
    }
    .elementor-grid-tablet_extra-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr);
    }
    .elementor-grid-tablet_extra-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr);
    }
    .elementor-grid-tablet_extra-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr);
    }
    .elementor-grid-tablet_extra-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr);
    }
    .elementor-grid-tablet_extra-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr);
    }
    .elementor-grid-tablet_extra-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr);
    }
    .elementor-grid-tablet_extra-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (max-width: 1024px) {
    .elementor-grid-tablet-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap));
    }
    .elementor-grid-tablet-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word;
    }
    .elementor-grid-tablet-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .elementor-grid-tablet-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .elementor-grid-tablet-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .elementor-grid-tablet-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .elementor-grid-tablet-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr);
    }
    .elementor-grid-tablet-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr);
    }
    .elementor-grid-tablet-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr);
    }
    .elementor-grid-tablet-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr);
    }
    .elementor-grid-tablet-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr);
    }
    .elementor-grid-tablet-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr);
    }
    .elementor-grid-tablet-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr);
    }
    .elementor-grid-tablet-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (max-width: -1) {
    .elementor-grid-mobile_extra-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap));
    }
    .elementor-grid-mobile_extra-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word;
    }
    .elementor-grid-mobile_extra-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .elementor-grid-mobile_extra-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .elementor-grid-mobile_extra-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .elementor-grid-mobile_extra-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .elementor-grid-mobile_extra-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr);
    }
    .elementor-grid-mobile_extra-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr);
    }
    .elementor-grid-mobile_extra-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr);
    }
    .elementor-grid-mobile_extra-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr);
    }
    .elementor-grid-mobile_extra-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr);
    }
    .elementor-grid-mobile_extra-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr);
    }
    .elementor-grid-mobile_extra-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr);
    }
    .elementor-grid-mobile_extra-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr);
    }
}
@media (max-width: 767px) {
    .elementor-grid-mobile-0 .elementor-grid {
        display: inline-block;
        width: 100%;
        word-spacing: var(--grid-column-gap);
        margin-bottom: calc(-1 * var(--grid-row-gap));
    }
    .elementor-grid-mobile-0 .elementor-grid .elementor-grid-item {
        display: inline-block;
        margin-bottom: var(--grid-row-gap);
        word-break: break-word;
    }
    .elementor-grid-mobile-1 .elementor-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .elementor-grid-mobile-2 .elementor-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .elementor-grid-mobile-3 .elementor-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .elementor-grid-mobile-4 .elementor-grid {
        grid-template-columns: repeat(4, 1fr);
    }
    .elementor-grid-mobile-5 .elementor-grid {
        grid-template-columns: repeat(5, 1fr);
    }
    .elementor-grid-mobile-6 .elementor-grid {
        grid-template-columns: repeat(6, 1fr);
    }
    .elementor-grid-mobile-7 .elementor-grid {
        grid-template-columns: repeat(7, 1fr);
    }
    .elementor-grid-mobile-8 .elementor-grid {
        grid-template-columns: repeat(8, 1fr);
    }
    .elementor-grid-mobile-9 .elementor-grid {
        grid-template-columns: repeat(9, 1fr);
    }
    .elementor-grid-mobile-10 .elementor-grid {
        grid-template-columns: repeat(10, 1fr);
    }
    .elementor-grid-mobile-11 .elementor-grid {
        grid-template-columns: repeat(11, 1fr);
    }
    .elementor-grid-mobile-12 .elementor-grid {
        grid-template-columns: repeat(12, 1fr);
    }
}

@media (min-width: 1025px) {
    #elementor-device-mode:after {
        content: "desktop";
    }
}
@media (min-width: -1) {
    #elementor-device-mode:after {
        content: "widescreen";
    }
}
@media (max-width: -1) {
    #elementor-device-mode:after {
        content: "laptop";
    }
}
@media (max-width: -1) {
    #elementor-device-mode:after {
        content: "tablet_extra";
    }
}
@media (max-width: 1024px) {
    #elementor-device-mode:after {
        content: "tablet";
    }
}
@media (max-width: -1) {
    #elementor-device-mode:after {
        content: "mobile_extra";
    }
}
@media (max-width: 767px) {
    #elementor-device-mode:after {
        content: "mobile";
    }
}

.e-con {
    --border-radius: 0;
    --border-width-top: 0px;
    --border-width-right: 0px;
    --border-width-bottom: 0px;
    --border-width-left: 0px;
    --border-style: initial;
    --border-color: initial;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --content-width: Min( 100%, var( --container-max-width, 1140px ) );
    --width: 100%;
    --min-height: initial;
    --height: auto;
    --text-align: initial;
    --margin-top: 0;
    --margin-right: 0;
    --margin-bottom: 0;
    --margin-left: 0;
    --padding-top: var( --container-default-padding-top, 10px );
    --padding-right: var( --container-default-padding-right, 10px );
    --padding-bottom: var( --container-default-padding-bottom, 10px );
    --padding-left: var( --container-default-padding-left, 10px );
    --position: relative;
    --z-index: revert;
    --overflow: visible;
    --gap: var( --widgets-spacing, 20px );
    --overlay-mix-blend-mode: initial;
    --overlay-opacity: 1;
    --overlay-transition: 0.3s;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(2, 1fr);
    position: var(--position);
    width: var(--width);
    min-width: 0;
    min-height: var(--min-height);
    height: var(--height);
    border-radius: var(--border-radius);
    margin: var(--margin-top) var(--margin-right) var(--margin-bottom) var(--margin-left);
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);
    z-index: var(--z-index);
    overflow: var(--overflow);
    transition: background var(--background-transition, 0.3s), border var(--border-transition, 0.3s), box-shadow var(--border-transition, 0.3s), transform var(--e-con-transform-transition-duration, 0.4s);
}
.e-con.e-flex {
    --flex-direction: column;
    --flex-basis: auto;
    --flex-grow: 0;
    --flex-shrink: 1;
    flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
}
.e-con-full, .e-con > .e-con-inner {
    text-align: var(--text-align);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
}
.e-con-full.e-flex, .e-con.e-flex > .e-con-inner {
    flex-direction: var(--flex-direction);
}
.e-con, .e-con > .e-con-inner {
    display: var(--display);
}
.e-con.e-grid {
    --grid-justify-content: start;
    --grid-align-content: start;
    --grid-auto-flow: row;
}
.e-con.e-grid, .e-con.e-grid > .e-con-inner {
    grid-template-columns: var(--e-con-grid-template-columns);
    grid-template-rows: var(--e-con-grid-template-rows);
    justify-content: var(--grid-justify-content);
    align-content: var(--grid-align-content);
    grid-auto-flow: var(--grid-auto-flow);
}
.e-con-boxed.e-flex {
    flex-direction: column;
    flex-wrap: initial;
    justify-content: initial;
    align-items: initial;
    align-content: initial;
}
.e-con-boxed.e-grid {
    justify-items: initial;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}
.e-con-boxed {
    text-align: initial;
    gap: initial;
}
.e-con.e-flex > .e-con-inner {
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    flex-basis: initial;
    flex-grow: 1;
    flex-shrink: initial;
    align-self: initial;
}
.e-con.e-grid > .e-con-inner {
    justify-items: var(--justify-items);
    align-items: var(--align-items);
}
.e-con > .e-con-inner {
    gap: var(--gap);
    width: 100%;
    max-width: var(--content-width);
    margin: 0 auto;
    padding-inline: 0;
    height: 100%;
}
:is(.elementor-section-wrap, [data-elementor-id]) > .e-con {
    --margin-right: auto;
    --margin-left: auto;
    max-width: min(100%, var(--width));
}
.e-con .elementor-widget.elementor-widget {
    margin-bottom: 0;
}
.e-con::before, :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before,
.e-con > .elementor-motion-effects-container > .elementor-motion-effects-layer::before,
.e-con > .elementor-background-slideshow::before {
    content: var(--background-overlay);
    display: block;
    position: absolute;
    mix-blend-mode: var(--overlay-mix-blend-mode);
    opacity: var(--overlay-opacity);
    transition: var(--overlay-transition, 0.3s);
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-top-width: var(--border-width-top);
    border-right-width: var(--border-width-right);
    border-bottom-width: var(--border-width-bottom);
    border-left-width: var(--border-width-left);
    top: calc(0px - var(--border-width-top));
    left: calc(0px - var(--border-width-left));
    width: max(100% + var(--border-width-right) + var(--border-width-left), 100%);
    height: max(100% + var(--border-width-top) + var(--border-width-bottom), 100%);
}
.e-con::before {
    transition: background var(--overlay-transition, 0.3s), border-radius var(--border-transition, 0.3s), opacity var(--overlay-transition, 0.3s);
}
.e-con > .elementor-background-slideshow, :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container {
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-top-width: var(--border-width-top);
    border-right-width: var(--border-width-right);
    border-bottom-width: var(--border-width-bottom);
    border-left-width: var(--border-width-left);
    top: calc(0px - var(--border-width-top));
    left: calc(0px - var(--border-width-left));
    width: max(100% + var(--border-width-right) + var(--border-width-left), 100%);
    height: max(100% + var(--border-width-top) + var(--border-width-bottom), 100%);
}
:is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before {
    z-index: 1;
}
:is(.e-con, .e-con > .e-con-inner) > .elementor-background-slideshow::before {
    z-index: 2;
}
.e-con .elementor-widget {
    min-width: 0;
}
.e-con .elementor-widget-video, .e-con .elementor-widget-google_maps, .e-con .elementor-widget-empty {
    width: 100%;
}
.e-con .elementor-widget.e-widget-swiper {
    width: 100%;
}
.e-con > .elementor-widget > .elementor-widget-container, .e-con > .e-con-inner > .elementor-widget > .elementor-widget-container {
    height: 100%;
}
.elementor.elementor .e-con > .elementor-widget, .e-con.e-con > .e-con-inner > .elementor-widget {
    max-width: 100%;
}

@media (max-width: 767px) {
    .e-con.e-flex {
        --width: 100%;
        --flex-wrap: wrap;
    }
}
.elementor-form-fields-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group > input, .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group > textarea, .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group > .elementor-select-wrapper, .elementor-form-fields-wrapper.elementor-labels-above .elementor-field-group .elementor-field-subgroup {
    flex-basis: 100%;
    max-width: 100%;
}
.elementor-form-fields-wrapper.elementor-labels-inline > .elementor-field-group > input, .elementor-form-fields-wrapper.elementor-labels-inline > .elementor-field-group .elementor-select-wrapper {
    flex-grow: 1;
}

.elementor-field-group {
    flex-wrap: wrap;
    align-items: center;
}
.elementor-field-group.elementor-field-type-submit {
    align-items: flex-end;
}
.elementor-field-group .elementor-select-wrapper {
    display: flex;
    position: relative;
    width: 100%;
}
.elementor-field-group .elementor-select-wrapper select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    flex-basis: 100%;
    padding-right: 20px;
}
.elementor-field-group .elementor-select-wrapper:before {
    content: "\e92a";
    font-family: "eicons";
    font-size: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    pointer-events: none;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.elementor-field-group.elementor-field-type-select-multiple .elementor-select-wrapper:before {
    content: "";
}

.elementor-field-subgroup {
    display: flex;
    flex-wrap: wrap;
}
.elementor-field-subgroup .elementor-field-option label {
    display: inline-block;
}
.elementor-field-subgroup.elementor-subgroup-inline .elementor-field-option {
    padding-right: 10px;
}
.elementor-field-subgroup:not(.elementor-subgroup-inline) .elementor-field-option {
    flex-basis: 100%;
}

.elementor-field-type-checkbox .elementor-field-subgroup .elementor-field-option input,
.elementor-field-type-checkbox .elementor-field-subgroup .elementor-field-option label,
.elementor-field-type-radio .elementor-field-subgroup .elementor-field-option input,
.elementor-field-type-radio .elementor-field-subgroup .elementor-field-option label,
.elementor-field-type-acceptance .elementor-field-subgroup .elementor-field-option input,
.elementor-field-type-acceptance .elementor-field-subgroup .elementor-field-option label {
    display: inline;
}

.elementor-field-label {
    cursor: pointer;
}
.elementor-mark-required .elementor-field-label:after {
    content: "*";
    color: red;
    padding-left: 0.2em;
}

@media screen and (max-width: 767px) {
    .elementor-mobile-button-align-center .elementor-field-type-submit,
    .elementor-mobile-button-align-center .e-form__buttons {
        justify-content: center;
    }
    .elementor-mobile-button-align-start .elementor-field-type-submit,
    .elementor-mobile-button-align-start .e-form__buttons {
        justify-content: flex-start;
    }
    .elementor-mobile-button-align-end .elementor-field-type-submit,
    .elementor-mobile-button-align-end .e-form__buttons {
        justify-content: flex-end;
    }
    .elementor-mobile-button-align-center .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button, .elementor-mobile-button-align-start .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button, .elementor-mobile-button-align-end .elementor-field-type-submit:not(.e-form__buttons__wrapper) .elementor-button {
        flex-basis: initial;
    }
    .elementor-mobile-button-align-center .e-form__buttons__wrapper, .elementor-mobile-button-align-start .e-form__buttons__wrapper, .elementor-mobile-button-align-end .e-form__buttons__wrapper {
        flex-grow: initial;
    }
    .elementor-mobile-button-align-center .e-form__buttons__wrapper, .elementor-mobile-button-align-center .e-form__buttons__wrapper__button, .elementor-mobile-button-align-start .e-form__buttons__wrapper, .elementor-mobile-button-align-start .e-form__buttons__wrapper__button, .elementor-mobile-button-align-end .e-form__buttons__wrapper, .elementor-mobile-button-align-end .e-form__buttons__wrapper__button {
        flex-basis: initial;
    }
}
.elementor-error .elementor-field {
    border-color: #d9534f;
}
.elementor-error .help-inline {
    color: #d9534f;
    font-size: 0.9em;
}

.elementor-message {
    margin: 10px 0;
    font-size: 1em;
    line-height: 1;
}
.elementor-message:before {
    content: "\e90e";
    display: inline-block;
    font-family: eicons;
    font-weight: normal;
    font-style: normal;
    vertical-align: middle;
    margin-right: 5px;
}
.elementor-message.elementor-message-danger {
    color: #d9534f;
}
.elementor-message.elementor-message-danger:before {
    content: "\e87f";
}
.elementor-message.form-message-success {
    color: #5cb85c;
}

.elementor-form .elementor-button {
    padding-top: 0;
    padding-bottom: 0;
    border: none;
}
.elementor-form .elementor-button > span {
    display: flex;
    justify-content: center;
}
.elementor-form .elementor-button.elementor-size-xs {
    min-height: 33px;
}
.elementor-form .elementor-button.elementor-size-sm {
    min-height: 40px;
}
.elementor-form .elementor-button.elementor-size-md {
    min-height: 47px;
}
.elementor-form .elementor-button.elementor-size-lg {
    min-height: 59px;
}
.elementor-form .elementor-button.elementor-size-xl {
    min-height: 72px;
}

.elementor-element .elementor-widget-container {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, transform var(--e-transform-transition-duration, 400ms);
}

.elementor-button {
    display: inline-block;
    line-height: 1;
    background-color: #69727D;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    transition: all 0.3s;
}
.elementor-button:hover, .elementor-button:focus, .elementor-button:visited {
    color: #fff;
}
.elementor-button-content-wrapper {
    display: flex;
    justify-content: center;
}
.elementor-button-icon {
    flex-grow: 0;
    order: 5;
}
.elementor-button-icon svg {
    width: 1em;
    height: auto;
}
.elementor-button-icon .e-font-icon-svg {
    height: 1em;
}
.elementor-button-text {
    flex-grow: 1;
    order: 10;
    display: inline-block;
}
.elementor-button.elementor-size-xs {
    font-size: 13px;
    padding: 10px 20px;
    border-radius: 2px;
}
.elementor-button.elementor-size-md {
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 4px;
}
.elementor-button.elementor-size-lg {
    font-size: 18px;
    padding: 20px 40px;
    border-radius: 5px;
}
.elementor-button.elementor-size-xl {
    font-size: 20px;
    padding: 25px 50px;
    border-radius: 6px;
}
.elementor-button .elementor-align-icon-right {
    margin-left: 5px;
    order: 15;
}
.elementor-button .elementor-align-icon-left {
    margin-right: 5px;
    order: 5;
}
.elementor-button span {
    text-decoration: inherit;
}

.elementor-element.elementor-button-info .elementor-button {
    background-color: #5bc0de;
}
.elementor-element.elementor-button-success .elementor-button {
    background-color: #5cb85c;
}
.elementor-element.elementor-button-warning .elementor-button {
    background-color: #f0ad4e;
}
.elementor-element.elementor-button-danger .elementor-button {
    background-color: #d9534f;
}

.elementor-widget-button .elementor-button .elementor-button-info {
    background-color: #5bc0de;
}
.elementor-widget-button .elementor-button .elementor-button-success {
    background-color: #5cb85c;
}
.elementor-widget-button .elementor-button .elementor-button-warning {
    background-color: #f0ad4e;
}
.elementor-widget-button .elementor-button .elementor-button-danger {
    background-color: #d9534f;
}

.elementor-tab-title a {
    color: inherit;
}

.elementor-view-stacked .elementor-icon {
    padding: 0.5em;
    background-color: #69727D;
    color: #fff;
    fill: #fff;
}

.elementor-view-framed .elementor-icon {
    padding: 0.5em;
    color: #69727D;
    border: 3px solid #69727D;
    background-color: transparent;
}

.elementor-icon {
    display: inline-block;
    line-height: 1;
    transition: all 0.3s;
    color: #69727D;
    font-size: 50px;
    text-align: center;
}
.elementor-icon:hover {
    color: #69727D;
}
.elementor-icon i, .elementor-icon svg {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}
.elementor-icon i:before, .elementor-icon svg:before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.elementor-icon i.fad {
    width: initial;
}

.elementor-shape-circle .elementor-icon {
    border-radius: 50%;
}

.e-transform .elementor-widget-container {
    transform: perspective(var(--e-transform-perspective, 0)) rotateZ(var(--e-transform-rotateZ, 0)) rotateX(var(--e-transform-rotateX, 0)) rotateY(var(--e-transform-rotateY, 0)) translate(var(--e-transform-translate, 0)) translateX(var(--e-transform-translateX, 0)) translateY(var(--e-transform-translateY, 0)) scaleX(calc(var(--e-transform-flipX, 1) * var(--e-transform-scaleX, var(--e-transform-scale, 1)))) scaleY(calc(var(--e-transform-flipY, 1) * var(--e-transform-scaleY, var(--e-transform-scale, 1)))) skewX(var(--e-transform-skewX, 0)) skewY(var(--e-transform-skewY, 0));
    transform-origin: var(--e-transform-origin-y) var(--e-transform-origin-x);
}

.e-con.e-transform {
    transform: perspective(var(--e-con-transform-perspective, 0)) rotateZ(var(--e-con-transform-rotateZ, 0)) rotateX(var(--e-con-transform-rotateX, 0)) rotateY(var(--e-con-transform-rotateY, 0)) translate(var(--e-con-transform-translate, 0)) translateX(var(--e-con-transform-translateX, 0)) translateY(var(--e-con-transform-translateY, 0)) scaleX(calc(var(--e-con-transform-flipX, 1) * var(--e-con-transform-scaleX, var(--e-con-transform-scale, 1)))) scaleY(calc(var(--e-con-transform-flipY, 1) * var(--e-con-transform-scaleY, var(--e-con-transform-scale, 1)))) skewX(var(--e-con-transform-skewX, 0)) skewY(var(--e-con-transform-skewY, 0));
    transform-origin: var(--e-con-transform-origin-y) var(--e-con-transform-origin-x);
}

.elementor-element {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}
.elementor-element .swiper .swiper-slide figure,
.elementor-element .swiper-container .swiper-slide figure {
    line-height: 0;
}

.elementor-element .swiper .elementor-swiper-button,
.elementor-element .swiper ~ .elementor-swiper-button,
.elementor-element .swiper-container .elementor-swiper-button,
.elementor-element .swiper-container ~ .elementor-swiper-button {
    position: absolute;
    display: inline-flex;
    z-index: 1;
    cursor: pointer;
    font-size: 25px;
    color: rgba(238, 238, 238, 0.9);
    top: 50%;
    transform: translateY(-50%);
}
.elementor-element .swiper .elementor-swiper-button svg,
.elementor-element .swiper ~ .elementor-swiper-button svg,
.elementor-element .swiper-container .elementor-swiper-button svg,
.elementor-element .swiper-container ~ .elementor-swiper-button svg {
    fill: rgba(238, 238, 238, 0.9);
    height: 1em;
    width: 1em;
}
.elementor-element .swiper .elementor-swiper-button-prev,
.elementor-element .swiper ~ .elementor-swiper-button-prev,
.elementor-element .swiper-container .elementor-swiper-button-prev,
.elementor-element .swiper-container ~ .elementor-swiper-button-prev {
    left: 10px;
}
.elementor-element .swiper .elementor-swiper-button-next,
.elementor-element .swiper ~ .elementor-swiper-button-next,
.elementor-element .swiper-container .elementor-swiper-button-next,
.elementor-element .swiper-container ~ .elementor-swiper-button-next {
    right: 10px;
}
.elementor-element .swiper .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper ~ .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper-container .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper-container ~ .elementor-swiper-button.swiper-button-disabled {
    opacity: 0.3;
}
.elementor-element .swiper .swiper-image-stretch .swiper-slide .swiper-slide-image,
.elementor-element .swiper-container .swiper-image-stretch .swiper-slide .swiper-slide-image {
    width: 100%;
}
.elementor-element .swiper .swiper-pagination-fraction,
.elementor-element .swiper .swiper-horizontal > .swiper-pagination-bullets,
.elementor-element .swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper ~ .swiper-pagination-fraction,
.elementor-element .swiper ~ .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper-container .swiper-pagination-fraction,
.elementor-element .swiper-container .swiper-horizontal > .swiper-pagination-bullets,
.elementor-element .swiper-container .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper-container ~ .swiper-pagination-fraction,
.elementor-element .swiper-container ~ .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 5px;
}
.elementor-element .swiper.swiper-cube .elementor-swiper-button,
.elementor-element .swiper.swiper-cube ~ .elementor-swiper-button,
.elementor-element .swiper-container.swiper-cube .elementor-swiper-button,
.elementor-element .swiper-container.swiper-cube ~ .elementor-swiper-button {
    transform: translate3d(0, -50%, 1px);
}
.elementor-element :where(.swiper-container-horizontal) ~ .swiper-pagination-bullets {
    bottom: 5px;
    left: 0;
    width: 100%;
}
.elementor-element :where(.swiper-container-horizontal) ~ .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}
.elementor-element :where(.swiper-container-horizontal) ~ .swiper-pagination-progressbar {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
}

.elementor-element .elementor-swiper {
    position: relative;
}
.elementor-element .elementor-main-swiper {
    position: static;
}

.animated {
    animation-duration: 1.25s;
}
.animated.animated-slow {
    animation-duration: 2s;
}
.animated.animated-fast {
    animation-duration: 0.75s;
}
.animated.infinite {
    animation-iteration-count: infinite;
}
.animated.reverse {
    animation-direction: reverse;
    animation-fill-mode: forwards;
}

@media (prefers-reduced-motion: reduce) {
    .animated {
        animation: none;
    }
}
.elementor-shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    /*
     * @TODO: The `z-index: -1` rules below are temporary fixes for Chrome 85 issue.
     *   It will be removed in a future version of Chrome.
     */
}
.elementor-shape-top {
    top: -1px;
}
.elementor-shape-top:not([data-negative=false]) svg {
    z-index: -1;
}
.elementor-shape-bottom {
    bottom: -1px;
}
.elementor-shape-bottom:not([data-negative=true]) svg {
    z-index: -1;
}
.elementor-shape[data-negative=false].elementor-shape-bottom {
    transform: rotate(180deg);
}
.elementor-shape[data-negative=true].elementor-shape-top {
    transform: rotate(180deg);
}
.elementor-shape svg {
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.elementor-shape .elementor-shape-fill {
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0deg);
}

.elementor-post__thumbnail__link {
    transition: none;
}

.e-n-tabs-none > .elementor-widget-container > .e-n-tabs > .e-n-tabs-content > .e-collapse {
    display: none;
}
