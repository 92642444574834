.elementor-17 .elementor-element.elementor-element-5c7d55cf > .elementor-container {
    min-height: 125vh;
}

.elementor-17 .elementor-element.elementor-element-5c7d55cf:not(.elementor-motion-effects-element-type-background) {
    background-image: url("../../images/mechanic.jpeg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.elementor-17 .elementor-element.elementor-element-5c7d55cf > .elementor-background-overlay {
    background-color: #000000;
    opacity: 0.75;
    mix-blend-mode: multiply;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-17 .elementor-element.elementor-element-5c7d55cf {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: -85px;
    margin-bottom: 0;
    z-index: 0;
}

.elementor-17 .elementor-element.elementor-element-5c7d55cf > .elementor-shape-bottom .elementor-shape-fill {
    fill: #DAA520;
}

.elementor-17 .elementor-element.elementor-element-5c7d55cf > .elementor-shape-bottom svg {
    height: 250px;
    transform: translateX(-50%) rotateY(180deg);
}

.elementor-17 .elementor-element.elementor-element-2ba35694 {
    text-align: center;
}

.elementor-17 .elementor-element.elementor-element-6cba7690 {
    text-align: center;
}

.elementor-17 .elementor-element.elementor-element-6cba7690 .elementor-heading-title {
    font-family: var(--e-global-typography-579108b-font-family), Sans-serif;
    font-size: var(--e-global-typography-579108b-font-size);
    font-weight: var(--e-global-typography-579108b-font-weight);
    line-height: var(--e-global-typography-579108b-line-height);
    letter-spacing: var(--e-global-typography-579108b-letter-spacing);
    word-spacing: var(--e-global-typography-579108b-word-spacing);
}

.elementor-17 .elementor-element.elementor-element-e9346f0 {
    text-align: center;
}

.elementor-17 .elementor-element.elementor-element-6bff978c img {
    width: 60%;
}

.elementor-17 .elementor-element.elementor-element-6bff978c > .elementor-widget-container {
    margin: 0 0 0 0;
}

.elementor-17 .elementor-element.elementor-element-78de8a8e > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-17 .elementor-element.elementor-element-78de8a8e:not(.elementor-motion-effects-element-type-background) {
    background-color: #202020;
}

.elementor-17 .elementor-element.elementor-element-78de8a8e .elementor-background-overlay {
    filter: brightness(38%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-78de8a8e > .elementor-shape-top .elementor-shape-fill {
    fill: #DAA520;
}

.elementor-17 .elementor-element.elementor-element-78de8a8e > .elementor-shape-top svg {
    height: 250px;
    transform: translateX(-50%) rotateY(180deg);
}

.elementor-17 .elementor-element.elementor-element-78de8a8e > .elementor-shape-bottom .elementor-shape-fill {
    fill: #DAA520;
}

.elementor-17 .elementor-element.elementor-element-1cd00c79 img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center center;
    filter: brightness(100%) contrast(100%) saturate(25%) blur(0px) hue-rotate(0deg);
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.elementor-17 .elementor-element.elementor-element-1cd00c79:hover img {
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-1cd00c79 > .elementor-widget-container {
    margin: 25% 0 0 0;
}

.elementor-17 .elementor-element.elementor-element-28c8f7d7 img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center center;
    filter: brightness(100%) contrast(100%) saturate(25%) blur(0px) hue-rotate(0deg);
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.elementor-17 .elementor-element.elementor-element-28c8f7d7:hover img {
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-28c8f7d7 > .elementor-widget-container {
    margin: 0 0 25% 0;
}

.elementor-17 .elementor-element.elementor-element-417155e7 > .elementor-element-populated {
    padding: 5% 5% 5% 5%;
}

.elementor-17 .elementor-element.elementor-element-63f51f4b {
    --divider-border-style: parallelogram;
    --divider-color: #F77F00;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
}

.elementor-17 .elementor-element.elementor-element-63f51f4b .elementor-divider-separator {
    width: 100px;
}

.elementor-17 .elementor-element.elementor-element-63f51f4b .elementor-divider {
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-4dade9a8 {
    width: auto;
    max-width: auto;
}

.elementor-17 .elementor-element.elementor-element-6bd318ca {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0;
    margin-bottom: 0;
    padding: 5% 0 5% 0;
}

.elementor-17 .elementor-element.elementor-element-6bd318ca > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-17 .elementor-element.elementor-element-6bd318ca > .elementor-shape-top .elementor-shape-fill {
    fill: #DAA520;
}

.elementor-17 .elementor-element.elementor-element-6bd318ca > .elementor-shape-top svg {
    height: 250px;
}

.elementor-17 .elementor-element.elementor-element-26c8bfb1:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap {
    background-color: #FFFFFF;
}

.elementor-17 .elementor-element.elementor-element-26c8bfb1 > .elementor-element-populated, .elementor-17 .elementor-element.elementor-element-26c8bfb1 > .elementor-element-populated > .elementor-background-overlay, .elementor-17 .elementor-element.elementor-element-26c8bfb1 > .elementor-background-slideshow {
    border-radius: 5px 5px 5px 5px;
}

.elementor-17 .elementor-element.elementor-element-26c8bfb1 > .elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 5% 5% 5% 5%;
}

.elementor-17 .elementor-element.elementor-element-26c8bfb1 > .elementor-element-populated > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-17 .elementor-element.elementor-element-74572657 > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-17 .elementor-element.elementor-element-62637479 .elementor-heading-title {
    color: var(--e-global-color-12b5d5f);
}

.elementor-17 .elementor-element.elementor-element-1bc4bb {
    --divider-border-style: parallelogram;
    --divider-color: #000000;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
}

.elementor-17 .elementor-element.elementor-element-1bc4bb .elementor-divider-separator {
    width: 100px;
}

.elementor-17 .elementor-element.elementor-element-1bc4bb .elementor-divider {
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-410872b7 {
    text-align: center;
    color: #000000;
}

.elementor-17 .elementor-element.elementor-element-281e264a {
    margin-top: 0;
    margin-bottom: 0;
}

.elementor-17 .elementor-element.elementor-element-3483e87e > .elementor-element-populated {
    padding: 5% 5% 5% 5%;
}

.elementor-17 .elementor-element.elementor-element-61295c1e.elementor-position-right .elementor-image-box-img {
    margin-left: 15px;
}

.elementor-17 .elementor-element.elementor-element-61295c1e.elementor-position-left .elementor-image-box-img {
    margin-right: 15px;
}

.elementor-17 .elementor-element.elementor-element-61295c1e.elementor-position-top .elementor-image-box-img {
    margin-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-61295c1e .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-17 .elementor-element.elementor-element-61295c1e .elementor-image-box-img img {
    border-radius: 5px;
    filter: brightness(100%) contrast(100%) saturate(25%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.3s;
}

.elementor-17 .elementor-element.elementor-element-61295c1e:hover .elementor-image-box-img img {
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-61295c1e .elementor-image-box-title {
    color: #FFFFFF;
}

.elementor-17 .elementor-element.elementor-element-61295c1e > .elementor-widget-container {
    padding: 0 0 20px 0;
    background-color: #000000;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.elementor-17 .elementor-element.elementor-element-61295c1e.bdt-background-overlay-yes > .elementor-widget-container:before {
    transition: background 0.3s;
}

.elementor-17 .elementor-element.elementor-element-d522f9c > .elementor-element-populated {
    padding: 5% 5% 5% 5%;
}

.elementor-17 .elementor-element.elementor-element-46f0257.elementor-position-right .elementor-image-box-img {
    margin-left: 15px;
}

.elementor-17 .elementor-element.elementor-element-46f0257.elementor-position-left .elementor-image-box-img {
    margin-right: 15px;
}

.elementor-17 .elementor-element.elementor-element-46f0257.elementor-position-top .elementor-image-box-img {
    margin-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-46f0257 .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-17 .elementor-element.elementor-element-46f0257 .elementor-image-box-img img {
    border-radius: 5px;
    filter: brightness(100%) contrast(100%) saturate(25%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.3s;
}

.elementor-17 .elementor-element.elementor-element-46f0257:hover .elementor-image-box-img img {
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-46f0257 > .elementor-widget-container {
    padding: 0 0 20px 0;
    background-color: #000000;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.elementor-17 .elementor-element.elementor-element-46f0257.bdt-background-overlay-yes > .elementor-widget-container:before {
    transition: background 0.3s;
}

.elementor-17 .elementor-element.elementor-element-4ad88b9e > .elementor-element-populated {
    padding: 5% 5% 5% 5%;
}

.elementor-17 .elementor-element.elementor-element-65555ee4.elementor-position-right .elementor-image-box-img {
    margin-left: 15px;
}

.elementor-17 .elementor-element.elementor-element-65555ee4.elementor-position-left .elementor-image-box-img {
    margin-right: 15px;
}

.elementor-17 .elementor-element.elementor-element-65555ee4.elementor-position-top .elementor-image-box-img {
    margin-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-65555ee4 .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-17 .elementor-element.elementor-element-65555ee4 .elementor-image-box-img img {
    border-radius: 5px;
    filter: brightness(100%) contrast(100%) saturate(25%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.3s;
}

.elementor-17 .elementor-element.elementor-element-65555ee4:hover .elementor-image-box-img img {
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-65555ee4 > .elementor-widget-container {
    padding: 0 0 20px 0;
    background-color: #000000;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.elementor-17 .elementor-element.elementor-element-65555ee4.bdt-background-overlay-yes > .elementor-widget-container:before {
    transition: background 0.3s;
}

.elementor-17 .elementor-element.elementor-element-47ac6dc4 > .elementor-element-populated {
    padding: 5% 5% 5% 5%;
}

.elementor-17 .elementor-element.elementor-element-274617ea.elementor-position-right .elementor-image-box-img {
    margin-left: 15px;
}

.elementor-17 .elementor-element.elementor-element-274617ea.elementor-position-left .elementor-image-box-img {
    margin-right: 15px;
}

.elementor-17 .elementor-element.elementor-element-274617ea.elementor-position-top .elementor-image-box-img {
    margin-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-274617ea .elementor-image-box-wrapper .elementor-image-box-img {
    width: 100%;
}

.elementor-17 .elementor-element.elementor-element-274617ea .elementor-image-box-img img {
    border-radius: 5px;
    filter: brightness(100%) contrast(100%) saturate(25%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.3s;
}

.elementor-17 .elementor-element.elementor-element-274617ea:hover .elementor-image-box-img img {
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-274617ea > .elementor-widget-container {
    padding: 0 0 20px 0;
    background-color: #000000;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.elementor-17 .elementor-element.elementor-element-274617ea.bdt-background-overlay-yes > .elementor-widget-container:before {
    transition: background 0.3s;
}

.elementor-17 .elementor-element.elementor-element-43f97b2e > .elementor-container > .elementor-column > .elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-17 .elementor-element.elementor-element-43f97b2e > .elementor-background-overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #202020 5%, #202020BA 100%);
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-17 .elementor-element.elementor-element-43f97b2e {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 0 0 0 0;
}

.elementor-17 .elementor-element.elementor-element-43f97b2e > .elementor-shape-bottom .elementor-shape-fill {
    fill: #DAA520;
}

.elementor-17 .elementor-element.elementor-element-43f97b2e > .elementor-shape-bottom svg {
    height: 250px;
}

.elementor-17 .elementor-element.elementor-element-15c1ac59 > .elementor-element-populated {
    padding: 10% 10% 10% 10%;
}

.elementor-17 .elementor-element.elementor-element-6266aef7 img {
    width: 100%;
    height: 585px;
    object-fit: cover;
    object-position: center center;
    filter: brightness(100%) contrast(100%) saturate(25%) blur(0px) hue-rotate(0deg);
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.elementor-17 .elementor-element.elementor-element-6266aef7:hover img {
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-632f2b97 .elementor-blockquote__content {
    color: #000000;
    font-family: "Montserrat", Sans-serif;
    font-size: 21px;
    font-weight: 700;
}

.elementor-17 .elementor-element.elementor-element-632f2b97 .elementor-blockquote__author {
    color: #000000;
    font-family: "Montserrat", Sans-serif;
    font-size: 28px;
    font-weight: 800;
}

.elementor-17 .elementor-element.elementor-element-632f2b97 .elementor-blockquote {
    border-radius: 5px;
}

.elementor-17 .elementor-element.elementor-element-632f2b97 {
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
    bottom: 8%;
}

body:not(.rtl) .elementor-17 .elementor-element.elementor-element-632f2b97 {
    left: 42%;
}

body.rtl .elementor-17 .elementor-element.elementor-element-632f2b97 {
    right: 42%;
}

.elementor-17 .elementor-element.elementor-element-52983086 > .elementor-element-populated {
    padding: 10% 10% 10% 10%;
}

.elementor-17 .elementor-element.elementor-element-1916d75 {
    --divider-border-style: parallelogram;
    --divider-color: #F77F00;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
}

.elementor-17 .elementor-element.elementor-element-1916d75 .elementor-divider-separator {
    width: 100px;
}

.elementor-17 .elementor-element.elementor-element-1916d75 .elementor-divider {
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec.ekit-equal-height-enable, .elementor-17 .elementor-element.elementor-element-1d1941ec.ekit-equal-height-enable .elementor-widget-container, .elementor-17 .elementor-element.elementor-element-1d1941ec.ekit-equal-height-enable .ekit-wid-con, .elementor-17 .elementor-element.elementor-element-1d1941ec.ekit-equal-height-enable .ekit-wid-con .elementskit-infobox {
    height: 100%;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec .elementskit-infobox {
    padding: 10% 10% 10% 10%;
    border-style: solid;
    border-width: 0 0 0 0;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec .elementskit-infobox .elementskit-info-box-title {
    padding: 0 0 0 0;
    color: #000000;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec .elementkit-infobox-icon {
    color: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec .elementskit-info-box-icon > svg path {
    fill: #F77F00;
    stroke: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec .elementskit-infobox .elementskit-info-box-icon i {
    font-size: 50px;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec .elementskit-info-box-icon > svg {
    max-width: 50px;
    height: auto;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec .elementskit-infobox .elementskit-info-box-icon {
    transform: rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-1d1941ec > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
}

.elementor-17 .elementor-element.elementor-element-1d1941ec {
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-27a31259.ekit-equal-height-enable, .elementor-17 .elementor-element.elementor-element-27a31259.ekit-equal-height-enable .elementor-widget-container, .elementor-17 .elementor-element.elementor-element-27a31259.ekit-equal-height-enable .ekit-wid-con, .elementor-17 .elementor-element.elementor-element-27a31259.ekit-equal-height-enable .ekit-wid-con .elementskit-infobox {
    height: 100%;
}

.elementor-17 .elementor-element.elementor-element-27a31259 .elementskit-infobox {
    padding: 10% 10% 10% 10%;
    border-style: solid;
    border-width: 0 0 0 0;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}

.elementor-17 .elementor-element.elementor-element-27a31259 .elementskit-infobox .elementskit-info-box-title {
    padding: 0 0 0 0;
    color: #000000;
}

.elementor-17 .elementor-element.elementor-element-27a31259 .elementkit-infobox-icon {
    color: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-27a31259 .elementskit-info-box-icon > svg path {
    fill: #F77F00;
    stroke: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-27a31259 .elementskit-infobox .elementskit-info-box-icon i {
    font-size: 50px;
}

.elementor-17 .elementor-element.elementor-element-27a31259 .elementskit-info-box-icon > svg {
    max-width: 50px;
    height: auto;
}

.elementor-17 .elementor-element.elementor-element-27a31259 .elementskit-infobox .elementskit-info-box-icon {
    transform: rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-27a31259 > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
}

.elementor-17 .elementor-element.elementor-element-27a31259 {
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-3e497bff.ekit-equal-height-enable, .elementor-17 .elementor-element.elementor-element-3e497bff.ekit-equal-height-enable .elementor-widget-container, .elementor-17 .elementor-element.elementor-element-3e497bff.ekit-equal-height-enable .ekit-wid-con, .elementor-17 .elementor-element.elementor-element-3e497bff.ekit-equal-height-enable .ekit-wid-con .elementskit-infobox {
    height: 100%;
}

.elementor-17 .elementor-element.elementor-element-3e497bff .elementskit-infobox {
    padding: 10% 10% 10% 10%;
    border-style: solid;
    border-width: 0 0 0 0;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}

.elementor-17 .elementor-element.elementor-element-3e497bff .elementskit-infobox .elementskit-info-box-title {
    padding: 0 0 0 0;
    color: #000000;
}

.elementor-17 .elementor-element.elementor-element-3e497bff .elementkit-infobox-icon {
    color: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-3e497bff .elementskit-info-box-icon > svg path {
    fill: #F77F00;
    stroke: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-3e497bff .elementskit-infobox .elementskit-info-box-icon i {
    font-size: 50px;
}

.elementor-17 .elementor-element.elementor-element-3e497bff .elementskit-info-box-icon > svg {
    max-width: 50px;
    height: auto;
}

.elementor-17 .elementor-element.elementor-element-3e497bff .elementskit-infobox .elementskit-info-box-icon {
    transform: rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-3e497bff > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
}

.elementor-17 .elementor-element.elementor-element-3e497bff {
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-12315986.ekit-equal-height-enable, .elementor-17 .elementor-element.elementor-element-12315986.ekit-equal-height-enable .elementor-widget-container, .elementor-17 .elementor-element.elementor-element-12315986.ekit-equal-height-enable .ekit-wid-con, .elementor-17 .elementor-element.elementor-element-12315986.ekit-equal-height-enable .ekit-wid-con .elementskit-infobox {
    height: 100%;
}

.elementor-17 .elementor-element.elementor-element-12315986 .elementskit-infobox {
    padding: 10% 10% 10% 10%;
    border-style: solid;
    border-width: 0 0 0 0;
    border-color: #f5f5f5;
    border-radius: 5px 5px 5px 5px;
}

.elementor-17 .elementor-element.elementor-element-12315986 .elementskit-infobox .elementskit-info-box-title {
    padding: 0 0 0 0;
    color: #000000;
}

.elementor-17 .elementor-element.elementor-element-12315986 .elementkit-infobox-icon {
    color: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-12315986 .elementskit-info-box-icon > svg path {
    fill: #F77F00;
    stroke: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-12315986 .elementskit-infobox .elementskit-info-box-icon i {
    font-size: 50px;
}

.elementor-17 .elementor-element.elementor-element-12315986 .elementskit-info-box-icon > svg {
    max-width: 50px;
    height: auto;
}

.elementor-17 .elementor-element.elementor-element-12315986 .elementskit-infobox .elementskit-info-box-icon {
    transform: rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-12315986 > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
}

.elementor-17 .elementor-element.elementor-element-12315986 {
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-2a2cbd80 > .elementor-background-overlay {
    background-image: url("https://show.moxcreative.com/carbon/wp-content/uploads/sites/61/2023/03/happy-men-in-auto-repair-service.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.1;
}

.elementor-17 .elementor-element.elementor-element-2a2cbd80 > .elementor-shape-top .elementor-shape-fill {
    fill: #DAA520;
}

.elementor-17 .elementor-element.elementor-element-2a2cbd80 {
    padding: 5% 0 5% 0;
}

.elementor-17 .elementor-element.elementor-element-28069df0.elementor-column > .elementor-widget-wrap {
    justify-content: flex-end;
}

.elementor-17 .elementor-element.elementor-element-28069df0:not(.elementor-motion-effects-element-type-background) > .elementor-widget-wrap {
    background-color: #FFFFFF;
}

.elementor-17 .elementor-element.elementor-element-28069df0 > .elementor-element-populated, .elementor-17 .elementor-element.elementor-element-28069df0 > .elementor-element-populated > .elementor-background-overlay, .elementor-17 .elementor-element.elementor-element-28069df0 > .elementor-background-slideshow {
    border-radius: 5px 5px 5px 5px;
}

.elementor-17 .elementor-element.elementor-element-28069df0 > .elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0 0 0 0;
    padding: 5% 10%
}

.elementor-17 .elementor-element.elementor-element-28069df0 > .elementor-element-populated > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-17 .elementor-element.elementor-element-1982473e {
    text-align: left;
}

.elementor-17 .elementor-element.elementor-element-1982473e .elementor-heading-title {
    color: #000000;
}

.elementor-17 .elementor-element.elementor-element-2228a623 {
    --divider-border-style: parallelogram;
    --divider-color: #F77F00;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
}

.elementor-17 .elementor-element.elementor-element-2228a623 .elementor-divider-separator {
    width: 100px;
}

.elementor-17 .elementor-element.elementor-element-2228a623 .elementor-divider {
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .elementor-testimonial__text {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
    font-style: normal;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .elementor-testimonial__name {
    color: #000000;
    font-size: 16px;
    font-weight: 800;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .elementor-testimonial__title {
    color: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .elementor-testimonial__image img {
    width: 75px;
    height: 75px;
}

.elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_left .elementor-testimonial__content:after,
.elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_right .elementor-testimonial__content:after {
    top: calc(10% + (75px / 2) - 8px);
}

body:not(.rtl) .elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_stacked:not(.elementor-testimonial--align-center):not(.elementor-testimonial--align-right) .elementor-testimonial__content:after,
body:not(.rtl) .elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_inline:not(.elementor-testimonial--align-center):not(.elementor-testimonial--align-right) .elementor-testimonial__content:after,
.elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-left .elementor-testimonial__content:after,
.elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_inline.elementor-testimonial--align-left .elementor-testimonial__content:after {
    left: calc(10% + (75px / 2) - 8px);
    right: auto;
}

body.rtl .elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_stacked:not(.elementor-testimonial--align-center):not(.elementor-testimonial--align-left) .elementor-testimonial__content:after,
body.rtl .elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_inline:not(.elementor-testimonial--align-center):not(.elementor-testimonial--align-left) .elementor-testimonial__content:after,
.elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_stacked.elementor-testimonial--align-right .elementor-testimonial__content:after,
.elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_inline.elementor-testimonial--align-right .elementor-testimonial__content:after {
    right: calc(10% + (75px / 2) - 8px);
    left: auto;
}

body:not(.rtl) .elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_above:not(.elementor-testimonial--align-center):not(.elementor-testimonial--align-right) .elementor-testimonial__content:after,
.elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_above.elementor-testimonial--align-left .elementor-testimonial__content:after {
    left: calc(10% + (75px / 2) - 8px);
    right: auto;
}

body.rtl .elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_above:not(.elementor-testimonial--align-center):not(.elementor-testimonial--align-left) .elementor-testimonial__content:after,
.elementor-17 .elementor-element.elementor-element-36ac326b.elementor-testimonial--layout-image_above.elementor-testimonial--align-right .elementor-testimonial__content:after {
    right: calc(10% + (75px / 2) - 8px);
    left: auto;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .elementor-swiper-button {
    font-size: 20px;
    color: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .elementor-swiper-button svg {
    fill: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .swiper-pagination-bullet {
    height: 13px;
    width: 13px;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .swiper-container-horizontal .swiper-pagination-progressbar {
    height: 13px;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .swiper-pagination-fraction {
    font-size: 13px;
    color: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-36ac326b .swiper-pagination-bullet-active, .elementor-17 .elementor-element.elementor-element-36ac326b .swiper-pagination-progressbar-fill {
    background-color: #F77F00;
}

.elementor-17 .elementor-element.elementor-element-412b4a23 > .elementor-element-populated {
    padding: 0 0 0 0;
}

.elementor-17 .elementor-element.elementor-element-16dade0 {
    padding: 5% 0 5% 0;
}

.elementor-17 .elementor-element.elementor-element-1881ddb1 {
    text-align: center;
}

.elementor-17 .elementor-element.elementor-element-482986f7 {
    --divider-border-style: parallelogram;
    --divider-color: #F77F00;
    --divider-pattern-height: 20px;
    --divider-pattern-size: 20px;
}

.elementor-17 .elementor-element.elementor-element-482986f7 .elementor-divider-separator {
    width: 100px;
    margin: 0 auto;
    margin-center: 0;
}

.elementor-17 .elementor-element.elementor-element-482986f7 .elementor-divider {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6:after {
    content: "1.3";
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__thumbnail__link {
    width: 50%;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6 {
    --grid-column-gap: 30px;
    --grid-row-gap: 35px;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post {
    border-radius: 5px;
    background-color: #333533;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__text {
    padding: 25px 0 0 0;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6.elementor-posts--thumbnail-left .elementor-post__thumbnail__link {
    margin-right: 20px;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6.elementor-posts--thumbnail-right .elementor-post__thumbnail__link {
    margin-left: 20px;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6.elementor-posts--thumbnail-top .elementor-post__thumbnail__link {
    margin-bottom: 20px;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__title, .elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__title a {
    font-size: 21px;
    line-height: 1.5em;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__title {
    margin-bottom: 16px;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__excerpt p {
    color: #FFFFFF;
}

.elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__excerpt {
    margin-bottom: 16px;
}

.elementor-17 .elementor-element.elementor-element-4a57d911 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-17 .elementor-element.elementor-element-4a57d911 > .elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-17 .elementor-element.elementor-element-3a425bdf img {
    filter: brightness(200%) contrast(100%) saturate(200%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-3a425bdf {
    width: var(--container-widget-width, 20%);
    max-width: 20%;
    --container-widget-width: 20%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-18f20dac img {
    filter: brightness(200%) contrast(100%) saturate(200%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-18f20dac {
    width: var(--container-widget-width, 20%);
    max-width: 20%;
    --container-widget-width: 20%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-55864ca2 img {
    filter: brightness(200%) contrast(100%) saturate(200%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-55864ca2 {
    width: var(--container-widget-width, 20%);
    max-width: 20%;
    --container-widget-width: 20%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-27a5d56c img {
    filter: brightness(200%) contrast(100%) saturate(200%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-27a5d56c {
    width: var(--container-widget-width, 20%);
    max-width: 20%;
    --container-widget-width: 20%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-36d2139b img {
    filter: brightness(200%) contrast(100%) saturate(200%) blur(0px) hue-rotate(0deg);
}

.elementor-17 .elementor-element.elementor-element-36d2139b {
    width: var(--container-widget-width, 20%);
    max-width: 20%;
    --container-widget-width: 20%;
    --container-widget-flex-grow: 0;
}

.elementor-17 .elementor-element.elementor-element-49595d41:not(.elementor-motion-effects-element-type-background) {
    background-color: #202020;
}

.elementor-17 .elementor-element.elementor-element-49595d41 > .elementor-background-overlay {
    background-image: url("../../images/tools.jpeg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.2;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-17 .elementor-element.elementor-element-49595d41 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 10% 0 10% 0;
}

.elementor-17 .elementor-element.elementor-element-375d1bb6 {
    text-align: center;
}

.elementor-17 .elementor-element.elementor-element-14dff119 {
    text-align: center;
}

.elementor-17 .elementor-element.elementor-element-290723d6 {
    text-align: center;
}

@media (max-width: 1024px) {
    .elementor-17 .elementor-element.elementor-element-5c7d55cf > .elementor-container {
        min-height: 85vh;
    }

    .elementor-17 .elementor-element.elementor-element-6cba7690 .elementor-heading-title {
        font-size: var(--e-global-typography-579108b-font-size);
        line-height: var(--e-global-typography-579108b-line-height);
        letter-spacing: var(--e-global-typography-579108b-letter-spacing);
        word-spacing: var(--e-global-typography-579108b-word-spacing);
    }

    .elementor-17 .elementor-element.elementor-element-15c1ac59 > .elementor-element-populated {
        padding: 5% 5% 5% 5%;
    }

    .elementor-17 .elementor-element.elementor-element-6266aef7 img {
        height: 45vh;
    }

    .elementor-17 .elementor-element.elementor-element-28069df0 > .elementor-element-populated {
        margin: 0 0 0 0;
        --e-column-margin-right: 0%;
        --e-column-margin-left: 0%;
        padding: 5% 5% 5% 5%;
    }

    .elementor-17 .elementor-element.elementor-element-1982473e {
        text-align: center;
    }

    .elementor-17 .elementor-element.elementor-element-2228a623 .elementor-divider {
        text-align: center;
    }

    .elementor-17 .elementor-element.elementor-element-2228a623 .elementor-divider-separator {
        margin: 0 auto;
        margin-center: 0;
    }

    .elementor-17 .elementor-element.elementor-element-412b4a23 > .elementor-element-populated {
        margin: 0 0 0 0;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }
}

@media (max-width: 767px) {
    .elementor-17 .elementor-element.elementor-element-6cba7690 .elementor-heading-title {
        font-size: 45px;
        line-height: var(--e-global-typography-579108b-line-height);
        letter-spacing: var(--e-global-typography-579108b-letter-spacing);
        word-spacing: var(--e-global-typography-579108b-word-spacing);
    }

    .elementor-17 .elementor-element.elementor-element-6bff978c img {
        width: 100%;
    }

    .elementor-17 .elementor-element.elementor-element-3dc9e8b4 {
        width: 50%;
    }

    .elementor-17 .elementor-element.elementor-element-2780d8a {
        width: 50%;
    }

    .elementor-17 .elementor-element.elementor-element-b0743b9 {
        text-align: center;
    }

    .elementor-17 .elementor-element.elementor-element-63f51f4b .elementor-divider {
        text-align: center;
    }

    .elementor-17 .elementor-element.elementor-element-63f51f4b .elementor-divider-separator {
        margin: 0 auto;
        margin-center: 0;
    }

    .elementor-17 .elementor-element.elementor-element-1df73afe {
        text-align: center;
    }

    .elementor-17 .elementor-element.elementor-element-4dade9a8 {
        width: 100%;
        max-width: 100%;
    }

    .elementor-17 .elementor-element.elementor-element-3483e87e {
        width: 50%;
    }

    .elementor-17 .elementor-element.elementor-element-61295c1e .elementor-image-box-img {
        margin-bottom: 15px;
    }

    .elementor-17 .elementor-element.elementor-element-d522f9c {
        width: 50%;
    }

    .elementor-17 .elementor-element.elementor-element-46f0257 .elementor-image-box-img {
        margin-bottom: 15px;
    }

    .elementor-17 .elementor-element.elementor-element-4ad88b9e {
        width: 50%;
    }

    .elementor-17 .elementor-element.elementor-element-65555ee4 .elementor-image-box-img {
        margin-bottom: 15px;
    }

    .elementor-17 .elementor-element.elementor-element-47ac6dc4 {
        width: 50%;
    }

    .elementor-17 .elementor-element.elementor-element-274617ea .elementor-image-box-img {
        margin-bottom: 15px;
    }

    .elementor-17 .elementor-element.elementor-element-632f2b97 {
        width: var(--container-widget-width, 60%);
        max-width: 60%;
        --container-widget-width: 60%;
        --container-widget-flex-grow: 0;
        bottom: 0;
    }

    body:not(.rtl) .elementor-17 .elementor-element.elementor-element-632f2b97 {
        left: 0;
    }

    body.rtl .elementor-17 .elementor-element.elementor-element-632f2b97 {
        right: 0;
    }

    .elementor-17 .elementor-element.elementor-element-52983086 > .elementor-element-populated {
        padding: 5% 5% 5% 5%;
    }

    .elementor-17 .elementor-element.elementor-element-4bac0a2e {
        text-align: center;
    }

    .elementor-17 .elementor-element.elementor-element-2e56cf45 {
        text-align: center;
    }

    .elementor-17 .elementor-element.elementor-element-1d1941ec .elementskit-infobox .elementskit-info-box-title {
        font-size: 18px;
    }

    .elementor-17 .elementor-element.elementor-element-27a31259 .elementskit-infobox .elementskit-info-box-title {
        font-size: 18px;
    }

    .elementor-17 .elementor-element.elementor-element-3e497bff .elementskit-infobox .elementskit-info-box-title {
        font-size: 18px;
    }

    .elementor-17 .elementor-element.elementor-element-12315986 .elementskit-infobox .elementskit-info-box-title {
        font-size: 18px;
    }

    .elementor-17 .elementor-element.elementor-element-1982473e {
        text-align: center;
    }

    .elementor-17 .elementor-element.elementor-element-36ac326b .elementor-testimonial__text {
        font-size: 16px;
    }

    .elementor-17 .elementor-element.elementor-element-1ab7f1c6:after {
        content: "1.5";
    }

    .elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__thumbnail__link {
        width: 40%;
    }

    .elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__title, .elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__title a {
        font-size: 16px;
        letter-spacing: 0;
    }

    .elementor-17 .elementor-element.elementor-element-1ab7f1c6 .elementor-post__excerpt p {
        font-size: 14px;
    }
}

@media (min-width: 768px) {
    .elementor-17 .elementor-element.elementor-element-3dc9e8b4 {
        width: 25%;
    }

    .elementor-17 .elementor-element.elementor-element-2780d8a {
        width: 25%;
    }

    .elementor-17 .elementor-element.elementor-element-417155e7 {
        width: 49.664%;
    }

    .elementor-17 .elementor-element.elementor-element-15c1ac59 {
        width: 50%;
    }

    .elementor-17 .elementor-element.elementor-element-52983086 {
        width: 50%;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .elementor-17 .elementor-element.elementor-element-15c1ac59 {
        width: 100%;
    }

    .elementor-17 .elementor-element.elementor-element-52983086 {
        width: 100%;
    }

    .elementor-17 .elementor-element.elementor-element-28069df0 {
        width: 100%;
    }

    .elementor-17 .elementor-element.elementor-element-412b4a23 {
        width: 100%;
    }
}
