.elementor-kit-6 {
    --e-global-color-primary: #6EC1E4;
    --e-global-color-secondary: #54595F;
    --e-global-color-text: #7A7A7A;
    --e-global-color-accent: #61CE70;
    --e-global-color-12b5d5f: #000000;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
    --e-global-typography-579108b-font-family: "Montserrat";
    --e-global-typography-579108b-font-size: 60px;
    --e-global-typography-579108b-font-weight: 800;
    --e-global-typography-579108b-line-height: 1.2em;
    --e-global-typography-67cc080-font-family: "Montserrat";
    --e-global-typography-67cc080-font-size: 38px;
    --e-global-typography-67cc080-font-weight: 700;
    color: #FFFFFF;
    font-family: "Archivo", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.9em;
    letter-spacing: 1px;
    background-color: #202020;
}

.elementor-kit-6 a {
    color: #DAA520;
}

.elementor-kit-6 a:hover {
    color: #b18412;
}

.elementor-kit-6 h1 {
    font-family: "Montserrat", Sans-serif;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -1.5px;
}

.elementor-kit-6 h2 {
    font-family: "Montserrat", Sans-serif;
    font-size: 38px;
    font-weight: 700;
    letter-spacing: -1.5px;
}

.elementor-kit-6 h3 {
    font-family: "Montserrat", Sans-serif;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -1.2px;
}

.elementor-kit-6 h4 {
    font-family: "Montserrat", Sans-serif;
    font-size: 21px;
    font-weight: 700;
    letter-spacing: -1.2px;
}

.elementor-kit-6 h5 {
    font-family: "Montserrat", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -1.2px;
}

.elementor-kit-6 h6 {
    font-family: "Lato", Sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.elementor-kit-6 button, .elementor-kit-6 input[type="button"], .elementor-kit-6 input[type="submit"], .elementor-kit-6 .elementor-button {
    font-family: "Montserrat", Sans-serif;
    font-weight: 700;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #000000;
    background-color: #DAA520;
    border-style: solid;
    border-width: 0 0 0 0;
    border-radius: 5px 5px 5px 5px;
}

.elementor-kit-6 button:hover, .elementor-kit-6 button:focus, .elementor-kit-6 input[type="button"]:hover, .elementor-kit-6 input[type="button"]:focus, .elementor-kit-6 input[type="submit"]:hover, .elementor-kit-6 input[type="submit"]:focus, .elementor-kit-6 .elementor-button:hover, .elementor-kit-6 .elementor-button:focus {
    color: #000000;
    background-color: #b18412;
}

.elementor-kit-6 label {
    color: #F77F00;
    font-family: "Montserrat", Sans-serif;
    font-weight: 600;
    line-height: 1.9em;
}

.elementor-kit-6 input:not([type="button"]):not([type="submit"]) {
    color: #202020;
    background-color: #D6D6D6;
    border-style: solid;
    border-width: 0 0 0 0;
}

.elementor-kit-6 input:focus:not([type="button"]):not([type="submit"]) {
    background-color: #FFFFFF;
}

.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
}

.e-con {
    --container-max-width: 1140px;
}

.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}

.elementor-element {
    --widgets-spacing: 20px;
}

{
}
h1.entry-title {
    display: var(--page-title-display);
}

.elementor-kit-6 e-page-transition {
    background-color: #FFBC7D;
}

.site-header {
    padding-right: 0;
    padding-left: 0;
}

@media (max-width: 1024px) {
    .elementor-section.elementor-section-boxed > .elementor-container {
        max-width: 1024px;
    }

    .e-con {
        --container-max-width: 1024px;
    }
}

@media (max-width: 767px) {
    .elementor-kit-6 {
        font-size: 14px;
    }

    .elementor-kit-6 h1 {
        font-size: 28px;
    }

    .elementor-kit-6 h2 {
        font-size: 21px;
    }

    .elementor-kit-6 h3 {
        font-size: 18px;
    }

    .elementor-kit-6 h4 {
        font-size: 16px;
    }

    .elementor-kit-6 h5 {
        font-size: 14px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
        max-width: 767px;
    }

    .e-con {
        --container-max-width: 767px;
    }
}
