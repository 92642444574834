.ekit-wid-con .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.ekit-wid-con .col, .ekit-wid-con .col-1, .ekit-wid-con .col-10, .ekit-wid-con .col-11, .ekit-wid-con .col-12, .ekit-wid-con .col-2, .ekit-wid-con .col-3, .ekit-wid-con .col-4, .ekit-wid-con .col-5, .ekit-wid-con .col-6, .ekit-wid-con .col-7, .ekit-wid-con .col-8, .ekit-wid-con .col-9, .ekit-wid-con .col-auto, .ekit-wid-con .col-lg, .ekit-wid-con .col-lg-1, .ekit-wid-con .col-lg-10, .ekit-wid-con .col-lg-11, .ekit-wid-con .col-lg-12, .ekit-wid-con .col-lg-2, .ekit-wid-con .col-lg-3, .ekit-wid-con .col-lg-4, .ekit-wid-con .col-lg-5, .ekit-wid-con .col-lg-6, .ekit-wid-con .col-lg-7, .ekit-wid-con .col-lg-8, .ekit-wid-con .col-lg-9, .ekit-wid-con .col-lg-auto, .ekit-wid-con .col-md, .ekit-wid-con .col-md-1, .ekit-wid-con .col-md-10, .ekit-wid-con .col-md-11, .ekit-wid-con .col-md-12, .ekit-wid-con .col-md-2, .ekit-wid-con .col-md-3, .ekit-wid-con .col-md-4, .ekit-wid-con .col-md-5, .ekit-wid-con .col-md-6, .ekit-wid-con .col-md-7, .ekit-wid-con .col-md-8, .ekit-wid-con .col-md-9, .ekit-wid-con .col-md-auto, .ekit-wid-con .col-sm, .ekit-wid-con .col-sm-1, .ekit-wid-con .col-sm-10, .ekit-wid-con .col-sm-11, .ekit-wid-con .col-sm-12, .ekit-wid-con .col-sm-2, .ekit-wid-con .col-sm-3, .ekit-wid-con .col-sm-4, .ekit-wid-con .col-sm-5, .ekit-wid-con .col-sm-6, .ekit-wid-con .col-sm-7, .ekit-wid-con .col-sm-8, .ekit-wid-con .col-sm-9, .ekit-wid-con .col-sm-auto, .ekit-wid-con .col-xl, .ekit-wid-con .col-xl-1, .ekit-wid-con .col-xl-10, .ekit-wid-con .col-xl-11, .ekit-wid-con .col-xl-12, .ekit-wid-con .col-xl-2, .ekit-wid-con .col-xl-3, .ekit-wid-con .col-xl-4, .ekit-wid-con .col-xl-5, .ekit-wid-con .col-xl-6, .ekit-wid-con .col-xl-7, .ekit-wid-con .col-xl-8, .ekit-wid-con .col-xl-9, .ekit-wid-con .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.ekit-wid-con .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.ekit-wid-con .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%
}

.ekit-wid-con .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%
}

.ekit-wid-con .row-cols-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.ekit-wid-con .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%
}

.ekit-wid-con .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%
}

.ekit-wid-con .row-cols-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.ekit-wid-con .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.ekit-wid-con .col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%
}

.ekit-wid-con .col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

.ekit-wid-con .col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.ekit-wid-con .col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.ekit-wid-con .col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%
}

.ekit-wid-con .col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.ekit-wid-con .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%
}

.ekit-wid-con .col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.ekit-wid-con .col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.ekit-wid-con .col-10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.ekit-wid-con .col-11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 91.666667%;
    max-width: 91.666667%
}

.ekit-wid-con .col-12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%
}

.ekit-wid-con .order-first {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1
}

.ekit-wid-con .order-last {
    -ms-flex-order: 13;
    -webkit-box-ordinal-group: 14;
    order: 13
}

.ekit-wid-con .order-0 {
    -ms-flex-order: 0;
    -webkit-box-ordinal-group: 1;
    order: 0
}

.ekit-wid-con .order-1 {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1
}

.ekit-wid-con .order-2 {
    -ms-flex-order: 2;
    -webkit-box-ordinal-group: 3;
    order: 2
}

.ekit-wid-con .order-3 {
    -ms-flex-order: 3;
    -webkit-box-ordinal-group: 4;
    order: 3
}

.ekit-wid-con .order-4 {
    -ms-flex-order: 4;
    -webkit-box-ordinal-group: 5;
    order: 4
}

.ekit-wid-con .order-5 {
    -ms-flex-order: 5;
    -webkit-box-ordinal-group: 6;
    order: 5
}

.ekit-wid-con .order-6 {
    -ms-flex-order: 6;
    -webkit-box-ordinal-group: 7;
    order: 6
}

.ekit-wid-con .order-7 {
    -ms-flex-order: 7;
    -webkit-box-ordinal-group: 8;
    order: 7
}

.ekit-wid-con .order-8 {
    -ms-flex-order: 8;
    -webkit-box-ordinal-group: 9;
    order: 8
}

.ekit-wid-con .order-9 {
    -ms-flex-order: 9;
    -webkit-box-ordinal-group: 10;
    order: 9
}

.ekit-wid-con .order-10 {
    -ms-flex-order: 10;
    -webkit-box-ordinal-group: 11;
    order: 10
}

.ekit-wid-con .order-11 {
    -ms-flex-order: 11;
    -webkit-box-ordinal-group: 12;
    order: 11
}

.ekit-wid-con .order-12 {
    -ms-flex-order: 12;
    -webkit-box-ordinal-group: 13;
    order: 12
}

.ekit-wid-con .offset-1 {
    margin-left: 8.333333%
}

.ekit-wid-con .offset-2 {
    margin-left: 16.666667%
}

.ekit-wid-con .offset-3 {
    margin-left: 25%
}

.ekit-wid-con .offset-4 {
    margin-left: 33.333333%
}

.ekit-wid-con .offset-5 {
    margin-left: 41.666667%
}

.ekit-wid-con .offset-6 {
    margin-left: 50%
}

.ekit-wid-con .offset-7 {
    margin-left: 58.333333%
}

.ekit-wid-con .offset-8 {
    margin-left: 66.666667%
}

.ekit-wid-con .offset-9 {
    margin-left: 75%
}

.ekit-wid-con .offset-10 {
    margin-left: 83.333333%
}

.ekit-wid-con .offset-11 {
    margin-left: 91.666667%
}

@media (min-width: 576px) {
    .ekit-wid-con .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .ekit-wid-con .row-cols-sm-1 > * {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .ekit-wid-con .row-cols-sm-2 > * {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .ekit-wid-con .row-cols-sm-3 > * {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .ekit-wid-con .row-cols-sm-4 > * {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .ekit-wid-con .row-cols-sm-5 > * {
        -ms-flex: 0 0 20%;
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .ekit-wid-con .row-cols-sm-6 > * {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .ekit-wid-con .col-sm-auto {
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .ekit-wid-con .col-sm-1 {
        -ms-flex: 0 0 8.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .ekit-wid-con .col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .ekit-wid-con .col-sm-3 {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .ekit-wid-con .col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .ekit-wid-con .col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .ekit-wid-con .col-sm-6 {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .ekit-wid-con .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .ekit-wid-con .col-sm-8 {
        -ms-flex: 0 0 66.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .ekit-wid-con .col-sm-9 {
        -ms-flex: 0 0 75%;
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .ekit-wid-con .col-sm-10 {
        -ms-flex: 0 0 83.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .ekit-wid-con .col-sm-11 {
        -ms-flex: 0 0 91.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .ekit-wid-con .col-sm-12 {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .ekit-wid-con .order-sm-first {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .ekit-wid-con .order-sm-last {
        -ms-flex-order: 13;
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .ekit-wid-con .order-sm-0 {
        -ms-flex-order: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .ekit-wid-con .order-sm-1 {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .ekit-wid-con .order-sm-2 {
        -ms-flex-order: 2;
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .ekit-wid-con .order-sm-3 {
        -ms-flex-order: 3;
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .ekit-wid-con .order-sm-4 {
        -ms-flex-order: 4;
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .ekit-wid-con .order-sm-5 {
        -ms-flex-order: 5;
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .ekit-wid-con .order-sm-6 {
        -ms-flex-order: 6;
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .ekit-wid-con .order-sm-7 {
        -ms-flex-order: 7;
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .ekit-wid-con .order-sm-8 {
        -ms-flex-order: 8;
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .ekit-wid-con .order-sm-9 {
        -ms-flex-order: 9;
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .ekit-wid-con .order-sm-10 {
        -ms-flex-order: 10;
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .ekit-wid-con .order-sm-11 {
        -ms-flex-order: 11;
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .ekit-wid-con .order-sm-12 {
        -ms-flex-order: 12;
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .ekit-wid-con .offset-sm-0 {
        margin-left: 0
    }

    .ekit-wid-con .offset-sm-1 {
        margin-left: 8.333333%
    }

    .ekit-wid-con .offset-sm-2 {
        margin-left: 16.666667%
    }

    .ekit-wid-con .offset-sm-3 {
        margin-left: 25%
    }

    .ekit-wid-con .offset-sm-4 {
        margin-left: 33.333333%
    }

    .ekit-wid-con .offset-sm-5 {
        margin-left: 41.666667%
    }

    .ekit-wid-con .offset-sm-6 {
        margin-left: 50%
    }

    .ekit-wid-con .offset-sm-7 {
        margin-left: 58.333333%
    }

    .ekit-wid-con .offset-sm-8 {
        margin-left: 66.666667%
    }

    .ekit-wid-con .offset-sm-9 {
        margin-left: 75%
    }

    .ekit-wid-con .offset-sm-10 {
        margin-left: 83.333333%
    }

    .ekit-wid-con .offset-sm-11 {
        margin-left: 91.666667%
    }
}

@media (min-width: 768px) {
    .ekit-wid-con .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .ekit-wid-con .row-cols-md-1 > * {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .ekit-wid-con .row-cols-md-2 > * {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .ekit-wid-con .row-cols-md-3 > * {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .ekit-wid-con .row-cols-md-4 > * {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .ekit-wid-con .row-cols-md-5 > * {
        -ms-flex: 0 0 20%;
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .ekit-wid-con .row-cols-md-6 > * {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .ekit-wid-con .col-md-auto {
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .ekit-wid-con .col-md-1 {
        -ms-flex: 0 0 8.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .ekit-wid-con .col-md-2 {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .ekit-wid-con .col-md-3 {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .ekit-wid-con .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .ekit-wid-con .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .ekit-wid-con .col-md-6 {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .ekit-wid-con .col-md-7 {
        -ms-flex: 0 0 58.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .ekit-wid-con .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .ekit-wid-con .col-md-9 {
        -ms-flex: 0 0 75%;
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .ekit-wid-con .col-md-10 {
        -ms-flex: 0 0 83.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .ekit-wid-con .col-md-11 {
        -ms-flex: 0 0 91.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .ekit-wid-con .col-md-12 {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .ekit-wid-con .order-md-first {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .ekit-wid-con .order-md-last {
        -ms-flex-order: 13;
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .ekit-wid-con .order-md-0 {
        -ms-flex-order: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .ekit-wid-con .order-md-1 {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .ekit-wid-con .order-md-2 {
        -ms-flex-order: 2;
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .ekit-wid-con .order-md-3 {
        -ms-flex-order: 3;
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .ekit-wid-con .order-md-4 {
        -ms-flex-order: 4;
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .ekit-wid-con .order-md-5 {
        -ms-flex-order: 5;
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .ekit-wid-con .order-md-6 {
        -ms-flex-order: 6;
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .ekit-wid-con .order-md-7 {
        -ms-flex-order: 7;
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .ekit-wid-con .order-md-8 {
        -ms-flex-order: 8;
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .ekit-wid-con .order-md-9 {
        -ms-flex-order: 9;
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .ekit-wid-con .order-md-10 {
        -ms-flex-order: 10;
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .ekit-wid-con .order-md-11 {
        -ms-flex-order: 11;
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .ekit-wid-con .order-md-12 {
        -ms-flex-order: 12;
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .ekit-wid-con .offset-md-0 {
        margin-left: 0
    }

    .ekit-wid-con .offset-md-1 {
        margin-left: 8.333333%
    }

    .ekit-wid-con .offset-md-2 {
        margin-left: 16.666667%
    }

    .ekit-wid-con .offset-md-3 {
        margin-left: 25%
    }

    .ekit-wid-con .offset-md-4 {
        margin-left: 33.333333%
    }

    .ekit-wid-con .offset-md-5 {
        margin-left: 41.666667%
    }

    .ekit-wid-con .offset-md-6 {
        margin-left: 50%
    }

    .ekit-wid-con .offset-md-7 {
        margin-left: 58.333333%
    }

    .ekit-wid-con .offset-md-8 {
        margin-left: 66.666667%
    }

    .ekit-wid-con .offset-md-9 {
        margin-left: 75%
    }

    .ekit-wid-con .offset-md-10 {
        margin-left: 83.333333%
    }

    .ekit-wid-con .offset-md-11 {
        margin-left: 91.666667%
    }
}

@media (min-width: 992px) {
    .ekit-wid-con .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .ekit-wid-con .row-cols-lg-1 > * {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .ekit-wid-con .row-cols-lg-2 > * {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .ekit-wid-con .row-cols-lg-3 > * {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .ekit-wid-con .row-cols-lg-4 > * {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .ekit-wid-con .row-cols-lg-5 > * {
        -ms-flex: 0 0 20%;
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .ekit-wid-con .row-cols-lg-6 > * {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .ekit-wid-con .col-lg-auto {
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .ekit-wid-con .col-lg-1 {
        -ms-flex: 0 0 8.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .ekit-wid-con .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .ekit-wid-con .col-lg-3 {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .ekit-wid-con .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .ekit-wid-con .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .ekit-wid-con .col-lg-6 {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .ekit-wid-con .col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .ekit-wid-con .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .ekit-wid-con .col-lg-9 {
        -ms-flex: 0 0 75%;
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .ekit-wid-con .col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .ekit-wid-con .col-lg-11 {
        -ms-flex: 0 0 91.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .ekit-wid-con .col-lg-12 {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .ekit-wid-con .order-lg-first {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .ekit-wid-con .order-lg-last {
        -ms-flex-order: 13;
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .ekit-wid-con .order-lg-0 {
        -ms-flex-order: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .ekit-wid-con .order-lg-1 {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .ekit-wid-con .order-lg-2 {
        -ms-flex-order: 2;
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .ekit-wid-con .order-lg-3 {
        -ms-flex-order: 3;
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .ekit-wid-con .order-lg-4 {
        -ms-flex-order: 4;
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .ekit-wid-con .order-lg-5 {
        -ms-flex-order: 5;
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .ekit-wid-con .order-lg-6 {
        -ms-flex-order: 6;
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .ekit-wid-con .order-lg-7 {
        -ms-flex-order: 7;
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .ekit-wid-con .order-lg-8 {
        -ms-flex-order: 8;
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .ekit-wid-con .order-lg-9 {
        -ms-flex-order: 9;
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .ekit-wid-con .order-lg-10 {
        -ms-flex-order: 10;
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .ekit-wid-con .order-lg-11 {
        -ms-flex-order: 11;
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .ekit-wid-con .order-lg-12 {
        -ms-flex-order: 12;
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .ekit-wid-con .offset-lg-0 {
        margin-left: 0
    }

    .ekit-wid-con .offset-lg-1 {
        margin-left: 8.333333%
    }

    .ekit-wid-con .offset-lg-2 {
        margin-left: 16.666667%
    }

    .ekit-wid-con .offset-lg-3 {
        margin-left: 25%
    }

    .ekit-wid-con .offset-lg-4 {
        margin-left: 33.333333%
    }

    .ekit-wid-con .offset-lg-5 {
        margin-left: 41.666667%
    }

    .ekit-wid-con .offset-lg-6 {
        margin-left: 50%
    }

    .ekit-wid-con .offset-lg-7 {
        margin-left: 58.333333%
    }

    .ekit-wid-con .offset-lg-8 {
        margin-left: 66.666667%
    }

    .ekit-wid-con .offset-lg-9 {
        margin-left: 75%
    }

    .ekit-wid-con .offset-lg-10 {
        margin-left: 83.333333%
    }

    .ekit-wid-con .offset-lg-11 {
        margin-left: 91.666667%
    }
}

@media (min-width: 1200px) {
    .ekit-wid-con .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .ekit-wid-con .row-cols-xl-1 > * {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .ekit-wid-con .row-cols-xl-2 > * {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .ekit-wid-con .row-cols-xl-3 > * {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .ekit-wid-con .row-cols-xl-4 > * {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .ekit-wid-con .row-cols-xl-5 > * {
        -ms-flex: 0 0 20%;
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%
    }

    .ekit-wid-con .row-cols-xl-6 > * {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .ekit-wid-con .col-xl-auto {
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .ekit-wid-con .col-xl-1 {
        -ms-flex: 0 0 8.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .ekit-wid-con .col-xl-2 {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .ekit-wid-con .col-xl-3 {
        -ms-flex: 0 0 25%;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .ekit-wid-con .col-xl-4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .ekit-wid-con .col-xl-5 {
        -ms-flex: 0 0 41.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .ekit-wid-con .col-xl-6 {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .ekit-wid-con .col-xl-7 {
        -ms-flex: 0 0 58.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .ekit-wid-con .col-xl-8 {
        -ms-flex: 0 0 66.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .ekit-wid-con .col-xl-9 {
        -ms-flex: 0 0 75%;
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .ekit-wid-con .col-xl-10 {
        -ms-flex: 0 0 83.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .ekit-wid-con .col-xl-11 {
        -ms-flex: 0 0 91.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .ekit-wid-con .col-xl-12 {
        -ms-flex: 0 0 100%;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .ekit-wid-con .order-xl-first {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1
    }

    .ekit-wid-con .order-xl-last {
        -ms-flex-order: 13;
        -webkit-box-ordinal-group: 14;
        order: 13
    }

    .ekit-wid-con .order-xl-0 {
        -ms-flex-order: 0;
        -webkit-box-ordinal-group: 1;
        order: 0
    }

    .ekit-wid-con .order-xl-1 {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1
    }

    .ekit-wid-con .order-xl-2 {
        -ms-flex-order: 2;
        -webkit-box-ordinal-group: 3;
        order: 2
    }

    .ekit-wid-con .order-xl-3 {
        -ms-flex-order: 3;
        -webkit-box-ordinal-group: 4;
        order: 3
    }

    .ekit-wid-con .order-xl-4 {
        -ms-flex-order: 4;
        -webkit-box-ordinal-group: 5;
        order: 4
    }

    .ekit-wid-con .order-xl-5 {
        -ms-flex-order: 5;
        -webkit-box-ordinal-group: 6;
        order: 5
    }

    .ekit-wid-con .order-xl-6 {
        -ms-flex-order: 6;
        -webkit-box-ordinal-group: 7;
        order: 6
    }

    .ekit-wid-con .order-xl-7 {
        -ms-flex-order: 7;
        -webkit-box-ordinal-group: 8;
        order: 7
    }

    .ekit-wid-con .order-xl-8 {
        -ms-flex-order: 8;
        -webkit-box-ordinal-group: 9;
        order: 8
    }

    .ekit-wid-con .order-xl-9 {
        -ms-flex-order: 9;
        -webkit-box-ordinal-group: 10;
        order: 9
    }

    .ekit-wid-con .order-xl-10 {
        -ms-flex-order: 10;
        -webkit-box-ordinal-group: 11;
        order: 10
    }

    .ekit-wid-con .order-xl-11 {
        -ms-flex-order: 11;
        -webkit-box-ordinal-group: 12;
        order: 11
    }

    .ekit-wid-con .order-xl-12 {
        -ms-flex-order: 12;
        -webkit-box-ordinal-group: 13;
        order: 12
    }

    .ekit-wid-con .offset-xl-0 {
        margin-left: 0
    }

    .ekit-wid-con .offset-xl-1 {
        margin-left: 8.333333%
    }

    .ekit-wid-con .offset-xl-2 {
        margin-left: 16.666667%
    }

    .ekit-wid-con .offset-xl-3 {
        margin-left: 25%
    }

    .ekit-wid-con .offset-xl-4 {
        margin-left: 33.333333%
    }

    .ekit-wid-con .offset-xl-5 {
        margin-left: 41.666667%
    }

    .ekit-wid-con .offset-xl-6 {
        margin-left: 50%
    }

    .ekit-wid-con .offset-xl-7 {
        margin-left: 58.333333%
    }

    .ekit-wid-con .offset-xl-8 {
        margin-left: 66.666667%
    }

    .ekit-wid-con .offset-xl-9 {
        margin-left: 75%
    }

    .ekit-wid-con .offset-xl-10 {
        margin-left: 83.333333%
    }

    .ekit-wid-con .offset-xl-11 {
        margin-left: 91.666667%
    }
}

.ekit-wid-con .fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.ekit-wid-con .fade.in {
    opacity: 1
}

.ekit-wid-con .collapse {
    display: none
}

.ekit-wid-con .collapse.in {
    display: block
}

.ekit-wid-con .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease
}

.ekit-wid-con .nav-tabs {
    border-bottom: 1px solid #ddd
}

.ekit-wid-con .nav-tabs > li {
    float: left;
    margin-bottom: -1px
}

.ekit-wid-con .nav-tabs > li > a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0
}

.ekit-wid-con .nav-tabs > li.active > a, .ekit-wid-con .nav-tabs > li.active > a:focus, .ekit-wid-con .nav-tabs > li.active > a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent
}

.ekit-wid-con .tab-content > .tab-pane {
    display: none
}

.ekit-wid-con .tab-content > .active {
    display: block
}

.ekit-wid-con .clearfix::after, .ekit-wid-con .clearfix::before, .ekit-wid-con .nav::after, .ekit-wid-con .nav::before, .ekit-wid-con .row::after, .ekit-wid-con .row::before {
    display: table;
    content: " "
}

.ekit-wid-con .clearfix::after, .ekit-wid-con .nav::after, .ekit-wid-con .row::after {
    clear: both
}

.ekit-wid-con .hide {
    display: none !important
}

.ekit-wid-con .show {
    display: block !important
}

:focus {
    outline: 0
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: .8
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: 0 !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.mfp-container::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle
}

.mfp-align-top .mfp-container::before {
    display: none
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content, .mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close.ekit-popup-close {
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow, .mfp-close.ekit-popup-close, .mfp-counter, .mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #ccc
}

.mfp-preloader a:hover {
    color: #fff
}

.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-s-error .mfp-content {
    display: none
}

button.mfp-arrow, button.mfp-close.ekit-popup-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close.ekit-popup-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0 0 18px 10px;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace
}

.mfp-close.ekit-popup-close:focus, .mfp-close.ekit-popup-close:hover {
    opacity: 1
}

.mfp-close.ekit-popup-close:active {
    top: 1px
}

.mfp-close-btn-in .mfp-closeekit-popup-close {
    color: #333
}

.mfp-iframe-holder .mfp-close.ekit-popup-close, .mfp-image-holder .mfp-close.ekit-popup-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    top: 50%;
    margin: -55px 0 0;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:focus, .mfp-arrow:hover {
    opacity: 1
}

.mfp-arrow::after, .mfp-arrow::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent
}

.mfp-arrow::after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px
}

.mfp-arrow::before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: .7
}

.mfp-arrow-left {
    left: 0
}

.mfp-arrow-left::after {
    border-right: 17px solid #fff;
    margin-left: 31px
}

.mfp-arrow-left::before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f
}

.mfp-arrow-right {
    right: 0
}

.mfp-arrow-right::after {
    border-left: 17px solid #fff;
    margin-left: 39px
}

.mfp-arrow-right::before {
    border-left: 27px solid #3f3f3f
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close.ekit-popup-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #000
}

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto
}

.mfp-figure {
    line-height: 0
}

.mfp-figure::after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #444
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure::after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close.ekit-popup-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.mfp-fade.mfp-bg {
    opacity: 0;
    -webkit-transition: all .15s ease-out;
    transition: all .15s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
    opacity: .8
}

.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all .15s ease-out;
    transition: all .15s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0
}

.mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: .8
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(.8);
    transform: scale(.8);
    opacity: 0
}

.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0
}

.twentytwenty-horizontal .twentytwenty-handle::after, .twentytwenty-horizontal .twentytwenty-handle::before, .twentytwenty-vertical .twentytwenty-handle::after, .twentytwenty-vertical .twentytwenty-handle::before {
    content: " ";
    display: block;
    background: #fff;
    position: absolute;
    z-index: 30;
    -webkit-box-shadow: 0 0 12px rgba(51, 51, 51, .5);
    box-shadow: 0 0 12px rgba(51, 51, 51, .5)
}

.twentytwenty-horizontal .twentytwenty-handle::after, .twentytwenty-horizontal .twentytwenty-handle::before {
    width: 3px;
    height: 9999px;
    left: 50%;
    margin-left: -1.5px
}

.twentytwenty-vertical .twentytwenty-handle::after, .twentytwenty-vertical .twentytwenty-handle::before {
    width: 9999px;
    height: 3px;
    top: 50%;
    margin-top: -1.5px
}

.twentytwenty-after-label, .twentytwenty-before-label, .twentytwenty-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%
}

.twentytwenty-after-label, .twentytwenty-before-label, .twentytwenty-overlay {
    -webkit-transition-duration: .5s;
    transition-duration: .5s
}

.twentytwenty-after-label, .twentytwenty-before-label {
    -webkit-transition-property: opacity;
    transition-property: opacity
}

.twentytwenty-after-label::before, .twentytwenty-before-label::before {
    color: #fff;
    font-size: 13px;
    letter-spacing: .1em
}

.twentytwenty-after-label::before, .twentytwenty-before-label::before {
    position: absolute;
    background: rgba(255, 255, 255, .2);
    line-height: 38px;
    padding: 0 20px;
    border-radius: 2px
}

.twentytwenty-horizontal .twentytwenty-after-label::before, .twentytwenty-horizontal .twentytwenty-before-label::before {
    top: 50%;
    margin-top: -19px
}

.twentytwenty-vertical .twentytwenty-after-label::before, .twentytwenty-vertical .twentytwenty-before-label::before {
    left: 50%;
    margin-left: -45px;
    text-align: center;
    width: auto;
    display: inline-block
}

.twentytwenty-down-arrow, .twentytwenty-left-arrow, .twentytwenty-right-arrow, .twentytwenty-up-arrow {
    width: 0;
    height: 0;
    border: 6px inset transparent;
    position: absolute
}

.twentytwenty-handle .twentytwenty-left-arrow, .twentytwenty-handle .twentytwenty-right-arrow {
    top: 50%;
    margin-top: -6px
}

.twentytwenty-handle .twentytwenty-down-arrow, .twentytwenty-handle .twentytwenty-up-arrow {
    left: 50%;
    margin-left: -6px
}

.twentytwenty-container {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    z-index: 0;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none
}

.twentytwenty-container img {
    max-width: 100%;
    position: absolute;
    top: 0;
    display: block
}

.twentytwenty-container.active .twentytwenty-overlay, .twentytwenty-container.active :hover.twentytwenty-overlay {
    background: rgba(0, 0, 0, 0)
}

.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-after-label, .twentytwenty-container.active .twentytwenty-overlay .twentytwenty-before-label, .twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-after-label, .twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-before-label {
    opacity: 0
}

.twentytwenty-container * {
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

.twentytwenty-before-label {
    opacity: 0
}

.twentytwenty-before-label::before {
    content: attr(data-content)
}

.twentytwenty-after-label {
    opacity: 0
}

.twentytwenty-after-label::before {
    content: attr(data-content)
}

.twentytwenty-horizontal .twentytwenty-before-label::before {
    left: 10px
}

.twentytwenty-horizontal .twentytwenty-after-label::before {
    right: 10px
}

.twentytwenty-vertical .twentytwenty-before-label::before {
    top: 10px
}

.twentytwenty-vertical .twentytwenty-after-label::before {
    bottom: 10px
}

.twentytwenty-overlay {
    -webkit-transition-property: background;
    transition-property: background;
    background: rgba(0, 0, 0, 0);
    z-index: 25
}

.twentytwenty-overlay:hover {
    background: rgba(0, 0, 0, .5)
}

.twentytwenty-overlay:hover .twentytwenty-after-label {
    opacity: 1
}

.twentytwenty-overlay:hover .twentytwenty-before-label {
    opacity: 1
}

.twentytwenty-before {
    z-index: 20
}

.twentytwenty-after {
    z-index: 10
}

div.twentytwenty-handle {
    height: 38px;
    width: 38px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -22px;
    margin-top: -22px;
    border: 3px solid #fff;
    border-radius: 1000px;
    -webkit-box-shadow: 0 0 12px rgba(51, 51, 51, .5);
    box-shadow: 0 0 12px rgba(51, 51, 51, .5);
    z-index: 40;
    cursor: pointer
}

.twentytwenty-horizontal .twentytwenty-handle::before {
    bottom: 50%;
    margin-bottom: 22px;
    -webkit-box-shadow: 0 3px 0 #fff, 0 0 12px rgba(51, 51, 51, .5);
    box-shadow: 0 3px 0 #fff, 0 0 12px rgba(51, 51, 51, .5)
}

.twentytwenty-horizontal .twentytwenty-handle::after {
    top: 50%;
    margin-top: 22px;
    -webkit-box-shadow: 0 -3px 0 #fff, 0 0 12px rgba(51, 51, 51, .5);
    box-shadow: 0 -3px 0 #fff, 0 0 12px rgba(51, 51, 51, .5)
}

.twentytwenty-vertical .twentytwenty-handle::before {
    left: 50%;
    margin-left: 22px;
    -webkit-box-shadow: 3px 0 0 #fff, 0 0 12px rgba(51, 51, 51, .5);
    box-shadow: 3px 0 0 #fff, 0 0 12px rgba(51, 51, 51, .5)
}

.twentytwenty-vertical .twentytwenty-handle::after {
    right: 50%;
    margin-right: 22px;
    -webkit-box-shadow: -3px 0 0 #fff, 0 0 12px rgba(51, 51, 51, .5);
    box-shadow: -3px 0 0 #fff, 0 0 12px rgba(51, 51, 51, .5)
}

.twentytwenty-handle > .twentytwenty-left-arrow {
    border-right: 6px solid #fff;
    left: 50%;
    margin-left: -17px
}

.twentytwenty-handle > .twentytwenty-right-arrow {
    border-left: 6px solid #fff;
    right: 50%;
    margin-right: -17px
}

.twentytwenty-handle > .twentytwenty-up-arrow {
    border-bottom: 6px solid #fff;
    top: 50%;
    margin-top: -17px
}

.twentytwenty-handle > .twentytwenty-down-arrow {
    border-top: 6px solid #fff;
    bottom: 50%;
    margin-bottom: -17px
}

.nav-alignment-dynamic, .nav-alignment-flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.elementskit-menu-container {
    z-index: 10000
}

.elementskit-navbar-nav > li > a .ekit-menu-icon {
    padding-right: 5px
}

.ekit-menu-badge {
    position: absolute;
    top: 5px;
    left: 50%;
    background-color: #bbb;
    color: #fff;
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 8px;
    line-height: 1;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 10
}

.ekit-menu-badge > .ekit-menu-badge-arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-left: solid transparent;
    border-right: solid transparent;
    border-top: solid #bbb;
    border-bottom: solid transparent;
    border-width: 6px
}

.elementskit-dropdown li {
    position: relative
}

.elementskit-dropdown .ekit-menu-badge {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.elementskit-navbar-nav-default .elementskit-navbar-nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-left: 0
}

.elementskit-navbar-nav-default .elementskit-navbar-nav > li {
    position: relative
}

.elementskit-navbar-nav-default .elementskit-navbar-nav > li > a {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none
}

.elementskit-navbar-nav-default .elementskit-navbar-nav > li > a:hover {
    text-decoration: none
}

.elementskit-navbar-nav-default .elementskit-navbar-nav > li.elementskit-megamenu-has {
    position: static
}

.elementskit-navbar-nav-default .elementskit-navbar-nav > li > a {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    text-transform: none;
    font-weight: 500;
    letter-spacing: normal
}

.elementskit-navbar-nav-default .elementskit-dropdown {
    border-top: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    border-right: 1px solid #dadada;
    background-color: #f4f4f4;
    border-radius: 0;
    padding-left: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease
}

.elementskit-navbar-nav-default .elementskit-dropdown > li > .elementskit-dropdown {
    left: 100%;
    top: 0
}

.elementskit-navbar-nav-default .elementskit-dropdown > li > .elementskit-dropdown.open-fromleft {
    left: auto;
    right: 100%
}

.elementskit-navbar-nav-default .elementskit-submenu-panel > li > a {
    display: block;
    padding: 15px 10px;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    -webkit-transition: all .3s;
    transition: all .3s
}

.elementskit-navbar-nav-default .elementskit-navbar-nav.elementskit-dropdown li:hover > a, .elementskit-navbar-nav-default .elementskit-navbar-nav > .elementskit-dropdown li > a:hover {
    color: #1e73be;
    background-color: #fff
}

.elementskit-navbar-nav-default .elementskit-submenu-panel > li:hover > a, .elementskit-navbar-nav-default .elementskit-submenu-panel > li > a:hover {
    color: #0d3a4f;
    background-color: rgba(255, 255, 255, .5)
}

.elementskit-navbar-nav-default .elementskit-megamenu-panel {
    width: 100%
}

.elementskit-navbar-nav-default .elementskit-overlay {
    display: none
}

.elementskit-navbar-nav-default .elementskit-nav-identity-panel {
    display: none
}

.elementskit-navbar-nav-default .elementskit-menu-close {
    border: 1px solid rgba(0, 0, 0, .5);
    color: rgba(51, 51, 51, .5);
    float: right;
    margin: 20px;
}

.elementskit-navbar-nav-default .elementskit-dropdown-has > a {
    position: relative
}

.elementskit-navbar-nav-default .elementskit-dropdown-has > a .elementskit-submenu-indicator {
    margin-left: 6px;
    display: block;
    float: right;
    cursor: pointer;
    position: relative;
    font-size: 11px
}

@media (max-width: 1024px) {
    .elementskit-navbar-nav-default .elementskit-dropdown-has > a .elementskit-submenu-indicator {
        padding: 4px 15px
    }
}

.elementskit-navbar-nav-default .elementskit-dropdown-has > a .elementskit-submenu-indicator {
    font-weight: 900;
    font-style: normal
}

.elementskit-navbar-nav-default .elementskit-dropdown-has > a svg.elementskit-submenu-indicator {
    width: 1em;
    height: 1em
}

.elementskit-navbar-nav-default.elementskit-menu-container {
    background: rgba(255, 255, 255, 0);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 0;
    position: relative;
    height: 100px;
    z-index: 90000
}

.elementskit-navbar-nav-default .elementskit-dropdown {
    min-width: 250px;
    margin-left: 0
}

.elementskit-navbar-nav-default .elementskit-navbar-nav {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.elementskit-navbar-nav-default .elementskit-navbar-nav > li > a {
    font-size: 15px;
    color: #000;
    padding-left: 15px;
    padding-right: 15px
}

.elementskit-navbar-nav-default .elementskit-navbar-nav.elementskit-menu-po-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.elementskit-navbar-nav-default .elementskit-navbar-nav.elementskit-menu-po-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.elementskit-navbar-nav-default .elementskit-navbar-nav.elementskit-menu-po-justified {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (min-width: 1025px) {
    .elementskit-navbar-nav-default .elementskit-dropdown {
        -webkit-box-shadow: 0 10px 30px 0 rgba(45, 45, 45, .2);
        box-shadow: 0 10px 30px 0 rgba(45, 45, 45, .2);
        position: absolute;
        top: 100%;
        left: 0;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        pointer-events: none;
        max-height: none;
        z-index: 999
    }

    .elementskit-navbar-nav-default .elementskit-megamenu-panel {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        margin-left: 0;
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        z-index: 999
    }
}

@media (min-width: 1025px) {
    .elementskit-navbar-nav-default.ekit-nav-dropdown-hover .elementskit-megamenu-has:hover > .elementskit-megamenu-panel {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        pointer-events: auto
    }
}

.elementskit-navbar-nav-default.ekit-nav-dropdown-hover .elementskit-megamenu-has .elementskit-dropdown {
    display: none
}

.elementskit-navbar-nav-default.ekit-nav-dropdown-click .ekit-dropdown-open-onclick {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    pointer-events: auto
}

@media (max-width: 1024px) {
    .ekit_menu_responsive_tablet > .elementskit-navbar-nav-default {
        background-color: #f7f7f7
    }

    .elementskit-navbar-nav-default.elementskit-menu-offcanvas-elements {
        width: 100%;
        position: fixed;
        top: 0;
        left: -100vw;
        height: 100%;
        -webkit-box-shadow: 0 10px 30px 0 rgba(255, 165, 0, 0);
        box-shadow: 0 10px 30px 0 rgba(255, 165, 0, 0);
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-transition: left .6s cubic-bezier(.6, .1, .68, .53);
        transition: left .6s cubic-bezier(.6, .1, .68, .53)
    }

    .elementskit-navbar-nav-default .no-scroller {
        overflow: hidden
    }

    .elementskit-navbar-nav-default .elementskit-nav-identity-panel {
        display: block;
        position: relative;
        z-index: 5;
        width: 100%
    }

    .elementskit-navbar-nav-default .elementskit-nav-identity-panel .elementskit-site-title {
        float: left
    }

    .elementskit-navbar-nav-default .elementskit-nav-identity-panel .elementskit-menu-close {
        float: right
    }

    .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a {
        color: #000;
        font-size: 12px;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        padding: 5px 5px 5px 10px;
    }

    .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a:hover {
        color: #0d3a4f
    }

    .elementskit-navbar-nav-default .elementskit-navbar-nav > li:hover > a {
        color: #0d3a4f
    }

    .elementskit-navbar-nav-default .elementskit-submenu-panel > li > a {
        color: #000;
        font-size: 12px;
        padding: 7px;
    }

    .elementskit-navbar-nav-default .elementskit-dropdown {
        display: block;
        border: 0;
        margin-left: 0
    }

    .elementskit-navbar-nav-default .elementskit-megamenu-panel {
        display: none
    }

    .elementskit-navbar-nav-default .elementskit-navbar-nav > .elementskit-dropdown-has > .elementskit-dropdown li a {
        padding-left: 15px
    }
}

@media (max-width: 767px) {
    .ekit_menu_responsive_mobile > .elementskit-navbar-nav-default {
        background-color: #f7f7f7
    }
}

@media (min-width: 1025px) {
    .ekit-nav-dropdown-hover .elementskit-dropdown-has .elementskit-dropdown {
        -webkit-box-shadow: 0 10px 30px 0 rgba(45, 45, 45, .2);
        box-shadow: 0 10px 30px 0 rgba(45, 45, 45, .2);
        position: absolute;
        top: 100%;
        left: 0;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        pointer-events: none;
        max-height: none;
        z-index: 999
    }

    .ekit-nav-dropdown-hover .elementskit-dropdown-has:hover > .elementskit-dropdown {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        pointer-events: auto
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default.ekit-nav-dropdown-hover .elementskit-dropdown-has:hover > .elementskit-dropdown, .ekit_menu_responsive_mobile .elementskit-navbar-nav-default.ekit-nav-dropdown-hover .elementskit-dropdown-has:hover > .elementskit-megamenu-panel, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default.ekit-nav-dropdown-hover .elementskit-dropdown-has:hover > .elementskit-dropdown, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default.ekit-nav-dropdown-hover .elementskit-dropdown-has:hover > .elementskit-megamenu-panel {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        pointer-events: auto
    }
}

@media only screen and (max-width: 1024px) and (min-width: 766px) {
    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-megamenu-has.elementskit-mobile-builder-content .elementskit-dropdown {
        display: none
    }

    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-megamenu-has.elementskit-mobile-builder-content .elementskit-megamenu-panel.elementskit-dropdown-open {
        display: block
    }

    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-megamenu-panel.elementskit-dropdown-open {
        display: block
    }

    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown {
        display: none
    }

    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown ~ .elementskit-megamenu-panel {
        display: none
    }

    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown.elementskit-dropdown-open {
        display: block;
        max-height: 2500px;
        opacity: 1;
        visibility: visible;
        -webkit-transition: max-height 5s ease-out;
        transition: max-height 5s ease-out
    }

    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-navbar-nav {
        overflow-y: auto
    }

    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown > li > .elementskit-dropdown {
        left: 0
    }
}

.elementskit-menu-close, .elementskit-menu-hamburger {
    display: none
}

.elementskit-menu-hamburger {
    color: #000
}

@media (max-width: 1024px) {
    .elementskit-menu-overlay {
        display: block;
        position: fixed;
        z-index: 14;
        top: 0;
        left: -100%;
        height: 100%;
        width: 100%;
        background-color: rgba(51, 51, 51, .5);
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all .6s cubic-bezier(.6, .1, .68, .53) .4s;
        transition: all .6s cubic-bezier(.6, .1, .68, .53) .4s
    }

    .elementor-element-edit-mode .elementskit-menu-overlay {
        left: 100%
    }

    .elementskit-menu-hamburger {
        border: 1px solid rgba(0, 0, 0, .2);
        float: right
    }

    .elementskit-menu-hamburger .elementskit-menu-hamburger-icon {
        background-color: rgba(0, 0, 0, .5)
    }

    .elementskit-menu-close, .elementskit-menu-hamburger {
        padding: 8px;
        background-color: transparent;
        border-radius: .25rem;
        position: relative;
        z-index: 10;
        cursor: pointer;
        width: 45px;
        -webkit-transition: all .4s ease-in;
        transition: all .4s ease-in
    }

    .elementskit-menu-close .elementskit-menu-hamburger-icon, .elementskit-menu-hamburger .elementskit-menu-hamburger-icon {
        height: 1px;
        width: 100%;
        display: block;
        margin-bottom: 4px
    }

    .elementskit-menu-close .elementskit-menu-hamburger-icon:last-child, .elementskit-menu-hamburger .elementskit-menu-hamburger-icon:last-child {
        margin-bottom: 0
    }

    .elementskit-navbar-nav .ekit-menu-badge {
        font-size: 7px
    }
}

.elementskit-navbar-nav-default .elementskit-navbar-nav > li > .elementskit-dropdown > li > a .ekit-menu-badge .ekit-menu-badge-arrow {
    display: none
}

.dropdown-item, .ekit-menu-nav-link {
    position: relative
}

.dropdown-item > i, .ekit-menu-nav-link > i {
    padding-right: 5px
}

@-webkit-keyframes xs-zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    50% {
        opacity: 1
    }
}

@keyframes xs-zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    50% {
        opacity: 1
    }
}

.xs-zoomIn {
    -webkit-animation-name: xs-zoomIn;
    animation-name: xs-zoomIn
}

.elementskit-nav-logo {
    display: inline-block
}

@media (max-width: 1024px) {
    .elementor-widget-ekit-nav-menu {
        -webkit-animation: none !important;
        animation: none !important
    }

    .ekit-wid-con:not(.ekit_menu_responsive_mobile) .elementskit-navbar-nav {
        display: block
    }
}

@media (max-width: 1024px) {
    .elementskit-menu-close, .elementskit-menu-hamburger {
        display: block
    }

    .elementskit-menu-container {
        max-width: 350px
    }

    .elementskit-menu-overlay.active {
        left: 0;
        -webkit-transition: all .6s cubic-bezier(.22, .61, .24, 1) 0s;
        transition: all .6s cubic-bezier(.22, .61, .24, 1) 0s
    }

    .elementskit-menu-offcanvas-elements {
        height: 100% !important;
        padding-bottom: 10px
    }

    .elementskit-menu-offcanvas-elements.active {
        left: 0
    }

    .elementskit-dropdown {
        position: relative;
        max-height: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: max-height 2s cubic-bezier(0, 1, 0, 1);
        transition: max-height 2s cubic-bezier(0, 1, 0, 1)
    }

    .ekit_menu_responsive_tablet .ekit-menu-badge {
        position: static;
        margin-left: 10px;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        -webkit-transform: none;
        transform: none
    }

    .ekit_menu_responsive_tablet .ekit-menu-badge > .ekit-menu-badge-arrow {
        display: none
    }

    .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown-has > a .elementskit-submenu-indicator {
        margin-left: auto
    }

    .ekit_menu_responsive_tablet .elementskit-submenu-indicator {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        border: 1px solid;
        border-radius: 30px
    }
}

@media screen and (min-width: 766px) and (max-width: 1024px) {
    .ekit_menu_responsive_mobile .elementskit-menu-offcanvas-elements {
        position: static
    }

    .ekit_menu_responsive_mobile .elementskit-nav-identity-panel {
        display: none
    }

    .ekit_menu_responsive_mobile .elementskit-menu-hamburger {
        display: none
    }

    .ekit_menu_responsive_mobile .elementskit-menu-container {
        overflow-y: unset;
        overflow-x: unset
    }

    .ekit_menu_responsive_mobile .elementskit-dropdown {
        -webkit-box-shadow: 0 10px 30px 0 rgba(45, 45, 45, .2);
        box-shadow: 0 10px 30px 0 rgba(45, 45, 45, .2);
        position: absolute;
        top: 100%;
        left: 0;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        pointer-events: none;
        max-height: none;
        z-index: 999
    }

    .ekit_menu_responsive_mobile .elementskit-megamenu-panel {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
        margin-left: 0;
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        z-index: 999
    }

    .ekit_menu_responsive_mobile .elementskit-dropdown-menu-full_width .elementskit-megamenu-panel {
        width: 100vw
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav > li.elementskit-megamenu-has.relative_position {
        position: relative
    }

    .ekit_menu_responsive_mobile .elementskit-megamenu-panel .elementor-section-wrap > .elementor-section > .elementor-container {
        max-width: none
    }
}

@media (max-width: 767px) {
    .ekit_menu_responsive_mobile .elementskit-navbar-nav {
        display: block
    }

    .ekit_menu_responsive_mobile .elementskit-dropdown {
        position: relative
    }

    .ekit_menu_responsive_mobile .ekit-menu-badge {
        position: static;
        margin-left: 10px;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        -webkit-transform: none;
        transform: none
    }

    .ekit_menu_responsive_mobile .ekit-menu-badge > .ekit-menu-badge-arrow {
        display: none
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-dropdown-has > a .elementskit-submenu-indicator {
        margin-left: auto
    }

    .ekit_menu_responsive_mobile .elementskit-submenu-indicator {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        border: 1px solid;
        border-radius: 30px
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-megamenu-has.elementskit-mobile-builder-content .elementskit-dropdown, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-megamenu-has.elementskit-mobile-builder-content .elementskit-dropdown {
        display: none
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-megamenu-has.elementskit-mobile-builder-content .elementskit-megamenu-panel.elementskit-dropdown-open, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-megamenu-has.elementskit-mobile-builder-content .elementskit-megamenu-panel.elementskit-dropdown-open {
        display: block
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-megamenu-panel.elementskit-dropdown-open, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-megamenu-panel.elementskit-dropdown-open {
        display: block
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-dropdown, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown {
        display: none
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-dropdown ~ .elementskit-megamenu-panel, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown ~ .elementskit-megamenu-panel {
        display: none
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-dropdown.elementskit-dropdown-open, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown.elementskit-dropdown-open {
        display: block;
        max-height: 2500px;
        opacity: 1;
        visibility: visible;
        -webkit-transition: max-height 5s ease-out;
        transition: max-height 5s ease-out
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-navbar-nav, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-navbar-nav {
        overflow-y: auto
    }

    .ekit_menu_responsive_mobile .elementskit-navbar-nav-default .elementskit-dropdown > li > .elementskit-dropdown, .ekit_menu_responsive_tablet .elementskit-navbar-nav-default .elementskit-dropdown > li > .elementskit-dropdown {
        left: 0
    }
}

.elementskit-navbar-nav .elementskit-submenu-panel > li:hover > a > i, .elementskit-navbar-nav .elementskit-submenu-panel > li > a:hover > i {
    color: inherit !important
}

@media (max-width: 767px) {
    .ekit-sticky .elementskit-menu-container.elementskit-menu-offcanvas-elements {
        height: 120vh !important
    }

    .ekit-sticky .elementskit-menu-overlay {
        display: none
    }

    .ekit-sticky .elementskit-menu-offcanvas-elements::before {
        position: fixed;
        content: "";
        top: 0;
        left: -110%;
        height: 100%;
        width: 100%;
        background-color: rgba(51, 51, 51, .5);
        -webkit-transition: left .5s ease;
        transition: left .5s ease
    }

    .ekit-sticky .elementskit-menu-offcanvas-elements::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: inherit
    }

    .ekit-sticky .elementskit-menu-offcanvas-elements.active::before {
        left: 0
    }

    .ekit-sticky .elementskit-navbar-nav-default .elementskit-navbar-nav {
        position: relative;
        z-index: 5
    }
}

@media (min-width: 1025px) {
    .elementor-widget-ekit-nav-menu .elementskit-dropdown-menu-full_width .elementskit-megamenu-panel {
        width: 100vw
    }

    .elementor-widget-ekit-nav-menu .elementskit-navbar-nav > li.elementskit-megamenu-has.relative_position {
        position: relative
    }

    .elementskit-megamenu-panel .elementor-section-wrap > .elementor-section > .elementor-container {
        max-width: none
    }
}

.elementskit-dropdown-has > .sub-menu-toggle {
    display: none
}

.ekit-nav-menu--empty-fallback {
    font-size: 0
}

@media (min-width: 766px) {
    .ekit_menu_responsive_mobile .ekit-nav-menu--empty-fallback, .ekit_menu_responsive_mobile .ekit-nav-menu--overlay {
        display: none
    }
}

@media (min-width: 1025px) {
    .ekit_menu_responsive_tablet .ekit-nav-menu--empty-fallback, .ekit_menu_responsive_tablet .ekit-nav-menu--overlay {
        display: none
    }
}

.ekit-vertical-menu-tigger {
    display: block;
    background: #ffb25d;
    -webkit-box-shadow: 0 7px 15px rgba(255, 178, 93, .3);
    box-shadow: 0 7px 15px rgba(255, 178, 93, .3);
    padding: 16px 16px 16px 20px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    line-height: 1
}

.ekit-vertical-menu-tigger img:not([draggable]).vertical-menu-icon {
    max-width: 14px
}

.ekit-vertical-menu-tigger .vertical-menu-icon {
    -webkit-transition: all .4s ease-in;
    transition: all .4s ease-in
}

.ekit-vertical-menu-tigger:hover {
    color: #fff
}

.vertical-menu-right-icon {
    margin-right: 7px
}

.vertical-menu-left-icon {
    float: right
}

.ekit-vertical-navbar-nav {
    padding-left: 0;
    list-style: none;
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
    box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
    background-color: #fff;
    margin-left: 0;
    margin-bottom: 0;
    list-style: none
}

.ekit-vertical-navbar-nav .elementskit-dropdown, .ekit-vertical-navbar-nav .elementskit-megamenu-panel {
    margin-left: 0;
    list-style: none;
    margin-bottom: 0
}

.ekit-vertical-navbar-nav .elementskit-megamenu-has .elementskit-dropdown {
    display: none
}

.ekit-vertical-navbar-nav > li:not(:last-child) {
    border-bottom: 1px solid #ededed
}

.ekit-vertical-navbar-nav > li > a {
    font-size: 14px;
    font-weight: 500;
    color: #101010;
    display: block;
    padding: 12px 19px 12px 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-vertical-navbar-nav > li > a .ekit-menu-icon {
    margin-right: 7px;
    padding-right: 0
}

.ekit-vertical-navbar-nav > li > a .ekit-menu-badge-arrow {
    display: none
}

.ekit-vertical-navbar-nav > li > a .ekit-menu-badge {
    position: static;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 10px;
    -webkit-transform: none;
    transform: none
}

.ekit-vertical-navbar-nav > li > a:hover {
    color: #4285f4
}

.ekit-vertical-navbar-nav > li:hover > a {
    color: #4285f4
}

.ekit-vertical-navbar-nav li.elementskit-dropdown-has {
    position: relative
}

@media (min-width: 1025px) {
    .ekit-vertical-navbar-nav li.elementskit-dropdown-has:hover > .elementskit-dropdown {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        pointer-events: auto
    }
}

@media (min-width: 1025px) {
    .ekit-vertical-navbar-nav li.elementskit-megamenu-has.relative_position {
        position: relative
    }

    .ekit-vertical-navbar-nav li.elementskit-megamenu-has.top_position {
        position: static
    }

    .ekit-vertical-navbar-nav li.elementskit-megamenu-has:hover > .elementskit-megamenu-panel {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        pointer-events: auto
    }
}

@media (max-width: 1024px) {
    .ekit-vertical-navbar-nav .elementskit-megamenu-panel {
        display: none;
        width: auto !important
    }

    .ekit-vertical-navbar-nav .elementskit-mobile-builder-content .elementskit-megamenu-panel.elementskit-dropdown-open {
        display: block
    }

    .ekit-vertical-navbar-nav .elementskit-dropdown-has .elementskit-dropdown.elementskit-dropdown-open {
        display: block
    }

    .ekit-vertical-navbar-nav .elementskit-megamenu-has.elementskit-mobile-builder-content .elementskit-dropdown {
        display: none
    }
}

.ekit-vertical-navbar-nav .elementskit-dropdown {
    padding-left: 0;
    list-style: none;
    max-width: 220px;
    width: 100%
}

@media (min-width: 1025px) {
    .ekit-vertical-navbar-nav .elementskit-dropdown {
        position: absolute;
        left: 100%;
        top: 0;
        z-index: 100;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
        box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .3s linear;
        transition: all .3s linear;
        pointer-events: none
    }
}

@media (max-width: 1024px) {
    .ekit-vertical-navbar-nav .elementskit-dropdown {
        display: none;
        max-height: none;
        max-width: 100%;
        padding: 10px 15px
    }
}

@media (min-width: 1025px) {
    .ekit-vertical-navbar-nav .elementskit-dropdown > li:not(:last-child) {
        border-bottom: 1px solid #ededed
    }
}

.ekit-vertical-navbar-nav .elementskit-dropdown > li:first-child {
    margin-top: 0
}

.ekit-vertical-navbar-nav .elementskit-dropdown > li:last-child {
    margin-bottom: 0
}

.ekit-vertical-navbar-nav .elementskit-dropdown > li > a {
    font-size: 14px;
    font-weight: 500;
    color: #101010;
    padding: 12px 19px 12px 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (min-width: 1025px) {
    .ekit-vertical-navbar-nav .elementskit-megamenu-panel {
        position: absolute;
        left: 100%;
        top: 0;
        z-index: 100;
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .3s linear;
        transition: all .3s linear;
        pointer-events: none;
        width: 100%
    }
}

.ekit-vertical-navbar-nav .elementskit-submenu-indicator {
    display: block;
    line-height: 1;
    margin-left: auto;
    position: relative;
    font-weight: 900;
    font-style: normal;
    font-family: "Font Awesome 5 Free";
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.ekit-vertical-navbar-nav .elementskit-submenu-indicator::before {
    content: "\f105"
}

.ekit-vertical-navbar-nav .ekit-submenu-indicator-icon {
    display: block;
    margin-left: auto;
    line-height: 1;
    position: relative;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.ekit-vertical-navbar-nav svg.ekit-submenu-indicator-icon {
    width: 1em;
    height: 1em
}

.ekit-vertical-navbar-nav .ekit-ajax-loading .eicon-loading {
    font-family: eicons
}

.ekit-vertical-navbar-nav .ekit-ajax-loading .eicon-loading::before {
    content: "\e8fb"
}

.badge-position-right .ekit-vertical-navbar-nav .ekit-menu-badge {
    margin-left: auto
}

.badge-position-right .ekit-menu-badge ~ .elementskit-submenu-indicator {
    margin-left: 12px
}

.badge-position-right .ekit-menu-badge ~ .ekit-submenu-indicator-icon {
    margin-left: 12px
}

.ekit-vertical-main-menu-on-click {
    position: relative
}

.ekit-vertical-main-menu-on-click .ekit-vertical-menu-container {
    position: absolute;
    top: 100%;
    z-index: 1111;
    left: 0;
    width: 100%
}

.ekit-vertical-main-menu-on-click.vertical-menu-active .ekit-vertical-menu-container {
    opacity: 1;
    visibility: visible
}

.ekit-vertical-main-menu-on-click.vertical-menu-active .ekit-vertical-menu-tigger .vertical-menu-left-icon {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg)
}

.ekit-vertical-main-menu-on-click .ekit-vertical-menu-container {
    opacity: 0;
    visibility: hidden
}

.ekit-vertical-menu-container {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

@keyframes dtb-spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes dtb-spinner {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

div.dt-button-info {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    margin-top: -100px;
    margin-left: -200px;
    background-color: #fff;
    border: 2px solid #111;
    -webkit-box-shadow: 3px 3px 8px rgba(0, 0, 0, .3);
    box-shadow: 3px 3px 8px rgba(0, 0, 0, .3);
    border-radius: 3px;
    text-align: center;
    z-index: 21
}

div.dt-button-info h2 {
    padding: .5em;
    margin: 0;
    font-weight: 400;
    border-bottom: 1px solid #ddd;
    background-color: #f3f3f3
}

div.dt-button-info > div {
    padding: 1em
}

div.dt-button-collection-title {
    text-align: center;
    padding: .3em 0 .5em;
    font-size: .9em
}

div.dt-button-collection-title:empty {
    display: none
}

a.dt-button, button.dt-button, div.dt-button {
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: .333em;
    margin-bottom: .333em;
    padding: .5em 1em;
    border: 1px solid #999;
    border-radius: 2px;
    cursor: pointer;
    font-size: .88em;
    line-height: 1.6em;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    background-color: #e9e9e9;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e9e9e9));
    background-image: linear-gradient(to bottom, #fff 0, #e9e9e9 100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    outline: 0
}

a.dt-button.disabled, button.dt-button.disabled, div.dt-button.disabled {
    color: #999;
    border: 1px solid #d0d0d0;
    cursor: default;
    background-color: #f9f9f9;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f9f9f9));
    background-image: linear-gradient(to bottom, #fff 0, #f9f9f9 100%)
}

a.dt-button.active:not(.disabled), a.dt-button:active:not(.disabled), button.dt-button.active:not(.disabled), button.dt-button:active:not(.disabled), div.dt-button.active:not(.disabled), div.dt-button:active:not(.disabled) {
    background-color: #e2e2e2;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f3f3f3), to(#e2e2e2));
    background-image: linear-gradient(to bottom, #f3f3f3 0, #e2e2e2 100%);
    -webkit-box-shadow: inset 1px 1px 3px #999;
    box-shadow: inset 1px 1px 3px #999
}

a.dt-button.active:not(.disabled):hover:not(.disabled), a.dt-button:active:not(.disabled):hover:not(.disabled), button.dt-button.active:not(.disabled):hover:not(.disabled), button.dt-button:active:not(.disabled):hover:not(.disabled), div.dt-button.active:not(.disabled):hover:not(.disabled), div.dt-button:active:not(.disabled):hover:not(.disabled) {
    -webkit-box-shadow: inset 1px 1px 3px #999;
    box-shadow: inset 1px 1px 3px #999;
    background-color: #ccc;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#eaeaea), to(#ccc));
    background-image: linear-gradient(to bottom, #eaeaea 0, #ccc 100%)
}

a.dt-button:hover, button.dt-button:hover, div.dt-button:hover {
    text-decoration: none
}

a.dt-button:hover:not(.disabled), button.dt-button:hover:not(.disabled), div.dt-button:hover:not(.disabled) {
    border: 1px solid #666;
    background-color: #e0e0e0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#e0e0e0));
    background-image: linear-gradient(to bottom, #f9f9f9 0, #e0e0e0 100%)
}

a.dt-button:focus:not(.disabled), button.dt-button:focus:not(.disabled), div.dt-button:focus:not(.disabled) {
    border: 1px solid #426c9e;
    text-shadow: 0 1px 0 #c4def1;
    outline: 0;
    background-color: #79ace9;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#bddef4), to(#79ace9));
    background-image: linear-gradient(to bottom, #bddef4 0, #79ace9 100%)
}

.dt-button embed {
    outline: 0
}

div.dt-buttons {
    position: relative;
    float: left
}

div.dt-buttons.buttons-right {
    float: right
}

div.dt-button-collection {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    margin-top: 3px;
    padding: 8px 8px 4px 8px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .4);
    background-color: #fff;
    overflow: hidden;
    z-index: 2002;
    border-radius: 5px;
    -webkit-box-shadow: 3px 3px 5px rgba(0, 0, 0, .3);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, .3);
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    -ms-column-gap: 8px;
    -o-column-gap: 8px;
    column-gap: 8px
}

div.dt-button-collection a.dt-button, div.dt-button-collection button.dt-button, div.dt-button-collection div.dt-button {
    position: relative;
    left: 0;
    right: 0;
    width: 100%;
    display: block;
    float: none;
    margin-bottom: 4px;
    margin-right: 0
}

div.dt-button-collection a.dt-button.active:not(.disabled), div.dt-button-collection a.dt-button:active:not(.disabled), div.dt-button-collection button.dt-button.active:not(.disabled), div.dt-button-collection button.dt-button:active:not(.disabled), div.dt-button-collection div.dt-button.active:not(.disabled), div.dt-button-collection div.dt-button:active:not(.disabled) {
    background-color: #dadada;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f0f0f0), to(#dadada));
    background-image: linear-gradient(to bottom, #f0f0f0 0, #dadada 100%);
    -webkit-box-shadow: inset 1px 1px 3px #666;
    box-shadow: inset 1px 1px 3px #666
}

div.dt-button-collection.fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -75px;
    border-radius: 0
}

div.dt-button-collection.fixed.two-column {
    margin-left: -150px
}

div.dt-button-collection.fixed.three-column {
    margin-left: -225px
}

div.dt-button-collection.fixed.four-column {
    margin-left: -300px
}

div.dt-button-collection > * {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid
}

div.dt-button-collection.two-column {
    width: 300px;
    padding-bottom: 1px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    -ms-column-count: 2;
    -o-column-count: 2;
    column-count: 2
}

div.dt-button-collection.three-column {
    width: 450px;
    padding-bottom: 1px;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3
}

div.dt-button-collection.four-column {
    width: 600px;
    padding-bottom: 1px;
    -webkit-column-count: 4;
    -moz-column-count: 4;
    -ms-column-count: 4;
    -o-column-count: 4;
    column-count: 4
}

div.dt-button-collection .dt-button {
    border-radius: 0
}

div.dt-button-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, .3) 0, rgba(0, 0, 0, .7) 100%);
    z-index: 2001
}

@media screen and (max-width: 640px) {
    div.dt-buttons {
        float: none !important;
        text-align: center
    }
}

a.dt-button.processing, button.dt-button.processing, div.dt-button.processing {
    color: rgba(0, 0, 0, .2)
}

a.dt-button.processing::after, button.dt-button.processing::after, div.dt-button.processing::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin: -8px 0 0 -8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    content: " ";
    border: 2px solid #282828;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: dtb-spinner 1.5s infinite linear;
    -o-animation: dtb-spinner 1.5s infinite linear;
    -ms-animation: dtb-spinner 1.5s infinite linear;
    -webkit-animation: dtb-spinner 1.5s infinite linear;
    -moz-animation: dtb-spinner 1.5s infinite linear
}

table.DTFC_Cloned tfoot, table.DTFC_Cloned thead {
    background-color: #fff
}

div.DTFC_Blocker {
    background-color: #fff
}

table.fixedHeader-floating {
    position: fixed !important;
    background-color: #fff
}

table.fixedHeader-floating.no-footer {
    border-bottom-width: 0
}

table.fixedHeader-locked {
    position: absolute !important;
    background-color: #fff
}

@media print {
    table.fixedHeader-floating {
        display: none
    }
}

div.dtr-modal {
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 10em 1em
}

div.dtr-modal div.dtr-modal-display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    z-index: 102;
    overflow: auto;
    background-color: #f5f5f7;
    border: 1px solid #000;
    border-radius: .5em;
    -webkit-box-shadow: 0 12px 30px rgba(0, 0, 0, .6);
    box-shadow: 0 12px 30px rgba(0, 0, 0, .6)
}

div.dtr-modal div.dtr-modal-content {
    position: relative;
    padding: 1em
}

div.dtr-modal div.dtr-modal-close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 22px;
    height: 22px;
    border: 1px solid #eaeaea;
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    z-index: 12
}

div.dtr-modal div.dtr-modal-close:hover {
    background-color: #eaeaea
}

div.dtr-modal div.dtr-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    background: rgba(0, 0, 0, .6)
}

@media screen and (max-width: 767px) {
    div.dtr-modal div.dtr-modal-display {
        width: 95%
    }
}

div.dtr-modal {
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 10em 1em
}

div.dtr-modal div.dtr-modal-display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    z-index: 102;
    overflow: auto;
    background-color: #f5f5f7;
    border: 1px solid #000;
    border-radius: .5em;
    -webkit-box-shadow: 0 12px 30px rgba(0, 0, 0, .6);
    box-shadow: 0 12px 30px rgba(0, 0, 0, .6)
}

div.dtr-modal div.dtr-modal-content {
    position: relative;
    padding: 1em
}

div.dtr-modal div.dtr-modal-close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 22px;
    height: 22px;
    border: 1px solid #eaeaea;
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    z-index: 12
}

div.dtr-modal div.dtr-modal-close:hover {
    background-color: #eaeaea
}

div.dtr-modal div.dtr-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    background: rgba(0, 0, 0, .6)
}

@media screen and (max-width: 767px) {
    div.dtr-modal div.dtr-modal-display {
        width: 95%
    }
}

.elementskit-invisible {
    visibility: hidden
}

.ekit-wid-con .select {
    cursor: pointer;
    display: block;
    position: relative;
    font-size: 16px;
    color: #7b7b7b;
    width: 220px;
    height: 44px;
    float: right
}

.met_d--block {
    display: block
}

.met_d--none {
    display: none
}

.met_d--flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.before\:last\:met_d--none:last-child::before {
    display: none
}

.after\:first\:met_d--none:first-child::after {
    display: none
}

.after\:active\:met_d--none.active::after {
    display: none
}

.met_w--12 {
    width: 12px
}

.met_w--16 {
    width: 16px
}

.met_w--20 {
    width: 20px
}

.met_w--24 {
    width: 24px
}

.met_w--48 {
    width: 48px
}

.before\:met_w--4::before {
    width: 4px
}

.before\:met_w--16::before {
    width: 16px
}

.before\:met_w--19::before {
    width: 19px
}

.before\:met_w--48::before {
    width: 48px
}

.active\::before\:met_w--18.active::before {
    width: 18px
}

.after\:met_w--1::after {
    width: 1px
}

.met_h--12 {
    height: 12px
}

.met_h--16 {
    height: 16px
}

.met_h--20 {
    height: 20px
}

.met_h--24 {
    height: 24px
}

.met_h--48 {
    height: 48px
}

.before\:met_h--4::before {
    height: 4px
}

.before\:met_h--16::before {
    height: 16px
}

.before\:met_h--19::before {
    height: 19px
}

.before\:met_h--48::before {
    height: 48px
}

.active\::before\:met_h--18.active::before {
    height: 18px
}

.after\:met_h--1::after {
    height: 1px
}

.after\:met_content::after, .before\:met_content::before {
    content: " "
}

.met_pos--fixed {
    position: fixed
}

.met_pos--relative {
    position: relative
}

.met_pos--absolute {
    position: absolute
}

.before\:met_pos--absolute::before {
    position: absolute
}

.after\:met_pos--absolute::after {
    position: absolute
}

.met_top--0 {
    top: 0
}

.met_top--15 {
    top: 15px
}

.met_top--20 {
    top: 20px
}

.met_top--50p {
    top: 50%
}

.met_top--100p {
    top: 100%
}

.before\:met_top--0::before {
    top: 0
}

.before\:met_top--5::before {
    top: 5px
}

.before\:met_top--12::before {
    top: 12px
}

.before\:active\:met_top--13.active::before {
    top: 13px
}

.after\:met_top--m1::after {
    top: -1px
}

.after\:met_top--5::after {
    top: 5px
}

.met_bottom--0 {
    bottom: 0
}

.met_bottom--20 {
    bottom: 20px
}

.met_bottom--100p {
    bottom: 100%
}

.before\:met_bottom--0::before {
    bottom: 0
}

.met_left--0 {
    left: 0
}

.met_left--1 {
    left: 1px
}

.met_left--20 {
    left: 20px
}

.met_left--50p {
    left: 50%
}

.met_left--100p {
    left: 100%
}

.before\:met_left--0::before {
    left: 0
}

.before\:met_left--5::before {
    left: 5px
}

.before\:met_left--12::before {
    left: 12px
}

.before\:active\:met_left--13.active::before {
    left: 13px
}

.after\:met_left--m1::after {
    left: -1px
}

.after\:met_left--5::after {
    left: 5px
}

.met_right--0 {
    right: 0
}

.met_right--20 {
    right: 20px
}

.met_right--100p {
    right: 100%
}

.met_mx--m40 {
    margin-left: -40px;
    margin-right: -40px
}

.met_mx--m28 {
    margin-left: -28px;
    margin-right: -28px
}

.met_mx--12 {
    margin-left: 12px;
    margin-right: 12px
}

.met_mx--20 {
    margin-left: 20px;
    margin-right: 20px
}

.met_mx--24 {
    margin-left: 24px;
    margin-right: 24px
}

.met_mx--36 {
    margin-left: 36px;
    margin-right: 36px
}

.met_my--m40 {
    margin-top: -40px;
    margin-bottom: -40px
}

.met_my--m28 {
    margin-top: -28px;
    margin-bottom: -28px
}

.met_my--12 {
    margin-top: 12px;
    margin-bottom: 12px
}

.met_my--20 {
    margin-top: 20px;
    margin-bottom: 20px
}

.met_my--24 {
    margin-top: 24px;
    margin-bottom: 24px
}

.met_my--36 {
    margin-top: 36px;
    margin-bottom: 36px
}

.met_m--0 {
    margin: 0
}

.met_ml--1 {
    margin-left: 1px
}

.met_ml--6 {
    margin-left: 6px
}

.met_ml--15 {
    margin-left: 15px
}

.met_ml--20 {
    margin-left: 20px
}

.met_ml--21 {
    margin-left: 21px
}

.met_ml--35 {
    margin-left: 35px
}

.met_mr--1 {
    margin-right: 1px
}

.met_mr--10 {
    margin-right: 10px
}

.met_mr--15 {
    margin-right: 15px
}

.met_mr--20 {
    margin-right: 20px
}

.met_mb--0 {
    margin-bottom: 0
}

.met_mb--0i {
    margin-bottom: 0 !important
}

.met_mb--20 {
    margin-bottom: 20px
}

.met_not_last_mr--20:not(:last-child) {
    margin-right: 20px
}

.met_not_last_mb--20:not(:last-child) {
    margin-bottom: 20px
}

.met_p--0 {
    padding: 0
}

.met_px--8 {
    padding-left: 8px;
    padding-right: 8px
}

.met_py--5 {
    padding-top: 5px;
    padding-bottom: 5px
}

.met_pt--10 {
    padding-top: 10px
}

.met_pl--10 {
    padding-left: 10px
}

.met_pr--10 {
    padding-right: 10px
}

.met_pb--10 {
    padding-bottom: 10px
}

.met_color--white {
    color: #fff
}

.active > .active\:met_bgc--nonei {
    background-color: transparent !important
}

.active .active\:_\:met_bgc--nonei {
    background-color: transparent !important
}

.met_bd--2 {
    border: solid 2px
}

.met_bd--6 {
    border: solid 6px
}

.met_bdt--2 {
    border-top: solid 2px
}

.met_bdt--4 {
    border-top: solid 4px
}

.met_bdt--6 {
    border-top: solid 6px
}

.met_bdl--2 {
    border-left: solid 2px
}

.met_bdl--4 {
    border-left: solid 4px
}

.met_bdl--6 {
    border-left: solid 6px
}

.before\:met_bdt--2::before {
    border-top: solid 2px
}

.before\:met_bdl--2::before {
    border-left: solid 2px
}

.after\:met_bdt--2::after {
    border-top: solid 2px
}

.after\:met_bdl--2::after {
    border-left: solid 2px
}

.active > .active\:met_bd--1 {
    border-style: solid;
    border-width: 1px
}

.active .active\:_\:met_bd--1 {
    border-style: solid;
    border-width: 1px
}

.met_bdc--none {
    border-color: transparent
}

.met_bdt_color--current {
    border-top-color: currentColor
}

.met_bdb_color--current {
    border-bottom-color: currentColor
}

.met_bdl_color--current {
    border-left-color: currentColor
}

.met_bdr_color--current {
    border-right-color: currentColor
}

.met_radius--4 {
    border-radius: 4px
}

.met_radius--circle {
    border-radius: 50%
}

.before\:met_radius--circle::before {
    border-radius: 50%
}

.met_shadow_inset--6 {
    -webkit-box-shadow: inset 0 0 0 6px currentColor;
    box-shadow: inset 0 0 0 6px currentColor
}

.active > .active\:met_shadow_inset--1 {
    -webkit-box-shadow: inset 0 0 0 1px currentColor;
    box-shadow: inset 0 0 0 1px currentColor
}

.active > .active\:met_shadow_inset--8 {
    -webkit-box-shadow: inset 0 0 0 8px currentColor;
    box-shadow: inset 0 0 0 8px currentColor
}

.active .active\:_\:met_shadow_inset--1 {
    -webkit-box-shadow: inset 0 0 0 1px currentColor;
    box-shadow: inset 0 0 0 1px currentColor
}

.met_fs--14 {
    font-size: 14px
}

.met_lh--0 {
    line-height: 0
}

.met_lh--16 {
    line-height: 16px
}

.met_list--none {
    list-style: none
}

.met_text--nowrap {
    white-space: nowrap
}

.whitespace--normal {
    white-space: normal !important
}

.met_opacity--0 {
    opacity: 0
}

.met_opacity--8 {
    opacity: .08
}

.met_opacity--30 {
    opacity: .3
}

.met_opacity--70 {
    opacity: .7
}

.hover\:met_opacity--60:hover {
    opacity: .6
}

.hover\:met_opacity--100:hover {
    opacity: 1
}

a:hover > .a\:hover\:met_opacity--60 {
    opacity: .6
}

a:hover > .a\:hover\:met_opacity--100 {
    opacity: 1
}

.before\:met_opacity--30::before {
    opacity: .3
}

.after\:met_opacity--30::after {
    opacity: .3
}

.active > .active\:met_opacity--0 {
    opacity: 0
}

.active > .active\:met_opacity--100 {
    opacity: 1
}

.active .active\:_\:met_opacity--0 {
    opacity: 0
}

.active .active\:_\:met_opacity--100 {
    opacity: 100
}

.met_transition--300ms {
    -webkit-transition: all .3s;
    transition: all .3s
}

.before\:met_transition--300ms::before {
    -webkit-transition: all .3s;
    transition: all .3s
}

.met_translateTop--m50p {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.met_translateLeft--m50p {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.met_scale--0 {
    -webkit-transform: scale(0);
    transform: scale(0)
}

.hover\:met_scaleX--200:hover {
    -webkit-transform: scaleX(2);
    transform: scaleX(2)
}

.hover\:met_scaleY--200:hover {
    -webkit-transform: scaleY(2);
    transform: scaleY(2)
}

a:hover > .a:hover\:met_scalex--200:hover {
    -webkit-transform: scaleX(2);
    transform: scaleX(2)
}

a:hover > .a:hover\:met_scaley--200:hover {
    -webkit-transform: scaleY(2);
    transform: scaleY(2)
}

.active > .active\:met_scale--150 {
    -webkit-transform: scale(1.5);
    transform: scale(1.5)
}

.active > .active\:met_scaleX--20 {
    -webkit-transform: scaleX(.2);
    transform: scaleX(.2)
}

.active > .active\:met_scaleX--200 {
    -webkit-transform: scaleX(2);
    transform: scaleX(2)
}

.active > .active\:met_scaleY--20 {
    -webkit-transform: scaleY(.2);
    transform: scaleY(.2)
}

.active > .active\:met_scaleY--200 {
    -webkit-transform: scaleY(2);
    transform: scaleY(2)
}

.active .active\:_\:met_scale--40 {
    -webkit-transform: scale(.4);
    transform: scale(.4)
}

.active .active\:_\:met_scale--150 {
    -webkit-transform: scale(1.5);
    transform: scale(1.5)
}

.active .active\:_\:met_scaleX--20 {
    -webkit-transform: scaleX(.2);
    transform: scaleX(.2)
}

.active .active\:_\:met_scaleX--100 {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.active .active\:_\:met_scaleX--200 {
    -webkit-transform: scaleX(2);
    transform: scaleX(2)
}

.active .active\:_\:met_scaleY--20 {
    -webkit-transform: scaleY(.2);
    transform: scaleY(.2)
}

.active .active\:_\:met_scaleY--100 {
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
}

.active .active\:_\:met_scaleY--200 {
    -webkit-transform: scaleY(2);
    transform: scaleY(2)
}

.met_scale--top {
    -webkit-transform-origin: top;
    transform-origin: top
}

.met_scale--bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom
}

.met_scale--left {
    -webkit-transform-origin: left;
    transform-origin: left
}

.met_scale--right {
    -webkit-transform-origin: right;
    transform-origin: right
}

.elementor-editor-active .editor\:met_display--none, .elementor-editor-preview .editor\:met_display--none {
    display: none
}

.ekit-wid-con .ekit_social_media, .ekit-wid-con .ekit_socialshare {
    margin-left: 0;
    padding-left: 0;
    list-style: none
}

.ekit-wid-con .ekit_social_media > li > a, .ekit-wid-con .ekit_socialshare > li > a {
    display: block;
    cursor: pointer;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .ekit_social_media > li > a i, .ekit-wid-con .ekit_socialshare > li > a i {
    vertical-align: middle;
    display: inline-block
}

.ekit-wid-con .ekit_social_media > li > a svg, .ekit-wid-con .ekit_socialshare > li > a svg {
    max-width: 16px;
    height: auto;
    vertical-align: middle
}

@media (max-width: 1024px) {
    .ekit-wid-con .ekit_social_media > li, .ekit-wid-con .ekit_socialshare > li {
        margin-bottom: 20px
    }
}

.ekit-wid-con .ekit-post-list-wrapper .elementor-icon-list-item a {
    background-color: transparent !important
}

.ekit-wid-con .ekit-post-list-wrapper.elementor-inline-items .elementor-icon-list-item {
    margin: 0
}

.ekit-wid-con .elementor-inline-items {
    margin: 0 !important
}

.ekit-wid-con .elementor-inline-items .elementor-icon-list-item {
    display: inline-block
}

.ekit-wid-con .elementor-inline-items .elementor-icon-list-item::after {
    display: inline-block
}

.elementor-widget .ekit-wid-con .elementor-inline-items .elementor-icon-list-item::after {
    position: absolute
}

.ekit-wid-con .elementor-inline-items .elementor-icon-list-item:last-child::after {
    display: none
}

.elementor-widget-elementskit-page-list:not(.ekit-has-divider-yes) .ekit-wid-con .elementor-inline-items .elementor-icon-list-item::after {
    display: none
}

.ekit-wid-con .elementor-inline-items.row .elementor-icon-list-item {
    margin: 0;
    padding: 0
}

.ekit-wid-con .elementor-inline-items.row .elementor-icon-list-item a {
    padding: 5px 15px
}

.ekit-wid-con .elementor-icon-list-item > a {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.ekit-wid-con .elementor-icon-list-item > a.ekit_badge_right {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%
}

.ekit-wid-con .ekit_menu_label {
    border-radius: 3px;
    padding: 2px 5px;
    display: inline-block;
    font-size: 10px;
    color: #fff;
    background-color: #c91765;
    margin-left: 5px
}

.ekit-wid-con .ekit_menu_subtitle {
    font-size: 11px;
    font-style: normal;
    color: #8d96ae;
    display: block;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .ekit_page_list_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.elementor-widget-elementskit-page-list.elementor-align-center .ekit-wid-con .ekit_page_list_content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.elementor-widget-elementskit-page-list.elementor-align-left .ekit-wid-con .ekit_page_list_content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.elementor-widget-elementskit-page-list.elementor-align-right .ekit-wid-con .ekit_page_list_content {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.ekit-wid-con .elementor-icon-list-icon {
    display: inline-block;
    text-align: center;
    line-height: inherit
}

.ekit-wid-con .elementor-icon-list-icon svg {
    width: inherit;
    max-width: 100%;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementor-icon-list-icon > i {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-enabled-bg-img .elementor-icon-list-item a {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-enabled-bg-img .elementor-icon-list-item a::after {
    content: "";
    background-color: rgba(34, 34, 34, .7);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    -webkit-transition: all .4s;
    transition: all .4s
}

.ekit-enabled-bg-img .elementor-icon-list-item a:hover::after {
    background-color: rgba(34, 34, 34, .9)
}

.ekit-enabled-bg-img .elementor-icon-list-item a .ekit_post_list_content_wraper {
    z-index: 1
}

.ekit-enabled-bg-img .elementor-icon-list-item a > img {
    z-index: 1
}

.ekit-wid-con .nav {
    margin: 0;
    padding: 0;
    list-style: none
}

.ekit-wid-con .ekit-form form p {
    margin-bottom: 0
}

.ekit-wid-con .ekit-form form input:not([type=submit]):not([type=checkbox]):not([type=radio]) {
    display: block;
    width: 100%;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    height: 56px;
    border-radius: 0;
    padding: .375rem .75rem .375rem 30px;
    -webkit-box-shadow: none;
    box-shadow: none
}

.ekit-wid-con .ekit-form form input:not([type=submit]):not([type=checkbox]):not([type=radio]):hover {
    border-color: #333
}

.ekit-wid-con .ekit-form form input:not([type=submit]):not([type=checkbox]):not([type=radio]):focus {
    border-color: #4d68ff
}

.ekit-wid-con .ekit-form form input[type=submit] {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    padding: 12px 72px;
    font-size: .9375rem;
    background-color: #313636;
    border: 0 solid #000;
    color: #fff;
    cursor: pointer
}

.ekit-wid-con .ekit-form form input[type=submit]:hover {
    background-color: #4d68ff
}

.ekit-wid-con .ekit-form form select {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    height: 56px
}

.ekit-wid-con .ekit-form form select[multiple] {
    height: 100px
}

.ekit-wid-con .ekit-form form label {
    margin-bottom: 7px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #313636;
    font-weight: 400
}

.ekit-wid-con .ekit-form form label span {
    color: #777;
    font-size: .8125rem;
    display: block
}

.ekit-wid-con .ekit-form form textarea {
    display: block;
    width: 100%;
    font-size: 1rem;
    color: #495057;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    border-radius: 0;
    height: 176px;
    resize: none;
    padding: 15px .75rem .375rem 30px;
    -webkit-box-shadow: none;
    box-shadow: none
}

.ekit-wid-con .ekit-form form textarea:hover {
    border-color: #333
}

.ekit-wid-con .ekit-form form textarea:focus {
    border-color: #4d68ff
}

.ekit-wid-con .ekit-form form .intl-tel-input {
    width: 100%
}

.ekit-wid-con .ekit-form form .ekit-form-input {
    margin-bottom: 50px
}

.ekit-wid-con .btn-text-primary {
    color: #4285f4
}

.ekit-wid-con .btn-pill {
    border-radius: 2rem
}

.ekit-wid-con .d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-wid-con .ekit-review-wrapper {
    background-color: #f2f2f5;
    font-family: Roboto, sans-serif;
    overflow: hidden
}

.ekit-wid-con .ekit-review-overview, .ekit-wid-con .review-card-overview {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #fff
}

.ekit-wid-con .ekit-review-card, .ekit-wid-con .ekit-review-overview {
    background-color: #fff
}

.ekit-wid-con .ekit-review-card h4, .ekit-wid-con .ekit-review-overview h4 {
    margin: 0;
    white-space: nowrap
}

.ekit-wid-con .ekit-review-card--name, .ekit-wid-con .ekit-review-card--rating .rating-average, .ekit-wid-con .ekit-review-card--title h4, .ekit-wid-con .ekit-review-overview--name, .ekit-wid-con .ekit-review-overview--rating .rating-average, .ekit-wid-con .ekit-review-overview--title h4 {
    color: #000032
}

.ekit-wid-con .ekit-review-card--rating .rating-average, .ekit-wid-con .ekit-review-overview--rating .rating-average {
    font-weight: 500
}

.ekit-wid-con .ekit-review-card--rating > div, .ekit-wid-con .ekit-review-overview--rating > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-wid-con .ekit-review-card--rating .rating-average, .ekit-wid-con .ekit-review-overview--rating .rating-average {
    font-size: 18px;
    font-weight: 700
}

.ekit-wid-con .ekit-review-card--rating .rating-stars, .ekit-wid-con .ekit-review-overview--rating .rating-stars {
    margin: 0 .5rem
}

.ekit-wid-con .ekit-review-card--rating .rating-text, .ekit-wid-con .ekit-review-overview--rating .rating-text {
    color: #878787;
    margin-bottom: 0
}

.ekit-wid-con .ekit-review-overview {
    padding: 1.5rem 2rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 1.5rem 0;
    border-width: 1px
}

.ekit-wid-con .ekit-review-overview--thumbnail {
    margin-right: 1rem
}

.ekit-wid-con .ekit-review-overview--thumbnail img {
    width: 40px;
    height: 40px;
    border-radius: 50%
}

.ekit-wid-con .ekit-review-overview--title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-wid-con .ekit-review-overview--title img {
    height: 26px;
    margin-right: .5rem
}

.ekit-wid-con .ekit-review-overview--title h4 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 400
}

.ekit-wid-con .ekit-review-overview--title h4 span {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 900;
    text-transform: capitalize
}

.ekit-wid-con .ekit-review-overview--rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .ekit-review-overview--rating .rating-average, .ekit-wid-con .ekit-review-overview--rating .rating-text {
    line-height: 27px
}

.ekit-wid-con .ekit-review-overview--rating .rating-text {
    font-style: italic;
    font-size: 12px;
    font-weight: 400
}

.ekit-wid-con .ekit-review-overview--rating .rating-average {
    font-size: 22px;
    font-weight: 700
}

.ekit-wid-con .ekit-review-overview--stars {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 10px;
    height: 27px
}

.ekit-wid-con .ekit-review-overview--stars .icon {
    font-size: 20px
}

.ekit-wid-con .ekit-review-overview--actions {
    display: grid;
    place-items: center
}

.ekit-wid-con .ekit-review-overview--actions .btn {
    font-size: 15px;
    font-weight: 400;
    padding: 0 30px;
    color: #fff;
    min-height: 35px;
    line-height: 35px
}

.ekit-wid-con .ekit-review-cards-grid .ekit-review-card--comment, .ekit-wid-con .ekit-review-slider-wrapper .ekit-review-card--comment {
    overflow: hidden
}

.ekit-wid-con .ekit-review-cards-masonry .ekit-review-card {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid-column
}

.ekit-wid-con .ekit-review-cards-list .ekit-review-card--comment {
    min-height: unset
}

.ekit-wid-con .ekit-review-card {
    overflow: hidden;
    position: relative;
    border-color: #f2f2f5;
    border-width: 1px;
    padding: 2rem;
    display: grid;
    grid-template-columns:auto auto 1fr;
    grid-template-areas:"thumbnail name name" "thumbnail date date" "thumbnail stars stars" "comment comment comment" "posted-on posted-on posted-on"
}

.ekit-wid-con .ekit-review-card::before {
    content: ""
}

.ekit-wid-con .ekit-review-card--date, .ekit-wid-con .ekit-review-card--desc {
    grid-area: date
}

.ekit-wid-con .ekit-review-card--top-right-logo {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10
}

.ekit-wid-con .ekit-review-card--top-right-logo i {
    font-size: 20px
}

.ekit-wid-con .ekit-review-card--image, .ekit-wid-con .ekit-review-card--image img, .ekit-wid-con .ekit-review-card--thumbnail img {
    border-radius: 50%
}

.ekit-wid-con .ekit-review-card--image {
    width: 60px;
    height: 60px;
    grid-area: thumbnail;
    min-width: 60px;
    border-radius: 50%;
    background-color: #eae9f7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem;
    position: relative
}

.ekit-wid-con .ekit-review-card--image img {
    height: 100%;
    width: 100%
}

.ekit-wid-con .ekit-review-card--thumbnail {
    grid-area: thumbnail;
    padding-right: 1rem
}

.ekit-wid-con .ekit-review-card--thumbnail .thumbnail {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%
}

.ekit-wid-con .ekit-review-card--thumbnail-badge .badge, .ekit-wid-con .ekit-review-card--thumbnail-badge > div {
    position: relative
}

.ekit-wid-con .ekit-review-card--thumbnail-badge .badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0
}

.ekit-wid-con .ekit-review-card--thumbnail-badge .badge img {
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute
}

.ekit-wid-con .ekit-review-card--average, .ekit-wid-con .ekit-review-card--title {
    color: #000032
}

.ekit-wid-con .ekit-review-card--title {
    font-size: 24px;
    line-height: 24px;
    margin: 0 0 .8rem 0
}

.ekit-wid-con .ekit-review-card--name {
    grid-area: name;
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 .25rem 0
}

.ekit-wid-con .ekit-review-card--rating .rating-text {
    font-size: 13px
}

.ekit-wid-con .ekit-review-card--average {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-right: 1rem
}

.ekit-wid-con .ekit-review-card--stars {
    grid-area: stars;
    color: #f4be28;
    font-size: 13px;
    line-height: 20px
}

.ekit-wid-con .ekit-review-card--date, .ekit-wid-con .ekit-review-card--desc, .ekit-wid-con .ekit-review-card--posted-on p {
    margin-bottom: 0
}

.ekit-wid-con .ekit-review-card--top-right-image {
    position: absolute;
    top: 2rem;
    right: 2rem
}

.ekit-wid-con .ekit-review-card--comment {
    grid-area: comment;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #32323d
}

.ekit-wid-con .ekit-review-card--comment .more {
    color: #3476e0;
    cursor: pointer
}

.ekit-wid-con .ekit-review-card--actions {
    grid-area: actions
}

.ekit-wid-con .ekit-review-card--actions .btn {
    padding: 0;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    color: #4285f4
}

.ekit-wid-con .ekit-review-card--actions .btn:hover {
    color: #3476e0
}

.ekit-wid-con .ekit-review-card--actions .btn:first-child {
    margin-right: 10px
}

.ekit-wid-con .ekit-review-card--posted-on {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-area: posted-on
}

.ekit-wid-con .ekit-review-card--posted-on p {
    font-size: 13px;
    line-height: 13px;
    font-weight: 400
}

.ekit-wid-con .ekit-review-card--posted-on h5 {
    margin: 0;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500
}

.ekit-wid-con .ekit-review-card-bubble {
    background-color: transparent !important
}

.ekit-wid-con .ekit-review-card-bubble::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: calc(100% - 100px);
    z-index: 1
}

.ekit-wid-con .ekit-review-card-bubble::after {
    content: "";
    position: absolute;
    left: 2.4rem;
    top: calc(100% - 116px);
    height: 1.5rem;
    width: 1.5rem;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--comment, .ekit-wid-con .ekit-review-card-bubble .ekit-review-card--date, .ekit-wid-con .ekit-review-card-bubble .ekit-review-card--name, .ekit-wid-con .ekit-review-card-bubble .ekit-review-card--posted-on, .ekit-wid-con .ekit-review-card-bubble .ekit-review-card--stars, .ekit-wid-con .ekit-review-card-bubble .ekit-review-card--thumbnail {
    z-index: 10
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--posted-on {
    display: none
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--comment, .ekit-wid-con .ekit-review-card-bubble .ekit-review-card--stars {
    grid-column: 1/4
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--stars {
    grid-row: 1
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--comment {
    grid-row: 2
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--date, .ekit-wid-con .ekit-review-card-bubble .ekit-review-card--name {
    grid-column: 2/4;
    text-align: left
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--name, .ekit-wid-con .ekit-review-card-bubble .ekit-review-card--thumbnail {
    margin-top: 2.5rem
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--thumbnail {
    padding-right: 1rem;
    grid-row: 3/5;
    grid-column: 1/2
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--name {
    grid-row: 3
}

.ekit-wid-con .ekit-review-card-bubble .ekit-review-card--date {
    grid-row: 4
}

.ekit-wid-con .ekit-review-card-rating-inline .ekit-review-card--date {
    grid-column: 3
}

.ekit-wid-con .ekit-review-card-rating-inline .ekit-review-card--stars {
    grid-column: 2;
    grid-row: 2
}

.ekit-wid-con .ekit-review-card-thumbnail-left {
    grid-template-areas:"thumbnail name name" "thumbnail date date" "thumbnail stars stars" "thumbnail comment comment" "thumbnail posted-on posted-on"
}

.ekit-wid-con .ekit-review-card-align-center {
    text-align: center;
    grid-template-areas:"thumbnail thumbnail thumbnail" "name name name" "date date date" "stars stars stars" "comment comment comment" "posted-on posted-on posted-on"
}

.ekit-wid-con .ekit-review-card-align-center .ekit-review-card--thumbnail {
    padding-right: 0;
    margin-bottom: 2rem
}

.ekit-wid-con .ekit-review-card-align-center .ekit-review-card--thumbnail > div {
    width: 40px;
    margin: 0 auto
}

.ekit-wid-con .ekit-review-card-align-center .ekit-review-card--posted-on {
    display: block;
    grid-row: 6;
    padding-top: 1rem
}

.ekit-wid-con .ekit-review-card-align-center .ekit-review-card--posted-on i {
    margin: 0 auto !important
}

.ekit-wid-con .ekit-review-card-name-bottom .ekit-review-card--stars {
    grid-row: 2
}

.ekit-wid-con .ekit-review-card-name-bottom .ekit-review-card--comment {
    grid-row: 3
}

.ekit-wid-con .ekit-review-card-name-bottom .ekit-review-card--name {
    grid-row: 4
}

.ekit-wid-con .ekit-review-card-name-bottom .ekit-review-card--date {
    grid-row: 5
}

.ekit-wid-con .ekit-review-card-name-bottom .ekit-review-card--posted-on {
    grid-row: 6
}

.ekit-wid-con .ekit_review_card_stars_inline .ekit-review-card--stars {
    grid-row: 2;
    grid-column: 2/3
}

.ekit-wid-con .ekit_review_card_stars_inline .ekit-review-card--date {
    grid-row: 2;
    grid-column: 3
}

.ekit-wid-con .ekit-review-card-name-bottom.ekit-review-card-thumbnail-left .ekit-review-card--posted-on {
    grid-column: 1/4
}

.ekit-wid-con .ekit-review-card-overview {
    grid-template-areas:"thumbnail name name" "thumbnail average stars" "thumbnail date date" "thumbnail actions actions"
}

.ekit-wid-con .ekit-review-card-overview::before {
    display: none
}

.ekit-wid-con .ekit-review-card-overview .ekit-review-card--image {
    margin-right: 1rem
}

.ekit-wid-con .ekit-review-card-overview .ekit-review-card--name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 1rem
}

.ekit-wid-con .ekit-review-card-overview .ekit-review-card--average {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-right: 6px
}

.ekit-wid-con .ekit-review-card-overview .ekit-review-card--desc {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400
}

.ekit-wid-con .ekit-review-card-overview .ekit-review-card--actions {
    margin-top: 1rem
}

.ekit-wid-con .ekit-review-card-overview .ekit-review-card--actions .btn {
    padding: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 20px;
    text-transform: none;
    background-color: transparent
}

.ekit-wid-con .ekit-review-card-overview .ekit-review-card--stars {
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .small {
    font-size: 12px;
    line-height: 20px
}

.ekit-wid-con .muted {
    color: #adadad
}

@media screen and (max-width: 411px) {
    .ekit-wid-con .ekit-review-card-overview {
        grid-template-areas:"thumbnail name name" "thumbnail average stars" "thumbnail date date" "actions actions actions"
    }

    .ekit-wid-con .ekit-review-overview {
        display: block !important
    }

    .ekit-wid-con .ekit-review-overview--actions {
        display: block !important;
        margin-top: 1rem !important;
        text-align: center
    }

    .ekit-wid-con .ekit-review-overview--actions .btn {
        padding: .75rem 3rem
    }
}

.ekit-wid-con iframe {
    border: none
}

.ekit-wid-con a, .ekit-wid-con b, .ekit-wid-con div, .ekit-wid-con li, .ekit-wid-con ul {
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent
}

.ekit-wid-con a:active, .ekit-wid-con a:focus, .ekit-wid-con input, .ekit-wid-con input:active, .ekit-wid-con input:focus, .ekit-wid-con input:hover, .ekit-wid-con textarea, .ekit-wid-con textarea:active, .ekit-wid-con textarea:focus, .ekit-wid-con textarea:hover {
    -moz-outline: none;
    outline: 0
}

.ekit-wid-con embed, .ekit-wid-con img:not([draggable]), .ekit-wid-con object, .ekit-wid-con video {
    max-width: 100%;
    height: auto
}

.ekit-wid-con a, .ekit-wid-con button {
    text-decoration: none;
    outline: 0
}

.ekit-wid-con a:active, .ekit-wid-con a:focus, .ekit-wid-con a:hover, .ekit-wid-con a:visited {
    text-decoration: none;
    outline: 0
}

.ekit-wid-con img {
    border: none;
    max-width: 100%
}

.ekit-wid-con li, .ekit-wid-con ul {
    margin: 0;
    padding: 0
}

.ekit-wid-con .elementor-text-editor ol, .ekit-wid-con .elementor-text-editor ul {
    padding-left: 18px
}

.ekit-wid-con h4, .ekit-wid-con p {
    margin-bottom: 10px
}

.ekit-wid-con .clear-both::after, .ekit-wid-con .clear-both::before {
    display: table;
    content: "";
    clear: both
}

.ekit-wid-con button:focus {
    outline: 0
}

.ekit-wid-con .text-left {
    text-align: left !important
}

.ekit-wid-con .text-right {
    text-align: right !important
}

.ekit-wid-con .text-center {
    text-align: center !important
}

.ekit-wid-con .ekit-flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.ekit-wid-con .ekit-flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.ekit-wid-con .ekit-flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.ekit-wid-con .ekit-flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.ekit-wid-con .tab-content > .tab-pane {
    font-family: inherit
}

.ekit-wid-con .elementskit-border-divider {
    left: 13px
}

.ekit-wid-con .elementskit-border-divider.elementskit-style-long {
    left: inherit
}

.ekit-wid-con .elementskit-border-divider::before {
    left: -26px
}

.ekit-wid-con .elementskit-navbar-nav-default.elementskit-menu-container {
    z-index: 1000
}

.ekit-wid-con .elementskit-navbar-nav-default .elementskit-navbar-nav > li > a:hover {
    background-color: transparent
}

.ekit-wid-con .elementor-icon-list-item a > img {
    width: 100px;
    margin-right: 15px
}

.ekit-wid-con .elementor-icon-list-item .meta-lists {
    line-height: 1
}

.ekit-wid-con .elementor-icon-list-item .meta-lists svg {
    max-width: 12px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementor-icon-list-item .meta-lists > span {
    color: #7f8595;
    font-size: 12px;
    display: inline-block;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .nf-error.field-wrap .nf-field-element::after, .ekit-wid-con .nf-pass.field-wrap .nf-field-element::after {
    top: 0;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: normal
}

.ekit-wid-con .nf-response-msg > p {
    line-height: normal
}

.ekit-wid-con textarea.ninja-forms-field {
    display: block
}

.ekit-wid-con .column-count-1 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1
}

.ekit-wid-con .column-count-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2
}

.ekit-wid-con .column-count-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3
}

.ekit-wid-con .column-count-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4
}

.ekit-wid-con .column-count-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5
}

.ekit-wid-con .column-count-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6
}

.ekit-wid-con .text-left {
    text-align: left !important
}

.ekit-wid-con .text-right {
    text-align: right !important
}

.ekit-wid-con .text-center {
    text-align: center !important
}

.ekit-wid-con .ekit-img-overlay {
    position: relative;
    z-index: 0
}

.ekit-wid-con .ekit-img-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1
}

.ekit-wid-con .ekit-wrapper-link {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 10
}

.ekit-wid-con .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

@media (min-width: 1025px) {
    .ekit-wid-con .default_menu_position .elementor-column, .ekit-wid-con .default_menu_position .elementor-column-wrap, .ekit-wid-con .default_menu_position .elementor-widget, .ekit-wid-con .default_menu_position .elementor-widget-wrap, .ekit-wid-con .default_menu_position .elementskit-navbar-nav-default.elementskit-menu-container {
        position: static
    }

    .ekit-wid-con .default_menu_position .elementskit-menu-po-right .elementskit-megamenu-has.top_position.elementskit-dropdown-menu-custom_width .elementskit-megamenu-panel, .ekit-wid-con .default_menu_position .elementskit-menu-po-right .elementskit-megamenu-has.top_position.elementskit-dropdown-menu-default_width .elementskit-megamenu-panel, .ekit-wid-con .elementskit-navbar-nav-default .elementskit-menu-po-right .relative_position.elementskit-megamenu-has .elementskit-megamenu-panel {
        right: 0;
        left: auto
    }

    .ekit-wid-con .elementskit-navbar-nav-default .elementskit-megamenu-has .elementskit-megamenu-panel {
        -webkit-transition: transform .4s ease;
        -webkit-transition: -webkit-transform .4s ease;
        transition: -webkit-transform .4s ease;
        transition: transform .4s ease;
        transition: transform .4s ease, -webkit-transform .4s ease
    }

    .ekit-wid-con .elementskit-menu-po-center .elementskit-megamenu-has.top_position.elementskit-dropdown-menu-custom_width .elementskit-megamenu-panel, .ekit-wid-con .elementskit-menu-po-center .elementskit-megamenu-has.top_position.elementskit-dropdown-menu-default_width .elementskit-megamenu-panel {
        -webkit-transform: translateY(-10px) translateX(-50%);
        transform: translateY(-10px) translateX(-50%);
        left: 50%
    }

    .ekit-wid-con .default_menu_position .elementskit-menu-po-center .elementskit-megamenu-has.top_position.elementskit-dropdown-menu-custom_width:hover > .elementskit-megamenu-panel, .ekit-wid-con .default_menu_position .elementskit-menu-po-center .elementskit-megamenu-has.top_position.elementskit-dropdown-menu-default_width:hover > .elementskit-megamenu-panel {
        -webkit-transform: translateY(0) translateX(-50%);
        transform: translateY(0) translateX(-50%);
        left: 50%
    }

    .ekit-wid-con body.is-vertical-menu-active .ekit-wid-con .elementskit-navbar-nav-default.elementskit-menu-container {
        z-index: 9
    }

    .ekit-wid-con body.is-vertical-menu-active .vertical-menu-active .xs-vertical-menu-backdrop {
        z-index: 150
    }

    .ekit-wid-con body.is-vertical-menu-active .ekit-vertical-menu-tigger {
        z-index: 160
    }
}

@media (max-width: 1024px) {
    .ekit-wid-con .ekit-vertical-navbar-nav .elementskit-submenu-indicator {
        padding: 3px 10px;
        border: 1px solid;
        border-radius: 30px
    }
}

.ekit-wid-con .xs-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .5;
    z-index: 0
}

.ekit-wid-con .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.ekit-wid-con .swiper-container {
    width: inherit;
    height: inherit
}

.ekit-wid-con .swiper-container.ekit-main-swiper, .ekit-wid-con .swiper.ekit-main-swiper {
    position: static
}

.ekit-wid-con .swiper .swiper-navigation-button, .ekit-wid-con .swiper-container .swiper-navigation-button {
    background: rgba(0, 0, 0, 0);
    width: auto;
    height: auto
}

.ekit-wid-con .tab-pane.animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s
}

.ekit-wid-con .mfp-iframe-scaler iframe {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: 0 0
}

.ekit-wid-con img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ekit-wid-con .image-switcher .content-image {
    position: relative
}

.ekit-wid-con .image-switcher .content-image .hover-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.ekit-wid-con .image-switcher:hover .content-image .main-image {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.ekit-wid-con .image-switcher:hover .content-image .hover-image {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible
}

.ekit-wid-con .fill-icon {
    display: inline-block;
    background-color: #2575fc;
    color: #fff;
    font-size: 40px;
    text-align: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .fill-icon.round {
    border-radius: 50%
}

.ekit-wid-con .fill-icon.style-light {
    background-color: #f5f5f5;
    color: #000
}

.ekit-wid-con button {
    cursor: pointer;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .form-control {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.ekit-wid-con .widgetarea_warper_edit {
    display: none
}

.ekit-wid-con .swiper-pagination-bullet {
    opacity: inherit;
    background-color: inherit
}

.ekit-wid-con [data-ajax-post-id]:not(.is--loaded):before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-style: solid;
    border-width: 3px;
    border-color: #acacac #ddd #ddd;
    border-radius: 50%;
    -webkit-animation: eicon-spin 1.5s infinite linear;
    animation: eicon-spin 1.5s infinite linear
}

.elementor-widget-container .ekit-wid-con a {
    text-decoration: none
}

#wp-admin-bar-elementor_edit_page-default:empty {
    display: none
}

.elementor-widget-elementskit-pricing .elementor-widget-container {
    overflow: hidden
}

.elementor-widget-elementskit-timeline .elementor-widget-container {
    padding: 35px 45px 60px;
}

.elementor-widget-elementskit-business-hours .elementor-widget-container {
    padding: 20px 20px 20px 20px;
    background-color: #fff;
    -webkit-box-shadow: 0 10px 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, .1)
}

.elementor-widget-elementskit-dual-button-center .ekit-element-align-wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.elementor-widget-elementskit-dual-button-end .ekit-element-align-wrapper {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.elementor-widget-elementskit-dual-button-start .ekit-element-align-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.ekit_wpForms_container-form-button-full-width .wpforms-submit-container .wpforms-submit {
    width: 100%
}

.ekit_ninjaForms_container-button-full-width .submit-container input[type=button] {
    width: 100%
}

.elemenetskit-alert-info {
    position: relative;
    padding: .75rem 1.25rem;
    border-radius: .25rem;
    color: #0c5460;
    background-color: #d1ecf1;
    border: 1px solid #bee5eb;
}

.elementor-widget-elementskit-team.animated {
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none
}

@media (max-width: 1024px) {
    .ekit-tab-hide {
        display: none
    }
}

@media (max-width: 767px) {
    .ekit-mobile-hide {
        display: none
    }
}

.ekit-template-content-footer, .ekit-template-content-header {
    clear: both
}

.elementor-edit-area-active .elementor-widget:hover .widgetarea_warper_edit {
    display: block
}

.ekit-sticky {
    z-index: 9999
}

div.elementor .ekit-sticky.elementor-element {
    -webkit-transition: background-color .3s ease-in;
    transition: background-color .3s ease-in
}

.ekit-sticky[data-ekit-sticky] {
    z-index: 1
}

div.elementor .ekit-sticky--show_on_scroll_up.elementor-element {
    -webkit-transition: background-color .3s ease-in, opacity .3s, visibility .3s;
    transition: background-color .3s ease-in, opacity .3s, visibility .3s
}

.ekit-sticky--effects.ekit-sticky--down.ekit-sticky--show_on_scroll_up {
    opacity: 0;
    visibility: hidden
}

.weforms_submit_btn {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    cursor: pointer
}

.wpuf-label > label {
    margin-bottom: 0
}

ul.wpuf-form li label.wpuf-form-sub-label {
    margin-bottom: 0
}

.image-source-link {
    color: #98c3d1
}

.mfp-bg, .mfp-container {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.mfp-ready .mfp-container {
    opacity: 1
}

.mfp-ready.mfp-bg {
    opacity: .8
}

.mfp-removing .mfp-container, .mfp-removing.mfp-bg {
    opacity: 0
}

.mfp-move-horizontal .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px)
}

.mfp-move-horizontal.mfp-bg {
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s
}

.mfp-move-horizontal.mfp-ready .mfp-with-anim {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mfp-move-horizontal.mfp-ready.mfp-bg {
    opacity: .8
}

.mfp-move-horizontal.mfp-removing .mfp-with-anim {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0
}

.mfp-move-horizontal.mfp-removing.mfp-bg {
    opacity: 0
}

.mfp-zoom-out .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
}

.mfp-zoom-out.mfp-bg {
    opacity: 0;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.mfp-zoom-out.mfp-ready.mfp-bg {
    opacity: .8
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0
}

.mfp-zoom-out.mfp-removing.mfp-bg {
    opacity: 0
}

button.mfp-close.ekit-popup-close {
    border: 1px solid #fff;
    margin: 20px;
    border-radius: 50%;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

button.mfp-close.ekit-popup-close:hover {
    background-color: #000;
    color: #fff;
    border-color: #000;
    width: 44px
}

.mfp-iframe-holder .mfp-close.ekit-popup-close {
    width: 44px;
    text-align: center;
    padding-right: 0;
    top: 0;
    right: 0
}

.mfp-container.mfp-iframe-holder {
    position: fixed
}

.mfp-container.mfp-iframe-holder .mfp-close.ekit-popup-close {
    position: fixed
}

.mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: .8
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
    -webkit-transform: scale(.8);
    transform: scale(.8);
    opacity: 0
}

.mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0
}

.white-popup-block {
    background: #ccc;
    padding: 20px;
    max-width: 300px;
    margin: 0 auto;
    -webkit-animation: open 1s;
    animation: open 1s
}

.mfp-removing {
    -webkit-animation: close 1s;
    animation: close 1s
}

.mfp-open .modal-popup-group, .mfp-open .xs-modal-sidebar {
    -webkit-animation-name: galleryopen;
    animation-name: galleryopen;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.mfp-bg.ekit-promo-popup {
    background-color: rgba(0, 0, 0, .87);
    padding-bottom: 100%;
    border-radius: 100%;
    overflow: hidden;
    -webkit-animation: menu-animation .8s ease-out forwards;
    animation: menu-animation .8s ease-out forwards
}

.ekit-promo-popup .modal-content {
    background-color: transparent;
    padding: 0;
    border: 0
}

.ekit-promo-popup .mfp-close {
    color: #fff;
    opacity: 0;
    -webkit-transition: all 1s ease .8s;
    transition: all 1s ease .8s;
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px)
}

.ekit-promo-popup.mfp-ready .mfp-close {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    position: fixed
}

body.admin-bar .ekit-promo-popup.mfp-ready .mfp-close {
    margin-top: 45px
}

.ekit-promo-popup.my-mfp-slide-bottom .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 1s ease .8s;
    transition: all 1s ease .8s;
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px)
}

.ekit-promo-popup.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

@-webkit-keyframes bounceAnim {
    0%, 100%, 20%, 50%, 80% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    40% {
        -webkit-transform: translateY(-16px);
        transform: translateY(-16px)
    }
    60% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }
}

@keyframes bounceAnim {
    0%, 100%, 20%, 50%, 80% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    40% {
        -webkit-transform: translateY(-16px);
        transform: translateY(-16px)
    }
    60% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px)
    }
}

@-webkit-keyframes menu-animation {
    0% {
        opacity: 0;
        -webkit-transform: scale(.04) translateY(300%);
        transform: scale(.04) translateY(300%)
    }
    40% {
        -webkit-transform: scale(.04) translateY(0);
        transform: scale(.04) translateY(0);
        -webkit-transition: ease-out;
        transition: ease-out
    }
    40% {
        -webkit-transform: scale(.04) translateY(0);
        transform: scale(.04) translateY(0)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(.02) translateY(0);
        transform: scale(.02) translateY(0)
    }
    61% {
        opacity: 1;
        -webkit-transform: scale(.04) translateY(0);
        transform: scale(.04) translateY(0)
    }
    99.9% {
        opacity: 1;
        height: 0;
        padding-bottom: 100%;
        border-radius: 100%
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(2) translateY(0);
        transform: scale(2) translateY(0);
        height: 100%;
        padding-bottom: 0;
        border-radius: 0
    }
}

@keyframes menu-animation {
    0% {
        opacity: 0;
        -webkit-transform: scale(.04) translateY(300%);
        transform: scale(.04) translateY(300%)
    }
    40% {
        -webkit-transform: scale(.04) translateY(0);
        transform: scale(.04) translateY(0);
        -webkit-transition: ease-out;
        transition: ease-out
    }
    40% {
        -webkit-transform: scale(.04) translateY(0);
        transform: scale(.04) translateY(0)
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(.02) translateY(0);
        transform: scale(.02) translateY(0)
    }
    61% {
        opacity: 1;
        -webkit-transform: scale(.04) translateY(0);
        transform: scale(.04) translateY(0)
    }
    99.9% {
        opacity: 1;
        height: 0;
        padding-bottom: 100%;
        border-radius: 100%
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(2) translateY(0);
        transform: scale(2) translateY(0);
        height: 100%;
        padding-bottom: 0;
        border-radius: 0
    }
}

@-webkit-keyframes show-fadein {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes show-fadein {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes hide-fadeout {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes hide-fadeout {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes show-animation {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes show-animation {
    0% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes hide-animation {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    100% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

@keyframes hide-animation {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    100% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0
    }
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

@-webkit-keyframes button-ripple {
    70% {
        -webkit-box-shadow: 0 0 0 var(--glow-size, 15px) currentColor;
        box-shadow: 0 0 0 var(--glow-size, 15px) currentColor;
        opacity: 0
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 currentColor;
        box-shadow: 0 0 0 0 currentColor;
        opacity: 0
    }
}

@keyframes button-ripple {
    70% {
        -webkit-box-shadow: 0 0 0 var(--glow-size, 15px) currentColor;
        box-shadow: 0 0 0 var(--glow-size, 15px) currentColor;
        opacity: 0
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 currentColor;
        box-shadow: 0 0 0 0 currentColor;
        opacity: 0
    }
}

@-webkit-keyframes open {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes open {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes close {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@keyframes close {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes galleryopen {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes galleryopen {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@-webkit-keyframes location-indicator {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(12, 90, 219, .2);
        box-shadow: 0 0 0 0 rgba(12, 90, 219, .2)
    }
    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(12, 90, 219, 0);
        box-shadow: 0 0 0 30px rgba(12, 90, 219, 0)
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(12, 90, 219, 0);
        box-shadow: 0 0 0 0 rgba(12, 90, 219, 0)
    }
}

@keyframes location-indicator {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(12, 90, 219, .2);
        box-shadow: 0 0 0 0 rgba(12, 90, 219, .2)
    }
    70% {
        -webkit-box-shadow: 0 0 0 30px rgba(12, 90, 219, 0);
        box-shadow: 0 0 0 30px rgba(12, 90, 219, 0)
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(12, 90, 219, 0);
        box-shadow: 0 0 0 0 rgba(12, 90, 219, 0)
    }
}

@-webkit-keyframes iconTranslateY {
    49% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    51% {
        opacity: 1
    }
}

@keyframes iconTranslateY {
    49% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    51% {
        opacity: 1
    }
}

@-webkit-keyframes RainDrop {
    0%, 100%, 25%, 55%, 75%, 87%, 97% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
    26%, 56%, 76% {
        -webkit-transform: scaleX(1.3) scaleY(.8);
        transform: scaleX(1.3) scaleY(.8)
    }
    31%, 61%, 81% {
        -webkit-transform: scaleX(.8) scaleY(1.2);
        transform: scaleX(.8) scaleY(1.2)
    }
    76%, 88% {
        -webkit-transform: scaleX(1.2);
        transform: scaleX(1.2)
    }
    98% {
        -webkit-transform: scaleX(1.1);
        transform: scaleX(1.1)
    }
}

@keyframes RainDrop {
    0%, 100%, 25%, 55%, 75%, 87%, 97% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
    26%, 56%, 76% {
        -webkit-transform: scaleX(1.3) scaleY(.8);
        transform: scaleX(1.3) scaleY(.8)
    }
    31%, 61%, 81% {
        -webkit-transform: scaleX(.8) scaleY(1.2);
        transform: scaleX(.8) scaleY(1.2)
    }
    76%, 88% {
        -webkit-transform: scaleX(1.2);
        transform: scaleX(1.2)
    }
    98% {
        -webkit-transform: scaleX(1.1);
        transform: scaleX(1.1)
    }
}

@-webkit-keyframes WaterWave {
    50% {
        -webkit-transform: skewY(1deg) skewX(-1deg) scale(1.06);
        transform: skewY(1deg) skewX(-1deg) scale(1.06)
    }
}

@keyframes WaterWave {
    50% {
        -webkit-transform: skewY(1deg) skewX(-1deg) scale(1.06);
        transform: skewY(1deg) skewX(-1deg) scale(1.06)
    }
}

@-webkit-keyframes lightning {
    50%, from, to {
        opacity: 1
    }
    25%, 75% {
        opacity: 0
    }
}

@keyframes lightning {
    50%, from, to {
        opacity: 1
    }
    25%, 75% {
        opacity: 0
    }
}

@-webkit-keyframes JoltZoom {
    10% {
        font-size: 140%
    }
    40% {
        font-size: 80%
    }
    100% {
        font-size: 100%
    }
}

@keyframes JoltZoom {
    10% {
        font-size: 140%
    }
    40% {
        font-size: 80%
    }
    100% {
        font-size: 100%
    }
}

@-webkit-keyframes typing {
    from {
        width: 0
    }
}

@keyframes typing {
    from {
        width: 0
    }
}

@-webkit-keyframes cursor {
    50% {
        border-color: transparent
    }
}

@keyframes cursor {
    50% {
        border-color: transparent
    }
}

@-webkit-keyframes wipe {
    to {
        width: 0
    }
}

@keyframes wipe {
    to {
        width: 0
    }
}

@keyframes open {
    from {
        width: 0
    }
}

@-webkit-keyframes Magnify {
    50% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        letter-spacing: 26px
    }
}

@keyframes Magnify {
    50% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        letter-spacing: 26px
    }
}

@-webkit-keyframes Beat {
    14%, 42% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3)
    }
    28%, 70% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes Beat {
    14%, 42% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3)
    }
    28%, 70% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes FadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes FadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes FadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-60px);
        transform: translateX(-60px)
    }
    to {
        opacity: 1
    }
}

@keyframes FadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-60px);
        transform: translateX(-60px)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes FadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(60px);
        transform: translateX(60px)
    }
    to {
        opacity: 1
    }
}

@keyframes FadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(60px);
        transform: translateX(60px)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes FadeInTop {
    from {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    to {
        opacity: 1
    }
}

@keyframes FadeInTop {
    from {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes FadeInBottom {
    from {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    to {
        opacity: 1
    }
}

@keyframes FadeInBottom {
    from {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes FadeOut {
    to {
        opacity: 0
    }
}

@keyframes FadeOut {
    to {
        opacity: 0
    }
}

@-webkit-keyframes FadeOutLeft {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(-60px);
        transform: translateX(-60px)
    }
}

@keyframes FadeOutLeft {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(-60px);
        transform: translateX(-60px)
    }
}

@-webkit-keyframes FadeOutRight {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(60px);
        transform: translateX(60px)
    }
}

@keyframes FadeOutRight {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(60px);
        transform: translateX(60px)
    }
}

@-webkit-keyframes FadeOutTop {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@keyframes FadeOutTop {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@-webkit-keyframes FadeOutBottom {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@keyframes FadeOutBottom {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@-webkit-keyframes MovingBackFromRight {
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0, .9, .7, 1.45);
        animation-timing-function: cubic-bezier(0, .9, .7, 1.45)
    }
}

@keyframes MovingBackFromRight {
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0, .9, .7, 1.45);
        animation-timing-function: cubic-bezier(0, .9, .7, 1.45)
    }
}

@-webkit-keyframes MovingBackFromLeft {
    40% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0, .9, .7, 1.45);
        animation-timing-function: cubic-bezier(0, .9, .7, 1.45)
    }
}

@keyframes MovingBackFromLeft {
    40% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0, .9, .7, 1.45);
        animation-timing-function: cubic-bezier(0, .9, .7, 1.45)
    }
}

@-webkit-keyframes KickOutFront {
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0, .9, .7, 1.45);
        animation-timing-function: cubic-bezier(0, .9, .7, 1.45)
    }
}

@keyframes KickOutFront {
    40% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0, .9, .7, 1.45);
        animation-timing-function: cubic-bezier(0, .9, .7, 1.45)
    }
}

@-webkit-keyframes KickOutBehind {
    40% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0, .9, .7, 1.45);
        animation-timing-function: cubic-bezier(0, .9, .7, 1.45)
    }
}

@keyframes KickOutBehind {
    40% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-animation-timing-function: cubic-bezier(0, .9, .7, 1.45);
        animation-timing-function: cubic-bezier(0, .9, .7, 1.45)
    }
}

@-webkit-keyframes ScaleXIn {
    from {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0
    }
    50% {
        opacity: 1
    }
}

@keyframes ScaleXIn {
    from {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0
    }
    50% {
        opacity: 1
    }
}

@-webkit-keyframes ScaleXOut {
    to {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0
    }
    from {
        opacity: 1
    }
}

@keyframes ScaleXOut {
    to {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0
    }
    from {
        opacity: 1
    }
}

@-webkit-keyframes ScaleYIn {
    from {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        opacity: 0
    }
    50% {
        opacity: 1
    }
}

@keyframes ScaleYIn {
    from {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        opacity: 0
    }
    50% {
        opacity: 1
    }
}

@-webkit-keyframes ScaleYOut {
    to {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        opacity: 0
    }
    from {
        opacity: 1
    }
}

@keyframes ScaleYOut {
    to {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        opacity: 0
    }
    from {
        opacity: 1
    }
}

@-webkit-keyframes Jump {
    to {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
}

@keyframes Jump {
    to {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px)
    }
}

@-webkit-keyframes AboundTop {
    50% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes AboundTop {
    50% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@-webkit-keyframes AboundBottom {
    50% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes AboundBottom {
    50% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@-webkit-keyframes AboundLeft {
    50% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes AboundLeft {
    50% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@-webkit-keyframes AboundRight {
    50% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes AboundRight {
    50% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@-webkit-keyframes FlyInTop {
    0% {
        -webkit-transform: translate(0, -80px);
        transform: translate(0, -80px);
        opacity: 0
    }
    50% {
        -webkit-transform: translate(10px, 50px);
        transform: translate(10px, 50px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
}

@keyframes FlyInTop {
    0% {
        -webkit-transform: translate(0, -80px);
        transform: translate(0, -80px);
        opacity: 0
    }
    50% {
        -webkit-transform: translate(10px, 50px);
        transform: translate(10px, 50px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
}

@-webkit-keyframes FlyInLeft {
    0% {
        -webkit-transform: translate(-40px, 0);
        transform: translate(-40px, 0);
        opacity: 0
    }
    50% {
        -webkit-transform: translate(40px, 0);
        transform: translate(40px, 0)
    }
}

@keyframes FlyInLeft {
    0% {
        -webkit-transform: translate(-40px, 0);
        transform: translate(-40px, 0);
        opacity: 0
    }
    50% {
        -webkit-transform: translate(40px, 0);
        transform: translate(40px, 0)
    }
}

@-webkit-keyframes FlyInRight {
    0% {
        -webkit-transform: translate(40px, 0);
        transform: translate(40px, 0);
        opacity: 0
    }
    50% {
        -webkit-transform: translate(-40px, 0);
        transform: translate(-40px, 0)
    }
}

@keyframes FlyInRight {
    0% {
        -webkit-transform: translate(40px, 0);
        transform: translate(40px, 0);
        opacity: 0
    }
    50% {
        -webkit-transform: translate(-40px, 0);
        transform: translate(-40px, 0)
    }
}

@-webkit-keyframes FlyInBottom {
    0% {
        -webkit-transform: translate(0, 80px);
        transform: translate(0, 80px);
        opacity: 0
    }
    50% {
        -webkit-transform: translate(10px, -50px);
        transform: translate(10px, -50px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
}

@keyframes FlyInBottom {
    0% {
        -webkit-transform: translate(0, 80px);
        transform: translate(0, 80px);
        opacity: 0
    }
    50% {
        -webkit-transform: translate(10px, -50px);
        transform: translate(10px, -50px);
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out
    }
}

@-webkit-keyframes FlyOutTop {
    50% {
        -webkit-transform: translate(0, 50px);
        transform: translate(0, 50px)
    }
    to {
        -webkit-transform: translate(0, -100px);
        transform: translate(0, -100px);
        opacity: 0
    }
}

@keyframes FlyOutTop {
    50% {
        -webkit-transform: translate(0, 50px);
        transform: translate(0, 50px)
    }
    to {
        -webkit-transform: translate(0, -100px);
        transform: translate(0, -100px);
        opacity: 0
    }
}

@-webkit-keyframes FlyOutLeft {
    50% {
        -webkit-transform: translate(40px, 0);
        transform: translate(40px, 0)
    }
    to {
        -webkit-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
        opacity: 0
    }
}

@keyframes FlyOutLeft {
    50% {
        -webkit-transform: translate(40px, 0);
        transform: translate(40px, 0)
    }
    to {
        -webkit-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
        opacity: 0
    }
}

@-webkit-keyframes FlyOutRight {
    50% {
        -webkit-transform: translate(-40px, 0);
        transform: translate(-40px, 0)
    }
    to {
        -webkit-transform: translate(100px, 0);
        transform: translate(100px, 0);
        opacity: 0
    }
}

@keyframes FlyOutRight {
    50% {
        -webkit-transform: translate(-40px, 0);
        transform: translate(-40px, 0)
    }
    to {
        -webkit-transform: translate(100px, 0);
        transform: translate(100px, 0);
        opacity: 0
    }
}

@-webkit-keyframes FlyOutBottom {
    50% {
        -webkit-transform: translate(0, -40px);
        transform: translate(0, -40px)
    }
    to {
        -webkit-transform: translate(0, 100px);
        transform: translate(0, 100px);
        opacity: 0
    }
}

@keyframes FlyOutBottom {
    50% {
        -webkit-transform: translate(0, -40px);
        transform: translate(0, -40px)
    }
    to {
        -webkit-transform: translate(0, 100px);
        transform: translate(0, 100px);
        opacity: 0
    }
}

@-webkit-keyframes DoorCloseLeft {
    from, to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    from {
        -webkit-transform: rotateY(90deg);
        transform: rotateY(90deg);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes DoorCloseLeft {
    from, to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    from {
        -webkit-transform: rotateY(90deg);
        transform: rotateY(90deg);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes DoorOpenRight {
    from, to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    to {
        -webkit-transform: rotateY(90deg);
        transform: rotateY(90deg);
        opacity: 0
    }
}

@keyframes DoorOpenRight {
    from, to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    to {
        -webkit-transform: rotateY(90deg);
        transform: rotateY(90deg);
        opacity: 0
    }
}

@-webkit-keyframes DoorCloseRight {
    from, to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    from {
        -webkit-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes DoorCloseRight {
    from, to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    from {
        -webkit-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes DoorOpenLeft {
    from, to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    to {
        -webkit-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
        opacity: 0
    }
}

@keyframes DoorOpenLeft {
    from, to {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    to {
        -webkit-transform: rotateY(-90deg);
        transform: rotateY(-90deg);
        opacity: 0
    }
}

@-webkit-keyframes HangAndDropLeft {
    from {
        -webkit-transform-origin: left;
        transform-origin: left
    }
    50% {
        -webkit-transform: rotate(100deg);
        transform: rotate(100deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    60% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    to {
        -webkit-transform: translateY(800px) rotate(70deg);
        transform: translateY(800px) rotate(70deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
}

@keyframes HangAndDropLeft {
    from {
        -webkit-transform-origin: left;
        transform-origin: left
    }
    50% {
        -webkit-transform: rotate(100deg);
        transform: rotate(100deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    60% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    to {
        -webkit-transform: translateY(800px) rotate(70deg);
        transform: translateY(800px) rotate(70deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
}

@-webkit-keyframes HangAndDropRight {
    from {
        -webkit-transform-origin: right;
        transform-origin: right
    }
    50% {
        -webkit-transform: rotate(-100deg);
        transform: rotate(-100deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    60% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    to {
        -webkit-transform: translateY(800px) rotate(-70deg);
        transform: translateY(800px) rotate(-70deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
}

@keyframes HangAndDropRight {
    from {
        -webkit-transform-origin: right;
        transform-origin: right
    }
    50% {
        -webkit-transform: rotate(-100deg);
        transform: rotate(-100deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    60% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    to {
        -webkit-transform: translateY(800px) rotate(-70deg);
        transform: translateY(800px) rotate(-70deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
}

@-webkit-keyframes PushReleaseFrom {
    from {
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0
    }
    50% {
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }
}

@keyframes PushReleaseFrom {
    from {
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0
    }
    50% {
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }
}

@-webkit-keyframes PushReleaseFromLeft {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0
    }
    30% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px)
    }
}

@keyframes PushReleaseFromLeft {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0
    }
    30% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px)
    }
}

@-webkit-keyframes PushReleaseFromTop {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0
    }
    30% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px)
    }
}

@keyframes PushReleaseFromTop {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0
    }
    30% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px)
    }
}

@-webkit-keyframes PushReleaseFromBottom {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0
    }
    30% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
}

@keyframes PushReleaseFromBottom {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0
    }
    30% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
}

@-webkit-keyframes PushReleaseTo {
    30% {
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }
    to {
        -webkit-transform: scale(5, 5);
        transform: scale(5, 5);
        opacity: 0
    }
}

@keyframes PushReleaseTo {
    30% {
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }
    to {
        -webkit-transform: scale(5, 5);
        transform: scale(5, 5);
        opacity: 0
    }
}

@-webkit-keyframes PushReleaseToTop {
    30% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px)
    }
    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0
    }
}

@keyframes PushReleaseToTop {
    30% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px)
    }
    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0
    }
}

@-webkit-keyframes PushReleaseToBottom {
    30% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0
    }
}

@keyframes PushReleaseToBottom {
    30% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0
    }
}

@-webkit-keyframes FlipInTop {
    from {
        -webkit-transform: perspective(600px);
        transform: perspective(600px);
        opacity: 0
    }
    30% {
        -webkit-transform: perspective(600px) rotateX(180deg);
        transform: perspective(600px) rotateX(180deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px)
    }
}

@keyframes FlipInTop {
    from {
        -webkit-transform: perspective(600px);
        transform: perspective(600px);
        opacity: 0
    }
    30% {
        -webkit-transform: perspective(600px) rotateX(180deg);
        transform: perspective(600px) rotateX(180deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px)
    }
}

@-webkit-keyframes FlipOutTop {
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px);
        opacity: 0
    }
    70% {
        -webkit-transform: perspective(600px) rotateX(180deg);
        transform: perspective(600px) rotateX(180deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px)
    }
}

@keyframes FlipOutTop {
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px);
        opacity: 0
    }
    70% {
        -webkit-transform: perspective(600px) rotateX(180deg);
        transform: perspective(600px) rotateX(180deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px)
    }
}

@-webkit-keyframes FlipInBottom {
    from {
        -webkit-transform: perspective(600px);
        transform: perspective(600px);
        opacity: 0
    }
    30% {
        -webkit-transform: perspective(600px) rotateX(180deg);
        transform: perspective(600px) rotateX(180deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px)
    }
}

@keyframes FlipInBottom {
    from {
        -webkit-transform: perspective(600px);
        transform: perspective(600px);
        opacity: 0
    }
    30% {
        -webkit-transform: perspective(600px) rotateX(180deg);
        transform: perspective(600px) rotateX(180deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px)
    }
}

@-webkit-keyframes FlipOutBottom {
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px);
        opacity: 0
    }
    70% {
        -webkit-transform: perspective(600px) rotateX(180deg);
        transform: perspective(600px) rotateX(180deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px)
    }
}

@keyframes FlipOutBottom {
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px);
        opacity: 0
    }
    70% {
        -webkit-transform: perspective(600px) rotateX(180deg);
        transform: perspective(600px) rotateX(180deg);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        -webkit-transform: perspective(600px);
        transform: perspective(600px)
    }
}

@-webkit-keyframes ElevateLeft {
    from {
        -webkit-transform: translateY(100%) rotate(-20deg);
        transform: translateY(100%) rotate(-20deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0
    }
    40% {
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    65% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: right;
        transform-origin: right
    }
}

@keyframes ElevateLeft {
    from {
        -webkit-transform: translateY(100%) rotate(-20deg);
        transform: translateY(100%) rotate(-20deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0
    }
    40% {
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    65% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: right;
        transform-origin: right
    }
}

@-webkit-keyframes ElevateRight {
    from {
        -webkit-transform: translateY(100%) rotate(20deg);
        transform: translateY(100%) rotate(20deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0
    }
    40% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    65% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: left;
        transform-origin: left
    }
}

@keyframes ElevateRight {
    from {
        -webkit-transform: translateY(100%) rotate(20deg);
        transform: translateY(100%) rotate(20deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0
    }
    40% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    65% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: left;
        transform-origin: left
    }
}

@-webkit-keyframes RollFromLeft {
    from {
        -webkit-transform: translateX(-60px) perspective(600px) rotateY(180deg);
        transform: translateX(-60px) perspective(600px) rotateY(180deg);
        opacity: 0
    }
}

@keyframes RollFromLeft {
    from {
        -webkit-transform: translateX(-60px) perspective(600px) rotateY(180deg);
        transform: translateX(-60px) perspective(600px) rotateY(180deg);
        opacity: 0
    }
}

@-webkit-keyframes RollFromRight {
    from {
        -webkit-transform: translateX(60px) perspective(600px) rotateY(-180deg);
        transform: translateX(60px) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
}

@keyframes RollFromRight {
    from {
        -webkit-transform: translateX(60px) perspective(600px) rotateY(-180deg);
        transform: translateX(60px) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
}

@-webkit-keyframes RollFromTop {
    from {
        -webkit-transform: translateY(-60px) perspective(600px) rotateX(180deg);
        transform: translateY(-60px) perspective(600px) rotateX(180deg);
        opacity: 0
    }
}

@keyframes RollFromTop {
    from {
        -webkit-transform: translateY(-60px) perspective(600px) rotateX(180deg);
        transform: translateY(-60px) perspective(600px) rotateX(180deg);
        opacity: 0
    }
}

@-webkit-keyframes RollFromBottom {
    from {
        -webkit-transform: translateY(60px) perspective(600px) rotateX(-180deg);
        transform: translateY(60px) perspective(600px) rotateX(-180deg);
        opacity: 0
    }
}

@keyframes RollFromBottom {
    from {
        -webkit-transform: translateY(60px) perspective(600px) rotateX(-180deg);
        transform: translateY(60px) perspective(600px) rotateX(-180deg);
        opacity: 0
    }
}

@-webkit-keyframes RollToLeft {
    to {
        -webkit-transform: translateX(-60px) perspective(600px) rotateY(180deg);
        transform: translateX(-60px) perspective(600px) rotateY(180deg);
        opacity: 0
    }
}

@keyframes RollToLeft {
    to {
        -webkit-transform: translateX(-60px) perspective(600px) rotateY(180deg);
        transform: translateX(-60px) perspective(600px) rotateY(180deg);
        opacity: 0
    }
}

@-webkit-keyframes RollToRight {
    to {
        -webkit-transform: translateX(60px) perspective(600px) rotateY(-180deg);
        transform: translateX(60px) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
}

@keyframes RollToRight {
    to {
        -webkit-transform: translateX(60px) perspective(600px) rotateY(-180deg);
        transform: translateX(60px) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
}

@-webkit-keyframes RollToTop {
    to {
        -webkit-transform: translateY(-60px) perspective(600px) rotateX(180deg);
        transform: translateY(-60px) perspective(600px) rotateX(180deg);
        opacity: 0
    }
}

@keyframes RollToTop {
    to {
        -webkit-transform: translateY(-60px) perspective(600px) rotateX(180deg);
        transform: translateY(-60px) perspective(600px) rotateX(180deg);
        opacity: 0
    }
}

@-webkit-keyframes RollToBottom {
    to {
        -webkit-transform: translateY(60px) perspective(600px) rotateX(-180deg);
        transform: translateY(60px) perspective(600px) rotateX(-180deg);
        opacity: 0
    }
}

@keyframes RollToBottom {
    to {
        -webkit-transform: translateY(60px) perspective(600px) rotateX(-180deg);
        transform: translateY(60px) perspective(600px) rotateX(-180deg);
        opacity: 0
    }
}

@-webkit-keyframes RotateSkateInRight {
    from {
        -webkit-transform: scaleX(.2) translateX(100px);
        transform: scaleX(.2) translateX(100px);
        opacity: 0
    }
}

@keyframes RotateSkateInRight {
    from {
        -webkit-transform: scaleX(.2) translateX(100px);
        transform: scaleX(.2) translateX(100px);
        opacity: 0
    }
}

@-webkit-keyframes RotateSkateInLeft {
    from {
        -webkit-transform: scaleX(.2) translateX(-100px);
        transform: scaleX(.2) translateX(-100px);
        opacity: 0
    }
}

@keyframes RotateSkateInLeft {
    from {
        -webkit-transform: scaleX(.2) translateX(-100px);
        transform: scaleX(.2) translateX(-100px);
        opacity: 0
    }
}

@-webkit-keyframes RotateSkateInTop {
    from {
        -webkit-transform: scaleY(.2) translateY(-100px);
        transform: scaleY(.2) translateY(-100px);
        opacity: 0
    }
}

@keyframes RotateSkateInTop {
    from {
        -webkit-transform: scaleY(.2) translateY(-100px);
        transform: scaleY(.2) translateY(-100px);
        opacity: 0
    }
}

@-webkit-keyframes RotateSkateInBottom {
    from {
        -webkit-transform: scaleY(.2) translateY(100px);
        transform: scaleY(.2) translateY(100px);
        opacity: 0
    }
}

@keyframes RotateSkateInBottom {
    from {
        -webkit-transform: scaleY(.2) translateY(100px);
        transform: scaleY(.2) translateY(100px);
        opacity: 0
    }
}

@-webkit-keyframes RotateSkateOutRight {
    to {
        -webkit-transform: scaleX(.2) translateX(100px);
        transform: scaleX(.2) translateX(100px);
        opacity: 0
    }
}

@keyframes RotateSkateOutRight {
    to {
        -webkit-transform: scaleX(.2) translateX(100px);
        transform: scaleX(.2) translateX(100px);
        opacity: 0
    }
}

@-webkit-keyframes RotateSkateOutLeft {
    to {
        -webkit-transform: scaleX(.2) translateX(-100px);
        transform: scaleX(.2) translateX(-100px);
        opacity: 0
    }
}

@keyframes RotateSkateOutLeft {
    to {
        -webkit-transform: scaleX(.2) translateX(-100px);
        transform: scaleX(.2) translateX(-100px);
        opacity: 0
    }
}

@-webkit-keyframes RotateSkateOutTop {
    to {
        -webkit-transform: scaleY(.2) translateY(-100px);
        transform: scaleY(.2) translateY(-100px);
        opacity: 0
    }
}

@keyframes RotateSkateOutTop {
    to {
        -webkit-transform: scaleY(.2) translateY(-100px);
        transform: scaleY(.2) translateY(-100px);
        opacity: 0
    }
}

@-webkit-keyframes RotateSkateOutBottom {
    to {
        -webkit-transform: scaleY(.2) translateY(100px);
        transform: scaleY(.2) translateY(100px);
        opacity: 0
    }
}

@keyframes RotateSkateOutBottom {
    to {
        -webkit-transform: scaleY(.2) translateY(100px);
        transform: scaleY(.2) translateY(100px);
        opacity: 0
    }
}

@-webkit-keyframes RotateXZoomIn {
    from {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        opacity: 0
    }
    5% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg)
    }
}

@keyframes RotateXZoomIn {
    from {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        opacity: 0
    }
    5% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg)
    }
}

@-webkit-keyframes RotateXZoomOut {
    95% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg)
    }
    to {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        opacity: 0
    }
}

@keyframes RotateXZoomOut {
    95% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg)
    }
    to {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        opacity: 0
    }
}

@-webkit-keyframes RotateYZoomIn {
    from {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        opacity: 0
    }
    5% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg)
    }
}

@keyframes RotateYZoomIn {
    from {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        opacity: 0
    }
    5% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg)
    }
}

@-webkit-keyframes RotateYZoomOut {
    95% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg)
    }
    to {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        opacity: 0
    }
}

@keyframes RotateYZoomOut {
    95% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg)
    }
    to {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        opacity: 0
    }
}

@-webkit-keyframes RotateIn {
    from {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes RotateIn {
    from {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes RotateOut {
    from {
        opacity: 1
    }
    to {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        opacity: 0
    }
}

@keyframes RotateOut {
    from {
        opacity: 1
    }
    to {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        opacity: 0
    }
}

@-webkit-keyframes RotateInLeft {
    from {
        -webkit-transform: rotate(-180deg) translateX(150px);
        transform: rotate(-180deg) translateX(150px);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes RotateInLeft {
    from {
        -webkit-transform: rotate(-180deg) translateX(150px);
        transform: rotate(-180deg) translateX(150px);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes RotateOutLeft {
    from {
        opacity: 1
    }
    to {
        -webkit-transform: rotate(180deg) translateX(150px);
        transform: rotate(180deg) translateX(150px);
        opacity: 0
    }
}

@keyframes RotateOutLeft {
    from {
        opacity: 1
    }
    to {
        -webkit-transform: rotate(180deg) translateX(150px);
        transform: rotate(180deg) translateX(150px);
        opacity: 0
    }
}

@-webkit-keyframes RotateInRight {
    from {
        -webkit-transform: rotate(-180deg) translateX(-150px);
        transform: rotate(-180deg) translateX(-150px);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes RotateInRight {
    from {
        -webkit-transform: rotate(-180deg) translateX(-150px);
        transform: rotate(-180deg) translateX(-150px);
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes RotateOutRight {
    from {
        opacity: 1
    }
    to {
        -webkit-transform: rotate(180deg) translateX(-150px);
        transform: rotate(180deg) translateX(-150px);
        opacity: 0
    }
}

@keyframes RotateOutRight {
    from {
        opacity: 1
    }
    to {
        -webkit-transform: rotate(180deg) translateX(-150px);
        transform: rotate(180deg) translateX(-150px);
        opacity: 0
    }
}

@-webkit-keyframes SpinInLeft {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    from {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@keyframes SpinInLeft {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    from {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@-webkit-keyframes SpinInRight {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    from {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@keyframes SpinInRight {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    from {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@-webkit-keyframes SpinOutLeft {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    to {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@keyframes SpinOutLeft {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    to {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@-webkit-keyframes SpinOutRight {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    to {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@keyframes SpinOutRight {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    to {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@-webkit-keyframes BlurIn {
    from {
        -webkit-transform: scaleX(.2);
        transform: scaleX(.2);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurIn {
    from {
        -webkit-transform: scaleX(.2);
        transform: scaleX(.2);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurInRight {
    from {
        -webkit-transform: scaleX(.2) translateX(100px);
        transform: scaleX(.2) translateX(100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurInRight {
    from {
        -webkit-transform: scaleX(.2) translateX(100px);
        transform: scaleX(.2) translateX(100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurInLeft {
    from {
        -webkit-transform: scaleX(.2) translateX(-100px);
        transform: scaleX(.2) translateX(-100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurInLeft {
    from {
        -webkit-transform: scaleX(.2) translateX(-100px);
        transform: scaleX(.2) translateX(-100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurInTop {
    from {
        -webkit-transform: scaleY(.2) translateY(-100px);
        transform: scaleY(.2) translateY(-100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurInTop {
    from {
        -webkit-transform: scaleY(.2) translateY(-100px);
        transform: scaleY(.2) translateY(-100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurInBottom {
    from {
        -webkit-transform: scaleY(.2) translateY(100px);
        transform: scaleY(.2) translateY(100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurInBottom {
    from {
        -webkit-transform: scaleY(.2) translateY(100px);
        transform: scaleY(.2) translateY(100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurOut {
    to {
        -webkit-transform: scaleX(.2);
        transform: scaleX(.2);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurOut {
    to {
        -webkit-transform: scaleX(.2);
        transform: scaleX(.2);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurOutRight {
    to {
        -webkit-transform: scaleX(.2) translateX(100px);
        transform: scaleX(.2) translateX(100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurOutRight {
    to {
        -webkit-transform: scaleX(.2) translateX(100px);
        transform: scaleX(.2) translateX(100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurOutLeft {
    to {
        -webkit-transform: scaleX(.2) translateX(-100px);
        transform: scaleX(.2) translateX(-100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurOutLeft {
    to {
        -webkit-transform: scaleX(.2) translateX(-100px);
        transform: scaleX(.2) translateX(-100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurOutTop {
    to {
        -webkit-transform: scaleY(.2) translateY(-100px);
        transform: scaleY(.2) translateY(-100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurOutTop {
    to {
        -webkit-transform: scaleY(.2) translateY(-100px);
        transform: scaleY(.2) translateY(-100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes BlurOutBottom {
    to {
        -webkit-transform: scaleY(.2) translateY(100px);
        transform: scaleY(.2) translateY(100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes BlurOutBottom {
    to {
        -webkit-transform: scaleY(.2) translateY(100px);
        transform: scaleY(.2) translateY(100px);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes PopUp {
    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
}

@keyframes PopUp {
    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
}

@-webkit-keyframes PopUpLeft {
    50% {
        -webkit-transform: translateX(-50px) scale(1.5);
        transform: translateX(-50px) scale(1.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes PopUpLeft {
    50% {
        -webkit-transform: translateX(-50px) scale(1.5);
        transform: translateX(-50px) scale(1.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@-webkit-keyframes PopUpRight {
    50% {
        -webkit-transform: translateX(50px) scale(1.5);
        transform: translateX(50px) scale(1.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes PopUpRight {
    50% {
        -webkit-transform: translateX(50px) scale(1.5);
        transform: translateX(50px) scale(1.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@-webkit-keyframes PopOut {
    50% {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
}

@keyframes PopOut {
    50% {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
}

@-webkit-keyframes PopOutLeft {
    50% {
        -webkit-transform: translateX(-50px) scale(.5);
        transform: translateX(-50px) scale(.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes PopOutLeft {
    50% {
        -webkit-transform: translateX(-50px) scale(.5);
        transform: translateX(-50px) scale(.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@-webkit-keyframes PopOutRight {
    50% {
        -webkit-transform: translateX(50px) scale(.5);
        transform: translateX(50px) scale(.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@keyframes PopOutRight {
    50% {
        -webkit-transform: translateX(50px) scale(.5);
        transform: translateX(50px) scale(.5);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
}

@-webkit-keyframes BounceFromTop {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, -80px, 0) scale3d(1, 1.2, 1);
        transform: translate3d(0, -80px, 0) scale3d(1, 1.2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

@keyframes BounceFromTop {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, -80px, 0) scale3d(1, 1.2, 1);
        transform: translate3d(0, -80px, 0) scale3d(1, 1.2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

@-webkit-keyframes BounceFromDown {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, 80px, 0) scale3d(1, 1.2, 1);
        transform: translate3d(0, 80px, 0) scale3d(1, 1.2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, 4px, 0);
        transform: translate3d(0, 4px, 0)
    }
}

@keyframes BounceFromDown {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, 80px, 0) scale3d(1, 1.2, 1);
        transform: translate3d(0, 80px, 0) scale3d(1, 1.2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, 4px, 0);
        transform: translate3d(0, 4px, 0)
    }
}

@-webkit-keyframes BounceY {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1, 2, 1);
        transform: scale3d(1, 2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1, 1.5, 1);
        transform: scale3d(1, 1.5, 1)
    }
    90% {
        -webkit-transform: scale3d(1, 1.1, 1);
        transform: scale3d(1, 1.1, 1)
    }
}

@keyframes BounceY {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1, 2, 1);
        transform: scale3d(1, 2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1, 1.5, 1);
        transform: scale3d(1, 1.5, 1)
    }
    90% {
        -webkit-transform: scale3d(1, 1.1, 1);
        transform: scale3d(1, 1.1, 1)
    }
}

@-webkit-keyframes BounceZoomIn {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(1.9);
        transform: scale(1.9)
    }
    90% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
}

@keyframes BounceZoomIn {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(1.9);
        transform: scale(1.9)
    }
    90% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
}

@-webkit-keyframes BounceZoomOut {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(.7);
        transform: scale(.7)
    }
    90% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

@keyframes BounceZoomOut {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(.7);
        transform: scale(.7)
    }
    90% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

@-webkit-keyframes PerspectiveOutTop {
    50% {
        opacity: 1
    }
    to {
        -webkit-transform: perspective(500px) rotateX(90deg) translateY(-50px) translateZ(50px);
        transform: perspective(500px) rotateX(90deg) translateY(-50px) translateZ(50px);
        opacity: 0
    }
}

@keyframes PerspectiveOutTop {
    50% {
        opacity: 1
    }
    to {
        -webkit-transform: perspective(500px) rotateX(90deg) translateY(-50px) translateZ(50px);
        transform: perspective(500px) rotateX(90deg) translateY(-50px) translateZ(50px);
        opacity: 0
    }
}

@-webkit-keyframes PerspectiveOutBottom {
    50% {
        opacity: 1
    }
    to {
        -webkit-transform: perspective(500px) rotateX(90deg) translateY(50px) translateZ(-50px);
        transform: perspective(500px) rotateX(90deg) translateY(50px) translateZ(-50px);
        opacity: 0
    }
}

@keyframes PerspectiveOutBottom {
    50% {
        opacity: 1
    }
    to {
        -webkit-transform: perspective(500px) rotateX(90deg) translateY(50px) translateZ(-50px);
        transform: perspective(500px) rotateX(90deg) translateY(50px) translateZ(-50px);
        opacity: 0
    }
}

@-webkit-keyframes ZoomIn {
    from {
        -webkit-transform: scale(10);
        transform: scale(10);
        opacity: 0
    }
}

@keyframes ZoomIn {
    from {
        -webkit-transform: scale(10);
        transform: scale(10);
        opacity: 0
    }
}

@-webkit-keyframes ZoomInLeft {
    from {
        -webkit-transform: scale(10) translateX(-150%);
        transform: scale(10) translateX(-150%);
        opacity: 0
    }
}

@keyframes ZoomInLeft {
    from {
        -webkit-transform: scale(10) translateX(-150%);
        transform: scale(10) translateX(-150%);
        opacity: 0
    }
}

@-webkit-keyframes ZoomInRight {
    from {
        -webkit-transform: scale(10) translateX(150%);
        transform: scale(10) translateX(150%);
        opacity: 0
    }
}

@keyframes ZoomInRight {
    from {
        -webkit-transform: scale(10) translateX(150%);
        transform: scale(10) translateX(150%);
        opacity: 0
    }
}

@-webkit-keyframes LetterZoomInTop {
    from {
        -webkit-transform: scale(10) translateY(-50%);
        transform: scale(10) translateY(-50%);
        opacity: 0
    }
}

@keyframes LetterZoomInTop {
    from {
        -webkit-transform: scale(10) translateY(-50%);
        transform: scale(10) translateY(-50%);
        opacity: 0
    }
}

@-webkit-keyframes ZoomInBottom {
    from {
        -webkit-transform: scale(10) translateY(50%);
        transform: scale(10) translateY(50%);
        opacity: 0
    }
}

@keyframes ZoomInBottom {
    from {
        -webkit-transform: scale(10) translateY(50%);
        transform: scale(10) translateY(50%);
        opacity: 0
    }
}

@-webkit-keyframes ZoomOut {
    to {
        -webkit-transform: scale(10);
        transform: scale(10);
        opacity: 0
    }
}

@keyframes ZoomOut {
    to {
        -webkit-transform: scale(10);
        transform: scale(10);
        opacity: 0
    }
}

@-webkit-keyframes ZoomOutLeft {
    to {
        -webkit-transform: scale(10) translateX(-150%);
        transform: scale(10) translateX(-150%);
        opacity: 0
    }
}

@keyframes ZoomOutLeft {
    to {
        -webkit-transform: scale(10) translateX(-150%);
        transform: scale(10) translateX(-150%);
        opacity: 0
    }
}

@-webkit-keyframes ZoomOutRight {
    to {
        -webkit-transform: scale(10) translateX(150%);
        transform: scale(10) translateX(150%);
        opacity: 0
    }
}

@keyframes ZoomOutRight {
    to {
        -webkit-transform: scale(10) translateX(150%);
        transform: scale(10) translateX(150%);
        opacity: 0
    }
}

@-webkit-keyframes ZoomOutTop {
    to {
        -webkit-transform: scale(10) translateY(-50%);
        transform: scale(10) translateY(-50%);
        opacity: 0
    }
}

@keyframes ZoomOutTop {
    to {
        -webkit-transform: scale(10) translateY(-50%);
        transform: scale(10) translateY(-50%);
        opacity: 0
    }
}

@-webkit-keyframes ZoomOutBottom {
    to {
        -webkit-transform: scale(10) translateY(50%);
        transform: scale(10) translateY(50%);
        opacity: 0
    }
}

@keyframes ZoomOutBottom {
    to {
        -webkit-transform: scale(10) translateY(50%);
        transform: scale(10) translateY(50%);
        opacity: 0
    }
}

@-webkit-keyframes oaoFadeInTop {
    from {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        opacity: 0
    }
}

@keyframes oaoFadeInTop {
    from {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        opacity: 0
    }
}

@-webkit-keyframes oaoFadeInBottom {
    from {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 0
    }
}

@keyframes oaoFadeInBottom {
    from {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 0
    }
}

@-webkit-keyframes oaoFadeOutop {
    to {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        opacity: 0
    }
}

@keyframes oaoFadeOutop {
    to {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        opacity: 0
    }
}

@-webkit-keyframes oaoFadeOutBottom {
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0
    }
}

@keyframes oaoFadeOutBottom {
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0
    }
}

@-webkit-keyframes oaoFlyInTop {
    0% {
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
        opacity: 0
    }
    50% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@keyframes oaoFlyInTop {
    0% {
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
        opacity: 0
    }
    50% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@-webkit-keyframes oaoFlyInBottom {
    0% {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px);
        opacity: 0
    }
    50% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@keyframes oaoFlyInBottom {
    0% {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px);
        opacity: 0
    }
    50% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
}

@-webkit-keyframes oaoFlyOutTop {
    30% {
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        opacity: 0
    }
}

@keyframes oaoFlyOutTop {
    30% {
        -webkit-transform: translateY(80px);
        transform: translateY(80px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        opacity: 0
    }
}

@-webkit-keyframes oaoFlyOutBottom {
    30% {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        opacity: 0
    }
}

@keyframes oaoFlyOutBottom {
    30% {
        -webkit-transform: translateY(-80px);
        transform: translateY(-80px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    to {
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateInTop {
    from {
        -webkit-transform: translateY(-100px) rotate(360deg);
        transform: translateY(-100px) rotate(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateInTop {
    from {
        -webkit-transform: translateY(-100px) rotate(360deg);
        transform: translateY(-100px) rotate(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateInBottom {
    from {
        -webkit-transform: translateY(100px) rotate(360deg);
        transform: translateY(100px) rotate(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateInBottom {
    from {
        -webkit-transform: translateY(100px) rotate(360deg);
        transform: translateY(100px) rotate(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateOutTop {
    to {
        -webkit-transform: translateY(-100px) rotate(360deg);
        transform: translateY(-100px) rotate(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateOutTop {
    to {
        -webkit-transform: translateY(-100px) rotate(360deg);
        transform: translateY(-100px) rotate(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateOutBottom {
    to {
        -webkit-transform: translateY(100px) rotate(360deg);
        transform: translateY(100px) rotate(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateOutBottom {
    to {
        -webkit-transform: translateY(100px) rotate(360deg);
        transform: translateY(100px) rotate(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateXInTop {
    from {
        -webkit-transform: translateY(-100px) rotateX(360deg);
        transform: translateY(-100px) rotateX(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateXInTop {
    from {
        -webkit-transform: translateY(-100px) rotateX(360deg);
        transform: translateY(-100px) rotateX(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateXInBottom {
    from {
        -webkit-transform: translateY(100px) rotateX(360deg);
        transform: translateY(100px) rotateX(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateXInBottom {
    from {
        -webkit-transform: translateY(100px) rotateX(360deg);
        transform: translateY(100px) rotateX(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateXOutTop {
    to {
        -webkit-transform: translateY(-100px) rotateX(360deg);
        transform: translateY(-100px) rotateX(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateXOutTop {
    to {
        -webkit-transform: translateY(-100px) rotateX(360deg);
        transform: translateY(-100px) rotateX(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateXOutBottom {
    to {
        -webkit-transform: translateY(100px) rotateX(360deg);
        transform: translateY(100px) rotateX(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateXOutBottom {
    to {
        -webkit-transform: translateY(100px) rotateX(360deg);
        transform: translateY(100px) rotateX(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateYInTop {
    from {
        -webkit-transform: translateY(-100px) rotateY(360deg);
        transform: translateY(-100px) rotateY(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateYInTop {
    from {
        -webkit-transform: translateY(-100px) rotateY(360deg);
        transform: translateY(-100px) rotateY(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateYInBottom {
    from {
        -webkit-transform: translateY(100px) rotateY(360deg);
        transform: translateY(100px) rotateY(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateYInBottom {
    from {
        -webkit-transform: translateY(100px) rotateY(360deg);
        transform: translateY(100px) rotateY(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateYOutTop {
    to {
        -webkit-transform: translateY(-100px) rotateY(360deg);
        transform: translateY(-100px) rotateY(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateYOutTop {
    to {
        -webkit-transform: translateY(-100px) rotateY(360deg);
        transform: translateY(-100px) rotateY(360deg);
        opacity: 0
    }
}

@-webkit-keyframes oaoRotateYOutBottom {
    to {
        -webkit-transform: translateY(100px) rotateY(360deg);
        transform: translateY(100px) rotateY(360deg);
        opacity: 0
    }
}

@keyframes oaoRotateYOutBottom {
    to {
        -webkit-transform: translateY(100px) rotateY(360deg);
        transform: translateY(100px) rotateY(360deg);
        opacity: 0
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    to {
        opacity: 1
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
    to {
        opacity: 1
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeInTop {
    from {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    to {
        opacity: 1
    }
}

@keyframes fadeInTop {
    from {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeInBottom {
    from {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    to {
        opacity: 1
    }
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeOut {
    to {
        opacity: 0
    }
}

@keyframes fadeOut {
    to {
        opacity: 0
    }
}

@-webkit-keyframes fadeOutLeft {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

@-webkit-keyframes fadeOutRight {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
}

@-webkit-keyframes fadeOutTop {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@keyframes fadeOutTop {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
}

@-webkit-keyframes fadeOutBottom {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@keyframes fadeOutBottom {
    from {
        opacity: 1
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
}

@-webkit-keyframes moveFromLeft {
    from {
        -webkit-transform: translateX(-800px);
        transform: translateX(-800px)
    }
}

@keyframes moveFromLeft {
    from {
        -webkit-transform: translateX(-800px);
        transform: translateX(-800px)
    }
}

@-webkit-keyframes moveFromRight {
    from {
        -webkit-transform: translateX(800px);
        transform: translateX(800px)
    }
}

@keyframes moveFromRight {
    from {
        -webkit-transform: translateX(800px);
        transform: translateX(800px)
    }
}

@-webkit-keyframes moveFromTop {
    from {
        -webkit-transform: translateY(-800px);
        transform: translateY(-800px)
    }
}

@keyframes moveFromTop {
    from {
        -webkit-transform: translateY(-800px);
        transform: translateY(-800px)
    }
}

@-webkit-keyframes moveFromBottom {
    from {
        -webkit-transform: translateY(800px);
        transform: translateY(800px)
    }
}

@keyframes moveFromBottom {
    from {
        -webkit-transform: translateY(800px);
        transform: translateY(800px)
    }
}

@-webkit-keyframes moveToLeft {
    to {
        -webkit-transform: translateX(-800px);
        transform: translateX(-800px)
    }
}

@keyframes moveToLeft {
    to {
        -webkit-transform: translateX(-800px);
        transform: translateX(-800px)
    }
}

@-webkit-keyframes moveToRight {
    to {
        -webkit-transform: translateX(800px);
        transform: translateX(800px)
    }
}

@keyframes moveToRight {
    to {
        -webkit-transform: translateX(800px);
        transform: translateX(800px)
    }
}

@-webkit-keyframes moveToTop {
    to {
        -webkit-transform: translateY(-800px);
        transform: translateY(-800px)
    }
}

@keyframes moveToTop {
    to {
        -webkit-transform: translateY(-800px);
        transform: translateY(-800px)
    }
}

@-webkit-keyframes moveToBottom {
    to {
        -webkit-transform: translateY(800px);
        transform: translateY(800px)
    }
}

@keyframes moveToBottom {
    to {
        -webkit-transform: translateY(800px);
        transform: translateY(800px)
    }
}

@-webkit-keyframes doorCloseFromLeft {
    from {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0
    }
    50%, to {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1
    }
}

@keyframes doorCloseFromLeft {
    from {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0
    }
    50%, to {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1
    }
}

@-webkit-keyframes doorOpenFromRight {
    from {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1
    }
    50%, to {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0
    }
}

@keyframes doorOpenFromRight {
    from {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 1
    }
    50%, to {
        -webkit-transform: perspective(400px) rotateY(90deg);
        transform: perspective(400px) rotateY(90deg);
        -webkit-transform-origin: left;
        transform-origin: left;
        opacity: 0
    }
}

@-webkit-keyframes doorCloseFromRight {
    from {
        -webkit-transform: perspective(400px) rotateY(-90deg);
        transform: perspective(400px) rotateY(-90deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0
    }
    50%, to {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 1
    }
}

@keyframes doorCloseFromRight {
    from {
        -webkit-transform: perspective(400px) rotateY(-90deg);
        transform: perspective(400px) rotateY(-90deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0
    }
    50%, to {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 1
    }
}

@-webkit-keyframes doorOpenFromLeft {
    from {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 1
    }
    50%, to {
        -webkit-transform: perspective(400px) rotateY(-90deg);
        transform: perspective(400px) rotateY(-90deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0
    }
}

@keyframes doorOpenFromLeft {
    from {
        -webkit-transform: perspective(400px) rotateY(0);
        transform: perspective(400px) rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 1
    }
    50%, to {
        -webkit-transform: perspective(400px) rotateY(-90deg);
        transform: perspective(400px) rotateY(-90deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0
    }
}

@-webkit-keyframes pushReleaseFrom {
    from {
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0
    }
    30% {
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }
}

@keyframes pushReleaseFrom {
    from {
        -webkit-transform: scale(3, 3);
        transform: scale(3, 3);
        opacity: 0
    }
    30% {
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }
}

@-webkit-keyframes pushReleaseFromLeft {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    30% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px)
    }
}

@keyframes pushReleaseFromLeft {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    30% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px)
    }
}

@-webkit-keyframes pushReleaseFromRight {
    from {
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
    30% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px)
    }
}

@keyframes pushReleaseFromRight {
    from {
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
    30% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px)
    }
}

@-webkit-keyframes pushReleaseFromTop {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    30% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px)
    }
}

@keyframes pushReleaseFromTop {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    30% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px)
    }
}

@-webkit-keyframes pushReleaseFromBottom {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    30% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
}

@keyframes pushReleaseFromBottom {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    30% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
}

@-webkit-keyframes pushReleaseTo {
    30% {
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }
    to {
        -webkit-transform: scale(5, 5);
        transform: scale(5, 5);
        opacity: 0
    }
}

@keyframes pushReleaseTo {
    30% {
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }
    to {
        -webkit-transform: scale(5, 5);
        transform: scale(5, 5);
        opacity: 0
    }
}

@-webkit-keyframes pushReleaseToLeft {
    30% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px)
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0
    }
}

@keyframes pushReleaseToLeft {
    30% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px)
    }
    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0
    }
}

@-webkit-keyframes pushReleaseToRight {
    30% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px)
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0
    }
}

@keyframes pushReleaseToRight {
    30% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px)
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        opacity: 0
    }
}

@-webkit-keyframes pushReleaseToTop {
    30% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px)
    }
    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0
    }
}

@keyframes pushReleaseToTop {
    30% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px)
    }
    to {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0
    }
}

@-webkit-keyframes pushReleaseToBottom {
    30% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0
    }
}

@keyframes pushReleaseToBottom {
    30% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
    to {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0
    }
}

@-webkit-keyframes flipX {
    from {
        -webkit-transform: perspective(600px) rotateX(-180deg);
        transform: perspective(600px) rotateX(-180deg);
        opacity: 0
    }
    to {
        -webkit-transform: perspective(600px) rotateX(-360deg);
        transform: perspective(600px) rotateX(-360deg)
    }
}

@keyframes flipX {
    from {
        -webkit-transform: perspective(600px) rotateX(-180deg);
        transform: perspective(600px) rotateX(-180deg);
        opacity: 0
    }
    to {
        -webkit-transform: perspective(600px) rotateX(-360deg);
        transform: perspective(600px) rotateX(-360deg)
    }
}

@-webkit-keyframes flipXZoomIn {
    from {
        -webkit-transform: perspective(600px) rotateX(0) scale(1);
        transform: perspective(600px) rotateX(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(600px) rotateX(-180deg) scale(1.8);
        transform: perspective(600px) rotateX(-180deg) scale(1.8);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: perspective(600px) rotateX(-360deg) scale(.7);
        transform: perspective(600px) rotateX(-360deg) scale(.7);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes flipXZoomIn {
    from {
        -webkit-transform: perspective(600px) rotateX(0) scale(1);
        transform: perspective(600px) rotateX(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(600px) rotateX(-180deg) scale(1.8);
        transform: perspective(600px) rotateX(-180deg) scale(1.8);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: perspective(600px) rotateX(-360deg) scale(.7);
        transform: perspective(600px) rotateX(-360deg) scale(.7);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes flipXZoomOut {
    from {
        -webkit-transform: perspective(600px) rotateX(0) scale(1);
        transform: perspective(600px) rotateX(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(600px) rotateX(180deg) scale(1.8);
        transform: perspective(600px) rotateX(180deg) scale(1.8);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: perspective(600px) rotateX(360deg) scale(.7);
        transform: perspective(600px) rotateX(360deg) scale(.7);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes flipXZoomOut {
    from {
        -webkit-transform: perspective(600px) rotateX(0) scale(1);
        transform: perspective(600px) rotateX(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(600px) rotateX(180deg) scale(1.8);
        transform: perspective(600px) rotateX(180deg) scale(1.8);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: perspective(600px) rotateX(360deg) scale(.7);
        transform: perspective(600px) rotateX(360deg) scale(.7);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes flipY {
    from {
        -webkit-transform: perspective(600px) rotateY(180deg);
        transform: perspective(600px) rotateY(180deg);
        opacity: 0
    }
    to {
        -webkit-transform: perspective(600px) rotateY(360deg);
        transform: perspective(600px) rotateY(360deg)
    }
}

@keyframes flipY {
    from {
        -webkit-transform: perspective(600px) rotateY(180deg);
        transform: perspective(600px) rotateY(180deg);
        opacity: 0
    }
    to {
        -webkit-transform: perspective(600px) rotateY(360deg);
        transform: perspective(600px) rotateY(360deg)
    }
}

@-webkit-keyframes flipYZoomIn {
    from {
        -webkit-transform: perspective(600px) rotateY(0) scale(1);
        transform: perspective(600px) rotateY(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(600px) rotateY(180deg) scale(1.6);
        transform: perspective(600px) rotateY(180deg) scale(1.6);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: perspective(600px) rotateY(360deg) scale(.7);
        transform: perspective(600px) rotateY(360deg) scale(.7);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes flipYZoomIn {
    from {
        -webkit-transform: perspective(600px) rotateY(0) scale(1);
        transform: perspective(600px) rotateY(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(600px) rotateY(180deg) scale(1.6);
        transform: perspective(600px) rotateY(180deg) scale(1.6);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: perspective(600px) rotateY(360deg) scale(.7);
        transform: perspective(600px) rotateY(360deg) scale(.7);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes flipYZoomOut {
    from {
        -webkit-transform: perspective(600px) rotateY(0) scale(1);
        transform: perspective(600px) rotateY(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(600px) rotateY(180deg) scale(.6);
        transform: perspective(600px) rotateY(180deg) scale(.6);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: perspective(600px) rotateY(360deg) scale(1.8);
        transform: perspective(600px) rotateY(360deg) scale(1.8);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes flipYZoomOut {
    from {
        -webkit-transform: perspective(600px) rotateY(0) scale(1);
        transform: perspective(600px) rotateY(0) scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    40% {
        -webkit-transform: perspective(600px) rotateY(180deg) scale(.6);
        transform: perspective(600px) rotateY(180deg) scale(.6);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    80% {
        -webkit-transform: perspective(600px) rotateY(360deg) scale(1.8);
        transform: perspective(600px) rotateY(360deg) scale(1.8);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes skewLeft {
    70% {
        -webkit-transform: skewX(40deg);
        transform: skewX(40deg)
    }
}

@keyframes skewLeft {
    70% {
        -webkit-transform: skewX(40deg);
        transform: skewX(40deg)
    }
}

@-webkit-keyframes skewRight {
    70% {
        -webkit-transform: skewX(-40deg);
        transform: skewX(-40deg)
    }
}

@keyframes skewRight {
    70% {
        -webkit-transform: skewX(-40deg);
        transform: skewX(-40deg)
    }
}

@-webkit-keyframes skewInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-100%) skewX(-40deg);
        transform: translateX(-100%) skewX(-40deg)
    }
    70% {
        -webkit-transform: skewX(-30deg);
        transform: skewX(-30deg)
    }
}

@keyframes skewInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-100%) skewX(-40deg);
        transform: translateX(-100%) skewX(-40deg)
    }
    70% {
        -webkit-transform: skewX(-30deg);
        transform: skewX(-30deg)
    }
}

@-webkit-keyframes skewInRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(100%) skewX(40deg);
        transform: translateX(100%) skewX(40deg)
    }
    70% {
        -webkit-transform: skewX(30deg);
        transform: skewX(30deg)
    }
}

@keyframes skewInRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(100%) skewX(40deg);
        transform: translateX(100%) skewX(40deg)
    }
    70% {
        -webkit-transform: skewX(30deg);
        transform: skewX(30deg)
    }
}

@-webkit-keyframes skewOutLeft {
    20% {
        -webkit-transform: skewX(40deg);
        transform: skewX(40deg)
    }
    to {
        -webkit-transform: translateX(-100%) skewX(40deg);
        transform: translateX(-100%) skewX(40deg);
        opacity: 0
    }
}

@keyframes skewOutLeft {
    20% {
        -webkit-transform: skewX(40deg);
        transform: skewX(40deg)
    }
    to {
        -webkit-transform: translateX(-100%) skewX(40deg);
        transform: translateX(-100%) skewX(40deg);
        opacity: 0
    }
}

@-webkit-keyframes skewOutRight {
    20% {
        -webkit-transform: skewX(-40deg);
        transform: skewX(-40deg)
    }
    to {
        -webkit-transform: translateX(100%) skewX(-40deg);
        transform: translateX(100%) skewX(-40deg);
        opacity: 0
    }
}

@keyframes skewOutRight {
    20% {
        -webkit-transform: skewX(-40deg);
        transform: skewX(-40deg)
    }
    to {
        -webkit-transform: translateX(100%) skewX(-40deg);
        transform: translateX(100%) skewX(-40deg);
        opacity: 0
    }
}

@-webkit-keyframes pullRelease {
    15% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8)
    }
}

@keyframes pullRelease {
    15% {
        -webkit-transform: scale(1.8);
        transform: scale(1.8)
    }
}

@-webkit-keyframes pushRelease {
    15% {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
}

@keyframes pushRelease {
    15% {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
}

@-webkit-keyframes swingInLeft {
    from {
        -webkit-transform: matrix(3, 0, 0, 3, -800, 0);
        transform: matrix(3, 0, 0, 3, -800, 0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: matrix(1.3, 0, 0, 1.3, 20, 0);
        transform: matrix(1.3, 0, 0, 1.3, 20, 0)
    }
}

@keyframes swingInLeft {
    from {
        -webkit-transform: matrix(3, 0, 0, 3, -800, 0);
        transform: matrix(3, 0, 0, 3, -800, 0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: matrix(1.3, 0, 0, 1.3, 20, 0);
        transform: matrix(1.3, 0, 0, 1.3, 20, 0)
    }
}

@-webkit-keyframes swingInRight {
    from {
        -webkit-transform: matrix(3, 0, 0, 3, 800, 0);
        transform: matrix(3, 0, 0, 3, 800, 0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: matrix(1.3, 0, 0, 1.3, -20, 0);
        transform: matrix(1.3, 0, 0, 1.3, -20, 0)
    }
}

@keyframes swingInRight {
    from {
        -webkit-transform: matrix(3, 0, 0, 3, 800, 0);
        transform: matrix(3, 0, 0, 3, 800, 0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: matrix(1.3, 0, 0, 1.3, -20, 0);
        transform: matrix(1.3, 0, 0, 1.3, -20, 0)
    }
}

@-webkit-keyframes swingInTop {
    from {
        -webkit-transform: matrix(3, 0, 0, 3, 0, -800);
        transform: matrix(3, 0, 0, 3, 0, -800);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: matrix(1.3, 0, 0, 1.3, 0, 20);
        transform: matrix(1.3, 0, 0, 1.3, 0, 20)
    }
}

@keyframes swingInTop {
    from {
        -webkit-transform: matrix(3, 0, 0, 3, 0, -800);
        transform: matrix(3, 0, 0, 3, 0, -800);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: matrix(1.3, 0, 0, 1.3, 0, 20);
        transform: matrix(1.3, 0, 0, 1.3, 0, 20)
    }
}

@-webkit-keyframes swingInBottom {
    from {
        -webkit-transform: matrix(3, 0, 0, 3, 0, 800);
        transform: matrix(3, 0, 0, 3, 0, 800);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: matrix(1.3, 0, 0, 1.3, 0, -20);
        transform: matrix(1.3, 0, 0, 1.3, 0, -20)
    }
}

@keyframes swingInBottom {
    from {
        -webkit-transform: matrix(3, 0, 0, 3, 0, 800);
        transform: matrix(3, 0, 0, 3, 0, 800);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out
    }
    50% {
        -webkit-transform: matrix(1.3, 0, 0, 1.3, 0, -20);
        transform: matrix(1.3, 0, 0, 1.3, 0, -20)
    }
}

@-webkit-keyframes elevateLeft {
    from {
        -webkit-transform: translateY(100%) rotate(-20deg);
        transform: translateY(100%) rotate(-20deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    40% {
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    65% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: right;
        transform-origin: right
    }
}

@keyframes elevateLeft {
    from {
        -webkit-transform: translateY(100%) rotate(-20deg);
        transform: translateY(100%) rotate(-20deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    40% {
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
        -webkit-transform-origin: right;
        transform-origin: right
    }
    65% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: right;
        transform-origin: right
    }
}

@-webkit-keyframes elevateRight {
    from {
        -webkit-transform: translateY(100%) rotate(20deg);
        transform: translateY(100%) rotate(20deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    40% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    65% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: left;
        transform-origin: left
    }
}

@keyframes elevateRight {
    from {
        -webkit-transform: translateY(100%) rotate(20deg);
        transform: translateY(100%) rotate(20deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    40% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);
        -webkit-transform-origin: left;
        transform-origin: left
    }
    65% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: left;
        transform-origin: left
    }
}

@-webkit-keyframes rollFromLeft {
    from {
        -webkit-transform: translateX(-100%) perspective(600px) rotateY(180deg);
        transform: translateX(-100%) perspective(600px) rotateY(180deg);
        opacity: 0
    }
    to {
        -webkit-transform: translateX(0) perspective(600px) rotateY(0);
        transform: translateX(0) perspective(600px) rotateY(0);
        opacity: 1
    }
}

@keyframes rollFromLeft {
    from {
        -webkit-transform: translateX(-100%) perspective(600px) rotateY(180deg);
        transform: translateX(-100%) perspective(600px) rotateY(180deg);
        opacity: 0
    }
    to {
        -webkit-transform: translateX(0) perspective(600px) rotateY(0);
        transform: translateX(0) perspective(600px) rotateY(0);
        opacity: 1
    }
}

@-webkit-keyframes rollFromRight {
    from {
        -webkit-transform: translateX(100%) perspective(600px) rotateY(-180deg);
        transform: translateX(100%) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
    to {
        -webkit-transform: translateX(0) perspective(600px) rotateY(0);
        transform: translateX(0) perspective(600px) rotateY(0);
        opacity: 1
    }
}

@keyframes rollFromRight {
    from {
        -webkit-transform: translateX(100%) perspective(600px) rotateY(-180deg);
        transform: translateX(100%) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
    to {
        -webkit-transform: translateX(0) perspective(600px) rotateY(0);
        transform: translateX(0) perspective(600px) rotateY(0);
        opacity: 1
    }
}

@-webkit-keyframes rollFromTop {
    from {
        -webkit-transform: translateY(-100%) perspective(600px) rotateY(180deg);
        transform: translateY(-100%) perspective(600px) rotateY(180deg);
        opacity: 0
    }
    to {
        -webkit-transform: translateY(0) perspective(600px) rotateY(0);
        transform: translateY(0) perspective(600px) rotateY(0);
        opacity: 1
    }
}

@keyframes rollFromTop {
    from {
        -webkit-transform: translateY(-100%) perspective(600px) rotateY(180deg);
        transform: translateY(-100%) perspective(600px) rotateY(180deg);
        opacity: 0
    }
    to {
        -webkit-transform: translateY(0) perspective(600px) rotateY(0);
        transform: translateY(0) perspective(600px) rotateY(0);
        opacity: 1
    }
}

@-webkit-keyframes rollFromBottom {
    from {
        -webkit-transform: translateY(100%) perspective(600px) rotateY(-180deg);
        transform: translateY(100%) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
    to {
        -webkit-transform: translateY(0) perspective(600px) rotateY(0);
        transform: translateY(0) perspective(600px) rotateY(0);
        opacity: 1
    }
}

@keyframes rollFromBottom {
    from {
        -webkit-transform: translateY(100%) perspective(600px) rotateY(-180deg);
        transform: translateY(100%) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
    to {
        -webkit-transform: translateY(0) perspective(600px) rotateY(0);
        transform: translateY(0) perspective(600px) rotateY(0);
        opacity: 1
    }
}

@-webkit-keyframes rollToLeft {
    from {
        -webkit-transform: translateX(0) perspective(600px) rotateY(0);
        transform: translateX(0) perspective(600px) rotateY(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateX(-100%) perspective(600px) rotateY(180deg);
        transform: translateX(-100%) perspective(600px) rotateY(180deg);
        opacity: 0
    }
}

@keyframes rollToLeft {
    from {
        -webkit-transform: translateX(0) perspective(600px) rotateY(0);
        transform: translateX(0) perspective(600px) rotateY(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateX(-100%) perspective(600px) rotateY(180deg);
        transform: translateX(-100%) perspective(600px) rotateY(180deg);
        opacity: 0
    }
}

@-webkit-keyframes rollToRight {
    from {
        -webkit-transform: translateX(0) perspective(600px) rotateY(0);
        transform: translateX(0) perspective(600px) rotateY(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateX(100%) perspective(600px) rotateY(-180deg);
        transform: translateX(100%) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
}

@keyframes rollToRight {
    from {
        -webkit-transform: translateX(0) perspective(600px) rotateY(0);
        transform: translateX(0) perspective(600px) rotateY(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateX(100%) perspective(600px) rotateY(-180deg);
        transform: translateX(100%) perspective(600px) rotateY(-180deg);
        opacity: 0
    }
}

@-webkit-keyframes rollToTop {
    from {
        -webkit-transform: translateY(0) perspective(600px) rotateX(0);
        transform: translateY(0) perspective(600px) rotateX(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateY(-100%) perspective(600px) rotateX(180deg);
        transform: translateY(-100%) perspective(600px) rotateX(180deg);
        opacity: 0
    }
}

@keyframes rollToTop {
    from {
        -webkit-transform: translateY(0) perspective(600px) rotateX(0);
        transform: translateY(0) perspective(600px) rotateX(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateY(-100%) perspective(600px) rotateX(180deg);
        transform: translateY(-100%) perspective(600px) rotateX(180deg);
        opacity: 0
    }
}

@-webkit-keyframes rollToBottom {
    to {
        -webkit-transform: translateY(0) perspective(600px) rotateX(0);
        transform: translateY(0) perspective(600px) rotateX(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateY(100%) perspective(600px) rotateX(-180deg);
        transform: translateY(100%) perspective(600px) rotateX(-180deg);
        opacity: 0
    }
}

@keyframes rollToBottom {
    to {
        -webkit-transform: translateY(0) perspective(600px) rotateX(0);
        transform: translateY(0) perspective(600px) rotateX(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateY(100%) perspective(600px) rotateX(-180deg);
        transform: translateY(100%) perspective(600px) rotateX(-180deg);
        opacity: 0
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes rotateX {
    from {
        -webkit-transform: perspective(600px) rotateX(0);
        transform: perspective(600px) rotateX(0)
    }
    to {
        -webkit-transform: perspective(600px) rotateX(90deg);
        transform: perspective(600px) rotateX(90deg)
    }
}

@keyframes rotateX {
    from {
        -webkit-transform: perspective(600px) rotateX(0);
        transform: perspective(600px) rotateX(0)
    }
    to {
        -webkit-transform: perspective(600px) rotateX(90deg);
        transform: perspective(600px) rotateX(90deg)
    }
}

@-webkit-keyframes rotateXIn {
    from {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        opacity: 0
    }
    5% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg)
    }
}

@keyframes rotateXIn {
    from {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        opacity: 0
    }
    5% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg)
    }
}

@-webkit-keyframes rotateXOut {
    95% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg)
    }
    to {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        opacity: 0
    }
}

@keyframes rotateXOut {
    95% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateX(75deg)
    }
    to {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateX(75deg);
        opacity: 0
    }
}

@-webkit-keyframes rotateY {
    from {
        -webkit-transform: perspective(600px) rotateY(0);
        transform: perspective(600px) rotateY(0)
    }
    to {
        -webkit-transform: perspective(600px) rotateY(90deg);
        transform: perspective(600px) rotateY(90deg)
    }
}

@keyframes rotateY {
    from {
        -webkit-transform: perspective(600px) rotateY(0);
        transform: perspective(600px) rotateY(0)
    }
    to {
        -webkit-transform: perspective(600px) rotateY(90deg);
        transform: perspective(600px) rotateY(90deg)
    }
}

@-webkit-keyframes rotateYIn {
    from {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        opacity: 0
    }
    5% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg)
    }
}

@keyframes rotateYIn {
    from {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        opacity: 0
    }
    5% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg)
    }
}

@-webkit-keyframes rotateYOut {
    95% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg)
    }
    to {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        opacity: 0
    }
}

@keyframes rotateYOut {
    95% {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -1500px) rotateY(75deg)
    }
    to {
        -webkit-transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        transform: perspective(600px) translate3d(0, -60px, -2000px) rotateY(75deg);
        opacity: 0
    }
}

@-webkit-keyframes rotateInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(0);
        transform: translateX(-100%) rotate(0)
    }
    to {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
}

@keyframes rotateInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-100%) rotate(0);
        transform: translateX(-100%) rotate(0)
    }
    to {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
}

@-webkit-keyframes rotateInRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(100%) rotate(0);
        transform: translateX(100%) rotate(0)
    }
    to {
        -webkit-transform: translateX(0) rotate(-360deg);
        transform: translateX(0) rotate(-360deg)
    }
}

@keyframes rotateInRight {
    from {
        opacity: 0;
        -webkit-transform: translateX(100%) rotate(0);
        transform: translateX(100%) rotate(0)
    }
    to {
        -webkit-transform: translateX(0) rotate(-360deg);
        transform: translateX(0) rotate(-360deg)
    }
}

@-webkit-keyframes rotateInTop {
    from {
        opacity: 0;
        -webkit-transform: translateY(-400%) rotate(0);
        transform: translateY(-400%) rotate(0)
    }
    to {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
}

@keyframes rotateInTop {
    from {
        opacity: 0;
        -webkit-transform: translateY(-400%) rotate(0);
        transform: translateY(-400%) rotate(0)
    }
    to {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
}

@-webkit-keyframes rotateInBottom {
    from {
        opacity: 0;
        -webkit-transform: translateY(400%) rotate(0);
        transform: translateY(400%) rotate(0)
    }
    to {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
}

@keyframes rotateInBottom {
    from {
        opacity: 0;
        -webkit-transform: translateY(400%) rotate(0);
        transform: translateY(400%) rotate(0)
    }
    to {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
}

@-webkit-keyframes rotateOutLeft {
    from {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
    to {
        -webkit-transform: translateX(-800px) rotate(0);
        transform: translateX(-800px) rotate(0);
        opacity: 0
    }
}

@keyframes rotateOutLeft {
    from {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
    to {
        -webkit-transform: translateX(-800px) rotate(0);
        transform: translateX(-800px) rotate(0);
        opacity: 0
    }
}

@-webkit-keyframes rotateOutRight {
    from {
        -webkit-transform: translateX(0) rotate(-360deg);
        transform: translateX(0) rotate(-360deg)
    }
    to {
        -webkit-transform: translateX(800px) rotate(0);
        transform: translateX(800px) rotate(0);
        opacity: 0
    }
}

@keyframes rotateOutRight {
    from {
        -webkit-transform: translateX(0) rotate(-360deg);
        transform: translateX(0) rotate(-360deg)
    }
    to {
        -webkit-transform: translateX(800px) rotate(0);
        transform: translateX(800px) rotate(0);
        opacity: 0
    }
}

@-webkit-keyframes rotateOutTop {
    from {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
    to {
        -webkit-transform: translateY(-800px) rotate(0);
        transform: translateY(-800px) rotate(0);
        opacity: 0
    }
}

@keyframes rotateOutTop {
    from {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
    to {
        -webkit-transform: translateY(-800px) rotate(0);
        transform: translateY(-800px) rotate(0);
        opacity: 0
    }
}

@-webkit-keyframes rotateOutBottom {
    from {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
    to {
        -webkit-transform: translateY(800px) rotate(0);
        transform: translateY(800px) rotate(0);
        opacity: 0
    }
}

@keyframes rotateOutBottom {
    from {
        -webkit-transform: translateX(0) rotate(360deg);
        transform: translateX(0) rotate(360deg)
    }
    to {
        -webkit-transform: translateY(800px) rotate(0);
        transform: translateY(800px) rotate(0);
        opacity: 0
    }
}

@-webkit-keyframes spinToLeft {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    from {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@keyframes spinToLeft {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    from {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@-webkit-keyframes spinToRight {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    from {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@keyframes spinToRight {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    from {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@-webkit-keyframes spinToTop {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    from {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@keyframes spinToTop {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    from {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@-webkit-keyframes spinToBottom {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    from {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@keyframes spinToBottom {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    from {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@-webkit-keyframes spinFromLeft {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    to {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@keyframes spinFromLeft {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    to {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@-webkit-keyframes spinFromRight {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    to {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@keyframes spinFromRight {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    to {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@-webkit-keyframes spinFromTop {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    to {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@keyframes spinFromTop {
    from, to {
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom
    }
    to {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        opacity: 0
    }
}

@-webkit-keyframes spinFromBottom {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    to {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@keyframes spinFromBottom {
    from, to {
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom
    }
    to {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        opacity: 0
    }
}

@-webkit-keyframes blurIn {
    from {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes blurIn {
    from {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes blurInLeft {
    from {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes blurInLeft {
    from {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes blurInRight {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes blurInRight {
    from {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes blurInTop {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes blurInTop {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes blurInBottom {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@keyframes blurInBottom {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: 0
    }
}

@-webkit-keyframes blurOut {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px)
    }
    to {
        opacity: 0
    }
}

@keyframes blurOut {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px)
    }
    to {
        opacity: 0
    }
}

@-webkit-keyframes blurOutLeft {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
    to {
        opacity: 0
    }
}

@keyframes blurOutLeft {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        -webkit-transform: translateX(100%);
        transform: translateX(100%)
    }
    to {
        opacity: 0
    }
}

@-webkit-keyframes blurOutRight {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    to {
        opacity: 0
    }
}

@keyframes blurOutRight {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    to {
        opacity: 0
    }
}

@-webkit-keyframes blurOutTop {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    to {
        opacity: 0
    }
}

@keyframes blurOutTop {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }
    to {
        opacity: 0
    }
}

@-webkit-keyframes blurOutBottom {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    to {
        opacity: 0
    }
}

@keyframes blurOutBottom {
    85%, to {
        -webkit-filter: blur(20px);
        filter: blur(20px);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }
    to {
        opacity: 0
    }
}

@-webkit-keyframes bounceFromTop {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, -80px, 0) scale3d(1, 1.6, 1);
        transform: translate3d(0, -80px, 0) scale3d(1, 1.6, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

@keyframes bounceFromTop {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, -80px, 0) scale3d(1, 1.6, 1);
        transform: translate3d(0, -80px, 0) scale3d(1, 1.6, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0)
    }
}

@-webkit-keyframes bounceFromDown {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, 80px, 0) scale3d(1, 1.2, 1);
        transform: translate3d(0, 80px, 0) scale3d(1, 1.2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, 4px, 0);
        transform: translate3d(0, 4px, 0)
    }
}

@keyframes bounceFromDown {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, 80px, 0) scale3d(1, 1.2, 1);
        transform: translate3d(0, 80px, 0) scale3d(1, 1.2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0)
    }
    90% {
        -webkit-transform: translate3d(0, 4px, 0);
        transform: translate3d(0, 4px, 0)
    }
}

@-webkit-keyframes bounceX {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1.8, 1, 1);
        transform: scale3d(1.8, 1, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1.5, 1, 1);
        transform: scale3d(1.5, 1, 1)
    }
    90% {
        -webkit-transform: scale3d(1.1, 1, 1);
        transform: scale3d(1.1, 1, 1)
    }
}

@keyframes bounceX {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1.8, 1, 1);
        transform: scale3d(1.8, 1, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1.5, 1, 1);
        transform: scale3d(1.5, 1, 1)
    }
    90% {
        -webkit-transform: scale3d(1.1, 1, 1);
        transform: scale3d(1.1, 1, 1)
    }
}

@-webkit-keyframes bounceY {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1, 2, 1);
        transform: scale3d(1, 2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1, 1.5, 1);
        transform: scale3d(1, 1.5, 1)
    }
    90% {
        -webkit-transform: scale3d(1, 1.1, 1);
        transform: scale3d(1, 1.1, 1)
    }
}

@keyframes bounceY {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1, 2, 1);
        transform: scale3d(1, 2, 1)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale3d(1, 1.5, 1);
        transform: scale3d(1, 1.5, 1)
    }
    90% {
        -webkit-transform: scale3d(1, 1.1, 1);
        transform: scale3d(1, 1.1, 1)
    }
}

@-webkit-keyframes bounceZoomIn {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(1.3);
        transform: scale(1.3)
    }
    90% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
}

@keyframes bounceZoomIn {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(1.3);
        transform: scale(1.3)
    }
    90% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
}

@-webkit-keyframes bounceZoomOut {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(.3);
        transform: scale(.3)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    90% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

@keyframes bounceZoomOut {
    0%, 100%, 25%, 55%, 85% {
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    41%, 44% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(.3);
        transform: scale(.3)
    }
    70% {
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    90% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

@-webkit-keyframes bounceInTop {
    0% {
        -webkit-transform: translate3d(0, -300%, 0);
        transform: translate3d(0, -300%, 0)
    }
    58% {
        -webkit-transform: translate3d(0, 27px, 0);
        transform: translate3d(0, 27px, 0)
    }
    73% {
        -webkit-transform: translate3d(0, -12px, 0);
        transform: translate3d(0, -12px, 0)
    }
    88% {
        -webkit-transform: translate3d(0, 7px, 0);
        transform: translate3d(0, 7px, 0)
    }
}

@keyframes bounceInTop {
    0% {
        -webkit-transform: translate3d(0, -300%, 0);
        transform: translate3d(0, -300%, 0)
    }
    58% {
        -webkit-transform: translate3d(0, 27px, 0);
        transform: translate3d(0, 27px, 0)
    }
    73% {
        -webkit-transform: translate3d(0, -12px, 0);
        transform: translate3d(0, -12px, 0)
    }
    88% {
        -webkit-transform: translate3d(0, 7px, 0);
        transform: translate3d(0, 7px, 0)
    }
}

@-webkit-keyframes bounceInBottom {
    0% {
        -webkit-transform: translate3d(0, 300%, 0);
        transform: translate3d(0, 300%, 0)
    }
    58% {
        -webkit-transform: translate3d(0, -27px, 0);
        transform: translate3d(0, -27px, 0)
    }
    73% {
        -webkit-transform: translate3d(0, 12px, 0);
        transform: translate3d(0, 12px, 0)
    }
    88% {
        -webkit-transform: translate3d(0, -8px, 0);
        transform: translate3d(0, -8px, 0)
    }
}

@keyframes bounceInBottom {
    0% {
        -webkit-transform: translate3d(0, 300%, 0);
        transform: translate3d(0, 300%, 0)
    }
    58% {
        -webkit-transform: translate3d(0, -27px, 0);
        transform: translate3d(0, -27px, 0)
    }
    73% {
        -webkit-transform: translate3d(0, 12px, 0);
        transform: translate3d(0, 12px, 0)
    }
    88% {
        -webkit-transform: translate3d(0, -8px, 0);
        transform: translate3d(0, -8px, 0)
    }
}

@-webkit-keyframes bounceInLeft {
    0% {
        -webkit-transform: translate3d(-300%, 0, 0);
        transform: translate3d(-300%, 0, 0)
    }
    58% {
        -webkit-transform: translate3d(27px, 0, 0);
        transform: translate3d(27px, 0, 0)
    }
    73% {
        -webkit-transform: translate3d(-12px, 0, 0);
        transform: translate3d(-12px, 0, 0)
    }
    88% {
        -webkit-transform: translate3d(8px, 0, 0);
        transform: translate3d(8px, 0, 0)
    }
}

@keyframes bounceInLeft {
    0% {
        -webkit-transform: translate3d(-300%, 0, 0);
        transform: translate3d(-300%, 0, 0)
    }
    58% {
        -webkit-transform: translate3d(27px, 0, 0);
        transform: translate3d(27px, 0, 0)
    }
    73% {
        -webkit-transform: translate3d(-12px, 0, 0);
        transform: translate3d(-12px, 0, 0)
    }
    88% {
        -webkit-transform: translate3d(8px, 0, 0);
        transform: translate3d(8px, 0, 0)
    }
}

@-webkit-keyframes bounceInRight {
    0% {
        -webkit-transform: translate3d(300%, 0, 0);
        transform: translate3d(300%, 0, 0)
    }
    58% {
        -webkit-transform: translate3d(-27px, 0, 0);
        transform: translate3d(-27px, 0, 0)
    }
    73% {
        -webkit-transform: translate3d(12px, 0, 0);
        transform: translate3d(12px, 0, 0)
    }
    88% {
        -webkit-transform: translate3d(-8px, 0, 0);
        transform: translate3d(-8px, 0, 0)
    }
}

@keyframes bounceInRight {
    0% {
        -webkit-transform: translate3d(300%, 0, 0);
        transform: translate3d(300%, 0, 0)
    }
    58% {
        -webkit-transform: translate3d(-27px, 0, 0);
        transform: translate3d(-27px, 0, 0)
    }
    73% {
        -webkit-transform: translate3d(12px, 0, 0);
        transform: translate3d(12px, 0, 0)
    }
    88% {
        -webkit-transform: translate3d(-8px, 0, 0);
        transform: translate3d(-8px, 0, 0)
    }
}

@-webkit-keyframes bounceOutTop {
    18%, 33%, 48% {
        opacity: 1
    }
    18% {
        -webkit-transform: translate3d(0, 27px, 0);
        transform: translate3d(0, 27px, 0)
    }
    33% {
        -webkit-transform: translate3d(0, -12px, 0);
        transform: translate3d(0, -12px, 0)
    }
    48% {
        -webkit-transform: translate3d(0, 8px, 0);
        transform: translate3d(0, 8px, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -300%, 0);
        transform: translate3d(0, -300%, 0)
    }
}

@keyframes bounceOutTop {
    18%, 33%, 48% {
        opacity: 1
    }
    18% {
        -webkit-transform: translate3d(0, 27px, 0);
        transform: translate3d(0, 27px, 0)
    }
    33% {
        -webkit-transform: translate3d(0, -12px, 0);
        transform: translate3d(0, -12px, 0)
    }
    48% {
        -webkit-transform: translate3d(0, 8px, 0);
        transform: translate3d(0, 8px, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, -300%, 0);
        transform: translate3d(0, -300%, 0)
    }
}

@-webkit-keyframes bounceOutBottom {
    18%, 33%, 48% {
        opacity: 1
    }
    18% {
        -webkit-transform: translate3d(0, -27px, 0);
        transform: translate3d(0, -27px, 0)
    }
    33% {
        -webkit-transform: translate3d(0, 12px, 0);
        transform: translate3d(0, 12px, 0)
    }
    48% {
        -webkit-transform: translate3d(0, -8px, 0);
        transform: translate3d(0, -8px, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 300%, 0);
        transform: translate3d(0, 300%, 0)
    }
}

@keyframes bounceOutBottom {
    18%, 33%, 48% {
        opacity: 1
    }
    18% {
        -webkit-transform: translate3d(0, -27px, 0);
        transform: translate3d(0, -27px, 0)
    }
    33% {
        -webkit-transform: translate3d(0, 12px, 0);
        transform: translate3d(0, 12px, 0)
    }
    48% {
        -webkit-transform: translate3d(0, -8px, 0);
        transform: translate3d(0, -8px, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 300%, 0);
        transform: translate3d(0, 300%, 0)
    }
}

@-webkit-keyframes bounceOutLeft {
    18%, 33%, 48% {
        opacity: 1
    }
    18% {
        -webkit-transform: translate3d(27px, 0, 0);
        transform: translate3d(27px, 0, 0)
    }
    33% {
        -webkit-transform: translate3d(-12px, 0, 0);
        transform: translate3d(-12px, 0, 0)
    }
    48% {
        -webkit-transform: translate3d(8px, 0, 0);
        transform: translate3d(8px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(-300%, 0, 0);
        transform: translate3d(-300%, 0, 0)
    }
}

@keyframes bounceOutLeft {
    18%, 33%, 48% {
        opacity: 1
    }
    18% {
        -webkit-transform: translate3d(27px, 0, 0);
        transform: translate3d(27px, 0, 0)
    }
    33% {
        -webkit-transform: translate3d(-12px, 0, 0);
        transform: translate3d(-12px, 0, 0)
    }
    48% {
        -webkit-transform: translate3d(8px, 0, 0);
        transform: translate3d(8px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(-300%, 0, 0);
        transform: translate3d(-300%, 0, 0)
    }
}

@-webkit-keyframes bounceOutRight {
    18%, 33%, 48% {
        opacity: 1
    }
    18% {
        -webkit-transform: translate3d(-27px, 0, 0);
        transform: translate3d(-27px, 0, 0)
    }
    33% {
        -webkit-transform: translate3d(12px, 0, 0);
        transform: translate3d(12px, 0, 0)
    }
    48% {
        -webkit-transform: translate3d(-8px, 0, 0);
        transform: translate3d(-8px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(300%, 0, 0);
        transform: translate3d(300%, 0, 0)
    }
}

@keyframes bounceOutRight {
    18%, 33%, 48% {
        opacity: 1
    }
    18% {
        -webkit-transform: translate3d(-27px, 0, 0);
        transform: translate3d(-27px, 0, 0)
    }
    33% {
        -webkit-transform: translate3d(12px, 0, 0);
        transform: translate3d(12px, 0, 0)
    }
    48% {
        -webkit-transform: translate3d(-8px, 0, 0);
        transform: translate3d(-8px, 0, 0)
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(300%, 0, 0);
        transform: translate3d(300%, 0, 0)
    }
}

@-webkit-keyframes perspectiveToTop {
    50% {
        opacity: 1
    }
    to {
        -webkit-transform: perspective(500px) rotateX(90deg) translateY(-50px) translateZ(50px);
        transform: perspective(500px) rotateX(90deg) translateY(-50px) translateZ(50px);
        opacity: 0
    }
}

@keyframes perspectiveToTop {
    50% {
        opacity: 1
    }
    to {
        -webkit-transform: perspective(500px) rotateX(90deg) translateY(-50px) translateZ(50px);
        transform: perspective(500px) rotateX(90deg) translateY(-50px) translateZ(50px);
        opacity: 0
    }
}

@-webkit-keyframes perspectiveToBottom {
    50% {
        opacity: 1
    }
    to {
        -webkit-transform: perspective(500px) rotateX(90deg) translateY(50px) translateZ(-50px);
        transform: perspective(500px) rotateX(90deg) translateY(50px) translateZ(-50px);
        opacity: 0
    }
}

@keyframes perspectiveToBottom {
    50% {
        opacity: 1
    }
    to {
        -webkit-transform: perspective(500px) rotateX(90deg) translateY(50px) translateZ(-50px);
        transform: perspective(500px) rotateX(90deg) translateY(50px) translateZ(-50px);
        opacity: 0
    }
}

@-webkit-keyframes zoomIn {
    from {
        -webkit-transform: scale(10);
        transform: scale(10);
        opacity: 0
    }
}

@keyframes zoomIn {
    from {
        -webkit-transform: scale(10);
        transform: scale(10);
        opacity: 0
    }
}

@-webkit-keyframes zoomInLeft {
    from {
        -webkit-transform: scale(10) translateX(-50%);
        transform: scale(10) translateX(-50%);
        opacity: 0
    }
}

@keyframes zoomInLeft {
    from {
        -webkit-transform: scale(10) translateX(-50%);
        transform: scale(10) translateX(-50%);
        opacity: 0
    }
}

@-webkit-keyframes zoomInRight {
    from {
        -webkit-transform: scale(10) translateX(50%);
        transform: scale(10) translateX(50%);
        opacity: 0
    }
}

@keyframes zoomInRight {
    from {
        -webkit-transform: scale(10) translateX(50%);
        transform: scale(10) translateX(50%);
        opacity: 0
    }
}

@-webkit-keyframes zoomInTop {
    from {
        -webkit-transform: scale(10) translateY(-50%);
        transform: scale(10) translateY(-50%);
        opacity: 0
    }
}

@keyframes zoomInTop {
    from {
        -webkit-transform: scale(10) translateY(-50%);
        transform: scale(10) translateY(-50%);
        opacity: 0
    }
}

@-webkit-keyframes zoomInBottom {
    from {
        -webkit-transform: scale(10) translateY(50%);
        transform: scale(10) translateY(50%);
        opacity: 0
    }
}

@keyframes zoomInBottom {
    from {
        -webkit-transform: scale(10) translateY(50%);
        transform: scale(10) translateY(50%);
        opacity: 0
    }
}

@-webkit-keyframes zoomOut {
    to {
        -webkit-transform: scale(10);
        transform: scale(10);
        opacity: 0
    }
}

@keyframes zoomOut {
    to {
        -webkit-transform: scale(10);
        transform: scale(10);
        opacity: 0
    }
}

@-webkit-keyframes zoomOutLeft {
    to {
        -webkit-transform: scale(10) translateX(-50%);
        transform: scale(10) translateX(-50%);
        opacity: 0
    }
}

@keyframes zoomOutLeft {
    to {
        -webkit-transform: scale(10) translateX(-50%);
        transform: scale(10) translateX(-50%);
        opacity: 0
    }
}

@-webkit-keyframes zoomOutRight {
    to {
        -webkit-transform: scale(10) translateX(50%);
        transform: scale(10) translateX(50%);
        opacity: 0
    }
}

@keyframes zoomOutRight {
    to {
        -webkit-transform: scale(10) translateX(50%);
        transform: scale(10) translateX(50%);
        opacity: 0
    }
}

@-webkit-keyframes zoomOutTop {
    to {
        -webkit-transform: scale(10) translateY(-50%);
        transform: scale(10) translateY(-50%);
        opacity: 0
    }
}

@keyframes zoomOutTop {
    to {
        -webkit-transform: scale(10) translateY(-50%);
        transform: scale(10) translateY(-50%);
        opacity: 0
    }
}

@-webkit-keyframes zoomOutBottom {
    to {
        -webkit-transform: scale(10) translateY(50%);
        transform: scale(10) translateY(50%);
        opacity: 0
    }
}

@keyframes zoomOutBottom {
    to {
        -webkit-transform: scale(10) translateY(50%);
        transform: scale(10) translateY(50%);
        opacity: 0
    }
}

@-webkit-keyframes ekitRevealInTop {
    form {
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0)
    }
}

@keyframes ekitRevealInTop {
    form {
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0)
    }
}

@-webkit-keyframes ekitRevealInBottom {
    form {
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0)
    }
}

@keyframes ekitRevealInBottom {
    form {
        -webkit-transform: scaleY(1);
        transform: scaleY(1)
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0)
    }
}

@-webkit-keyframes ekitRevealInLeft {
    form {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
    to {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }
}

@keyframes ekitRevealInLeft {
    form {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
    to {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }
}

@-webkit-keyframes ekitRevealInRight {
    form {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
    to {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }
}

@keyframes ekitRevealInRight {
    form {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
    to {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.ekit-wid-con .product-filter-area {
    margin-bottom: 30px
}

.ekit-wid-con .product-filter-wraper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.ekit-wid-con .product-filter-wraper .woocommerce-ordering {
    margin-right: 50px;
    margin-bottom: 0
}

.ekit-wid-con .woocommerce-ordering {
    position: relative;
    z-index: 10
}

.ekit-wid-con .woocommerce-ordering .shop-filter-title {
    color: #777;
    font-size: 14px;
    position: relative;
    top: 9px;
    padding-right: 50px
}

.ekit-wid-con .tab-filter .nav-tabs .nav-item .nav-link {
    border: 0;
    color: #777;
    font-size: 18px;
    padding: 10px
}

.ekit-wid-con .tab-filter .nav-tabs .nav-item .nav-link.active {
    color: #2575fc;
    background-color: transparent
}

.ekit-wid-con .elementskit-accordion {
    counter-reset: myNumber
}

.ekit-wid-con .elementskit-accordion .ekit-accordion-title {
    padding-right: 10px;
    display: inline-block
}

.ekit-wid-con .elementskit-accordion .elementskit-card {
    border: 0;
    border-radius: 0;
    background-color: transparent;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-accordion .elementskit-card:not(:last-child) {
    margin-bottom: 10px
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header {
    padding: 0;
    background-color: transparent;
    border-bottom: 0 solid #000
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header .ekit_accordion_icon_group svg {
    max-width: 30px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #000;
    background-color: #f7f9fb;
    font-weight: 700;
    padding: 14px 30px;
    border: 1px solid transparent;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link:focus, .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link:hover {
    text-decoration: none
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link > i {
    color: #2575fc;
    line-height: 1.6666666667;
    display: inline-block;
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link .ekit_accordion_icon_left_group, .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link > .icon-left {
    padding-right: 26px;
    float: left
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded=true] .icon-closed {
    display: block
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded=true] .icon-open {
    display: none
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded=true] .ekit_accordion_normal_icon {
    display: none
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded=true] .ekit_accordion_active_icon {
    display: block
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded=false] .icon-closed {
    display: none
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded=false] .icon-open {
    display: block
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded=false] .ekit_accordion_normal_icon {
    display: block
}

.ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded=false] .ekit_accordion_active_icon {
    display: none
}

.ekit-wid-con .elementskit-accordion .elementskit-card-body {
    padding: 20px 30px
}

.ekit-wid-con .elementskit-accordion .elementskit-card-body p {
    margin-top: 0;
    margin-bottom: 1em
}

.ekit-wid-con .elementskit-accordion .elementskit-card-body p:last-child {
    margin-bottom: 0
}

.ekit-wid-con .elementskit-accordion.floating-style .elementskit-card-header > .elementskit-btn-link {
    -webkit-box-shadow: -1.216px 6.894px 15px 0 rgba(37, 117, 252, .2);
    box-shadow: -1.216px 6.894px 15px 0 rgba(37, 117, 252, .2);
    border-radius: 0 0 5px 5px;
    background-color: #fff
}

.ekit-wid-con .elementskit-accordion.floating-style .elementskit-card-body {
    width: calc(100% - 40px);
    border-radius: 0 0 5px 5px;
    position: relative;
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .elementskit-accordion.floating-style .elementskit-card[aria-expanded=true] .elementskit-btn-link {
    background-color: #2575fc;
    color: #fff;
    -webkit-box-shadow: -1.216px 6.894px 15px 0 rgba(0, 0, 0, .07);
    box-shadow: -1.216px 6.894px 15px 0 rgba(0, 0, 0, .07)
}

.ekit-wid-con .elementskit-accordion.floating-style .elementskit-card[aria-expanded=true] .elementskit-btn-link > i {
    color: inherit
}

.ekit-wid-con .elementskit-accordion.floating-style .elementskit-card[aria-expanded=true] .elementskit-card-body {
    background-color: #2575fc
}

.ekit-wid-con .elementskit-accordion.floating-style .elementskit-card[aria-expanded=true] .elementskit-card-body p {
    color: #fff
}

.ekit-wid-con .elementskit-accordion.side-curve .elementskit-card-header > .elementskit-btn-link {
    background-color: #2575fc;
    position: relative;
    border: 0;
    padding-left: 70px
}

.ekit-wid-con .elementskit-accordion.side-curve .elementskit-card-header > .elementskit-btn-link::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 35px;
    background-color: rgba(255, 255, 255, .2);
    text-align: right;
    line-height: 55px
}

.ekit-wid-con .elementskit-accordion.side-curve .elementskit-card-header > .elementskit-btn-link::after {
    position: absolute;
    content: "";
    left: 35px;
    top: 0;
    border-top: 26.5px solid transparent;
    border-right: 26.5px solid transparent;
    border-left: 26.5px solid rgba(255, 255, 255, .2);
    border-bottom: 26.5px solid transparent
}

.ekit-wid-con .elementskit-accordion.side-curve .elementskit-card-header > .elementskit-btn-link .ekit_accordion_icon_left_group {
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.ekit-wid-con .elementskit-accordion.curve-shape .elementskit-card-header > .elementskit-btn-link {
    position: relative;
    z-index: 1;
    padding: 16.5px 30px
}

.ekit-wid-con .elementskit-accordion.curve-shape .elementskit-card-header > .elementskit-btn-link[aria-expanded=true] {
    color: #fff
}

.ekit-wid-con .elementskit-accordion.curve-shape .elementskit-card-header > .elementskit-btn-link[aria-expanded=true] > i {
    color: inherit
}

.ekit-wid-con .elementskit-accordion.curve-shape .elementskit-card-header > .elementskit-btn-link[aria-expanded=true] .path {
    fill: #2575fc
}

.ekit-wid-con .elementskit-accordion.curve-shape .svg-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1
}

.ekit-wid-con .elementskit-accordion .elementskit-card-body {
    padding: 30px 85px 30px 65px;
}

.ekit-wid-con .elementskit-accordion.accordion-4 .elementskit-card-header > .elementskit-btn-link {
    border: 1px solid #f5f5f5;
    border-bottom: 1px solid #2575fc;
    background-color: #fff;
    position: relative;
    padding-left: 85px
}

.ekit-wid-con .elementskit-accordion.accordion-4 .elementskit-card-header > .elementskit-btn-link::before {
    position: absolute;
    top: -1px;
    left: -1px;
    content: "";
    height: calc(100% + 2px);
    width: 55px;
    text-align: center;
    line-height: 55px;
    color: #fff;
    background-color: #2575fc
}

.ekit-wid-con .elementskit-accordion.accordion-4 .elementskit-card-header > .elementskit-btn-link .ekit_accordion_icon_left_group {
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff
}

.ekit-wid-con .elementskit-accordion.accordion-4 .elementskit-card-body {
    padding-left: 0;
    padding-right: 0
}

.ekit-wid-con .number::before {
    counter-increment: myNumber;
    content: counter(myNumber) "."
}

.ekit-wid-con .icon-right {
    float: right
}

.ekit-wid-con .icon-left {
    float: left
}

.ekit-wid-con .path {
    fill: #fff;
    stroke: #2575fc;
    stroke-miterlimit: 0
}

.ekit-wid-con .ekit_accordion_icon_group {
    position: absolute;
    right: 0;
    margin-right: 30px
}

.ekit-wid-con .elementskit-accordion.curve-shape .svg-shape {
    height: 100%
}

.ekit-accordion--content, .ekit-accordion--toggler {
    font-size: 16px;
    line-height: 24px
}

.ekit-wid-con .ekit-header-info {
    list-style: none
}

.ekit-wid-con .ekit-header-info > li:not(:last-child) {
    margin-right: 15px
}

.ekit-wid-con .ekit-header-info, .ekit-wid-con .ekit-header-info a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .ekit-header-info svg {
    max-width: 15px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .round-img img {
    border-radius: 100%
}

.ekit-wid-con .elementskit-infobox {
    padding: 60px 40px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center
}

.ekit-wid-con .elementskit-infobox.media .elementskit-box-header {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.ekit-wid-con .elementskit-infobox:hover.image-active::before {
    background-color: rgba(37, 117, 252, 0)
}

.ekit-wid-con .elementskit-infobox:hover.image-active .image-hover > img {
    opacity: 1;
    -webkit-transform: scale(1.06);
    transform: scale(1.06)
}

.ekit-wid-con .elementskit-infobox:hover .icon-hover {
    opacity: 1;
    bottom: -20px
}

.ekit-wid-con .elementskit-infobox .elementskit-box-header {
    position: relative;
    z-index: 2
}

.ekit-wid-con .elementskit-infobox .elementskit-box-header .elementskit-info-box-icon {
    display: inline-block;
    margin-bottom: 13px
}

.ekit-wid-con .elementskit-infobox .elementskit-box-header .elementskit-info-box-icon i {
    -webkit-transition: all .4s;
    transition: all .4s
}

.ekit-wid-con .elementskit-infobox .elementskit-box-header .icon-switcher {
    position: relative;
    display: inline-block;
    overflow: hidden
}

.ekit-wid-con .elementskit-infobox .elementskit-box-header .icon-switcher > img {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-infobox .elementskit-box-header .icon-switcher .main-image {
    opacity: 1;
    position: relative;
    top: 0
}

.ekit-wid-con .elementskit-infobox .elementskit-box-header .icon-switcher .hover-image {
    position: absolute;
    top: 80px;
    left: 0
}

.ekit-wid-con .elementskit-infobox .elementskit-box-header .info-box-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #2575fc;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-infobox .box-body {
    position: relative;
    z-index: 2
}

.ekit-wid-con .elementskit-infobox .box-body p {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-infobox .box-footer {
    position: relative;
    z-index: 2
}

.ekit-wid-con .elementskit-infobox .box-footer.enable_hover_btn .elementskit-btn {
    left: 0;
    bottom: 0;
    opacity: 0
}

.ekit-wid-con .elementskit-infobox .btn-wraper {
    position: relative;
    z-index: 2
}

.ekit-wid-con .elementskit-infobox .border-divider {
    margin-bottom: 25px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: 2
}

.ekit-wid-con .elementskit-infobox .link-btn {
    font-size: 13px
}

.ekit-wid-con .elementskit-infobox .icon-hover {
    position: absolute;
    bottom: -50px;
    right: 0;
    opacity: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: 1
}

.ekit-wid-con .elementskit-infobox .icon-hover i {
    font-size: 100px
}

.ekit-wid-con .elementskit-infobox .icon-hover svg {
    max-width: 100px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-infobox.gradient-active::before {
    position: absolute;
    content: "";
    top: 0;
    left: -101%;
    height: 100%;
    width: 100%;
    -webkit-transition: all .6s ease;
    transition: all .6s ease;
    background-size: 101% 101%
}

.ekit-wid-con .elementskit-infobox.gradient-active:hover::before {
    left: 0
}

.ekit-wid-con .elementskit-infobox.gradient-active.hover_from_top::before {
    left: 0;
    top: -100%
}

.ekit-wid-con .elementskit-infobox.gradient-active.hover_from_top:hover::before {
    top: 0
}

.ekit-wid-con .elementskit-infobox.gradient-active.hover_from_right::before {
    left: 100%
}

.ekit-wid-con .elementskit-infobox.gradient-active.hover_from_right:hover::before {
    left: 0
}

.ekit-wid-con .elementskit-infobox.gradient-active.hover_from_bottom::before {
    left: 0;
    top: 100%
}

.ekit-wid-con .elementskit-infobox.gradient-active.hover_from_bottom:hover::before {
    top: 0
}

.ekit-wid-con .elementskit-infobox.image-active::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(37, 117, 252, 0);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: 1;
    background-size: 101% 101%
}

.ekit-wid-con .elementskit-infobox.image-active .image-hover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.ekit-wid-con .elementskit-infobox.image-active .image-hover > img {
    opacity: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-infobox:hover .box-footer.enable_hover_btn .elementskit-btn {
    opacity: 1;
    visibility: visible
}

.ekit-wid-con .elementskit-infobox.icon-lef-right-aligin {
    padding: 15px
}

.ekit-wid-con .elementkit-infobox-icon.framed {
    border: 2px solid red;
    padding: 10px
}

.ekit-wid-con .elementskit-infobox.icon-lef-right-aligin {
    padding: 15px 30px
}

.ekit-wid-con .elementkit-infobox-icon.framed.round {
    border-radius: 100%
}

.ekit-wid-con .elementskit-icon-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.ekit-wid-con .infobox-ovelry {
    position: relative
}

.ekit-wid-con .infobox-ovelry::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #2575fc;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: 1
}

.ekit-wid-con .elementskit-info-box-title {
    font-size: 23px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    margin-top: 0
}

.ekit-wid-con .elementskit-info-box-title svg {
    max-width: 26px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-info-box-icon {
    display: inline-block
}

.ekit-wid-con .elementskit-info-box-icon > i {
    color: #000;
    font-size: 64px
}

.ekit-wid-con .elementskit-infobox {
    position: relative
}

.ekit-wid-con .ekit_global_link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 50
}

.ekit-wid-con .ekit-badge {
    background-color: #ff057c;
    font-size: .70588rem;
    color: #fff;
    font-weight: 500;
    padding: 1px 6px;
    border-radius: .29412rem 0 .29412rem 0;
    display: inline-block
}

.ekit-wid-con .ekit-icon-box-badge {
    position: absolute;
    line-height: 1;
    z-index: 2
}

.ekit-wid-con .ekit_position_top_left {
    top: 0;
    left: 0
}

.ekit-wid-con .ekit_position_top_center {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.ekit-wid-con .ekit_position_top_right {
    top: 0;
    right: 0
}

.ekit-wid-con .ekit_position_center_left {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0
}

.ekit-wid-con .ekit_position_center_right {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0
}

.ekit-wid-con .ekit_position_bottom_left {
    bottom: 0;
    left: 0
}

.ekit-wid-con .ekit_position_bottom_center {
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.ekit-wid-con .ekit_position_bottom_right {
    bottom: 0;
    right: 0
}

.ekit-wid-con .profile-card {
    background-color: #fff;
    -webkit-box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    padding: 40px 20px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative
}

.ekit-wid-con .profile-card.text-center .profile-header {
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .profile-card:hover {
    -webkit-box-shadow: 0 42px 60px 0 rgba(0, 0, 0, .3);
    box-shadow: 0 42px 60px 0 rgba(0, 0, 0, .3)
}

.ekit-wid-con .profile-card:hover .profile-body .profile-title {
    color: #2575fc
}

.ekit-wid-con .profile-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    z-index: 0
}

.ekit-wid-con .profile-icon[class*=icon-align-] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-wid-con .profile-icon.icon-align-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.ekit-wid-con .profile-icon.icon-align-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.ekit-wid-con .profile-icon.icon-align-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.ekit-wid-con .profile-icon svg {
    max-width: 60px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .profile-icon > i {
    display: block;
    width: 60px;
    height: 60px;
    color: #fff;
    background-color: #fc0467;
    border-radius: 50%;
    font-size: 22px;
    line-height: 50px;
    text-align: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .profile-body {
    padding-bottom: 20px
}

.ekit-wid-con .profile-body .profile-title {
    font-size: 22px;
    color: #000;
    margin: 0
}

.ekit-wid-con .profile-body .profile-title > a {
    color: inherit
}

.ekit-wid-con .profile-body .profile-designation {
    color: rgba(0, 0, 0, .5);
    font-size: 13px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    margin-bottom: 15px
}

.ekit-wid-con .profile-header {
    margin-bottom: 38px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    max-width: 100%
}

.ekit-wid-con .profile-header > img {
    width: 160px;
    height: 160px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .profile-image-card {
    position: relative;
    overflow: hidden
}

.ekit-wid-con .profile-image-card::before {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .7);
    -webkit-transition: all .4s ease .2s;
    transition: all .4s ease .2s;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    visibility: hidden
}

.ekit-wid-con .profile-image-card .hover-area {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
    z-index: 2;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.ekit-wid-con .profile-image-card .hover-area .profile-title {
    color: #fff
}

.ekit-wid-con .profile-image-card .hover-area .profile-designation {
    color: #fff
}

.ekit-wid-con .profile-image-card .hover-area .profile-content {
    color: #fff
}

.ekit-wid-con .profile-image-card:hover .hover-area {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s ease .1s;
    transition: all .3s ease .1s
}

.ekit-wid-con .profile-image-card:hover::before {
    top: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .4s ease 0s;
    transition: all .4s ease 0s
}

.ekit-wid-con .image-card-v2 .profile-image-card::before {
    background-color: #123075;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    width: auto;
    height: auto;
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0
}

.ekit-wid-con .image-card-v2 .profile-image-card:hover::before {
    opacity: .93;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.ekit-wid-con .image-card-v2 .profile-image-card .hover-area {
    padding-left: 50px;
    padding-right: 50px
}

.ekit-wid-con .image-card-v2 .profile-image-card .profile-body .border-hr {
    background-color: rgba(255, 255, 255, .6);
    margin-bottom: 25px
}

.ekit-wid-con .image-card-v2 .profile-image-card .profile-body .profile-content {
    color: #fff
}

.ekit-wid-con .image-card-v2.style-circle .profile-image-card {
    width: 100%;
    height: auto
}

.ekit-wid-con .image-card-v2.style-circle .profile-image-card::before {
    border-radius: inherit
}

.ekit-wid-con .image-card-v2.style-circle .profile-image-card::after {
    content: " ";
    display: block;
    padding-bottom: 100%
}

.ekit-wid-con .image-card-v2.style-circle .profile-image-card > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.ekit-wid-con .image-card-v3 .profile-image-card::before {
    display: none
}

.ekit-wid-con .image-card-v3 .profile-image-card::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #111;
    opacity: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    pointer-events: none
}

.ekit-wid-con .image-card-v3 .profile-image-card .hover-area {
    top: inherit;
    bottom: 0;
    -webkit-transform: none;
    transform: none
}

.ekit-wid-con .image-card-v3 .profile-image-card:hover .hover-area {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px)
}

.ekit-wid-con .image-card-v3 .profile-image-card:hover::after {
    opacity: .8
}

.ekit-wid-con .image-card-v3.tiny-gutters {
    margin-left: -3px;
    margin-right: -3px
}

.ekit-wid-con .image-card-v3.tiny-gutters > [class^=col-] {
    padding-left: 3px;
    padding-right: 3px;
    margin-bottom: 6px
}

.ekit-wid-con .profile-square-v .profile-card {
    padding: 20px 20px 40px;
    z-index: 1
}

.ekit-wid-con .profile-square-v .profile-card::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #123075;
    opacity: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: -1;
    background-size: 101% 102%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ekit-wid-con .profile-square-v .profile-card .profile-header {
    width: 100%;
    height: 100%;
    border-radius: 0
}

.ekit-wid-con .profile-square-v .profile-card .profile-content {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .profile-square-v .profile-card:hover::before {
    opacity: 1
}

.ekit-wid-con .profile-square-v .profile-card:hover .profile-body .profile-designation, .ekit-wid-con .profile-square-v .profile-card:hover .profile-body .profile-title {
    color: #fff
}

.ekit-wid-con .profile-square-v .profile-card:hover .profile-content {
    color: #fff
}

.ekit-wid-con .profile-square-v.square-v2 .profile-card::before {
    display: none
}

.ekit-wid-con .profile-square-v.square-v2 .profile-card:hover {
    background-color: #fff
}

.ekit-wid-con .profile-square-v.square-v2 .profile-card:hover .profile-body .profile-title {
    color: #2575fc
}

.ekit-wid-con .profile-square-v.square-v2 .profile-card:hover .profile-body .profile-designation {
    color: #656565
}

.ekit-wid-con .profile-square-v.square-v3 .profile-card:hover {
    -webkit-box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1)
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card {
    max-height: 380px;
    overflow: hidden
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card::before {
    display: none
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card .profile-header {
    margin-bottom: 0;
    max-width: 255px;
    max-height: 255px
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card.text-left .profile-header img {
    left: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card.text-right .profile-header img {
    left: auto;
    right: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card .profile-body {
    margin-bottom: 0;
    padding: 35px 20px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card .profile-body::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #123075;
    opacity: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: -1;
    background-size: 101% 102%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card .profile-body .ekit-team-social-list {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card:hover .profile-body {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px)
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card:hover .profile-body::before {
    opacity: 1
}

.ekit-wid-con .profile-square-v.square-v4 .profile-card:hover .profile-body .ekit-team-social-list {
    opacity: 1;
    visibility: visible
}

.ekit-wid-con .profile-square-v.square-v5 .profile-card {
    padding-bottom: 50px
}

.ekit-wid-con .profile-square-v.square-v5 .profile-card .profile-body, .ekit-wid-con .profile-square-v.square-v5 .profile-card .profile-header {
    padding-bottom: 0
}

.ekit-wid-con .profile-square-v.square-v5 .profile-card .profile-body {
    padding: 32px 60px
}

.ekit-wid-con .profile-square-v.square-v6 .profile-card {
    padding-bottom: 35px
}

.ekit-wid-con .profile-square-v.square-v6 .profile-card::after {
    position: absolute;
    content: "";
    height: 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #2965f1;
    opacity: .2;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .profile-square-v.square-v6 .profile-card .profile-body, .ekit-wid-con .profile-square-v.square-v6 .profile-card .profile-header {
    margin-bottom: 0
}

.ekit-wid-con .profile-square-v.square-v6 .profile-card .profile-body {
    padding: 40px
}

.ekit-wid-con .profile-square-v.square-v6 .profile-card:hover {
    -webkit-box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1)
}

.ekit-wid-con .profile-square-v.square-v6 .profile-card:hover::after {
    opacity: 1
}

.ekit-wid-con .profile-square-v.square-v6.square-v6-v2 .profile-card::after {
    display: none
}

.ekit-wid-con .profile-square-v.square-v6.square-v6-v2 .profile-card::before {
    opacity: 0;
    background-size: 101% 101%
}

.ekit-wid-con .profile-square-v.square-v6.square-v6-v2 .profile-card:hover::before {
    opacity: 1
}

.ekit-wid-con .profile-square-v.square-v6.square-v6-v2 .profile-card:hover .profile-body .profile-title {
    color: #fff
}

.ekit-wid-con .profile-square-v.square-v6.square-v6-v2 .profile-card:hover .profile-body .profile-content, .ekit-wid-con .profile-square-v.square-v6.square-v6-v2 .profile-card:hover .profile-body .profile-designation {
    color: #fff
}

.ekit-wid-con .profile-info-text {
    padding-right: 30px
}

.ekit-wid-con .profile-info-text .info-title {
    font-size: 48px;
    margin-bottom: 23px
}

.ekit-wid-con .profile-info-text p {
    margin-bottom: 37px
}

.ekit-wid-con .small-gutters .profile-card {
    padding: 10px 10px 40px
}

.ekit-wid-con .no_gutters .profile-card {
    padding: 0
}

.ekit-wid-con .style-circle .profile-image-card {
    width: 255px;
    height: 255px;
    border-radius: 50%
}

.ekit-wid-con .style-circle .profile-image-card.text-center {
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .text-center .profile-header {
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .popup-modal {
    display: inline-block
}

.ekit-wid-con .elementskit-portfolio-thumb img {
    width: 100%
}

.ekit-wid-con .ekit-team-style-default {
    background-image: none !important
}

.ekit-wid-con .ekit-team-style-default::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent !important;
    border-radius: inherit;
    opacity: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    z-index: -1
}

.ekit-wid-con .ekit-team-style-default:hover::before {
    opacity: 1
}

.ekit-wid-con .ekit-team-style-long_height_hover > img, .ekit-wid-con .ekit-team-style-overlay > img, .ekit-wid-con .ekit-team-style-overlay_details > img {
    display: block
}

.ekit-wid-con .ekit-team-style-long_height_details {
    overflow: hidden
}

.ekit-wid-con .ekit-team-style-hover_info .ekit-team-img {
    padding-top: 0 !important
}

.ekit-wid-con .ekit-team-style-centered_style_details .profile-designation, .ekit-wid-con .ekit-team-style-long_height_details .profile-designation, .ekit-wid-con .ekit-team-style-long_height_details_hover .profile-designation {
    margin-bottom: 30px
}

.ekit-wid-con .ekit-team-style-hover_info .profile-designation {
    margin-bottom: 25px
}

.ekit-wid-con .ekit-team-style-overlay_circle_hover .profile-designation, .ekit-wid-con .ekit-team-style-overlay_details .profile-designation {
    margin-bottom: 12px
}

.ekit-wid-con .ekit-team-img > img {
    margin-left: auto;
    margin-right: auto;
    border-radius: inherit
}

.ekit-wid-con .ekit-team-img.profile-header > img {
    -o-object-fit: cover;
    object-fit: cover
}

.ekit-wid-con .ekit-team-img.profile-image-card > img {
    width: 100%
}

.ekit-wid-con .ekit-team-modal.mfp-bg {
    border-radius: 0;
    -webkit-animation: none;
    animation: none
}

.ekit-wid-con .ekit-team-modal.mfp-wrap::before {
    opacity: .8;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.ekit-wid-con .ekit-team-modal.mfp-wrap.mfp-ready::before {
    opacity: 1
}

.ekit-wid-con .ekit-team-modal.mfp-wrap.ekit-promo-popup::before {
    background-color: #555;
    border-radius: 0;
    -webkit-animation: none;
    animation: none
}

.ekit-wid-con .ekit-team-modal.mfp-wrap > .mfp-container {
    padding: 60px 15px;
    overflow: auto
}

.ekit-wid-con .ekit-team-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #656565;
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    font-size: 18px;
    line-height: 1;
    outline: 0;
    z-index: 1
}

.ekit-wid-con .ekit-team-modal-title {
    margin-top: 0;
    margin-bottom: 8px;
    color: #000;
    font-size: 22px
}

.ekit-wid-con .ekit-team-modal-position {
    margin-bottom: 35px;
    color: rgba(0, 0, 0, .5);
    font-size: 13px
}

.ekit-wid-con .ekit-team-modal-img {
    width: 100%;
    margin-bottom: 30px
}

.ekit-wid-con .ekit-team-modal-img > img {
    display: block;
    width: 100%;
    -webkit-box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1)
}

@media screen and (min-width: 481px) {
    .ekit-wid-con .ekit-team-modal-img {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        margin-bottom: 0
    }
}

.ekit-wid-con .ekit-team-modal-info {
    width: 100%
}

.ekit-wid-con .ekit-team-modal-info .ekit-team-social-list > li > a {
    line-height: 32px;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1)
}

.ekit-wid-con .ekit-team-modal-info .ekit-team-social-list > li > a svg {
    max-width: 12px;
    height: auto;
    vertical-align: middle
}

@media screen and (min-width: 481px) {
    .ekit-wid-con .ekit-team-modal-info.has-img {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        padding-left: 30px
    }
}

@media screen and (min-width: 992px) {
    .ekit-wid-con .ekit-team-modal-info.has-img {
        padding-left: 50px
    }
}

.ekit-wid-con .ekit-team-modal-content {
    margin-bottom: 22px
}

.ekit-wid-con .ekit-team-modal-content p:not(:last-child) {
    margin-bottom: 10px
}

.ekit-wid-con .ekit-team-modal-list {
    margin-bottom: 25px;
    padding-left: 0;
    list-style: none;
    color: #656565;
    font-size: 15px
}

.ekit-wid-con .ekit-team-modal-list > li {
    padding: 19px 0;
    border-top: 1px solid rgba(0, 0, 0, .05)
}

.ekit-wid-con .ekit-team-modal-list > li:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.ekit-wid-con .ekit-team-modal-list strong {
    font-weight: 600;
    padding-right: 18px
}

.ekit-wid-con .ekit-team-modal-list a {
    color: inherit
}

.ekit-wid-con .ekit-team-modal-list a:hover {
    color: #2575fc
}

.ekit-wid-con .ekit-team-popup {
    -webkit-transition: color .3s;
    transition: color .3s
}

.ekit-wid-con .ekit-promo-popup.my-mfp-slide-bottom .zoom-anim-dialog.elementskit-team-popup {
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px)
}

.ekit-wid-con .ekit-promo-popup.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog.elementskit-team-popup {
    -webkit-transform: translate(0);
    transform: translate(0)
}

.ekit-wid-con .elementskit-team-popup .modal-content {
    -webkit-box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    border: 0;
    border-radius: 0
}

.ekit-wid-con .elementskit-team-popup .modal-body {
    padding: 60px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.ekit-wid-con .modal-header .mfp-close {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    cursor: pointer;
    margin: 0 0 0 auto;
}

.ekit-wid-con .ekit-promo-popup > .mfp-container {
    right: 0;
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .ekit-promo-popup > .mfp-container > .mfp-content {
    max-width: 920px
}

.ekit-wid-con .ekit-team-social-list {
    list-style: none
}

.ekit-wid-con .ekit-team-social-list > li {
    display: inline-block
}

.ekit-wid-con .ekit-team-social-list > li:not(:last-child) {
    margin-right: 10px
}

.ekit-wid-con .ekit-team-social-list > li > a {
    display: inline-block;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 12px;
    background-color: #000;
    text-align: center;
    -webkit-transition: all .3s;
    transition: all .3s
}

.ekit-wid-con .ekit-team-social-list > li > a:hover {
    background-color: rgba(0, 0, 0, .8);
    border-radius: 50%
}

.ekit-wid-con .ekit-team-social-list > li > a:hover > i::before {
    -webkit-animation: iconTranslateY .4s forwards;
    animation: iconTranslateY .4s forwards
}

.ekit-wid-con .ekit-team-social-list > li > a:hover > svg {
    -webkit-animation: iconTranslateY .4s forwards;
    animation: iconTranslateY .4s forwards
}

.ekit-wid-con .ekit-team-social-list > li > a svg {
    max-width: 12px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .ekit-team-social-list > li > a > i {
    overflow: hidden
}

.ekit-wid-con .ekit-team-social-list > li > a > i::before {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ekit-wid-con .ekit-team-img-block > img {
    display: block
}

.ekit-wid-con .ekit-team-img-fit > .profile-image-card > img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.ekit-wid-con .elementskit-blog-block-post {
    padding: 50px;
    background-color: #fff;
    -webkit-box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1);
    border-radius: 10px
}

.ekit-wid-con .elementskit-blog-block-post .elementskit-entry-thumb {
    border-radius: 10px;
    margin-right: 20px
}

.ekit-wid-con .elementskit-blog-block-post .entry-title {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 30px
}

.ekit-wid-con .elementskit-blog-block-post .entry-title a {
    color: #000
}

.ekit-wid-con .elementskit-blog-block-post .entry-title a:hover {
    opacity: .8
}

.ekit-wid-con .elementskit-blog-block-post .elementskit-entry-thumb {
    display: block;
    -webkit-box-shadow: 29.698px 29.698px 60px 0 rgba(0, 0, 0, .2);
    box-shadow: 29.698px 29.698px 60px 0 rgba(0, 0, 0, .2);
    width: 100%
}

.ekit-wid-con .elementskit-blog-block-post .elementskit-entry-thumb > img {
    display: block;
    width: 100%
}

.ekit-wid-con .elementskit-blog-block-post .elementskit-entry-header {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0
}

.ekit-wid-con .elementskit-blog-block-post .post-meta-list {
    margin-bottom: 15px
}

.ekit-wid-con .elementskit-blog-block-post .post-meta-list .meta-cat, .ekit-wid-con .elementskit-blog-block-post .post-meta-list .meta-date {
    margin-right: 40px
}

.ekit-wid-con .elementskit-blog-block-post .elementskit-post-footer > p {
    color: #8d8d8d;
    margin-bottom: 35px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-blog-block-post:hover .elementskit-entry-thumb > img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: .8
}

.ekit-wid-con .post-meta-list svg {
    max-width: 16px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-post-card {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    background: #123075;
    -webkit-box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1);
    padding: 60px 40px;
    border-radius: 6px
}

.ekit-wid-con .elementskit-post-card .elementskit-post-body {
    color: #a6aabb
}

.ekit-wid-con .elementskit-post-card .elementskit-entry-header .entry-title a {
    color: #fff
}

.ekit-wid-con .elementskit-post-card .post-author .author-name {
    color: inherit
}

.ekit-wid-con .elementskit-post-card .elementskit-border-hr {
    background-color: #8692af
}

.ekit-wid-con .elementskit-post-card .elementskit-entry-header .post-meta-list {
    margin-bottom: 17px
}

.ekit-wid-con .elementskit-post-card .elementskit-entry-header .entry-title {
    font-size: 18px;
    line-height: 1.333
}

.ekit-wid-con .elementskit-post-card .elementskit-entry-header .entry-title a {
    color: #000
}

.ekit-wid-con .elementskit-post-card .elementskit-entry-header .entry-title a:hover {
    opacity: .9
}

.ekit-wid-con .elementskit-post-card .elementskit-entry-header .elementskit-border-hr {
    margin-top: 25px;
    margin-bottom: 22px
}

.ekit-wid-con .elementskit-post-card .elementskit-post-body {
    color: #8d8d8d
}

.ekit-wid-con .elementskit-post-card .elementskit-post-body p {
    margin-bottom: 35px
}

.ekit-wid-con .elementskit-post-image-card {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 10px;
    background: #123075;
    -webkit-box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1);
    box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .1)
}

.ekit-wid-con .elementskit-post-image-card:hover .elementskit-entry-thumb > img {
    -webkit-transform: scale(1.05) rotate(2deg);
    transform: scale(1.05) rotate(2deg);
    opacity: .8
}

.ekit-wid-con .elementskit-post-image-card:hover .post-author .author-img > img {
    -webkit-box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .3);
    box-shadow: 9.899px 9.899px 30px 0 rgba(0, 0, 0, .3)
}

.ekit-wid-con .elementskit-post-image-card .elementskit-post-body {
    padding: 20px 40px 40px
}

.ekit-wid-con .elementskit-post-image-card .elementskit-post-body > p {
    color: #fff
}

.ekit-wid-con .elementskit-post-image-card .elementskit-entry-thumb {
    border-radius: 10px
}

.ekit-wid-con .elementskit-post-image-card .elementskit-entry-header {
    position: relative
}

.ekit-wid-con .elementskit-post-image-card .elementskit-entry-header .elementskit-entry-thumb {
    position: relative;
    padding: 20px;
    overflow: hidden;
    display: block
}

.ekit-wid-con .elementskit-post-image-card .elementskit-entry-header .elementskit-entry-thumb.box-shadow {
    -webkit-box-shadow: -3.473px 19.696px 25px 0 rgba(0, 0, 0, .2);
    box-shadow: -3.473px 19.696px 25px 0 rgba(0, 0, 0, .2)
}

.ekit-wid-con .elementskit-post-image-card .elementskit-entry-header .elementskit-entry-thumb > img {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    width: 100%
}

.ekit-wid-con .elementskit-post-image-card .elementskit-post-footer {
    margin-top: 16px
}

.ekit-wid-con .elementskit-post-image-card .elementskit-post-footer .btn-wraper {
    margin-top: 25px
}

.ekit-wid-con .elementskit-post-image-card .entry-title a {
    color: #fff
}

.ekit-wid-con .elementskit-post-image-card .post-meta-list {
    margin-bottom: 20px;
    color: rgba(255, 255, 255, .5)
}

.ekit-wid-con .elementskit-post-image-card .post-meta-list > span a {
    color: inherit;
    vertical-align: middle
}

.ekit-wid-con .elementskit-post-image-card .post-meta-list > span .meta-date-text {
    vertical-align: middle
}

.ekit-wid-con .elementskit-post-image-card .entry-title {
    font-size: 18px;
    margin-bottom: 28px
}

.ekit-wid-con .elementskit-post-image-card .post-author .author-name {
    color: inherit
}

.ekit-wid-con .elementskit-post-image-card.modern {
    margin-top: 40px
}

.ekit-wid-con .elementskit-post-image-card.modern:hover .elementskit-entry-thumb > img {
    opacity: 1
}

.ekit-wid-con .elementskit-post-image-card.modern .elementskit-entry-header .elementskit-entry-thumb {
    margin-top: -60px
}

.ekit-wid-con .blog-block-post[class*=gradient-] {
    background: #123075
}

.ekit-wid-con .blog-block-post[class*=gradient-] .entry-title a {
    color: #fff
}

.ekit-wid-con .blog-block-post[class*=gradient-] .post-meta-list {
    color: rgba(255, 255, 255, .5)
}

.ekit-wid-con .blog-block-post[class*=gradient-] .post-meta-list > span a {
    vertical-align: middle;
    color: inherit
}

.ekit-wid-con .blog-block-post[class*=gradient-] .elementskit-post-footer > p {
    color: rgba(255, 255, 255, .5)
}

.ekit-wid-con .blog-block-post[class*=gradient-] .post-author .author-name {
    color: inherit
}

.ekit-wid-con .blog-block-post[class*=gradient-] .first-letter::first-letter {
    border-bottom-color: #102966
}

.ekit-wid-con .blog-block-post[class*=gradient-] .elementskit-border-hr {
    background-color: #8692af
}

.ekit-wid-con .gradient-bg-2 {
    background: #fa2b56
}

.ekit-wid-con .post-meta-list {
    color: #a3a3a3
}

.ekit-wid-con .post-meta-list > span {
    display: inline-block
}

.ekit-wid-con .post-meta-list > span a {
    color: inherit;
    vertical-align: middle
}

.ekit-wid-con .post-meta-list > span i {
    padding-right: 5px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .post-meta-list > span:not(:last-child) {
    margin-right: 40px
}

.ekit-wid-con .meta-author .author-img {
    display: inline-block;
    margin-right: 15px;
    -webkit-box-shadow: 4.95px 4.95px 15px 0 rgba(0, 0, 0, .2);
    box-shadow: 4.95px 4.95px 15px 0 rgba(0, 0, 0, .2);
    border-radius: 50%;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    vertical-align: middle
}

.ekit-wid-con .meta-author .author-img img {
    max-width: 100%
}

.ekit-wid-con .meta-author .author-name {
    color: inherit
}

.ekit-wid-con .meta-author .author-name:hover {
    opacity: .8
}

.ekit-wid-con .first-letter {
    display: inline-block
}

.ekit-wid-con .first-letter::first-letter {
    border-bottom: 3px solid #e5e5e5
}

.ekit-wid-con .elementskit-border-hr {
    height: 3px;
    width: 30px;
    display: block;
    background-color: #e5e5e5
}

.ekit-wid-con .text-center .elementskit-border-hr {
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .text-right .elementskit-border-hr {
    margin-left: auto
}

.ekit-wid-con .elementskit-meta-lists {
    position: absolute;
    top: 83%;
    left: 30px
}

.ekit-wid-con .elementskit-meta-lists > .elementskit-single-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    text-align: center;
    color: #fff;
    background: #fa2b56;
    width: 55px;
    height: 55px;
    border-radius: 100px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .elementskit-meta-lists > .elementskit-single-meta:not(:last-child) {
    margin-right: 15px
}

.ekit-wid-con .elementskit-meta-lists > .elementskit-single-meta strong {
    display: block;
    font-size: 22px;
    font-weight: 900;
    line-height: 1;
    margin-bottom: 2px
}

.ekit-wid-con .elementskit-meta-lists > .elementskit-single-meta .elementskit-meta-wraper {
    display: block;
    margin: 10px 0;
    line-height: 1;
    width: 100%
}

.ekit-wid-con .elementskit-meta-lists.elementskit-style-tag {
    top: auto;
    left: 10px;
    bottom: 30px
}

.ekit-wid-con .elementskit-meta-lists.elementskit-style-tag > .elementskit-single-meta {
    width: auto;
    height: auto;
    border-radius: 0;
    padding-left: 22px;
    padding-right: 12px;
    position: relative
}

.ekit-wid-con .elementskit-meta-lists.elementskit-style-tag > .elementskit-single-meta::before {
    position: absolute;
    content: "";
    top: -10px;
    left: 0;
    color: #912f3d;
    border-bottom: solid currentColor;
    border-top: solid transparent;
    border-width: 5px
}

.ekit-wid-con .elementskit-meta-lists.elementskit-style-tag > .elementskit-single-meta.triangle_right::before {
    border-right: solid transparent;
    border-left: solid currentColor
}

.ekit-wid-con .elementskit-meta-lists.elementskit-style-tag > .elementskit-single-meta.triangle_left::before {
    border-right: solid currentColor;
    border-left: solid transparent
}

.ekit-wid-con .elementskit-meta-lists.elementskit-style-tag > .elementskit-single-meta .elementskit-meta-wraper {
    margin: 7px 0
}

.ekit-wid-con .elementskit-entry-thumb {
    position: relative;
    overflow: hidden;
    display: block
}

.ekit-wid-con .elementskit-entry-thumb > img {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-entry-header {
    position: relative
}

.ekit-wid-con .elementskit-post-body.ekit-highlight-border {
    position: relative
}

.ekit-wid-con .elementskit-post-body.ekit-highlight-border::before {
    content: "";
    height: 20px;
    width: 2px;
    background: #ff712b;
    position: absolute;
    z-index: 999;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%;
    left: 0
}

.ekit-wid-con .post-meta-list > span {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    cursor: pointer;
    line-height: 1
}

.ekit-wid-con .post-meta-list > span > i {
    display: inline-block
}

.ekit-wid-con .meta-author .author-img > img {
    margin: 0
}

.ekit-wid-con .elementskit-post-body {
    position: relative
}

.ekit-wid-con .elementskit-post-body > p {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-post-body > p:empty {
    display: none
}

.ekit-wid-con .elementskit-meta-categories {
    position: absolute;
    top: 15px;
    left: 18px
}

.ekit-wid-con .elementskit-meta-categories .elementskit-meta-wraper {
    display: block
}

.ekit-wid-con .elementskit-meta-categories .elementskit-meta-wraper span {
    background: #08f;
    border-radius: 3px;
    display: inline-block;
    font-size: 13px;
    line-height: 14px
}

.ekit-wid-con .elementskit-meta-categories .elementskit-meta-wraper span:not(:last-child) {
    margin-right: 5px
}

.ekit-wid-con .elementskit-meta-categories .elementskit-meta-wraper span > a {
    color: #fff;
    white-space: nowrap
}

.ekit-wid-con .elementskit-image-accordion-wraper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    min-height: 366px;
    position: relative
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    min-height: 460px;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion::before {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    background: -webkit-gradient(linear, left bottom, left top, from(black), color-stop(60%, rgba(255, 255, 255, 0)));
    background: linear-gradient(0deg, #000 0, rgba(255, 255, 255, 0) 60%);
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    opacity: 0
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion .elementskit-accordion-content {
    width: 100%
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion .elementskit-accordion-title {
    color: #fff;
    margin-bottom: 0
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion .elementskit-accordion-title-wraper {
    position: relative;
    -webkit-transition: all .6s ease;
    transition: all .6s ease;
    top: 100px;
    opacity: 0;
    visibility: hidden;
    display: block
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion .elementskit-btn-wraper {
    position: relative;
    -webkit-transition: all .7s ease;
    transition: all .7s ease;
    top: 100px;
    opacity: 0;
    visibility: hidden
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion .elementskit-icon-wraper {
    margin-bottom: 20px;
    position: relative;
    -webkit-transition: all .6s ease;
    transition: all .6s ease;
    top: -100px;
    opacity: 0;
    visibility: hidden
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion .elementskit-icon-wraper .icon-outline {
    color: #fff
}

.ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion .elementskit-icon-wraper svg {
    max-width: 16px;
    height: auto;
    vertical-align: middle
}

.ekit-image-accordion-click .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion:hover {
    -webkit-box-flex: 3;
    -ms-flex: 3;
    flex: 3
}

.ekit-image-accordion-click .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label::before, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label::before, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion:hover::before {
    top: 0;
    opacity: 1
}

.ekit-image-accordion-click .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label .elementskit-accordion-title-wraper, .ekit-image-accordion-click .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label .elementskit-btn-wraper, .ekit-image-accordion-click .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label .elementskit-icon-wraper, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label .elementskit-accordion-title-wraper, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label .elementskit-btn-wraper, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion--input:checked + label .elementskit-icon-wraper, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion:hover .elementskit-accordion-title-wraper, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion:hover .elementskit-btn-wraper, .ekit-image-accordion-hover .ekit-wid-con .elementskit-image-accordion-wraper .elementskit-single-image-accordion:hover .elementskit-icon-wraper {
    top: 0;
    opacity: 1;
    visibility: visible
}

.ekit-wid-con .ekit-image-accordion-actions {
    display: block
}

.ekit-wid-con .ekit-image-accordion-actions > a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all .3s;
    transition: all .3s
}

.ekit-wid-con .ekit-image-accordion-actions > a > svg {
    width: 100%
}

.ekit-wid-con .elementskit-accordion-title {
    color: #000;
    font-size: 22px;
    margin-bottom: 0;
    display: block;
    font-weight: 700;
    line-height: 1
}

.ekit-wid-con .elementskit-accordion-title svg {
    max-width: 28px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-accordion-title.icon-title {
    font-size: 28px;
    display: inline-block
}

.ekit-wid-con .elementskit-accordion-title.icon-title > i {
    position: relative;
    top: 3px;
    display: inline-block
}

.ekit-wid-con .elementskit-accordion-title.icon-title .icon-right {
    padding-left: 15px
}

.ekit-wid-con .elementskit-accordion-title.icon-title .icon-left {
    padding-right: 15px
}

.ekit-wid-con .icon-outline {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    color: #000;
    text-align: center;
    border: 1px solid currentColor
}

.ekit-wid-con .icon-outline.circle {
    border-radius: 50%
}

.ekit-image-accordion--btn {
    font-weight: 400
}

.ekit-wid-con .elementskit-clients-slider .elementskit-client-slider-item.log-separator {
    position: relative
}

.ekit-wid-con .elementskit-clients-slider .elementskit-client-slider-item.log-separator::after {
    content: "";
    background: #e7e7e7;
    position: absolute;
    z-index: 999;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.ekit-wid-con .elementskit-clients-slider .swiper-navigation-button.swiper-button-next {
    left: 100%
}

.ekit-wid-con .elementskit-clients-slider .hover-bg-gradient .single-client::before {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background: #f2709c;
    background: linear-gradient(315deg, #f2709c 0, #fe8f75 60%);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: -1
}

.ekit-wid-con .elementskit-clients-slider .single-client {
    min-height: 170px;
    z-index: 5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    -webkit-box-shadow: 5px 10px 15px 0 rgba(0, 0, 0, .1);
    box-shadow: 5px 10px 15px 0 rgba(0, 0, 0, .1);
    position: relative;
    overflow: hidden;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-clients-slider .single-client img {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-clients-slider .single-client .content-image {
    display: block
}

.ekit-wid-con .elementskit-clients-slider .single-client .content-image > .hover-image {
    display: none
}

.ekit-wid-con .elementskit-clients-slider .single-client.image-switcher .content-image > .hover-image {
    display: block
}

.ekit-wid-con .elementskit-clients-slider .single-client:hover {
    z-index: 6
}

.ekit-wid-con .elementskit-clients-slider .single-client:hover::before {
    opacity: 1;
    top: 0
}

.ekit-wid-con .elementskit-clients-slider .client-slider-item {
    position: relative
}

.ekit-wid-con .elementskit-clients-slider.simple_logo_image .single-client::before {
    display: none
}

.ekit-wid-con .elementskit-clients-slider.banner_logo_image .single-client::before {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f2709c;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: -1
}

.ekit-wid-con .elementskit-clients-slider.banner_logo_image .single-client:hover::before {
    opacity: 1;
    top: 0
}

.ekit-wid-con .elementskit-clients-slider.banner_logo_image.hover_from_top .single-client::before {
    top: -100%
}

.ekit-wid-con .elementskit-clients-slider.banner_logo_image.hover_from_top .single-client:hover::before {
    top: 0
}

.ekit-wid-con .elementskit-clients-slider.banner_logo_image.hover_from_left .single-client::before {
    top: 0;
    left: -100%
}

.ekit-wid-con .elementskit-clients-slider.banner_logo_image.hover_from_left .single-client:hover::before {
    left: 0
}

.ekit-wid-con .elementskit-clients-slider.banner_logo_image.hover_from_right .single-client::before {
    top: 0;
    left: 100%
}

.ekit-wid-con .elementskit-clients-slider.banner_logo_image.hover_from_right .single-client:hover::before {
    left: 0
}

.ekit-wid-con .elementskit-clients-sliderX .swiper-button-next, .ekit-wid-con .elementskit-clients-sliderX .swiper-button-prev {
    z-index: 1
}

.ekit-wid-con .elementskit-clients-sliderX .swiper-button-next::before, .ekit-wid-con .elementskit-clients-sliderX .swiper-button-prev::before {
    color: #000
}

.ekit-wid-con .elementskit-clients-sliderX .swiper-navigation-button {
    position: absolute;
    border: 0;
    background: 0 0;
    z-index: 1;
    padding: 0;
    height: initial !important;
    width: initial !important;
    line-height: initial !important;
    font-size: 20px;
    color: #000;
    padding: 15px
}

.ekit-wid-con .elementskit-clients-sliderX .swiper-navigation-button .swiper-button-next {
    left: 100%
}

.ekit-wid-con .elementskit-clients-slider.arrow_inside .swiper-button-prev {
    left: 15px
}

.ekit-wid-con .elementskit-clients-slider.arrow_inside .swiper-button-next {
    right: 15px;
    left: inherit
}

.ekit-wid-con .elementskit-clients-slider.arrow_outside .swiper-button-prev {
    left: -60px
}

.ekit-wid-con .swiper-pagination {
    line-height: 1
}

.ekit-wid-con .swiper-pagination span {
    background-color: #ededed;
    padding: 0
}

.ekit-wid-con .swiper-pagination span.swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #62606b
}

.ekit-wid-con .dot_dashed .swiper-pagination span, .ekit-wid-con .dot_dotted .swiper-pagination span {
    background-color: #101010;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .dot_dashed .swiper-pagination span.swiper-pagination-bullet-active, .ekit-wid-con .dot_dotted .swiper-pagination span.swiper-pagination-bullet-active {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 1
}

.ekit-wid-con .dot_dashed .swiper-pagination > span.swiper-pagination-bullet-active {
    width: 40px;
    -webkit-transform: scale(1);
    transform: scale(1);
    border-radius: 4px
}

.ekit-wid-con .dot_paginated .swiper-pagination {
    counter-reset: myCounter
}

.ekit-wid-con .dot_paginated .swiper-pagination > span {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    -webkit-box-shadow: 3.536px 3.536px 10px 0 rgba(0, 0, 0, .1);
    box-shadow: 3.536px 3.536px 10px 0 rgba(0, 0, 0, .1);
    color: #565656;
    opacity: 1;
    font-weight: 700;
    border-radius: 50%
}

.ekit-wid-con .dot_paginated .swiper-pagination > span::before {
    counter-increment: myCounter;
    content: counter(myCounter)
}

.ekit-wid-con .dot_paginated .swiper-pagination > span.swiper-pagination-bullet-active {
    background-color: #2575fc;
    color: #fff
}

.ekit-wid-con .dot_paginated .swiper-pagination > span > button {
    display: none
}

.ekit-wid-con .elementskit-clients-slider .swiper-container:not(.swiper-container-initialized) .swiper-slide {
    max-width: calc(100% / var(--ekit_client_logo_slidetosho, 4));
    margin-right: var(--ekit_client_logo_left_right_spacing, 15px)
}

.ekit-wid-con .elementskit-clients-slider .swiper:not(.swiper-initialized) .swiper-slide {
    max-width: calc(100% / var(--ekit_client_logo_slidetosho, 4));
    margin-right: var(--ekit_client_logo_left_right_spacing, 15px)
}

.ekit-wid-con .elementskit-info-image-box {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-info-image-box:hover .elementskit-box-content .elementskit-info-box-title {
    color: #2575fc
}

.ekit-wid-con .elementskit-info-image-box:hover .elementskit-btn {
    background-color: #2575fc;
    border-color: #2575fc;
    color: #fff
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-header {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative;
    overflow: hidden
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-header .box-thumb {
    display: block
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-header .box-thumb > img {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    width: 100%
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-header a {
    display: block;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-header img {
    display: block;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-body {
    padding: 55px 40px
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-content .elementskit-info-box-title {
    font-size: 18px;
    margin-bottom: 20px;
    color: #000;
    display: block;
    font-weight: 700
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-content .elementskit-info-box-title > i {
    font-size: 22px;
    padding-right: 14px;
    position: relative;
    top: 2px
}

.ekit-wid-con .elementskit-info-image-box .elementskit-outline-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px
}

.ekit-wid-con .elementskit-info-image-box .elementskit-box-footer {
    line-height: 1;
    margin-top: 20px
}

.ekit-wid-con .elementskit-info-image-box.style-modern {
    overflow: visible
}

.ekit-wid-con .elementskit-info-image-box.style-modern .elementskit-box-body {
    -webkit-box-shadow: -1.216px 6.894px 25px 0 rgba(0, 0, 0, .07);
    box-shadow: -1.216px 6.894px 25px 0 rgba(0, 0, 0, .07);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .9);
    width: calc(100% - 40px);
    padding: 30px 40px;
    position: relative;
    z-index: 2;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .elementskit-info-image-box.hover-border-bottom .elementskit-box-body {
    position: relative
}

.ekit-wid-con .elementskit-info-image-box.hover-border-bottom .elementskit-box-body::before {
    position: absolute;
    content: "";
    bottom: -1px;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #2575fc;
    -webkit-transition: -webkit-transform 250ms ease-in;
    transition: -webkit-transform 250ms ease-in;
    transition: transform 250ms ease-in;
    transition: transform 250ms ease-in, -webkit-transform 250ms ease-in;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%
}

.ekit-wid-con .elementskit-info-image-box.hover-border-bottom:hover .elementskit-box-body::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%
}

.ekit-wid-con .elementskit-info-image-box.hover-border-bottom.hover_from_left .elementskit-box-body::before {
    -webkit-transform-origin: right center;
    transform-origin: right center
}

.ekit-wid-con .elementskit-info-image-box.hover-border-bottom.hover_from_left:hover .elementskit-box-body::before {
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.ekit-wid-con .elementskit-info-image-box.hover-border-bottom.hover_from_center .elementskit-box-body::before {
    -webkit-transform-origin: center center;
    transform-origin: center center
}

.ekit-wid-con .elementskit-info-image-box.hover-border-bottom.hover_from_center:hover .elementskit-box-body::before {
    -webkit-transform-origin: center center;
    transform-origin: center center
}

.ekit-wid-con .elementskit-info-image-box.floating-style .elementskit-box-body {
    background-color: #fff;
    -webkit-box-shadow: -1.216px 6.894px 25px 0 rgba(0, 0, 0, .07);
    box-shadow: -1.216px 6.894px 25px 0 rgba(0, 0, 0, .07);
    border-radius: 10px;
    width: calc(100% - 40px);
    position: relative;
    padding: 30px 35px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -40px;
    position: relative
}

.ekit-wid-con .elementskit-info-image-box.floating-style .elementskit-box-body::after, .ekit-wid-con .elementskit-info-image-box.floating-style .elementskit-box-body::before {
    position: absolute;
    content: "";
    bottom: -10px;
    left: 50%;
    height: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: calc(100% - 20px);
    z-index: -1;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    opacity: 0;
    border-radius: 10px;
    background-color: #fff
}

.ekit-wid-con .elementskit-info-image-box.floating-style .elementskit-box-body::after {
    bottom: -20px;
    width: calc(100% - 40px);
    z-index: -2
}

.ekit-wid-con .elementskit-info-image-box.floating-style .elementskit-box-body .elementskit-box-content .elementskit-box-style-content {
    opacity: 0;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.ekit-wid-con .elementskit-info-image-box.floating-style .elementskit-box-body .elementskit-info-box-title {
    font-size: 18px;
    font-weight: 700
}

.ekit-wid-con .elementskit-info-image-box.floating-style .elementskit-box-body .elementskit-info-box-title > i {
    font-size: 26px
}

.ekit-wid-con .elementskit-info-image-box.floating-style .elementskit-box-footer {
    opacity: 0;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease
}

.ekit-wid-con .elementskit-info-image-box.floating-style:hover .elementskit-box-body {
    height: 185px
}

.ekit-wid-con .elementskit-info-image-box.floating-style:hover .elementskit-box-body::after, .ekit-wid-con .elementskit-info-image-box.floating-style:hover .elementskit-box-body::before {
    opacity: 1
}

.ekit-wid-con .elementskit-info-image-box.floating-style:hover .elementskit-box-body .elementskit-box-content .elementskit-box-style-content {
    opacity: 1
}

.ekit-wid-con .elementskit-info-image-box.floating-style:hover .elementskit-box-body .elementskit-box-footer {
    opacity: 1
}

.ekit-wid-con .elementskit-info-image-box.style-sideline .elementskit-box-body {
    padding-left: 0;
    padding-right: 0
}

.ekit-wid-con .elementskit-info-image-box.style-sideline .elementskit-box-body .elementskit-box-footer {
    padding-left: 40px;
    padding-right: 40px
}

.ekit-wid-con .elementskit-info-image-box.shadow-line .elementskit-box-body {
    position: relative
}

.ekit-wid-con .elementskit-info-image-box.shadow-line .elementskit-box-body::after, .ekit-wid-con .elementskit-info-image-box.shadow-line .elementskit-box-body::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    background-color: #fff;
    width: 10px;
    -webkit-box-shadow: -1.216px 6.894px 15px 0 rgba(0, 0, 0, .07);
    box-shadow: -1.216px 6.894px 15px 0 rgba(0, 0, 0, .07)
}

.ekit-wid-con .elementskit-info-image-box.shadow-line .elementskit-box-body::after {
    left: inherit;
    right: 0;
    height: calc(100% + 40px)
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card {
    position: relative;
    overflow: hidden
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card .elementskit-box-content, .ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card .elementskit-hover-content {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(247, 56, 89, .8);
    padding: 45px 40px;
    width: 100%;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card .elementskit-info-box-title {
    color: #fff;
    margin-bottom: 0;
    display: block
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card .elementskit-info-box-title > i {
    font-size: inherit;
    padding-right: 10px;
    top: 0
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card .border-separetor {
    background-color: #fff
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card .elementskit-hover-content {
    opacity: 0;
    bottom: -40%;
    visibility: hidden
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card .elementskit-hover-content p {
    margin-top: 10px;
    color: #fff
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card:hover .elementskit-info-box-title {
    color: #fff
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card:hover .elementskit-hover-content {
    opacity: 1;
    bottom: 0;
    visibility: visible;
    -webkit-transition: all .4s ease .1s;
    transition: all .4s ease .1s
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card:hover .elementskit-box-content {
    opacity: 0;
    bottom: -40%;
    visibility: hidden;
    -webkit-transition: all .4s ease .1s;
    transition: all .4s ease .1s
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card.color-1 .elementskit-box-content, .ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card.color-1 .elementskit-hover-content {
    background-color: rgba(0, 228, 154, .8)
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card.color-2 .elementskit-box-content, .ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card.color-2 .elementskit-hover-content {
    background-color: rgba(106, 17, 203, .8)
}

.ekit-wid-con .elementskit-info-image-box.image-card-2 {
    position: relative
}

.ekit-wid-con .elementskit-info-image-box.image-card-2.style-shadow .elementskit-box-body {
    -webkit-box-shadow: -1.216px 6.894px 15px 0 rgba(0, 0, 0, .07);
    box-shadow: -1.216px 6.894px 15px 0 rgba(0, 0, 0, .07)
}

.ekit-wid-con .elementskit-info-image-box.image-card-2 .elementskit-box-body {
    padding: 40px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-info-image-box.image-card-2 .elementskit-info-box-title {
    margin-bottom: 0
}

.ekit-wid-con .elementskit-info-image-box.image-card-2 .elementskit-info-box-title > i {
    font-size: inherit;
    padding-right: 10px;
    top: 0
}

.ekit-wid-con .elementskit-info-image-box.image-card-2 .elementskit-box-content > p {
    margin-top: 10px
}

.ekit-wid-con .elementskit-info-image-box.image-card-2 .elementskit-box-footer {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    margin-bottom: -60px
}

.ekit-wid-con .elementskit-info-image-box.image-card-2:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.ekit-wid-con .elementskit-info-image-box.image-card-2:hover .elementskit-box-body {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px)
}

.ekit-wid-con .elementskit-info-image-box.image-card-2:hover .elementskit-box-footer {
    opacity: 1;
    visibility: visible;
    margin-bottom: 0
}

.ekit-wid-con .elementskit-info-image-box.parallax-card {
    overflow: initial
}

.ekit-wid-con .elementskit-info-image-box.elementskit-thumb-card {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .image-box-img-center img {
    margin: 0 auto
}

.ekit-wid-con .image-box-img-left img {
    margin-right: auto
}

.ekit-wid-con .image-box-img-right img {
    margin-left: auto
}

.ekit-image-box.floating-style .ekit-image-box-body::after, .ekit-image-box.floating-style .ekit-image-box-body::before {
    -webkit-box-shadow: -1.216px 6.894px 25px 0 rgba(0, 0, 0, .07);
    box-shadow: -1.216px 6.894px 25px 0 rgba(0, 0, 0, .07)
}

.ekit-image-box.style-sideline .ekit-image-box-body-inner {
    padding-left: 40px;
    padding-right: 40px;
    border-style: solid;
    border-color: #2575fc;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
    -webkit-transition: border-color .3s;
    transition: border-color .3s
}

.ekit-image-box.style-sideline:not(.text-right) .ekit-image-box-body-inner {
    border-right-width: 0 !important
}

.ekit-image-box.style-sideline.text-right .ekit-image-box-body-inner {
    border-left-width: 0 !important
}

.ekit-equal-height-enable .ekit-wid-con .elementskit-info-image-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.ekit-equal-height-enable .ekit-wid-con .ekit-image-box-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.ekit-wid-con .skill-medium .skill-bar {
    height: 10px
}

.ekit-wid-con .skill-medium.skill-radius .skill-bar, .ekit-wid-con .skill-medium.skill-radius .skill-track {
    border-radius: 5px
}

.ekit-wid-con .skill-big .skill-bar {
    height: 35px
}

.ekit-wid-con .skill-big.skill-radius .skill-bar, .ekit-wid-con .skill-big.skill-radius .skill-track {
    border-radius: 17.5px
}

.ekit-wid-con .single-skill-bar:not(:last-child) {
    margin-bottom: 25px
}

.ekit-wid-con .single-skill-bar.color-1 .skill-bar .skill-track {
    background-color: #ff3984
}

.ekit-wid-con .single-skill-bar.color-2 .skill-bar .skill-track {
    background-color: #cc24fc
}

.ekit-wid-con .single-skill-bar.color-3 .skill-bar .skill-track {
    background-color: #00e49a
}

.ekit-wid-con .single-skill-bar.color-4 .skill-bar .skill-track {
    background-color: #4285f4
}

.ekit-wid-con .single-skill-bar.color-5 .skill-bar .skill-track {
    background-color: #7ed500
}

.ekit-wid-con .single-skill-bar.color-6 .skill-bar .skill-track {
    background-color: #f96933
}

.ekit-wid-con .single-skill-bar.color-7 .skill-bar .skill-track {
    background-color: #53f8a2
}

.ekit-wid-con .single-skill-bar.color-8 .skill-bar .skill-track {
    background-color: #fe8f75
}

.ekit-wid-con .single-skill-bar.color-9 .skill-bar .skill-track {
    background-color: #35ed7e
}

.ekit-wid-con .single-skill-bar.color-10 .skill-bar .skill-track {
    background-color: #9460ff
}

.ekit-wid-con .skill-bar {
    height: 3px;
    background-color: #f5f5f5
}

.ekit-wid-con .skill-bar .skill-track {
    width: 0;
    background-color: #333;
    display: block;
    height: 100%;
    overflow: visible !important;
    position: relative
}

.ekit-wid-con .skill-bar .skill-track .number-percentage-wraper {
    position: absolute;
    right: 0;
    top: -33px;
    z-index: 1
}

.ekit-wid-con .skill-bar .skill-track .fa, .ekit-wid-con .skill-bar .skill-track .icon, .ekit-wid-con .skill-bar .skill-track .skill-track-icon {
    position: absolute;
    right: 19px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #000
}

.ekit-wid-con .skill-bar .skill-track .fa + .number-percentage-wraper, .ekit-wid-con .skill-bar .skill-track .icon + .number-percentage-wraper, .ekit-wid-con .skill-bar .skill-track .skill-track-icon + .number-percentage-wraper {
    padding-right: 57px
}

.ekit-wid-con .skill-bar .skill-track .fa svg, .ekit-wid-con .skill-bar .skill-track .icon svg, .ekit-wid-con .skill-bar .skill-track .skill-track-icon svg {
    max-width: 15px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .skill-bar-content {
    margin-bottom: 7px
}

.ekit-wid-con .skill-bar-content .skill-title {
    font-weight: 700;
    color: #000
}

.ekit-wid-con .number-percentage-wraper {
    color: #000
}

.ekit-wid-con .number-percentage-wraper.style2 {
    font-size: 13px;
    font-weight: 700
}

.ekit-wid-con .inner-content .single-skill-bar {
    position: relative
}

.ekit-wid-con .inner-content .single-skill-bar .skill-bar-content {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-bottom: 0;
    padding-left: 30px
}

.ekit-wid-con .inner-content .single-skill-bar .skill-title {
    color: #fff
}

.ekit-wid-con .inner-content .skill-track .number-percentage-wraper {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    padding-right: 30px
}

.ekit-wid-con .inner-content .skill-track .fa, .ekit-wid-con .inner-content .skill-track .icon {
    color: #fff
}

.ekit-wid-con .skilltrack-style2 .single-skill-bar .skill-bar {
    height: 30px;
    background-color: #fff;
    padding: 10px;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .25);
    border-radius: 15px
}

.ekit-wid-con .skilltrack-style2 .single-skill-bar .skill-bar .skill-track {
    height: 10px;
    border-radius: 5px
}

.ekit-wid-con .skilltrack-style2 .single-skill-bar .skill-bar .skill-track .number-percentage-wraper {
    top: -39px
}

.ekit-wid-con .tooltip-style .single-skill-bar .number-percentage-wraper {
    color: #fff;
    height: 30px;
    padding: 0 10px;
    z-index: 1;
    text-align: center;
    right: calc(0% - 22px);
    top: -30px
}

.ekit-wid-con .tooltip-style .single-skill-bar .number-percentage-wraper .svg-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    fill: #333;
    height: 100%;
    width: 100%
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-1 .svg-content > svg {
    fill: #ff3984
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-2 .svg-content > svg {
    fill: #cc24fc
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-3 .svg-content > svg {
    fill: #00e49a
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-4 .svg-content > svg {
    fill: #4285f4
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-5 .svg-content > svg {
    fill: #7ed500
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-6 .svg-content > svg {
    fill: #f96933
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-7 .svg-content > svg {
    fill: #53f8a2
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-8 .svg-content > svg {
    fill: #fe8f75
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-9 .svg-content > svg {
    fill: #35ed7e
}

.ekit-wid-con .tooltip-style .single-skill-bar.color-10 .svg-content > svg {
    fill: #9460ff
}

.ekit-wid-con .pin-style .single-skill-bar .number-percentage-wraper {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 13px;
    right: calc(0% - 15.5px);
    top: -39.9px
}

.ekit-wid-con .pin-style .single-skill-bar .number-percentage-wraper::before {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 3px;
    height: 5px;
    background-color: #333
}

.ekit-wid-con .pin-style .single-skill-bar.color-1 .number-percentage-wraper {
    background-color: #ff3984
}

.ekit-wid-con .pin-style .single-skill-bar.color-1 .number-percentage-wraper::before {
    background-color: #ff3984
}

.ekit-wid-con .pin-style .single-skill-bar.color-2 .number-percentage-wraper {
    background-color: #cc24fc
}

.ekit-wid-con .pin-style .single-skill-bar.color-2 .number-percentage-wraper::before {
    background-color: #cc24fc
}

.ekit-wid-con .pin-style .single-skill-bar.color-3 .number-percentage-wraper {
    background-color: #00e49a
}

.ekit-wid-con .pin-style .single-skill-bar.color-3 .number-percentage-wraper::before {
    background-color: #00e49a
}

.ekit-wid-con .pin-style .single-skill-bar.color-4 .number-percentage-wraper {
    background-color: #4285f4
}

.ekit-wid-con .pin-style .single-skill-bar.color-4 .number-percentage-wraper::before {
    background-color: #4285f4
}

.ekit-wid-con .pin-style .single-skill-bar.color-5 .number-percentage-wraper {
    background-color: #7ed500
}

.ekit-wid-con .pin-style .single-skill-bar.color-5 .number-percentage-wraper::before {
    background-color: #7ed500
}

.ekit-wid-con .pin-style .single-skill-bar.color-6 .number-percentage-wraper {
    background-color: #f96933
}

.ekit-wid-con .pin-style .single-skill-bar.color-6 .number-percentage-wraper::before {
    background-color: #f96933
}

.ekit-wid-con .pin-style .single-skill-bar.color-7 .number-percentage-wraper {
    background-color: #53f8a2
}

.ekit-wid-con .pin-style .single-skill-bar.color-7 .number-percentage-wraper::before {
    background-color: #53f8a2
}

.ekit-wid-con .pin-style .single-skill-bar.color-8 .number-percentage-wraper {
    background-color: #fe8f75
}

.ekit-wid-con .pin-style .single-skill-bar.color-8 .number-percentage-wraper::before {
    background-color: #fe8f75
}

.ekit-wid-con .pin-style .single-skill-bar.color-9 .number-percentage-wraper {
    background-color: #35ed7e
}

.ekit-wid-con .pin-style .single-skill-bar.color-9 .number-percentage-wraper::before {
    background-color: #35ed7e
}

.ekit-wid-con .pin-style .single-skill-bar.color-10 .number-percentage-wraper {
    background-color: #9460ff
}

.ekit-wid-con .pin-style .single-skill-bar.color-10 .number-percentage-wraper::before {
    background-color: #9460ff
}

.ekit-wid-con .tooltip-style2 .single-skill-bar .number-percentage-wraper {
    height: 22px;
    padding: 0 8px;
    background-color: #333;
    text-align: center;
    font-size: 13px;
    color: #fff;
    z-index: 1;
    right: -20px;
    top: -31px
}

.ekit-wid-con .tooltip-style2 .single-skill-bar .number-percentage-wraper::before {
    position: absolute;
    content: "";
    bottom: -6px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    width: 14px;
    height: 14px;
    background-color: inherit;
    z-index: -1
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-1 .number-percentage-wraper {
    background-color: #ff3984
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-2 .number-percentage-wraper {
    background-color: #cc24fc
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-3 .number-percentage-wraper {
    background-color: #00e49a
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-4 .number-percentage-wraper {
    background-color: #4285f4
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-5 .number-percentage-wraper {
    background-color: #7ed500
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-6 .number-percentage-wraper {
    background-color: #f96933
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-7 .number-percentage-wraper {
    background-color: #53f8a2
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-8 .number-percentage-wraper {
    background-color: #fe8f75
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-9 .number-percentage-wraper {
    background-color: #35ed7e
}

.ekit-wid-con .tooltip-style2 .single-skill-bar.color-10 .number-percentage-wraper {
    background-color: #9460ff
}

.ekit-wid-con .tooltip-style3 .single-skill-bar .number-percentage-wraper {
    height: 24px;
    line-height: 24px;
    display: inline-block;
    color: #fff;
    font-size: 13px;
    background-color: #333;
    text-align: center;
    padding: 0 11px;
    border-radius: 4.32px 4.32px 0 4.32px;
}

.ekit-wid-con .tooltip-style3 .single-skill-bar .number-percentage-wraper::before {
    position: absolute;
    content: "";
    right: 0;
    bottom: -8px;
    color: #333;
    border-top: solid currentColor;
    border-right: solid currentColor;
    border-left: solid transparent;
    border-bottom: solid transparent;
    border-width: 4px
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-1 .number-percentage-wraper {
    background-color: #ff3984
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-1 .number-percentage-wraper::before {
    color: #ff3984
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-2 .number-percentage-wraper {
    background-color: #cc24fc
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-2 .number-percentage-wraper::before {
    color: #cc24fc
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-3 .number-percentage-wraper {
    background-color: #00e49a
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-3 .number-percentage-wraper::before {
    color: #00e49a
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-4 .number-percentage-wraper {
    background-color: #4285f4
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-4 .number-percentage-wraper::before {
    color: #4285f4
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-5 .number-percentage-wraper {
    background-color: #7ed500
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-5 .number-percentage-wraper::before {
    color: #7ed500
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-6 .number-percentage-wraper {
    background-color: #f96933
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-6 .number-percentage-wraper::before {
    color: #f96933
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-7 .number-percentage-wraper {
    background-color: #53f8a2
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-7 .number-percentage-wraper::before {
    color: #53f8a2
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-8 .number-percentage-wraper {
    background-color: #fe8f75
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-8 .number-percentage-wraper::before {
    color: #fe8f75
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-9 .number-percentage-wraper {
    background-color: #35ed7e
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-9 .number-percentage-wraper::before {
    color: #35ed7e
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-10 .number-percentage-wraper {
    background-color: #9460ff
}

.ekit-wid-con .tooltip-style3 .single-skill-bar.color-10 .number-percentage-wraper::before {
    color: #9460ff
}

.ekit-wid-con .style-switch .single-skill-bar .skill-bar-content {
    margin-bottom: 18px
}

.ekit-wid-con .style-switch .single-skill-bar .content-group {
    width: calc(100% - 50px);
    display: inline-block
}

.ekit-wid-con .style-switch .single-skill-bar .number-percentage-wraper {
    float: right;
    position: relative;
    top: 31px
}

.ekit-wid-con .style-switch .single-skill-bar .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar .skill-track::before {
    position: absolute;
    content: "";
    right: 0;
    border-radius: 50%;
    color: #333
}

.ekit-wid-con .style-switch .single-skill-bar .skill-track::before {
    top: -12.5px;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border: 1px solid currentColor;
    -webkit-box-shadow: -1.216px 6.894px 15px 0 rgba(0, 0, 0, .07), inset 0 2px 3px 0 rgba(0, 0, 0, .2);
    box-shadow: -1.216px 6.894px 15px 0 rgba(0, 0, 0, .07), inset 0 2px 3px 0 rgba(0, 0, 0, .2)
}

.ekit-wid-con .style-switch .single-skill-bar .skill-track::after {
    width: 8px;
    height: 8px;
    background-color: currentColor;
    top: -3px;
    right: 8px
}

.ekit-wid-con .style-switch .single-skill-bar.color-1 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-1 .skill-track::before {
    color: #ff3984
}

.ekit-wid-con .style-switch .single-skill-bar.color-2 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-2 .skill-track::before {
    color: #cc24fc
}

.ekit-wid-con .style-switch .single-skill-bar.color-3 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-3 .skill-track::before {
    color: #00e49a
}

.ekit-wid-con .style-switch .single-skill-bar.color-4 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-4 .skill-track::before {
    color: #4285f4
}

.ekit-wid-con .style-switch .single-skill-bar.color-5 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-5 .skill-track::before {
    color: #7ed500
}

.ekit-wid-con .style-switch .single-skill-bar.color-6 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-6 .skill-track::before {
    color: #f96933
}

.ekit-wid-con .style-switch .single-skill-bar.color-7 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-7 .skill-track::before {
    color: #53f8a2
}

.ekit-wid-con .style-switch .single-skill-bar.color-8 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-8 .skill-track::before {
    color: #fe8f75
}

.ekit-wid-con .style-switch .single-skill-bar.color-9 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-9 .skill-track::before {
    color: #35ed7e
}

.ekit-wid-con .style-switch .single-skill-bar.color-10 .skill-track::after, .ekit-wid-con .style-switch .single-skill-bar.color-10 .skill-track::before {
    color: #9460ff
}

.ekit-wid-con .style-stripe .single-skill-bar .skill-track {
    background: repeating-linear-gradient(to right, #333, #333 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-1 .skill-track {
    background: repeating-linear-gradient(to right, #ff3984, #ff3984 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-2 .skill-track {
    background: repeating-linear-gradient(to right, #cc24fc, #cc24fc 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-3 .skill-track {
    background: repeating-linear-gradient(to right, #00e49a, #00e49a 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-4 .skill-track {
    background: repeating-linear-gradient(to right, #4285f4, #4285f4 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-5 .skill-track {
    background: repeating-linear-gradient(to right, #7ed500, #7ed500 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-6 .skill-track {
    background: repeating-linear-gradient(to right, #f96933, #f96933 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-7 .skill-track {
    background: repeating-linear-gradient(to right, #53f8a2, #53f8a2 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-8 .skill-track {
    background: repeating-linear-gradient(to right, #fe8f75, #fe8f75 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-9 .skill-track {
    background: repeating-linear-gradient(to right, #35ed7e, #35ed7e 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-stripe .single-skill-bar.color-10 .skill-track {
    background: repeating-linear-gradient(to right, #9460ff, #9460ff 4px, #fff 4px, #fff 8px)
}

.ekit-wid-con .style-ribbon .single-skill-bar .number-percentage-wraper {
    height: 35px;
    line-height: 35px;
    color: #fff;
    background-color: #333;
    text-align: center;
    padding: 0 8px;
    top: -10px;
    z-index: 1
}

.ekit-wid-con .style-ribbon .single-skill-bar .number-percentage-wraper::before {
    position: absolute;
    content: "";
    top: 0;
    left: -10px;
    color: #1a1a1a;
    border-top: solid #fff;
    border-left: solid #fff;
    border-right: solid currentColor;
    border-bottom: solid currentColor;
    border-width: 5px
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-1 .number-percentage-wraper {
    background-color: #cc2e6a
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-1 .number-percentage-wraper::before {
    color: #801d42
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-2 .number-percentage-wraper {
    background-color: #a31dca
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-2 .number-percentage-wraper::before {
    color: #66127e
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-3 .number-percentage-wraper {
    background-color: #00b67b
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-3 .number-percentage-wraper::before {
    color: #00724d
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-4 .number-percentage-wraper {
    background-color: #356ac3
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-4 .number-percentage-wraper::before {
    color: #21437a
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-5 .number-percentage-wraper {
    background-color: #65aa00
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-5 .number-percentage-wraper::before {
    color: #3f6b00
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-6 .number-percentage-wraper {
    background-color: #c75429
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-6 .number-percentage-wraper::before {
    color: #7d351a
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-7 .number-percentage-wraper {
    background-color: #42c682
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-7 .number-percentage-wraper::before {
    color: #2a7c51
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-8 .number-percentage-wraper {
    background-color: #cb725e
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-8 .number-percentage-wraper::before {
    color: #7f483b
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-9 .number-percentage-wraper {
    background-color: #2abe65
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-9 .number-percentage-wraper::before {
    color: #1b773f
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-10 .number-percentage-wraper {
    background-color: #764dcc
}

.ekit-wid-con .style-ribbon .single-skill-bar.color-10 .number-percentage-wraper::before {
    color: #4a3080
}

.ekit-wid-con .elementskit-funfact {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center
}

.ekit-wid-con .elementskit-funfact-inner.position_left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .elementskit-funfact-inner.position_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.ekit-wid-con .elementskit-funfact .funfact-icon svg {
    vertical-align: middle;
    max-width: 50px;
    height: auto
}

.ekit-wid-con .elementskit-funfact-overlay {
    z-index: -1
}

.ekit-wid-con .elementskit-funfact .funfact-content {
    line-height: 1
}

.ekit-wid-con .elementskit-funfact .funfact-content .number-percentage-wraper {
    font-size: 28px;
    font-weight: 700;
    color: #333
}

.ekit-wid-con .elementskit-funfact .funfact-content .fa, .ekit-wid-con .elementskit-funfact .funfact-content .icon {
    font-size: 25px;
    padding-right: 5px
}

.ekit-wid-con .elementskit-funfact .elementskit-funfact-icon {
    display: inline-block
}

.ekit-wid-con .elementskit-funfact .funfact-title {
    color: gray;
    font-size: 15px;
    margin: 0;
    font-weight: 400
}

.ekit-wid-con .elementskit-funfact .funfact-title.black-v {
    color: #000
}

.ekit-wid-con .elementskit-funfact .super {
    font-size: 22px;
    color: #2575fc;
    vertical-align: super;
    position: relative;
    top: -5px
}

.ekit-wid-con .elementskit-funfact .color-1 .fa, .ekit-wid-con .elementskit-funfact .color-1 .icon, .ekit-wid-con .elementskit-funfact .color-1 .super {
    color: #9460ff
}

.ekit-wid-con .elementskit-funfact .color-2 .fa, .ekit-wid-con .elementskit-funfact .color-2 .icon, .ekit-wid-con .elementskit-funfact .color-2 .super {
    color: #fc5290
}

.ekit-wid-con .elementskit-funfact .color-3 .fa, .ekit-wid-con .elementskit-funfact .color-3 .icon, .ekit-wid-con .elementskit-funfact .color-3 .super {
    color: #09b2a0
}

.ekit-wid-con .elementskit-funfact .xs-overlay {
    z-index: -1;
    opacity: .7;
    pointer-events: none
}

.ekit-wid-con .elementskit-funfact.divider_funfact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-wid-con .elementskit-funfact.divider_funfact .elementskit-funfact-inner {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.ekit-wid-con .elementskit-funfact.divider_funfact.position_center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .elementskit-funfact.divider_funfact.position_bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.ekit-wid-con .style-border-bottom.elementskit-funfact::before {
    position: absolute;
    content: "";
    bottom: -15px;
    left: 0;
    height: 5px;
    width: 100%;
    z-index: 1;
    background-color: #53f995;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease
}

.ekit-wid-con .style-border-bottom.elementskit-funfact:hover::before {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%
}

.ekit-wid-con .style-border-bottom.elementskit-funfact.hover_from_left::before {
    -webkit-transform-origin: left center;
    transform-origin: left center
}

.ekit-wid-con .style-border-bottom.elementskit-funfact.hover_from_left:hover::before {
    -webkit-transform-origin: right center;
    transform-origin: right center
}

.ekit-wid-con .vertical-bar {
    width: 3px;
    height: 20px;
    background-color: #2575fc;
    display: inline-block;
    float: left
}

.ekit-wid-con .vertical-bar.border_left_side {
    float: right
}

.ekit-wid-con .elementskit-funfact.gradient-1 {
    background: #f2709c linear-gradient(-35deg, #f2709c 0, #fe8f75 100%);
}

.ekit-wid-con .elementskit-funfact.gradient-2 {
    background: #b18cfe linear-gradient(-35deg, #b18cfe 0, #9460ff 100%);
}

.ekit-wid-con .elementskit-funfact.gradient-3 {
    background: #82e182 linear-gradient(-35deg, #82e182 0, #0acbf5 100%);
}

.ekit-wid-con .elementskit-funfact.gradient-4 {
    background: #07b787 linear-gradient(-35deg, #07b787 0, #7ed500 100%);
}

.ekit-wid-con .elementskit-funfact.divider_funfact.text-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.ekit-wid-con .elementskit-funfact.divider_funfact.text-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.ekit-wid-con .ekit-single-piechart .piechart {
    position: relative;
    display: inline-block
}

.ekit-wid-con .ekit-single-piechart .piechart .ekit-chart-content {
    position: absolute;
    top: 47%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 1;
    display: inline-block
}

.ekit-wid-con .ekit-single-piechart .piechart .ekit-chart-content svg {
    max-width: 28px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .flip-card {
    position: relative;
    max-height: 280px;
    overflow: hidden;
    color: #fff
}

.ekit-wid-con .flip-card .back {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 15px 50px;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    background: #f2709c;
    background: linear-gradient(-35deg, #f2709c 0, #fe8f75 100%)
}

.ekit-wid-con .flip-card .back, .ekit-wid-con .flip-card .front {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ekit-wid-con .flip-card:hover .back {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible
}

.ekit-wid-con .flip-card:hover .front {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden
}

.ekit-wid-con .flip-card .ekit-piechart-title {
    color: #fff
}

.ekit-wid-con .elementskit-single-faq {
    position: relative;
    overflow: hidden
}

.ekit-wid-con .elementskit-single-faq .elementskit-faq-title {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0
}

.ekit-wid-con .elementskit-single-faq .elementskit-faq-header {
    background-color: #f5f5f5;
    padding-top: 21px;
    padding-bottom: 21px
}

.ekit-wid-con .elementskit-image-comparison {
    z-index: 3
}

.ekit-wid-con .elementskit-image-comparison > img {
    width: 100%
}

.ekit-wid-con .elementskit-image-comparison .twentytwenty-handle {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
    border-color: transparent;
    -webkit-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s
}

.ekit-wid-con .elementskit-image-comparison .twentytwenty-handle::before {
    -webkit-box-shadow: none;
    box-shadow: none
}

.ekit-wid-con .elementskit-image-comparison .twentytwenty-handle::after {
    -webkit-box-shadow: none;
    box-shadow: none
}

.ekit-wid-con .elementskit-image-comparison .twentytwenty-handle .twentytwenty-left-arrow {
    border-right-color: #000
}

.ekit-wid-con .elementskit-image-comparison .twentytwenty-handle .twentytwenty-right-arrow {
    border-left-color: #000
}

.ekit-wid-con .elementskit-countdown-timer {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.ekit-wid-con .elementskit-countdown-timer .elementskit-timer-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%
}

.ekit-wid-con .elementskit-countdown-timer .elementskit-inner-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #443aca;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .elementskit-countdown-timer .elementskit-timer-content {
    width: 100%
}

.ekit-wid-con .elementskit-countdown-timer .elementskit-timer-content > span {
    display: block;
    color: #fff;
    line-height: 1
}

.ekit-wid-con .elementskit-countdown-timer .elementskit-timer-count {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 13px
}

.ekit-wid-con .elementskit-countdown-timer .elementskit-timer-title {
    font-size: 14px;
    letter-spacing: 2.5px
}

.ekit-wid-con .elementskit-countdown-timer-2 {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.ekit-wid-con .elementskit-countdown-timer-2 .elementskit-timer-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    padding: 0 15px
}

.ekit-wid-con .elementskit-countdown-timer-2 .elementskit-timer-count {
    background-color: #2575fc;
    display: block;
    height: 107px;
    line-height: 87px;
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    border-radius: 10px
}

.ekit-wid-con .elementskit-countdown-timer-2 .elementskit-timer-title {
    display: block;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    border: 2px solid #2575fc;
    font-size: 14px;
    letter-spacing: 2.5px;
    font-weight: 700;
    background-color: #fff;
    max-width: 80%;
    margin: -20px auto 0;
    position: relative;
    z-index: 1
}

.ekit-wid-con .elementskit-countdown-timer-3 {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.ekit-wid-con .elementskit-countdown-timer-3 .elementskit-timer-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    padding: 0 3px
}

.ekit-wid-con .elementskit-countdown-timer-3 .elementskit-timer-count {
    height: 190px;
    line-height: 190px;
    color: #333;
    display: block;
    font-size: 48px;
    font-weight: 700;
    position: relative;
    background-color: #fff;
    z-index: 1
}

.ekit-wid-con .elementskit-countdown-timer-3 .elementskit-timer-count::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(-223deg, #fff 0, #fff 50%, #eaeaea 50%, #e7e7e7 100%)
}

.ekit-wid-con .elementskit-countdown-timer-3 .elementskit-timer-title {
    display: block;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    letter-spacing: 2.5px;
    font-weight: 700;
    position: relative;
    z-index: 1;
    background-color: #1cf196;
    color: #333
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-timer-content {
    background-color: #5558ed;
    -webkit-box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    height: 190px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 30px 0
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-inner-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-inner-container > span {
    color: #fff;
    background-color: transparent;
    height: auto;
    line-height: normal
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-timer-count {
    margin-bottom: 10px
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-timer-count::before {
    display: none
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-timer-container {
    padding: 0 5px
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-timer-container:nth-child(2) .elementskit-timer-content {
    height: 240px;
    background-color: #ffcc03
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-timer-container:nth-child(3) .elementskit-timer-content {
    background-color: #ff5657
}

.ekit-wid-con .elementskit-countdown-timer-3.elementskit-version-box .elementskit-timer-container:nth-child(4) .elementskit-timer-content {
    height: 150px;
    background-color: #09b2a0
}

.ekit-wid-con .elementskit-countdown-container {
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    border-radius: 10px;
    background-size: cover;
    margin-bottom: 62px;
    padding-top: 30px;
    padding-bottom: 60px
}

.ekit-wid-con .elementskit-countdown-container::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: .7;
    border-radius: inherit;
    pointer-events: none
}

.ekit-wid-con .elementskit-countdown-container::after {
    position: absolute;
    content: "";
    bottom: -62px;
    left: 0;
    width: 100%;
    height: 49px;
    background-repeat: no-repeat;
    background-position: center center;
    pointer-events: none;
    background-size: contain
}

.ekit-wid-con .elementskit-countdown-timer-4 {
    position: relative;
    z-index: 2;
    height: 120px;
    line-height: 120px;
    background-color: #fd3358;
    background-size: 101% 101%;
    width: calc(100% + 60px);
    margin-left: -30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.ekit-wid-con .elementskit-countdown-timer-4::after, .ekit-wid-con .elementskit-countdown-timer-4::before {
    position: absolute;
    content: "";
    bottom: -20px;
    pointer-events: none
}

.ekit-wid-con .elementskit-countdown-timer-4::before {
    left: 0;
    border-top: 20px solid #993535;
    border-left: 30px solid transparent
}

.ekit-wid-con .elementskit-countdown-timer-4::after {
    right: 0;
    border-bottom: 20px solid transparent;
    border-left: 30px solid #993535
}

.ekit-wid-con .elementskit-countdown-timer-4 .elementskit-timer-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    color: #fff;
    height: 100%
}

.ekit-wid-con .elementskit-countdown-timer-4 .elementskit-timer-count {
    font-size: 48px;
    font-weight: 700
}

.ekit-wid-con .elementskit-countdown-timer-4 .elementskit-timer-title {
    font-size: 18px;
    vertical-align: top
}

.ekit-wid-con .elementskit-coundown-finish {
    height: auto !important;
    line-height: normal !important;
    padding: 15px
}

.ekit-wid-con .ekit-countdown-inner {
    margin: 0 auto
}

.ekit-wid-con .elementskit-flip-clock > .elementskit-time {
    border-radius: 0 0 30px 30px;
    -webkit-box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 28px 40px 0 rgba(0, 0, 0, .1);
    display: inline-block;
    position: relative;
    height: 140px;
    width: 150px;
    -webkit-perspective: 500px;
    perspective: 500px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.ekit-wid-con .elementskit-flip-clock > .elementskit-time:not(:last-child) {
    margin-right: 30px
}

.ekit-wid-con .elementskit-flip-clock .elementskit-count {
    color: #333;
    display: block;
    font-size: 48px;
    font-weight: 700;
    background-color: #fff;
    overflow: hidden;
    line-height: 140px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform-style: flat;
    transform-style: flat
}

.ekit-wid-con .elementskit-flip-clock .elementskit-count.elementskit-top {
    border-radius: 30px 30px 0 0;
    height: 50%;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%
}

.ekit-wid-con .elementskit-flip-clock .elementskit-count.elementskit-bottom {
    border-radius: 0 0 30px 30px;
    line-height: 0;
    height: 50%;
    top: 50%;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0
}

.ekit-wid-con .elementskit-flip-clock .elementskit-count.elementskit-curr.elementskit-top {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    z-index: 3;
    border-bottom: 3px solid #e7e7e7
}

.ekit-wid-con .elementskit-flip-clock .elementskit-count.elementskit-next.elementskit-bottom {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    z-index: 2
}

.ekit-wid-con .elementskit-flip-clock .elementskit-count.elementskit-next.elementskit-top {
    border-bottom: 3px solid #e7e7e7
}

.ekit-wid-con .elementskit-flip-clock .elementskit-flip .elementskit-count.elementskit-curr.elementskit-top {
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg)
}

.ekit-wid-con .elementskit-flip-clock .elementskit-flip .elementskit-count.elementskit-next.elementskit-bottom {
    -webkit-transition: all 250ms ease-in-out 250ms;
    transition: all 250ms ease-in-out 250ms;
    -webkit-transform: rotateX(0);
    transform: rotateX(0)
}

.ekit-wid-con .elementskit-flip-clock .elementskit-label {
    background-color: #ff5657;
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    -webkit-box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, .35);
    box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, .35);
    color: #fff;
    font-size: 14px;
    border-radius: 30px 30px 0 0;
    font-weight: 700;
    letter-spacing: .5px;
    top: -20px
}

.ekit-wid-con .elementskit-pricing-tag {
    position: absolute;
    height: 100%;
    width: calc(100% + 30px);
    background-color: #666;
    top: 0;
    right: -30px;
    border-radius: 48px 0 0 48px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-pricing-tag + .elementskit-pricing-price {
    color: #fff
}

.ekit-wid-con .elementskit-single-pricing {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1
}

.ekit-wid-con .elementskit-single-pricing.flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.ekit-wid-con .elementskit-single-pricing .pricing-bg {
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1;
    height: 100%;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    opacity: 0;
    visibility: hidden
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-title {
    font-size: 22px;
    margin-bottom: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-subtitle {
    color: #5a5a5a;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-header {
    margin-bottom: 25px
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-header svg {
    max-width: 40px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-header > i {
    font-size: 40px;
    margin-bottom: 12px
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-header > p {
    margin-top: 7px
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-header > hr {
    border-top: 2px solid #fff;
    width: 30px
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-header > img {
    margin-bottom: 45px
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-price-wraper {
    position: relative;
    margin-bottom: 15px;
    line-height: initial
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-price {
    position: relative;
    z-index: 2;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -.4px;
    display: block;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-price > sub, .ekit-wid-con .elementskit-single-pricing .elementskit-pricing-price > sup {
    font-size: 18px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-price > sup {
    top: 0;
    left: 5px
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-price > sub {
    bottom: 0
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-content {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-content > p {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-btn {
    color: #fff;
    background-color: #666;
    border-radius: 25px;
    border-color: currentColor;
    font-size: 14px;
    font-weight: 700;
    padding: 11px 41px;
    display: inline-block;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-btn.style-2 {
    border-color: #e7e7e7;
    color: #000
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-btn svg {
    max-width: 14px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-single-pricing .btn-outline-primary {
    color: #2575fc
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-lists > li {
    color: #565656;
    font-size: 15px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-lists > li::before {
    content: "";
    display: block
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-lists > li:not(:last-child) {
    margin-bottom: 3px
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-lists > li svg {
    max-width: 10px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-single-pricing .elementskit-pricing-lists > li > i {
    color: #55b559;
    padding-right: 10px;
    font-size: 10px
}

.ekit-wid-con .elementskit-single-pricing .title-divider {
    position: relative
}

.ekit-wid-con .elementskit-single-pricing .title-divider::before {
    position: absolute;
    content: "";
    top: 50%;
    left: -30px;
    width: calc(100% + 60px);
    height: 1px;
    background-color: #e7e7e7
}

.ekit-wid-con .elementskit-single-pricing .title-divider .elementskit-pricing-title {
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0 45px;
    background-color: #fff
}

.ekit-wid-con .elementkit-pricing-icon {
    display: inline-block
}

.ekit-wid-con .elementor-widget-elementskit-pricing {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementor-widget-elementskit-pricing .elementor-widget-container {
    padding: 30px
}

.ekit-pricing-list-info {
    display: inline-block;
    position: relative;
    color: #afb1b9;
    background-color: currentColor;
    border-radius: 50%;
    font-size: 15px;
    font-style: normal;
    -webkit-transition: color .3s;
    transition: color .3s;
    z-index: 0;
    cursor: help
}

.ekit-pricing-list-info:hover {
    color: #81848d
}

.ekit-pricing-list-info:before {
    color: #fff
}

.ekit-pricing-list-info:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid currentColor;
    border-radius: inherit;
    z-index: 1
}

.ekit-pricing-list-info > span {
    display: none;
    position: absolute;
    top: -13px;
    left: 0;
    border-style: solid;
    border-width: 7px;
    border-color: #021343 transparent transparent;
    z-index: 1
}

.ekit-pricing-list-info.is-active > span {
    display: block
}

.ekit-pricing-list-info-content {
    position: absolute;
    left: 0;
    bottom: calc(100% + 13px);
    max-width: 280px;
    margin: 0;
    padding: 11px 20px 13px;
    color: #fff;
    background-color: #021343;
    border-radius: 6px;
    -webkit-box-shadow: 0 20px 20px rgba(0, 10, 38, .16);
    box-shadow: 0 20px 20px rgba(0, 10, 38, .16);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    z-index: 999
}

.ekit-pricing-list-info > .ekit-pricing-list-info-content {
    display: none;
    position: fixed
}

.ekit-wid-con .elementskit-single-testimonial-slider {
    background-color: #fff;
    -webkit-box-shadow: 9px 11px 14px 0 rgba(0, 0, 0, .1);
    box-shadow: 9px 11px 14px 0 rgba(0, 0, 0, .1);
    padding: 30px 30px 30px 60px;
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: 1
}

.ekit-wid-con .elementskit-single-testimonial-slider .elementskit-commentor-content > p {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-testimonial-slider:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: -1
}

.ekit-testimonial-fade .ekit-wid-con .elementskit-single-testimonial-slider:before {
    opacity: 0;
    -webkit-transform: none;
    transform: none
}

.ekit-testimonial-fade .ekit-wid-con .elementskit-single-testimonial-slider:hover:before {
    opacity: 1
}

.ekit-wid-con .elementskit-single-testimonial-slider:hover::before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1)
}

.ekit-wid-con .elementskit-single-testimonial-slider:hover .elementskit-client_logo .elementskit-testimonial-client-active-logo {
    opacity: 1
}

.ekit-wid-con .elementskit-single-testimonial-slider:hover .elementskit-client_logo .elementskit-testimonial-client-active-logo + .elementskit-testimonial-client-logo {
    opacity: 0
}

.ekit-wid-con .elementskit-single-testimonial-slider .tilte {
    font-size: 22px;
    margin-bottom: 22px
}

.ekit-wid-con .elementskit-single-testimonial-slider .ekit-team-social-list > li > a {
    font-size: 16px
}

.ekit-wid-con .elementskit-single-testimonial-slider.gradient {
    background: #f2709c;
    background: linear-gradient(45deg, #f2709c 0, #fe8f75 100%)
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style {
    padding: 55px 40px;
    -webkit-transition: border-bottom-color .5s ease;
    transition: border-bottom-color .5s ease
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style .elementskit-commentor-content {
    padding-right: 0
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style .elementskit-commentor-content > hr {
    width: 30px;
    border-top: 3px solid #e7e7e7;
    -webkit-transition: border-top-color .5s ease;
    transition: border-top-color .5s ease
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style2 {
    border-radius: 10px;
    position: relative;
    padding: 40px 40px 80px;
    margin-bottom: 105px
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style2:not(.white-v) .elementskit-commentor-content > i {
    color: rgba(37, 117, 252, .2)
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style2 .elementskit-commentor-bio {
    position: absolute;
    top: calc(100% - 35px);
    width: 100%;
    left: 0
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style3 {
    border-radius: 10px
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style3 .elementskit-commentor-content {
    margin-bottom: 30px
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style4 {
    overflow: hidden;
    border-radius: 10px;
    position: relative
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style4 .elementskit-watermark-icon {
    left: inherit;
    right: 20px;
    top: 20px
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style4 .elementskit-watermark-icon .quote {
    z-index: 1;
    position: relative;
    font-size: 28px
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style4 .elementskit-commentor-image {
    margin-bottom: 30px
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style4 .elementskit-commentor-bio {
    margin-bottom: 15px
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style4 .elementskit-stars {
    margin-bottom: 25px
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style4.white-v .elementskit-watermark-icon {
    line-height: 0
}

.ekit-wid-con .elementskit-single-testimonial-slider.block-style4.white-v .elementskit-profile-info .elementskit-author-des, .ekit-wid-con .elementskit-single-testimonial-slider.block-style4.white-v .elementskit-profile-info .elementskit-author-name {
    color: #fff
}

.ekit-wid-con .elementskit-single-testimonial-slider .commentor-header {
    margin-bottom: 15px
}

.ekit-wid-con .elementskit-single-testimonial-slider .quote {
    font-size: 48px
}

.ekit-wid-con .elementskit-single-testimonial-slider:not(.white-v) .quote {
    color: rgba(37, 117, 252, .2)
}

.ekit-wid-con .elementskit-single-testimonial-slider.ekit_testimonial_style_2 .elementskit-watermark-icon {
    position: absolute;
    top: 10px;
    left: 10px
}

.ekit-wid-con .elementskit-single-testimonial-slider .elementskit-watermark-icon {
    position: static;
    color: rgba(0, 0, 0, .1);
    line-height: 1;
    display: inline-block
}

.ekit-wid-con .elementskit-single-testimonial-slider .elementskit-watermark-icon > i {
    font-size: 48px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-single-testimonial-slider .elementskit-watermark-icon > svg {
    width: 48px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit-single-testimonial-slider .elementskit-watermark-icon.commentor-badge {
    line-height: 0
}

.ekit-wid-con .elementskit-single-testimonial-slider .elementskit-watermark-icon.commentor-badge .quote {
    color: #fff
}

.ekit-wid-con .elementskit-single-testimonial-slider .elementskit-watermark-icon.commentor-badge::before {
    position: absolute;
    content: "";
    top: -100px;
    left: -30px;
    background-color: #2575fc;
    width: 160px;
    height: 160px;
    border-radius: 50%
}

.ekit-wid-con .elementskit-single-testimonial-slider .main-logo {
    display: none
}

.ekit-wid-con .elementskit-single-testimonial-slider.style-2 {
    padding: 0 0 0 60px;
    border-left: 3px solid #2575fc
}

.ekit-wid-con .elementskit-single-testimonial-slider.style-2 .elementskit-profile-image-card {
    margin-left: 20px
}

.ekit-wid-con .elementskit-single-testimonial-slider.version-2 {
    background: #f2709c;
    background: linear-gradient(-35deg, #f2709c 0, #fe8f75 100%);
    padding: 40px 50px 80px;
    border-radius: 10px;
    position: relative
}

.ekit-wid-con .elementskit-single-testimonial-slider.version-2 .elementskit-commentor-content {
    padding-right: 0
}

.ekit-wid-con .elementskit-single-testimonial-slider.version-2 .elementskit-commentor-content > i {
    color: rgba(255, 255, 255, .5)
}

.ekit-wid-con .elementskit-single-testimonial-slider.version-2 .elementskit-commentor-content > p {
    margin-bottom: 25px
}

.ekit-wid-con .elementskit-single-testimonial-slider.version-2 .elementskit-commentor-bio {
    position: absolute;
    top: calc(100% - 45px);
    left: 0;
    width: 100%
}

.ekit-wid-con .elementskit-single-testimonial-slider.gradient-1 {
    background: #82e182;
    background: linear-gradient(-35deg, #82e182 0, #0acbf5 100%)
}

.ekit-wid-con .elementskit-single-testimonial-slider.gradient-2 {
    background: #f2709c;
    background: linear-gradient(-35deg, #f2709c 0, #fe8f75 100%)
}

.ekit-wid-con .elementskit-single-testimonial-slider.gradient-3 {
    background: #b18cfe;
    background: linear-gradient(-35deg, #b18cfe 0, #9460ff 100%)
}

.ekit-wid-con .elementskit-single-testimonial-slider.review-block {
    padding: 80px
}

.ekit-wid-con .elementskit-single-testimonial-slider.review-block .elementskit-commentor-content > p {
    margin-bottom: 20px
}

.ekit-wid-con .elementskit-single-testimonial-slider.review-block .elementskit-commentor-content .elementskit-author-name {
    font-size: 18px
}

.ekit-wid-con .elementskit-single-testimonial-slider.review-block .elementskit-stars {
    margin-bottom: 25px
}

.ekit-wid-con .elementskit-single-testimonial-slider.review-block .elementskit-watermark-icon {
    top: -40px;
    left: 0;
    font-size: 132px;
    color: rgba(0, 0, 0, .07)
}

.ekit-wid-con .elementskit-commentor-content {
    padding-right: 30px;
    position: relative
}

.ekit-wid-con .elementskit-commentor-content .elementskit-client_logo {
    margin-bottom: 32px;
    min-height: 50px;
    display: inline-block;
    position: relative
}

.ekit-wid-con .elementskit-commentor-content .elementskit-client_logo .elementskit-testimonial-client-active-logo {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-commentor-content .elementskit-client_logo .elementskit-testimonial-client-logo {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-commentor-content > i {
    font-size: 48px;
    margin-bottom: 23px;
    display: inline-block
}

.ekit-wid-con .elementskit-commentor-content > p {
    margin-bottom: 35px;
    color: rgba(0, 0, 0, .5)
}

.ekit-wid-con .elementskit-commentor-content > .elementskit-border-hr {
    width: 40px;
    height: 2px;
    background-color: #2575fc;
    margin-top: 0;
    margin-bottom: 20px;
    display: inline-block
}

.ekit-wid-con .elementskit-commentor-content .elementskit-profile-info {
    display: block
}

.ekit-wid-con .elementskit-commentor-content .elementskit-author-name {
    font-size: 22px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-commentor-content .elementskit-author-des {
    font-size: 13px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-testimonial_card {
    position: relative;
    padding: 200px 170px 80px;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    text-align: center;
    display: block
}

.ekit-wid-con .elementskit-testimonial_card .elementskit-stars {
    margin-bottom: 25px
}

.ekit-wid-con .elementskit-testimonial_card .elementskit-stars > li > a, .ekit-wid-con .elementskit-testimonial_card .elementskit-stars > li > span {
    color: #fff
}

.ekit-wid-con .elementskit-testimonial_card .video-popup {
    -webkit-box-shadow: 19.799px 19.799px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 19.799px 19.799px 40px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    color: #2575fc;
    font-size: 28px
}

.ekit-wid-con .elementskit-testimonial_card > p {
    font-size: 22px;
    font-style: italic;
    color: #fff;
    margin-bottom: 35px
}

.ekit-wid-con .elementskit-testimonial_card .xs-overlay {
    border-radius: inherit;
    z-index: -1
}

.ekit-wid-con .elementskit-testimonial_card .elementskit-video-popup-content {
    margin-bottom: 30px
}

.ekit-wid-con .elementskit-testimonial_card .elementskit-profile-info .elementskit-author-des, .ekit-wid-con .elementskit-testimonial_card .elementskit-profile-info .elementskit-author-name {
    color: #fff
}

.ekit-wid-con .elementskit-testimonial_card .elementskit-profile-info .elementskit-author-name {
    font-size: 22px
}

.ekit-wid-con .elementskit-testimonial_card .elementskit-profile-info .elementskit-author-des {
    font-size: 13px
}

.ekit-wid-con .elementskit-tootltip-testimonial .elementskit-commentor-content {
    background-color: #2575fc;
    border-radius: 10px;
    padding: 40px 60px;
    color: #fff;
    margin-bottom: 50px
}

.ekit-wid-con .elementskit-tootltip-testimonial .elementskit-commentor-content > p {
    margin-bottom: 0
}

.ekit-wid-con .elementskit-tootltip-testimonial .elementskit-commentor-content .elementskit-watermark-icon {
    position: absolute;
    bottom: -50px;
    left: 20px;
    font-size: 80px;
    color: rgba(0, 0, 0, .1);
    line-height: 1
}

.ekit-wid-con .elementskit-tootltip-testimonial .elementskit-commentor-content::before {
    position: absolute;
    content: "";
    bottom: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    width: 40px;
    height: 40px;
    background-color: #2575fc
}

.ekit-wid-con .elementskit-tootltip-testimonial .elementskit-commentor-content .elementskit-stars > li > a, .ekit-wid-con .elementskit-tootltip-testimonial .elementskit-commentor-content .elementskit-stars > li > span {
    color: #fff
}

.ekit-wid-con .elementskit-tootltip-testimonial.style-2 .elementskit-commentor-content {
    padding: 60px 50px;
    margin-bottom: 90px
}

.ekit-wid-con .elementskit-tootltip-testimonial.style-2 .elementskit-commentor-content::before {
    -webkit-transform: none;
    transform: none;
    left: 50%;
    bottom: inherit;
    top: 100%;
    border-top: 65px solid #2575fc;
    border-right: 89px solid transparent;
    background-color: transparent
}

.ekit-wid-con .elementskit-tootltip-testimonial.style-2 .elementskit-commentor-content .elementskit-watermark-icon {
    position: static;
    font-size: 48px;
    color: rgba(255, 255, 255, .1);
    padding-right: 30px
}

.ekit-wid-con .elementskit-tootltip-testimonial.style-3 .elementskit-commentor-content {
    margin-bottom: 0;
    padding-top: 60px;
    padding-bottom: 80px
}

.ekit-wid-con .elementskit-tootltip-testimonial.style-3 .elementskit-commentor-content::before {
    display: none
}

.ekit-wid-con .elementskit-tootltip-testimonial.style-3 .elementskit-commentor-content .elementskit-commentor-text {
    position: relative
}

.ekit-wid-con .elementskit-tootltip-testimonial.style-3 .elementskit-commentor-content .elementskit-commentor-text .elementskit-commentor-bio {
    position: absolute;
    top: calc(100% + 35px);
    left: 0
}

.ekit-wid-con .elementskit-commentor-bio .elementskit-commentor-image > img {
    border-radius: 50%;
    height: 70px;
    width: 70px
}

.ekit-wid-con .elementskit-commentor-bio .elementskit-author-name {
    font-size: 22px;
    line-height: 1
}

.ekit-wid-con .elementskit-commentor-bio .elementskit-author-des {
    font-size: 13px
}

.ekit-wid-con .elementskit-commentor-bio.style-2 .elementskit-commentor-image > img {
    border: 5px solid #fff;
    -webkit-box-shadow: 19.799px 19.799px 40px 0 rgba(0, 0, 0, .1);
    box-shadow: 19.799px 19.799px 40px 0 rgba(0, 0, 0, .1);
    width: 80px;
    height: 80px
}

.ekit-wid-con .elementskit-commentor-bio.style-3 .elementskit-commentor-image > img {
    width: 120px;
    height: 120px
}

.ekit-wid-con .elementskit-commentor-bio.media .elementskit-author-name {
    font-size: 18px
}

.ekit-wid-con .elementskit-commentor-bio.media .elementskit-author-des {
    font-size: 13px
}

.ekit-wid-con .testimonial-block-slider2 {
    padding-bottom: 100px
}

.ekit-wid-con .testimonial-block-slider2.modern-nav .owl-nav {
    top: calc(50% - 30px)
}

.ekit-wid-con .testimonial-block-slider3 {
    margin-bottom: 70px
}

.ekit-wid-con .testimonial-block-slider3 .owl-nav button > i {
    font-size: 36px;
    color: #2575fc
}

.ekit-wid-con .elementskit-profile-info .elementskit-author-name {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    -webkit-transition: color .3s;
    transition: color .3s
}

.ekit-wid-con .elementskit-profile-info .elementskit-author-des {
    color: rgba(0, 0, 0, .5);
    font-weight: 400
}

.ekit-wid-con .elementskit-profile-image-card {
    position: relative;
    overflow: hidden
}

.ekit-wid-con .elementskit-profile-image-card::before {
    position: absolute;
    content: "";
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    pointer-events: none
}

.ekit-wid-con .elementskit-profile-image-card > img {
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ekit-wid-con .elementskit-profile-image-card:hover::before {
    background-color: rgba(17, 17, 17, .8);
    top: 0
}

.ekit-wid-con .elementskit-testimonial-slider .swiper-wrapper {
    overflow: visible
}

.ekit-wid-con .elementskit-testimonial-slider .swiper-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.ekit-wid-con .elementskit-testimonial-slider .swiper-pagination span {
    padding: 0;
    margin: 0
}

.ekit-wid-con .elementskit-testimonial-slider .swiper-container:not(.swiper-container-initialized) .swiper-slide {
    max-width: calc(100% / var(--ekit_testimonial_slidetoshow, 1));
    margin-right: var(--ekit_testimonial_left_right_spacing, 15px);
    margin-left: var(--ekit_testimonial_left_right_spacing, 15px)
}

.ekit-wid-con .elementskit-testimonial_card .xs-overlay {
    opacity: 1;
    background-color: rgba(0, 0, 0, .5)
}

.ekit-wid-con .elementskit-commentor-bio {
    position: absolute;
    left: 0;
    width: 100%
}

.ekit-wid-con .elementskit-commentor-bio.client_center {
    text-align: center
}

.ekit-wid-con .elementskit-commentor-bio.client_center .elementskit-commentor-image > img {
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .elementskit-commentor-bio.client_right {
    text-align: right
}

.ekit-wid-con .elementskit-commentor-bio.client_right .elementskit-commentor-image > img {
    margin-left: auto
}

.ekit-wid-con .elementskit-commentor-bio.client_left {
    text-align: left
}

.ekit-wid-con .elementskit-testimonial-slider .swiper-button-next, .ekit-wid-con .elementskit-testimonial-slider .swiper-button-prev {
    font-size: 36px;
    color: #2575fc;
    width: auto;
    height: auto;
    z-index: 1
}

.ekit-wid-con .elementskit-testimonial-slider .swiper-button-next::before, .ekit-wid-con .elementskit-testimonial-slider .swiper-button-prev::before {
    display: none
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-two .elementskit-commentor-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-two .elementskit-stars {
    -ms-flex-item-align: end;
    align-self: flex-end
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-two .elementskit-commentor-bio {
    position: static
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-two .elementkit-commentor-details, .ekit-wid-con .elementskit-testimonial-slider-block-style-two .elementskit-commentor-bio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-two .elementkit-commentor-details {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-two .elementskit-icon-content {
    -ms-flex-item-align: end;
    align-self: flex-end
}

.ekit-wid-con .elementskit-testimonial_card .xs-overlay {
    background-color: rgba(0, 0, 0, .5)
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-three {
    overflow: hidden;
    position: relative
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-three .elementskit-commentor-bio {
    position: static
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-three .elementskit-profile-info {
    text-align: center
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-three .elementskit-commentor-content > p {
    margin-bottom: 30px
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-three .elementskit-icon-content {
    position: absolute;
    right: 20px;
    top: 20px;
    line-height: 1;
    z-index: 1
}

.ekit-wid-con .elementskit-testimonial-slider-block-style-three .elementskit-icon-content.commentor-badge::before {
    position: absolute;
    content: "";
    top: -100px;
    left: -30px;
    background-color: #2575fc;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    z-index: -1
}

.ekit-wid-con .elementkit-commentor-details.client_center {
    margin: auto
}

.ekit-wid-con .elementkit-commentor-details.client_left {
    margin-right: auto
}

.ekit-wid-con .elementkit-commentor-details.client_right {
    margin-left: auto
}

.ekit-wid-con .ekit_testimonial_style_2 {
    padding: 30px;
    border-bottom: 3px solid transparent;
    text-align: center
}

.ekit-wid-con .ekit_testimonial_style_2:hover {
    border-bottom-color: #2575fc
}

.ekit-wid-con .ekit_testimonial_style_2 .elementskit-commentor-content {
    padding-right: 0
}

.ekit-wid-con .ekit-testimonia-style-4 {
    text-align: center
}

.ekit-wid-con .ekit-testimonia-style-4 .elementskit-single-testimonial-slider {
    padding: 30px 30px 50px;
    margin-bottom: 80px;
    display: block
}

.ekit-wid-con .ekit-testimonia-style-4 .elementskit-single-testimonial-slider .elementskit-commentor-content {
    padding-right: 0
}

.ekit-wid-con .ekit-testimonia-style-4 .elementskit-single-testimonial-slider .elementskit-commentor-image img {
    margin: auto
}

.ekit-wid-con .ekit-testimonia-style-4 .elementskit-watermark-icon {
    margin-bottom: 23px;
    color: rgba(37, 117, 252, .2)
}

.ekit-wid-con .ekit-testimonia-style-4 .ekit-testimonial--avatar {
    margin-bottom: 20px
}

.ekit-wid-con .ekit_testimonial_style_5 {
    text-align: left
}

.ekit-wid-con .ekit_testimonial_style_5 .elementskit-commentor-header {
    display: block
}

.ekit-wid-con .ekit_testimonial_style_5 .elementskit-single-testimonial-slider {
    padding: 30px;
    display: block
}

.ekit-wid-con .ekit_testimonial_style_5 .elementskit-single-testimonial-slider .elementskit-commentor-content {
    padding-right: 0
}

.ekit-wid-con .ekit_testimonial_style_5 .elementskit-single-testimonial-slider .elementskit-commentor-content > p {
    margin-top: 30px;
    margin-bottom: 30px
}

.ekit-wid-con .ekit_testimonial_style_5 .elementskit-watermark-icon {
    color: rgba(37, 117, 252, .2)
}

.ekit-wid-con .ekit_testimonial_style_5 .ekit-testimonial--avatar {
    margin-right: 20px
}

.ekit-wid-con .ekit_testimonial_style_6 {
    text-align: center
}

.ekit-wid-con .ekit_testimonial_style_6 .elementskit-single-testimonial-slider {
    padding: 30px;
    display: block
}

.ekit-wid-con .ekit_testimonial_style_6 .elementskit-single-testimonial-slider .elementskit-commentor-content {
    padding-right: 0
}

.ekit-wid-con .ekit_testimonial_style_6 .elementskit-stars {
    margin-bottom: 20px
}

.ekit-wid-con .ekit_testimonial_style_6 .elementskit-watermark-icon {
    color: #fff
}

.ekit-wid-con .ekit_testimonial_style_6 .elementskit-watermark-icon > i {
    font-size: 30px
}

.ekit-wid-con .ekit_testimonial_style_6 .elementskit-watermark-icon > svg {
    width: 30px
}

.ekit-wid-con .ekit_testimonial_style_6 .elementskit-profile-info {
    margin-bottom: 15px
}

.ekit-wid-con .ekit_testimonial_style_6 .elementskit-commentor-image > img {
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .ekit_testimonial_style_6 .ekit-testimonial--avatar {
    margin-bottom: 20px
}

.ekit-wid-con .ekit_watermark_icon_custom_position {
    position: absolute
}

.ekit-wid-con .elementskit-commentor-content > i, .ekit-wid-con .elementskit-icon-content > i {
    display: inline-block
}

.ekit-wid-con .elementskit-stars {
    position: relative;
    z-index: 6
}

.ekit-wid-con .elementskit-stars > li {
    display: inline-block
}

.ekit-wid-con .elementskit-stars > li:not(:last-child) {
    margin-right: 5px
}

.ekit-wid-con .elementskit-stars > li > a, .ekit-wid-con .elementskit-stars > li > span {
    display: block;
    pointer-events: none
}

.ekit-wid-con .elementskit-stars.style-2 > li > a {
    color: #fec42d
}

.ekit-wid-con .elementskit-stars.style-3 > li > a {
    color: #000
}

.ekit-wid-con .ekit-testimonial--link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
    z-index: 1
}

.elementskit-commentor-content ol, .elementskit-commentor-content ul {
    padding-left: 18px
}

.ekit-wid-con .elementkit-tab-wraper {
    line-height: 0
}

.ekit-wid-con .elementkit-tab-wraper.vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-wid-con .elementkit-tab-wraper.vertical > ul {
    display: block;
    height: 100%
}

.ekit-wid-con .elementkit-tab-wraper.vertical > .elementkit-tab-nav .elementkit-nav-item {
    display: block;
    width: 100%;
    margin-right: 0
}

.ekit-wid-con .elementkit-tab-wraper.elementskit-fitcontent-tab:not(.vertical) .elementkit-tab-nav {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.ekit-wid-con .elementkit-tab-wraper.elementskit-fitcontent-tab .elementkit-tab-content {
    text-align: left;
    width: 100%;
    max-width: 100%
}

.ekit-wid-con .elementkit-tab-wraper .elementkit-nav-link {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .elementkit-tab-wraper .elementkit-nav-link.right-pos {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.ekit-wid-con .elementkit-tab-wraper .elementkit-nav-link .elementskit-tab-icon {
    display: block
}

.ekit-wid-con .elementkit-tab-wraper .elementkit-nav-link .elementskit-tab-icon svg {
    max-width: 30px;
    height: auto
}

.ekit-wid-con .elementkit-tab-wraper .elementkit-nav-link.left-pos .elementskit-tab-icon {
    -ms-flex-item-align: center;
    align-self: center
}

.ekit-wid-con .elementkit-tab-wraper .elementkit-nav-link.top-pos {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.ekit-wid-con .elementkit-tab-wraper .elementkit-nav-link.bottom-pos {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
}

.ekit-wid-con .elementkit-tab-nav {
    position: relative;
    border-bottom: 0 solid #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    z-index: 1
}

.ekit-wid-con .elementkit-tab-nav .elementkit-nav-item {
    margin-bottom: 0;
    float: none;
    display: inline-block
}

.ekit-wid-con .elementkit-tab-nav .elementkit-nav-item:not(:last-child) {
    margin-right: 10px
}

.ekit-wid-con .elementkit-tab-nav .elementkit-nav-item .elementkit-nav-link {
    font-weight: 700;
    padding: 14px 35px;
    border-radius: 0;
    position: relative;
    text-align: center;
    background-color: transparent;
    border: none;
    margin: 0
}

.ekit-wid-con .elementkit-tab-nav.elementskit_tab_border_bottm .elementkit-nav-item .elementkit-nav-link::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #2575fc;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease
}

.ekit-wid-con .elementkit-tab-nav.elementskit_tab_border_bottm .elementkit-nav-item .elementkit-nav-link.active::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%
}

.ekit-wid-con .elementkit-tab-nav.elementskit_tooltip_style .elementkit-nav-item .elementkit-nav-link::before {
    position: absolute;
    content: "";
    bottom: -24px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    height: 24px;
    width: 24px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    opacity: 0;
    visibility: hidden;
    background-image: -webkit-gradient(linear, left top, right bottom, from(rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0)), color-stop(50%, #4b3fe1), to(#4b3fe1));
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 50%, #4b3fe1 50%, #4b3fe1 100%);
    z-index: -1
}

.ekit-wid-con .elementkit-tab-nav.elementskit_tooltip_style .elementkit-nav-item .elementkit-nav-link.active::before {
    opacity: 1;
    visibility: visible
}

.ekit-wid-con .elementkit-tab-nav.elementskit_heartbit_style .elementkit-nav-item .elementkit-nav-link::after, .ekit-wid-con .elementkit-tab-nav.elementskit_heartbit_style .elementkit-nav-item .elementkit-nav-link::before {
    position: absolute;
    content: "";
    left: 50%;
    opacity: 0;
    z-index: 1
}

.ekit-wid-con .elementkit-tab-nav.elementskit_heartbit_style .elementkit-nav-item .elementkit-nav-link::before {
    bottom: -1px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 70px;
    height: 1px;
    background-color: #2575fc
}

.ekit-wid-con .elementkit-tab-nav.elementskit_heartbit_style .elementkit-nav-item .elementkit-nav-link::after {
    bottom: -5px;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    height: 10px;
    width: 10px;
    background-image: linear-gradient(136deg, rgba(0, 0, 0, 0) 50%, rgba(242, 41, 91, 0) 50%);
    border-style: solid;
    border-width: 1px 0 0 1px;
    border-color: #2575fc;
    z-index: 1
}

.ekit-wid-con .elementkit-tab-nav.elementskit_heartbit_style .elementkit-nav-item .elementkit-nav-link.active::after, .ekit-wid-con .elementkit-tab-nav.elementskit_heartbit_style .elementkit-nav-item .elementkit-nav-link.active::before {
    opacity: 1
}

.ekit-wid-con .elementkit-tab-nav.elementskit_pregress_style .elementkit-nav-item .elementkit-nav-link::before {
    position: absolute;
    content: "";
    bottom: -3px;
    left: 0;
    height: 3px;
    width: 100%;
    background-image: -webkit-gradient(linear, right top, left top, from(#07b787), to(#7ed500));
    background-image: linear-gradient(270deg, #07b787 0, #7ed500 100%);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 350ms ease 350ms;
    transition: -webkit-transform 350ms ease 350ms;
    transition: transform 350ms ease 350ms;
    transition: transform 350ms ease 350ms, -webkit-transform 350ms ease 350ms;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    z-index: 1
}

.ekit-wid-con .elementkit-tab-nav.elementskit_pregress_style .elementkit-nav-item .elementkit-nav-link::after {
    position: absolute;
    content: "";
    bottom: -10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: -webkit-gradient(linear, right top, left top, from(#07b787), to(#7ed500));
    background-image: linear-gradient(270deg, #07b787 0, #7ed500 100%);
    border: 5px solid #fff;
    opacity: 0;
    -webkit-transition: opacity 350ms ease 0s;
    transition: opacity 350ms ease 0s;
    z-index: 1
}

.ekit-wid-con .elementkit-tab-nav.elementskit_pregress_style .elementkit-nav-item .elementkit-nav-link.active::after {
    opacity: 1;
    -webkit-transition: opacity 350ms ease 350ms;
    transition: opacity 350ms ease 350ms
}

.ekit-wid-con .elementkit-tab-nav.elementskit_pregress_style .elementkit-nav-item .elementkit-nav-link.active::before {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: -webkit-transform 350ms ease 0s;
    transition: -webkit-transform 350ms ease 0s;
    transition: transform 350ms ease 0s;
    transition: transform 350ms ease 0s, -webkit-transform 350ms ease 0s
}

.ekit-wid-con .elementkit-tab-nav.elementskit_ribbon_style .elementkit-nav-item .elementkit-nav-link .elementskit-tab-icon, .ekit-wid-con .elementkit-tab-nav.elementskit_ribbon_style .elementkit-nav-item .elementkit-nav-link .elementskit-tab-title {
    position: relative;
    z-index: 11
}

.ekit-wid-con .elementkit-tab-nav.elementskit_ribbon_style .elementkit-nav-item .elementkit-nav-link::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% + 20px);
    background-image: linear-gradient(60deg, #f2709c 0, #fe8f75 100%);
    z-index: 10;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition: -webkit-transform .4s ease .4s;
    transition: -webkit-transform .4s ease .4s;
    transition: transform .4s ease .4s;
    transition: transform .4s ease .4s, -webkit-transform .4s ease .4s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-size: 101% auto
}

.ekit-wid-con .elementkit-tab-nav.elementskit_ribbon_style .elementkit-nav-item .elementkit-nav-link::after {
    position: absolute;
    content: "";
    bottom: -20px;
    right: -20px;
    width: 20px;
    height: 20px;
    z-index: 10;
    background-image: linear-gradient(-221deg, #174eac 50%, rgba(242, 41, 91, 0) 50%);
    opacity: 0;
    -webkit-transition: opacity .4s ease .4s;
    transition: opacity .4s ease .4s
}

.ekit-wid-con .elementkit-tab-nav.elementskit_ribbon_style .elementkit-nav-item .elementkit-nav-link.active::after {
    opacity: 1;
    -webkit-transition: opacity .4s ease .4s;
    transition: opacity .4s ease .4s
}

.ekit-wid-con .elementkit-tab-nav.elementskit_ribbon_style .elementkit-nav-item .elementkit-nav-link.active::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transition: -webkit-transform .4s ease 0s;
    transition: -webkit-transform .4s ease 0s;
    transition: transform .4s ease 0s;
    transition: transform .4s ease 0s, -webkit-transform .4s ease 0s
}

.ekit-wid-con .elementkit-tab-nav.elementskit-fullwidth-tab > .elementkit-nav-item {
    max-width: 100%;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.ekit-wid-con .elementkit-tab-content {
    line-height: 1.5
}

.ekit-wid-con .elementkit-tab-content .elementkit-tab-pane {
    padding: 20px;
}

.ekit-wid-con .tab-nav-fluid {
    width: 100%;
    display: block !important;
    height: auto !important
}

.ekit-wid-con .elementkit-nav-link {
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .ekit-tab-title-group {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto
}

@media (min-width: 768px) {
    .elementkit-tab-wraper:not(.vertical) > ul > li > a {
        white-space: nowrap
    }
}

.elementkit-tab-pane > div > ol, .elementkit-tab-pane > div > ul {
    padding-left: 18px
}

.ekit-wid-con .ekit-sidebar-group .ekit-overlay {
    left: 100%;
    top: 0;
    position: fixed;
    z-index: 1002;
    height: 100%;
    opacity: 0;
    width: 100%;
    visibility: hidden;
    -webkit-transition: all .4s ease-in .8s;
    transition: all .4s ease-in .8s;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .9)
}

.ekit-off-canvas-position-left .ekit-wid-con .ekit-sidebar-group .ekit-overlay {
    left: auto;
    right: 100%
}

.ekit-wid-con .ekit-sidebar-group .ekit_widget-heading {
    padding: 20px;
    float: right
}

.ekit-wid-con .ekit-sidebar-widget {
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 360px;
    z-index: 999999;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    -webkit-transition: all .5s cubic-bezier(.9, .03, 0, .96) .6s;
    transition: all .5s cubic-bezier(.9, .03, 0, .96) .6s;
    visibility: hidden;
    opacity: 0
}

.ekit-off-canvas-position-left .ekit-wid-con .ekit-sidebar-widget {
    left: -100%;
    right: auto
}

.ekit-wid-con .ekit-sidebar-group.ekit_isActive .ekit-overlay {
    visibility: visible;
    -webkit-transition: all .8s ease-out 0s;
    transition: all .8s ease-out 0s;
    left: 0;
    opacity: 1
}

.ekit-off-canvas-position-left .ekit-wid-con .ekit-sidebar-group.ekit_isActive .ekit-overlay {
    left: auto;
    right: 0
}

.ekit-wid-con .ekit-sidebar-group.ekit_isActive .ekit-sidebar-widget {
    opacity: 1;
    visibility: visible;
    right: 0;
    -webkit-transition: all .7s cubic-bezier(.9, .03, 0, .96) .4s;
    transition: all .7s cubic-bezier(.9, .03, 0, .96) .4s
}

.ekit-off-canvas-position-left .ekit-wid-con .ekit-sidebar-group.ekit_isActive .ekit-sidebar-widget {
    left: 0;
    right: auto
}

.ekit-wid-con .ekit_sidebar-textwidget {
    padding: 60px 20px 20px;
}

.ekit-wid-con .ekit_close-side-widget {
    position: absolute;
    color: #1768dd;
    font-size: 15px;
    display: block;
    font-weight: 400;
    line-height: 1;
    padding: 4px 7px 5px 7px;
    border: 1px solid #ccc;
    z-index: 1;
    background-color: #fff;
    top: 15px;
    right: 15px;
    text-align: center;
    -webkit-transition: all .3s;
    transition: all .3s
}

.ekit-wid-con .ekit_close-side-widget svg {
    width: 100%;
    max-width: 20px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .ekit_sidebar-widget-container {
    position: relative;
    top: 150px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease-in .3s;
    transition: all .3s ease-in .3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ekit-wid-con .ekit-sidebar-group.ekit_isActive .ekit_sidebar-widget-container {
    top: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 1s ease-out 1.2s;
    transition: all 1s ease-out 1.2s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.ekit-wid-con .ekit_navSidebar-button {
    display: inline-block;
    line-height: 1
}

.ekit-wid-con .ekit_offcanvas-sidebar {
    -webkit-transition: all .3s;
    transition: all .3s
}

.ekit-wid-con .ekit_offcanvas-sidebar svg {
    width: 100%;
    max-width: 20px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .ekit-dropcap-cotnent {
    margin-bottom: 0
}

.ekit-wid-con .ekit-dropcap-cotnent:first-child::first-letter {
    color: #903;
    float: left;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center
}

.ekit-dropcap-wraper > ol, .ekit-dropcap-wraper > ul {
    padding-left: 28px
}

.ekit-wid-con .ekit_double_button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 480px) {
    .ekit-wid-con .ekit_double_button {
        display: block
    }
}

.ekit-wid-con .ekit-double-btn {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    outline: 0;
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    text-align: center;
    overflow: hidden;
    color: #fff;
    width: 100%;
    background-color: #3b3b3b;
    padding: 20px;
    z-index: 1
}

.ekit-wid-con .ekit-double-btn svg {
    max-width: 14px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .ekit-double-btn:hover {
    background-color: #1a1919
}

.ekit-wid-con .ekit-element-align-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

.ekit-wid-con .ekit_button_middle_text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    -webkit-box-shadow: 0 0 0 6px rgba(255, 255, 255, .2);
    box-shadow: 0 0 0 6px rgba(255, 255, 255, .2);
    background: #fafaf8;
    color: #444;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 14px;
    z-index: 5
}

.ekit-wid-con .ekit-double-btn-one {
    background-color: #2575fc
}

.ekit-wid-con .ekit-double-btn-one:hover {
    background-color: #0359eb
}

.ekit-wid-con .ekit_creative_icon_box {
    display: inline-block;
    padding: 20px;
    background-color: #333;
    color: #fff;
    font-size: 20px;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;
    cursor: pointer;
    -webkit-transition: all .4s ease-in;
    transition: all .4s ease-in
}

.ekit-wid-con .ekit_hover_grow {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0)
}

.ekit-wid-con .ekit_hover_grow:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.ekit-wid-con .ekit_hover_pulse:hover {
    -webkit-animation-name: ekit-hvr-pulse;
    animation-name: ekit-hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

@-webkit-keyframes ekit-hvr-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
    75% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

@keyframes ekit-hvr-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
    75% {
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
}

.ekit-wid-con .ekit_hover_pushe:hover {
    -webkit-animation-name: ekit-hvr-push;
    animation-name: ekit-hvr-push;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1
}

@-webkit-keyframes ekit-hvr-push {
    50% {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes ekit-hvr-push {
    50% {
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.ekit-wid-con .ekit_hover_pop:hover {
    -webkit-animation-name: ekit-hvr-pop;
    animation-name: ekit-hvr-pop;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1
}

@-webkit-keyframes ekit-hvr-pop {
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }
}

@keyframes ekit-hvr-pop {
    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }
}

.ekit-wid-con .ekit_hover_rotate:hover {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg)
}

.ekit-wid-con .ekit_hover_float:hover {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px)
}

.ekit-wid-con .ekit_hover_sink:hover {
    -webkit-transform: translateY(8px);
    transform: translateY(8px)
}

.ekit-wid-con .ekit_hover_skew:hover {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg)
}

.ekit-wid-con .ekit_hover_wobble_horizontal:hover {
    -webkit-animation-name: ekit-hvr-wobble-horizontal;
    animation-name: ekit-hvr-wobble-horizontal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1
}

@-webkit-keyframes ekit-hvr-wobble-horizontal {
    16.65% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px)
    }
    33.3% {
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px)
    }
    49.95% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px)
    }
    66.6% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px)
    }
    83.25% {
        -webkit-transform: translateX(1px);
        transform: translateX(1px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes ekit-hvr-wobble-horizontal {
    16.65% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px)
    }
    33.3% {
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px)
    }
    49.95% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px)
    }
    66.6% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px)
    }
    83.25% {
        -webkit-transform: translateX(1px);
        transform: translateX(1px)
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.ekit-wid-con .ekit_hover_wobble_vertical:hover {
    -webkit-animation-name: ekit-hvr-wobble-vertical;
    animation-name: ekit-hvr-wobble-vertical;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1
}

@-webkit-keyframes ekit-hvr-wobble-vertical {
    16.65% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px)
    }
    33.3% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px)
    }
    49.95% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px)
    }
    66.6% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px)
    }
    83.25% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px)
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes ekit-hvr-wobble-vertical {
    16.65% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px)
    }
    33.3% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px)
    }
    49.95% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px)
    }
    66.6% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px)
    }
    83.25% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px)
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.ekit-wid-con .ekit_hover_shadow {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    background-color: #e1e1e1;
    color: #666
}

.ekit-wid-con .ekit_hover_shadow:hover {
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, .5);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, .5)
}

.ekit-wid-con .ekit_hover_float_shadow::before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, .35) 0, rgba(0, 0, 0, 0) 80%);
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform
}

.ekit-wid-con .ekit_hover_float_shadow:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
}

.ekit-wid-con .ekit_hover_float_shadow:hover::before {
    opacity: 1;
    -webkit-transform: translateY(5px);
    transform: translateY(5px)
}

.ekit-wid-con .ekit_hover_glow_out:hover {
    -webkit-transform: scale(.93);
    transform: scale(.93)
}

.ekit-wid-con .ekit_hover_glow_out:hover::before {
    -webkit-animation: ekit-hover-glow-out 1.3s ease-out 75ms;
    animation: ekit-hover-glow-out 1.3s ease-out 75ms
}

.ekit-wid-con .ekit_hover_glow_out::before {
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-box-shadow: 0 0 0 2px rgba(51, 51, 51, .1);
    box-shadow: 0 0 0 2px rgba(51, 51, 51, .1);
    opacity: 0;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    content: ""
}

@-webkit-keyframes ekit-hover-glow-out {
    0% {
        opacity: .3
    }
    40% {
        opacity: .5;
        -webkit-box-shadow: 0 0 0 2px rgba(51, 51, 51, .1), 0 0 10px 10px #333, 0 0 0 10px rgba(51, 51, 51, .5);
        box-shadow: 0 0 0 2px rgba(51, 51, 51, .1), 0 0 10px 10px #333, 0 0 0 10px rgba(51, 51, 51, .5)
    }
    100% {
        -webkit-box-shadow: 0 0 0 2px rgba(51, 51, 51, .1), 0 0 10px 10px #333, 0 0 0 10px rgba(51, 51, 51, .5);
        box-shadow: 0 0 0 2px rgba(51, 51, 51, .1), 0 0 10px 10px #333, 0 0 0 10px rgba(51, 51, 51, .5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

@keyframes ekit-hover-glow-out {
    0% {
        opacity: .3
    }
    40% {
        opacity: .5;
        -webkit-box-shadow: 0 0 0 2px rgba(51, 51, 51, .1), 0 0 10px 10px #333, 0 0 0 10px rgba(51, 51, 51, .5);
        box-shadow: 0 0 0 2px rgba(51, 51, 51, .1), 0 0 10px 10px #333, 0 0 0 10px rgba(51, 51, 51, .5)
    }
    100% {
        -webkit-box-shadow: 0 0 0 2px rgba(51, 51, 51, .1), 0 0 10px 10px #333, 0 0 0 10px rgba(51, 51, 51, .5);
        box-shadow: 0 0 0 2px rgba(51, 51, 51, .1), 0 0 10px 10px #333, 0 0 0 10px rgba(51, 51, 51, .5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0
    }
}

.ekit-wid-con .ekit_hover_border_spined, .ekit-wid-con .ekit_hover_dashed {
    background-color: #fff;
    color: #333;
    -webkit-transition: -webkit-box-shadow .2s ease;
    transition: -webkit-box-shadow .2s ease;
    transition: box-shadow .2s ease;
    transition: box-shadow .2s ease, -webkit-box-shadow .2s ease;
    -webkit-box-shadow: inset 0 0 0 4px #333;
    box-shadow: inset 0 0 0 4px #333
}

.ekit-wid-con .ekit_hover_border_spined::before, .ekit-wid-con .ekit_hover_dashed::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    border: 4px dashed #333;
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease;
    pointer-events: none;
    border-radius: inherit
}

.ekit-wid-con .ekit_hover_border_spined:hover, .ekit-wid-con .ekit_hover_dashed:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.ekit-wid-con .ekit_hover_border_spined:hover::before {
    -webkit-animation-name: ekit-hover-spined;
    animation-name: ekit-hover-spined;
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

@-webkit-keyframes ekit-hover-spined {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes ekit-hover-spined {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.ekit-wid-con .ekit_round_icon {
    height: 70px;
    width: 70px;
    border-radius: 50%
}

.ekit-wid-con .ekit_hover_fill_with_shadow {
    color: #333;
    -webkit-box-shadow: 0 0 0 4px #333;
    box-shadow: 0 0 0 4px #333;
    background-color: transparent
}

.ekit-wid-con .ekit_hover_fill_with_shadow:hover {
    -webkit-box-shadow: 0 0 0 8px rgba(51, 51, 51, .3);
    box-shadow: 0 0 0 8px rgba(51, 51, 51, .3);
    background-color: #333;
    color: #fff
}

.ekit-wid-con .ekit_hover_fill_only {
    -webkit-box-shadow: 0 0 0 4px #333;
    box-shadow: 0 0 0 4px #333;
    color: #333;
    background-color: transparent
}

.ekit-wid-con .ekit_hover_fill_only:hover {
    background-color: #333;
    color: #fff
}

.ekit-wid-con .ekit_hover_fill_and_scale_border {
    background-color: rgba(0, 0, 0, .1);
    color: #000
}

.ekit-wid-con .ekit_hover_fill_and_scale_border::before {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    content: "";
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    top: -7px;
    left: -7px;
    padding: 7px;
    -webkit-box-shadow: 0 0 0 4px #000;
    box-shadow: 0 0 0 4px #000;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    opacity: 0;
    z-index: -1
}

.ekit-wid-con .ekit_hover_fill_and_scale_border:hover {
    background-color: #000;
    color: #fff
}

.ekit-wid-con .ekit_hover_fill_and_scale_border:hover::before {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

.ekit-wid-con .ekit_hover_fill_and_scale_border_inset {
    -webkit-box-shadow: 0 0 0 3px #333;
    box-shadow: 0 0 0 3px #333;
    background-color: transparent
}

.ekit-wid-con .ekit_hover_fill_and_scale_border_inset::before {
    top: -2px;
    left: -2px;
    padding: 2px;
    z-index: -1;
    background-color: #333;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: "";
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: opacity .2s, -webkit-transform .2s;
    transition: opacity .2s, -webkit-transform .2s;
    transition: transform .2s, opacity .2s;
    transition: transform .2s, opacity .2s, -webkit-transform .2s
}

.ekit-wid-con .ekit_hover_fill_and_scale_border_inset:hover::before {
    -webkit-transform: scale(.85);
    transform: scale(.85)
}

.ekit-wid-con .ekit-video-popup {
    display: inline-block;
    text-align: center;
    font-size: 18px;
    background-color: #da3175;
    color: #fff;
    border-radius: 50%;
    position: relative;
    z-index: 6;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .ekit-video-popup.ekit_icon_button {
    padding: 10px
}

.ekit-wid-con .ekit-video-popup-btn {
    width: 60px;
    height: 60px;
    line-height: 45px;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    background-color: #da3175;
    color: #fff;
    border-radius: 50%;
    position: relative;
    z-index: 6;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .ekit-video-popup svg {
    max-width: 40px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .ekit-video-popup.fill-btn {
    width: 100px;
    height: 100px;
    line-height: 104px;
    background-color: #fff;
    -webkit-box-shadow: 0 25px 25px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 25px 25px 0 rgba(0, 0, 0, .15)
}

.ekit-wid-con .ekit-video-popup.text-btn {
    width: auto;
    height: auto;
    border-radius: 0;
    background-color: transparent;
    text-decoration: underline
}

.ekit-wid-con .ekit-video-popup.square-btn {
    height: 156px;
    line-height: 156px;
    width: 100%;
    border-radius: 100%;
    background-color: #fff;
    -webkit-box-shadow: 0 42px 60px 0 rgba(0, 0, 0, .3);
    box-shadow: 0 42px 60px 0 rgba(0, 0, 0, .3);
    padding: 0 69px
}

.ekit-wid-con .ekit-video-popup.square-btn:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
}

.ekit-wid-con .glow-btn {
    position: relative
}

.ekit-wid-con .glow-btn::after, .ekit-wid-con .glow-btn::before, .ekit-wid-con .glow-btn > i::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: red;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 currentColor;
    box-shadow: 0 0 0 0 currentColor;
    -webkit-animation: button-ripple 3s infinite;
    animation: button-ripple 3s infinite;
    opacity: .6;
    z-index: -1
}

.ekit-wid-con .glow-btn::before {
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}

.ekit-wid-con .glow-btn::after {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.ekit_self_video_wrap .mejs-video {
    margin: 0 auto;
    line-height: 0;
    width: 100%
}

.ekit_self_video_wrap .mfp-close {
    position: fixed;
    outline: 0
}

.ekit_self_video_wrap .mfp-close:hover {
    background: #000;
    border-color: #000
}

.ekit_self_video_wrap_content .mfp-content {
    max-width: 900px
}

.ekit-wid-con.ekit_wpForms_container .wpforms-submit {
    line-height: normal
}

.ekit-wid-con.ekit_wpForms_container .wpforms-container .wpforms-field input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .ekit-wid-con.ekit_wpForms_container .wpforms-container .wpforms-field select, .ekit-wid-con.ekit_wpForms_container .wpforms-container .wpforms-field textarea {
    max-width: 100% !important
}

.ekit-wid-con.ekit_wpForms_container .wpforms-container .wpforms-form .wpforms-field-name .wpforms-field-row {
    max-width: 100%
}

.ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=date], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=datetime-local], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=datetime], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=email], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=month], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=number], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=password], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=range], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=search], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=tel], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=text], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=time], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=url], .ekit-wid-con.ekit_wpForms_container .wpforms-form input[type=week], .ekit-wid-con.ekit_wpForms_container .wpforms-form select, .ekit-wid-con.ekit_wpForms_container .wpforms-form textarea {
    height: 100%
}

.elementor-element div.wpforms-container-full, .elementor-element div.wpforms-container-full .wpforms-form * {
    visibility: inherit
}

.ekit-wid-con .elementskit-btn {
    border-radius: 5px;
    font-size: 15px;
    padding: 15px 30px;
    display: inline-block;
    color: #fff;
    position: relative;
    display: inline-block;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    background-color: #2575fc;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .elementskit-btn:hover {
    background-color: #0350d2
}

.ekit-wid-con .elementskit-btn:hover::before {
    opacity: 1
}

.ekit-wid-con .elementskit-btn::before {
    position: absolute;
    content: "";
    border-radius: inherit;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: 102% 102%;
    opacity: 0
}

.ekit-wid-con .single-btn {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.ekit-wid-con .single-btn:last-child {
    margin-bottom: 0
}

.ekit-wid-con .btn.sm {
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    padding-left: 16px;
    padding-right: 16px
}

.ekit-wid-con .btn.sm.round {
    border-radius: 15px
}

.ekit-wid-con .btn.md {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding-left: 22px;
    padding-right: 22px
}

.ekit-wid-con .btn.md.round {
    border-radius: 20px
}

.ekit-wid-con .btn.lg {
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    padding-left: 40px;
    padding-right: 40px
}

.ekit-wid-con .btn.lg.round {
    border-radius: 25px
}

.ekit-wid-con .btn.xl {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    padding-left: 36px;
    padding-right: 36px
}

.ekit-wid-con .btn.xl.round {
    border-radius: 30px
}

.ekit-wid-con .btn.border-gra-one, .ekit-wid-con .btn.gra-one {
    background: gradieintplate(gra-one, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-one, angle)), color-stop(0, gradieintplate(gra-one, bg-one)), to(gradieintplate(gra-one, bg-two)));
    background: linear-gradient(gradieintplate(gra-one, angle), gradieintplate(gra-one, bg-one) 0, gradieintplate(gra-one, bg-two))
}

.ekit-wid-con .btn.border-gra-two, .ekit-wid-con .btn.gra-two {
    background: gradieintplate(gra-two, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-two, angle)), color-stop(0, gradieintplate(gra-two, bg-one)), to(gradieintplate(gra-two, bg-two)));
    background: linear-gradient(gradieintplate(gra-two, angle), gradieintplate(gra-two, bg-one) 0, gradieintplate(gra-two, bg-two))
}

.ekit-wid-con .btn.border-gra-three, .ekit-wid-con .btn.gra-three {
    background: gradieintplate(gra-three, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-three, angle)), color-stop(0, gradieintplate(gra-three, bg-one)), to(gradieintplate(gra-three, bg-two)));
    background: linear-gradient(gradieintplate(gra-three, angle), gradieintplate(gra-three, bg-one) 0, gradieintplate(gra-three, bg-two))
}

.ekit-wid-con .btn.border-gra-four, .ekit-wid-con .btn.gra-four {
    background: gradieintplate(gra-four, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-four, angle)), color-stop(0, gradieintplate(gra-four, bg-one)), to(gradieintplate(gra-four, bg-two)));
    background: linear-gradient(gradieintplate(gra-four, angle), gradieintplate(gra-four, bg-one) 0, gradieintplate(gra-four, bg-two))
}

.ekit-wid-con .btn.border-gra-five, .ekit-wid-con .btn.gra-five {
    background: gradieintplate(gra-five, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-five, angle)), color-stop(0, gradieintplate(gra-five, bg-one)), to(gradieintplate(gra-five, bg-two)));
    background: linear-gradient(gradieintplate(gra-five, angle), gradieintplate(gra-five, bg-one) 0, gradieintplate(gra-five, bg-two))
}

.ekit-wid-con .btn.border-gra-six, .ekit-wid-con .btn.gra-six {
    background: gradieintplate(gra-six, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-six, angle)), color-stop(0, gradieintplate(gra-six, bg-one)), to(gradieintplate(gra-six, bg-two)));
    background: linear-gradient(gradieintplate(gra-six, angle), gradieintplate(gra-six, bg-one) 0, gradieintplate(gra-six, bg-two))
}

.ekit-wid-con .btn.border-gra-seven, .ekit-wid-con .btn.gra-seven {
    background: gradieintplate(gra-seven, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-seven, angle)), color-stop(0, gradieintplate(gra-seven, bg-one)), to(gradieintplate(gra-seven, bg-two)));
    background: linear-gradient(gradieintplate(gra-seven, angle), gradieintplate(gra-seven, bg-one) 0, gradieintplate(gra-seven, bg-two))
}

.ekit-wid-con .btn.border-gra-eight, .ekit-wid-con .btn.gra-eight {
    background: gradieintplate(gra-eight, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-eight, angle)), color-stop(0, gradieintplate(gra-eight, bg-one)), to(gradieintplate(gra-eight, bg-two)));
    background: linear-gradient(gradieintplate(gra-eight, angle), gradieintplate(gra-eight, bg-one) 0, gradieintplate(gra-eight, bg-two))
}

.ekit-wid-con .btn.border-gra-nine, .ekit-wid-con .btn.gra-nine {
    background: gradieintplate(gra-nine, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-nine, angle)), color-stop(0, gradieintplate(gra-nine, bg-one)), to(gradieintplate(gra-nine, bg-two)));
    background: linear-gradient(gradieintplate(gra-nine, angle), gradieintplate(gra-nine, bg-one) 0, gradieintplate(gra-nine, bg-two))
}

.ekit-wid-con .btn.border-gra-ten, .ekit-wid-con .btn.gra-ten {
    background: gradieintplate(gra-ten, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-ten, angle)), color-stop(0, gradieintplate(gra-ten, bg-one)), to(gradieintplate(gra-ten, bg-two)));
    background: linear-gradient(gradieintplate(gra-ten, angle), gradieintplate(gra-ten, bg-one) 0, gradieintplate(gra-ten, bg-two))
}

.ekit-wid-con .btn.border-gra-eleven, .ekit-wid-con .btn.gra-eleven {
    background: gradieintplate(gra-eleven, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(gradieintplate(gra-eleven, angle)), color-stop(0, gradieintplate(gra-eleven, bg-one)), to(gradieintplate(gra-eleven, bg-two)));
    background: linear-gradient(gradieintplate(gra-eleven, angle), gradieintplate(gra-eleven, bg-one) 0, gradieintplate(gra-eleven, bg-two))
}

.ekit-wid-con .btn.gra-one::before {
    background: hovergradieintplate(gra-one, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-one, angle)), color-stop(0, hovergradieintplate(gra-one, bg-one)), to(hovergradieintplate(gra-one, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-one, angle), hovergradieintplate(gra-one, bg-one) 0, hovergradieintplate(gra-one, bg-two))
}

.ekit-wid-con .btn.gra-two::before {
    background: hovergradieintplate(gra-two, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-two, angle)), color-stop(0, hovergradieintplate(gra-two, bg-one)), to(hovergradieintplate(gra-two, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-two, angle), hovergradieintplate(gra-two, bg-one) 0, hovergradieintplate(gra-two, bg-two))
}

.ekit-wid-con .btn.gra-three::before {
    background: hovergradieintplate(gra-three, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-three, angle)), color-stop(0, hovergradieintplate(gra-three, bg-one)), to(hovergradieintplate(gra-three, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-three, angle), hovergradieintplate(gra-three, bg-one) 0, hovergradieintplate(gra-three, bg-two))
}

.ekit-wid-con .btn.gra-four::before {
    background: hovergradieintplate(gra-four, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-four, angle)), color-stop(0, hovergradieintplate(gra-four, bg-one)), to(hovergradieintplate(gra-four, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-four, angle), hovergradieintplate(gra-four, bg-one) 0, hovergradieintplate(gra-four, bg-two))
}

.ekit-wid-con .btn.gra-five::before {
    background: hovergradieintplate(gra-five, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-five, angle)), color-stop(0, hovergradieintplate(gra-five, bg-one)), to(hovergradieintplate(gra-five, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-five, angle), hovergradieintplate(gra-five, bg-one) 0, hovergradieintplate(gra-five, bg-two))
}

.ekit-wid-con .btn.gra-six::before {
    background: hovergradieintplate(gra-six, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-six, angle)), color-stop(0, hovergradieintplate(gra-six, bg-one)), to(hovergradieintplate(gra-six, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-six, angle), hovergradieintplate(gra-six, bg-one) 0, hovergradieintplate(gra-six, bg-two))
}

.ekit-wid-con .btn.gra-seven::before {
    background: hovergradieintplate(gra-seven, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-seven, angle)), color-stop(0, hovergradieintplate(gra-seven, bg-one)), to(hovergradieintplate(gra-seven, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-seven, angle), hovergradieintplate(gra-seven, bg-one) 0, hovergradieintplate(gra-seven, bg-two))
}

.ekit-wid-con .btn.gra-eight::before {
    background: hovergradieintplate(gra-eight, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-eight, angle)), color-stop(0, hovergradieintplate(gra-eight, bg-one)), to(hovergradieintplate(gra-eight, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-eight, angle), hovergradieintplate(gra-eight, bg-one) 0, hovergradieintplate(gra-eight, bg-two))
}

.ekit-wid-con .btn.gra-nine::before {
    background: hovergradieintplate(gra-nine, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-nine, angle)), color-stop(0, hovergradieintplate(gra-nine, bg-one)), to(hovergradieintplate(gra-nine, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-nine, angle), hovergradieintplate(gra-nine, bg-one) 0, hovergradieintplate(gra-nine, bg-two))
}

.ekit-wid-con .btn.gra-ten::before {
    background: hovergradieintplate(gra-ten, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-ten, angle)), color-stop(0, hovergradieintplate(gra-ten, bg-one)), to(hovergradieintplate(gra-ten, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-ten, angle), hovergradieintplate(gra-ten, bg-one) 0, hovergradieintplate(gra-ten, bg-two))
}

.ekit-wid-con .btn.gra-eleven::before {
    background: hovergradieintplate(gra-eleven, bg-one);
    background: -webkit-gradient(linear, left top, left bottom, from(hovergradieintplate(gra-eleven, angle)), color-stop(0, hovergradieintplate(gra-eleven, bg-one)), to(hovergradieintplate(gra-eleven, bg-two)));
    background: linear-gradient(hovergradieintplate(gra-eleven, angle), hovergradieintplate(gra-eleven, bg-one) 0, hovergradieintplate(gra-eleven, bg-two))
}

.ekit-wid-con .btn.square {
    border-radius: 0
}

.ekit-wid-con .btn-outline-primary {
    border-color: #f5f5f5;
    background-color: #fff
}

.ekit-wid-con .btn-info {
    background-color: #f15
}

.ekit-wid-con .btn.info-s2 {
    color: #f15;
    background-color: #fff;
    -webkit-box-shadow: -1.216px 6.894px 25px 0 rgba(0, 0, 0, .07);
    box-shadow: -1.216px 6.894px 25px 0 rgba(0, 0, 0, .07)
}

.ekit-wid-con .btn-outline-info {
    border-color: #f15;
    background-color: #fff;
    color: #000
}

.ekit-wid-con .btn-outline-info.info-s2 {
    color: #000
}

.ekit-wid-con .btn.border-gra, .ekit-wid-con .btn.btn-gra {
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: 1
}

.ekit-wid-con .btn.border-gra::before, .ekit-wid-con .btn.btn-gra::before {
    position: absolute;
    content: "";
    border-radius: inherit;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: -1
}

.ekit-wid-con .btn.btn-gra::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: 101% 101%;
    opacity: 0
}

.ekit-wid-con .btn.btn-gra:hover::before {
    opacity: 1
}

.ekit-wid-con .btn.border-gra {
    background-size: 101% 101%;
    border: 0
}

.ekit-wid-con .btn.border-gra::before {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background-color: #fff
}

.ekit-wid-con .btn.border-gra:hover::before {
    opacity: 0
}

.ekit-wid-con .elementskit-btn i {
    display: inline-block;
    vertical-align: middle
}

.ekit-wid-con .elementskit-btn svg {
    max-width: 14px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .ekit-mailChimpForm .elementskit_input_label {
    display: inline-block;
    margin-bottom: .5rem
}

.ekit-wid-con .ekit-mailChimpForm .elementskit_form_group {
    margin: 0;
}

.ekit-wid-con .ekit-mailChimpForm .ekit_form_control {
    display: block;
    width: 100%;
    height: auto;
    min-height: 42px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out
}

.ekit-wid-con .ekit-mailChimpForm .ekit_form_control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

.ekit-wid-con .ekit-mailChimpForm .ekit_form_control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.ekit-wid-con .ekit-mailChimpForm .ekit_form_control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.ekit-wid-con .ekit-mailChimpForm .ekit_form_control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.ekit-wid-con .ekit-mailChimpForm .ekit_form_control::placeholder {
    color: #6c757d;
    opacity: 1
}

.ekit-wid-con .ekit-mailChimpForm .ekit_form_control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}

.ekit-wid-con .ekit-mail-submit {
    position: relative;
    z-index: 1;
    font-size: 16px;
    padding: 15px 40px 15px 40px;
    -webkit-box-shadow: 0 7px 15px 0 rgba(55, 74, 94, .3);
    box-shadow: 0 7px 15px 0 rgba(55, 74, 94, .3);
    border-style: solid;
    border-width: 0;
    color: #fff;
    background-color: #374a5e;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 5px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 26px
}

.ekit-wid-con .ekit-mail-submit svg {
    max-width: 16px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .ekit-mail-submit::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    z-index: -1;
    opacity: 0;
    background-size: 101% 101%;
    border-radius: inherit
}

.ekit-wid-con .ekit-mail-submit:hover::before {
    opacity: 1
}

.ekit-wid-con .elementskit_input_group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.ekit-wid-con .elementskit_input_group .ekit_form_control {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%
}

.ekit-wid-con .elementskit_input_group .ekit_form_control.ekit_append_input {
    border-radius: 5px 0 0 5px
}

.ekit-wid-con .elementskit_input_group_prepend {
    margin-right: -1px
}

.ekit-wid-con .elementskit_input_group_prepend + .ekit_form_control {
    border-radius: 0 5px 5px 0
}

.ekit-wid-con .elementskit_input_group_append .elementskit_input_group_text {
    border-radius: 0 5px 5px 0;
    margin-left: -1px
}

.ekit-wid-con .elementskit_input_group_text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    height: 100%;
    padding: 15px;
    border-radius: 5px 0 0 5px
}

.ekit-wid-con .elementskit_input_group_text svg {
    max-width: 20px;
    height: auto;
    vertical-align: middle
}

.ekit-wid-con .elementskit_inline_form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.ekit-wid-con .elementskit_inline_form .elementskit_form_group {
    margin-bottom: 0
}

.ekit-wid-con .elementskit_inline_form .elementskit_input_wraper:not(.ekit_submit_input_holder) {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.ekit-wid-con .elementskit_inline_form .ekit_submit_input_holder {
    -ms-flex-item-align: end;
    align-self: flex-end
}

.ekit-wid-con .elementskit_inline_form .ekit-mail-submit {
    width: 100%
}

@media screen and (max-width: 767px) {
    .ekit-wid-con .elementskit_inline_form.has-extra-fields > div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-right: 0 !important
    }

    .ekit-wid-con .elementskit_inline_form.has-extra-fields > div:not(:last-child) {
        margin-bottom: 20px
    }
}

.ekit-wid-con .ekit-mail-submit {
    padding: 11.5px 40px
}

.ekit-wid-con .ekit-mail-message {
    display: none;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px
}

.ekit-wid-con .ekit-mail-message.error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb
}

.ekit-wid-con .ekit-mail-message.success {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb
}

.ekit-wid-con .ekit-single-day {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px
}

.ekit-wid-con .ekit-single-day:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.ekit-wid-con .ekit-single-day > span {
    display: inline-block
}

.ekit-wid-con .ekit-single-day .ekit-business-day {
    font-size: 30px;
    color: #3b3b3b;
    font-weight: 500;
    padding-left: 5px;
    margin-right: auto
}

.ekit-wid-con .ekit-single-day .ekit-business-time {
    font-size: 20px;
    color: #333;
    padding-right: 5px;
    margin-left: auto
}

@media (max-width: 480px) {
    .ekit-wid-con .ekit-single-day {
        display: block
    }

    .ekit-wid-con .ekit-single-day > span {
        display: block
    }

    .ekit-wid-con .ekit-single-day .ekit-business-time {
        padding-left: 5px
    }
}

.ekit-wid-con .ekit-heading {
    position: relative
}

.ekit-wid-con .ekit-heading__shadow-text {
    z-index: 0;
    font-size: 90px;
    position: absolute;
    font-weight: 700;
    line-height: 120px;
    white-space: nowrap;
    letter-spacing: -6px;
    font-family: Archivo, sans-serif;
    color: transparent;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(16, 16, 16, .1)
}

.ekit-wid-con .ekit-heading__shadow-text ~ * {
    z-index: 1;
    position: relative
}

.ekit-wid-con .ekit-heading__title-has-border {
    position: relative
}

.ekit-wid-con .ekit-heading__title-has-border::before {
    content: "";
    width: 4px;
    height: 100%;
    display: block;
    position: absolute;
    background: -webkit-gradient(linear, left top, left bottom, from(#ff512f), to(#dd2476));
    background: linear-gradient(to bottom, #ff512f, #dd2476)
}

.ekit-wid-con .ekit-heading__title-has-border.start::before {
    left: 0
}

.ekit-wid-con .ekit-heading__title-has-border.end::before {
    right: 0
}

.ekit-wid-con .ekit-heading__title-has-border ~ .ekit-heading__description {
    display: block
}

.ekit-wid-con .ekit-heading__title-in-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.ekit-wid-con .ekit-heading__subtitle-has-border {
    border: 1px solid #fff;
    padding: .25rem 3rem;
    border-radius: 2rem;
    display: inline-block;
    position: relative;
    vertical-align: middle
}

.ekit-wid-con .ekit-heading .ekit_border_custom img {
    display: inline-block
}

.ekit-wid-con .elementskit-border-divider {
    height: 4px;
    width: 30px;
    background: #2575fc;
    border-radius: 2px;
    position: relative;
    margin-left: 27px
}

.ekit-wid-con .elementskit-border-divider::before {
    content: "";
    position: absolute;
    color: #0c5adb;
    left: -27px;
    top: 0;
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #0c5adb;
    -webkit-box-shadow: 9px 0 0 0 currentColor, 18px 0 0 0 currentColor;
    box-shadow: 9px 0 0 0 currentColor, 18px 0 0 0 currentColor
}

.ekit-wid-con .elementskit-border-divider.elementskit-style-long {
    width: 150px;
    border-radius: 0;
    background: #2575fc;
    margin-left: 0
}

.ekit-wid-con .elementskit-border-divider.elementskit-style-long::before {
    display: none
}

.ekit-wid-con .elementskit-border-divider.elementskit-style-long.style-3 {
    width: 90px
}

.ekit-wid-con .elementskit-border-divider.style-2 {
    background: #e7e7e7
}

.ekit-wid-con .elementskit-border-divider.style-2::before {
    background-color: #e7e7e7;
    -webkit-box-shadow: 9px 0 0 0 #e7e7e7;
    box-shadow: 9px 0 0 0 #e7e7e7;
    left: -19px
}

.ekit-wid-con .elementskit-border-star {
    width: 135px;
    color: #2575fc;
    background: #2575fc;
    background: -webkit-gradient(linear, left top, right top, from(currentColor), color-stop(38%, currentColor), color-stop(38%, rgba(255, 255, 255, 0)), color-stop(62%, rgba(255, 255, 255, 0)), color-stop(62%, currentColor), to(currentColor));
    background: linear-gradient(90deg, currentColor 0, currentColor 38%, rgba(255, 255, 255, 0) 38%, rgba(255, 255, 255, 0) 62%, currentColor 62%, currentColor 100%);
    height: 2px;
    position: relative
}

.ekit-wid-con .elementskit-border-star::after {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    width: 14.3px;
    height: 14.3px;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    background-color: #2575fc;
    top: -7.15px
}

.ekit-wid-con .elementskit-border-star.style-2 {
    background: #d7d7d7;
    background: -webkit-gradient(linear, left top, right top, from(#d7d7d7), color-stop(38%, #d7d7d7), color-stop(38%, rgba(255, 255, 255, 0)), color-stop(62%, rgba(255, 255, 255, 0)), color-stop(62%, #d7d7d7), to(#d7d7d7));
    background: linear-gradient(90deg, #d7d7d7 0, #d7d7d7 38%, rgba(255, 255, 255, 0) 38%, rgba(255, 255, 255, 0) 62%, #d7d7d7 62%, #d7d7d7 100%)
}

.ekit-wid-con .elementskit-border-star.style-2::after {
    background-color: #d7d7d7
}

.ekit-wid-con .elementskit-border-star.elementskit-bullet {
    height: 1px
}

.ekit-wid-con .elementskit-border-star.elementskit-bullet::after {
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.ekit-wid-con .elementskit-section-title {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    margin: 0 0 20px;
}

.ekit-wid-con .elementskit-section-title.medium {
    font-size: 36px
}

.ekit-wid-con .elementskit-section-title em {
    font-family: "Playfair Display", serif
}

.ekit-wid-con .elementskit-section-title.block span {
    background-color: #2575fc;
    color: #fff;
    padding: 5px
}

.ekit-wid-con .elementskit-section-title a {
    color: inherit;
    font-size: inherit;
    line-height: inherit
}

.ekit-wid-con .elementskit-section-subtitle {
    font-weight: 700;
    color: rgba(0, 0, 0, .5)
}

.ekit-wid-con .elementskit-section-subtitle.primary-v {
    color: #2575fc
}

.ekit-wid-con .elementskit-section-subtitle.elementskit-gradient-title {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(-35deg, #2575fc 0, #6a11cb 100%);
    color: #2575fc;
    background-size: 50%
}

.ekit-wid-con .elementskit-section-subtitle.elementskit-style-border {
    display: inline-block;
    position: relative;
    vertical-align: middle
}

.ekit-wid-con .elementskit-section-subtitle.elementskit-style-border::after, .ekit-wid-con .elementskit-section-subtitle.elementskit-style-border::before {
    content: "";
    width: 40px;
    height: 3px;
    background-color: #d7d7d7;
    display: inline-block;
    vertical-align: middle
}

.ekit-wid-con .elementskit-section-subtitle.elementskit-style-border::before {
    margin-right: 15px
}

.ekit-wid-con .elementskit-section-subtitle.elementskit-style-border::after {
    margin-left: 15px
}

.ekit-wid-con .elementskitsection-title-wraper {
    position: relative;
    z-index: 2
}

.ekit-wid-con .elementskitsection-title-wraper > p {
    margin-top: 21px;
    margin-bottom: 12px
}

.ekit-wid-con .elementskitsection-title-wraper .section-title {
    margin-bottom: 5px
}

.ekit-wid-con .elementskitsection-title-wraper.text-center, .ekit-wid-con .elementskitsection-title-wraper.text-right {
    margin-bottom: 40px
}

.ekit-wid-con .elementskitsection-title-wraper.green-v .section-title {
    color: #53f995
}

.ekit-wid-con .elementskitsection-title-wraper.green-v .elementskit-border-divider {
    background: #53f995
}

.ekit-wid-con .elementskitsection-title-wraper.green-v .elementskit-border-divider::before {
    background-color: inherit;
    -webkit-box-shadow: 9px 0 0 0 #53f995, 18px 0 0 0 #53f995;
    box-shadow: 9px 0 0 0 #53f995, 18px 0 0 0 #53f995
}

.ekit-wid-con .elementskitsection-title-wraper.white-v .section-title {
    color: #fff
}

.ekit-wid-con .elementskitsection-title-wraper.white-v .elementskit-border-divider {
    background: #fff
}

.ekit-wid-con .elementskitsection-title-wraper.white-v .elementskit-border-divider::before {
    background-color: inherit;
    -webkit-box-shadow: 9px 0 0 0 #fff, 18px 0 0 0 #fff;
    box-shadow: 9px 0 0 0 #fff, 18px 0 0 0 #fff
}

.ekit-wid-con .elementskitsection-title-wraper.primary-v .section-title {
    color: #2575fc
}

.ekit-wid-con .elementskitsection-title-wraper.primary-v .elementskit-border-divider {
    background: #2575fc
}

.ekit-wid-con .elementskitsection-title-wraper.primary-v .elementskit-border-divider::before {
    background-color: inherit;
    -webkit-box-shadow: 9px 0 0 0 #2575fc, 18px 0 0 0 #2575fc;
    box-shadow: 9px 0 0 0 #2575fc, 18px 0 0 0 #2575fc
}

.ekit-wid-con .elementskitsection-title-wraper.version-2.primary-v .section-title {
    color: #000
}

.ekit-wid-con .elementskitsection-title-wraper.version-3 > p {
    margin-top: 18px
}

.ekit-wid-con .elementskitsection-title-wraper.version-4 .section-title {
    position: relative;
    margin-left: 52px
}

.ekit-wid-con .elementskitsection-title-wraper.version-4 .section-title::before {
    position: absolute;
    content: "";
    top: calc(50% - 1.5px);
    left: 0;
    width: 70px;
    height: 3px;
    background-color: #2575fc
}

.ekit-wid-con .elementskitsection-title-wraper.version-5 .mask-title {
    margin-bottom: 12px
}

.ekit-wid-con .elementskitsection-title-wraper.version-5 .section-title {
    font-size: 22px;
    color: rgba(0, 0, 0, .5);
    letter-spacing: 5.8px;
    margin-bottom: 10px
}

.ekit-wid-con .elementskitsection-title-wraper .shuffle-letter-title-wraper {
    position: absolute;
    top: -36px;
    left: 0;
    width: 100%;
    z-index: -1
}

.ekit-wid-con .elementskit-section-title {
    font-weight: 500
}

.ekit-wid-con .elementskit-section-title.text_fill > span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(-35deg, #2575fc 0, #6a11cb 100%);
    color: #2575fc
}

.ekit-wid-con .elementskit-section-title span {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    display: inline-block;
    font-weight: 700
}

.ekit-wid-con .text_fill > span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.ekit-wid-con .elementskit-section-title-wraper.center, .ekit-wid-con .elementskit-section-title-wraper.text_center {
    text-align: center
}

.ekit-wid-con .elementskit-section-title-wraper.center .elementskit-border-divider, .ekit-wid-con .elementskit-section-title-wraper.center .elementskit-border-star, .ekit-wid-con .elementskit-section-title-wraper.text_center .elementskit-border-divider, .ekit-wid-con .elementskit-section-title-wraper.text_center .elementskit-border-star {
    margin-left: auto;
    margin-right: auto
}

.ekit-wid-con .elementskit-section-title-wraper.right, .ekit-wid-con .elementskit-section-title-wraper.text_right {
    text-align: right
}

.ekit-wid-con .elementskit-section-title-wraper.right .elementskit-border-divider, .ekit-wid-con .elementskit-section-title-wraper.right .elementskit-border-star, .ekit-wid-con .elementskit-section-title-wraper.text_right .elementskit-border-divider, .ekit-wid-con .elementskit-section-title-wraper.text_right .elementskit-border-star {
    margin-left: auto
}

.ekit-wid-con .elementskit-section-title-wraper.right .elementskit-border-divider, .ekit-wid-con .elementskit-section-title-wraper.text_right .elementskit-border-divider {
    left: 0
}

.ekit-wid-con .elementskit-section-title-wraper.left, .ekit-wid-con .elementskit-section-title-wraper.text_left {
    text-align: left
}

.ekit-wid-con .elementskit-section-title-wraper.left .elementskit-border-divider, .ekit-wid-con .elementskit-section-title-wraper.text_left .elementskit-border-divider {
    left: 0
}

.ekit-heading--subtitle {
    margin-top: 8px;
    margin-bottom: 16px
}

.ekit-heading__description {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    line-height: 24px
}

.ekit-heading__description > p:first-child {
    margin-top: 10px
}

.ekit-wid-con .post--tab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.ekit-wid-con .post--tab .tabHeader {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%
}

.ekit-wid-con .post--tab .tabHeader .tab__list {
    list-style: none;
    padding-left: 0
}

.ekit-wid-con .post--tab .tabHeader .tab__list > .tab__list__item {
    font-size: 14px;
    color: #232323;
    border: none;
    padding: 14px 16px 14px 30px;
    cursor: pointer;
    background-color: #fff;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    display: block;
    position: relative
}

.ekit-wid-con .post--tab .tabHeader .tab__list > .tab__list__item.active, .ekit-wid-con .post--tab .tabHeader .tab__list > .tab__list__item:hover {
    color: #d72924;
    background-color: #f0f1f4;
    z-index: 2
}

.ekit-wid-con .post--tab .tabContent {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%
}

.ekit-wid-con .ekit--tab__post__details {
    min-height: 100px
}

.ekit-wid-con .ekit--tab__post__details .tabItem {
    display: none;
    -webkit-animation: hide-fadeout .6s ease-out;
    animation: hide-fadeout .6s ease-out
}

.ekit-wid-con .ekit--tab__post__details .tabItem.active {
    display: block;
    -webkit-animation: show-fadein .6s ease-in;
    animation: show-fadein .6s ease-in
}

.ekit-wid-con .ekit--tab__post__details .tab__post__single--item {
    border-top: 0;
    width: 33.3333333333%;
    padding-left: 15px;
    padding-right: 15px;
    float: left
}

.ekit-wid-con .ekit--tab__post__details .tab__post__single--item.ekit___column-2 {
    width: 50%
}

.ekit-wid-con .ekit--tab__post__details .tab__post__single--item.ekit___column-4 {
    width: 25%
}

.ekit-wid-con .ekit--tab__post__details .tab__post__single--item:hover .tab__post--header > img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.ekit-wid-con .ekit--tab__post__details .tab__post--header {
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0
}

.ekit-wid-con .ekit--tab__post__details .tab__post--header > img {
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    width: 100%
}

.ekit-wid-con .ekit--tab__post__details .tab__post--header .tab__post--icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 5
}

.ekit-wid-con .ekit--tab__post__details .tab__post--header .tab__post--icon > span {
    color: #fff;
    font-size: 48px
}

.ekit-wid-con .ekit--tab__post__details .tab__post--title {
    font-size: 15px;
    font-weight: 500;
    color: #232323
}

.ekit-wid-con .ekit--tab__post__details .tab__post--title > a {
    color: inherit;
    line-height: inherit;
    padding-left: 0;
    padding-right: 0
}

.ekit-wid-con .ekit--tab__post__details .post_grid_img_thumb {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: all .4s;
    transition: all .4s
}

.ekit-wid-con .ekit--tab__post__details .post_grid_img_thumb:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.ekit-wid-con .post--tab.disbale-hover img {
    -webkit-transform: inherit !important;
    transform: inherit !important
}

.ekit-wid-con .clearfix::after {
    display: block;
    content: "";
    clear: both
}

.ekit-wid-con .post--lists {
    list-style: none;
    padding-left: 0
}

.ekit-wid-con .post--lists > li {
    border: none !important
}

.ekit-wid-con .post--lists > li > a {
    padding: 8px 16px;
    line-height: normal;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit-wid-con .post--lists.vertical--list > li {
    float: left;
    margin-right: 16px
}

.ekit-wid-con .post--lists.vertical--list > li > a {
    padding-left: 0;
    padding-right: 0
}

.ekit-wid-con .ekit-post_grid-item {
    margin-bottom: 27px
}

.ekit-wid-con .ekit-post_grid-title {
    margin-top: 0;
    margin-bottom: 0
}

.ekit_modal-searchPanel .xs-serach {
    position: relative
}

.ekit_modal-searchPanel .xs-serach input:not([type=submit]) {
    padding: 0 20px;
    background-color: transparent;
    width: 100%;
    height: 44px;
    border-radius: 5px;
    border: 0;
    border: 1px solid #e7e7e7;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit_modal-searchPanel .xs-serach input:not([type=submit])::-moz-placeholder {
    color: #777;
    font-size: 14px;
    opacity: 1
}

.ekit_modal-searchPanel .xs-serach input:not([type=submit])::-webkit-input-placeholder {
    color: #777;
    font-size: 14px;
    opacity: 1
}

.ekit_modal-searchPanel .xs-serach input:not([type=submit]):-ms-input-placeholder {
    color: #777;
    font-size: 14px;
    opacity: 1
}

.ekit_modal-searchPanel .xs-serach input:not([type=submit])::-ms-input-placeholder {
    color: #777;
    font-size: 14px;
    opacity: 1
}

.ekit_modal-searchPanel .xs-serach input:not([type=submit])::placeholder {
    color: #777;
    font-size: 14px;
    opacity: 1
}

.ekit_modal-searchPanel .xs-serach input:not([type=submit]):focus {
    border-color: #2575fc
}

.ekit_modal-searchPanel .xs-serach .search-btn {
    width: 62px;
    border: 0;
    font-size: 14px;
    color: #777;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    cursor: pointer;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.ekit_modal-searchPanel .mfp-wrap.ekit-promo-popup::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, .87);
    padding-bottom: 100%;
    border-radius: 100%;
    overflow: hidden;
    -webkit-animation: menu-animation .8s ease-out forwards;
    animation: menu-animation .8s ease-out forwards
}

.ekit_modal-searchPanel .mfp-wrap.ekit-promo-popup {
    height: 100vh;
    overflow: hidden
}

.ekit_modal-searchPanel .mfp-bg.my-mfp-slide-bottom {
    display: none
}

.ekit_modal-searchPanel .ekit-sidebar-widget {
    height: 100vh
}

.ekit_modal-searchPanel .ekit-bg-black {
    height: 100vh !important
}

.ekit_modal-searchPanel .ekit-search-group {
    position: relative
}

.ekit_modal-searchPanel .ekit-search-group input:not([type=submit]) {
    height: 70px;
    background-color: transparent;
    border-radius: 50px;
    border: 2px solid #fff;
    color: #fff;
    padding: 0 30px;
    width: 100%
}

.ekit_modal-searchPanel .ekit-search-group input:not([type=submit])::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.ekit_modal-searchPanel .ekit-search-group input:not([type=submit])::-webkit-input-placeholder {
    color: #fff;
    opacity: 1
}

.ekit_modal-searchPanel .ekit-search-group input:not([type=submit]):-ms-input-placeholder {
    color: #fff;
    opacity: 1
}

.ekit_modal-searchPanel .ekit-search-group input:not([type=submit])::-ms-input-placeholder {
    color: #fff;
    opacity: 1
}

.ekit_modal-searchPanel .ekit-search-group input:not([type=submit])::placeholder {
    color: #fff;
    opacity: 1
}

.ekit_modal-searchPanel .ekit-search-group .ekit_search-button {
    background-color: transparent;
    border: 0;
    padding: 0;
    color: #fff;
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 100%;
    width: 70px;
    border-radius: 0 50px 50px 0
}

.ekit_modal-searchPanel .ekit-search-group input[type=search]::-webkit-search-cancel-button, .ekit_modal-searchPanel .ekit-search-group input[type=search]::-webkit-search-decoration, .ekit_modal-searchPanel .ekit-search-group input[type=search]::-webkit-search-results-button, .ekit_modal-searchPanel .ekit-search-group input[type=search]::-webkit-search-results-decoration {
    display: none
}

.ekit_modal-searchPanel .ekit-search-panel {
    max-width: 800px;
    margin: 1.75rem auto
}

.ekit_navsearch-button {
    display: inline-block
}

.ekit_navsearch-button svg {
    max-width: 20px;
    height: auto;
    vertical-align: middle
}

.elementor-widget-elementskit-header-search .mfp-close {
    width: 44px
}

.ekit-wid-con .fluentform .ff-el-ratings label {
    display: inline-block
}

.ekit-wid-con .fluentform .ff-el-progress, .ekit-wid-con .fluentform .ff-el-progress-bar {
    height: 20px
}

.ekit-wid-con .fluentform .ff-el-progress-status {
    font-size: 13px;
    display: none
}

.ekit-wid-con .fluentform .ff-el-progress-bar span {
    font-size: 10px
}

.ekit-wid-con .fluentform .ff-step-header {
    display: none
}

.ekit-wid-con .fluentform .choices[data-type*=select-multiple] .choices__inner {
    padding: 0
}

.ekit-wid-con .fluentform .ff-el-form-check-label .ff-el-form-check-input::after {
    display: none
}

.ekit-wid-con .fluentform-widget-custom-radio-checkbox .ff-el-form-check-input:checked::before {
    display: none
}

.ekit-wid-con .frm-fluent-form .choices__inner {
    border: none
}

.ekit-wid-con .fluentform-widget-custom-radio-checkbox input[type=checkbox]:checked::after, .ekit-wid-con .fluentform-widget-custom-radio-checkbox input[type=radio]:checked::after {
    position: static;
    -webkit-transform: inherit;
    transform: inherit
}

.ekit-fluent-form-widget-step-header-yes .ff-el-progress-status {
    display: block !important
}

.ekit-fluent-form-widget-step-progressbar-yes .ff-step-header {
    display: block !important
}

.ekit-wid-con .ekit-btt__button {
    padding: 0;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative
}

.ekit-wid-con .ekit-btt__button:focus, .ekit-wid-con .ekit-btt__button:hover {
    text-decoration: none
}

.ekit-wid-con .ekit-btt__button .canvas {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: inherit
}

.ekit-wid-con .ekit-btt__button.ekit-tt-show {
    -webkit-animation: ekit-btt-fadein .5s;
    animation: ekit-btt-fadein .5s;
    opacity: 1
}

.ekit-wid-con .ekit-btt__button.yes:not(.ekit-tt-show) {
    -webkit-animation: ekit-btt-fadeOut .5s;
    animation: ekit-btt-fadeOut .5s;
    opacity: 0
}

.ekit-wid-con .ekit-btt.progress_indicator .ekit-btt__button {
    border: 0 !important;
    border-radius: 50% !important
}

@-webkit-keyframes ekit-btt-fadein {
    from {
        opacity: 0;
        -webkit-transform: translateY(80px);
        transform: translateY(80px)
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes ekit-btt-fadein {
    from {
        opacity: 0;
        -webkit-transform: translateY(80px);
        transform: translateY(80px)
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes ekit-btt-fadeOut {
    from {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(80px);
        transform: translateY(80px)
    }
}

@keyframes ekit-btt-fadeOut {
    from {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(80px);
        transform: translateY(80px)
    }
}

.ekit-wid-con .ekit-form .caldera-form-wrap form label span {
    display: inline-block
}
