.elementor-widget-blockquote blockquote {
    margin: 0;
    padding: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    quotes: none;
    border: 0;
    font-style: normal;
    color: #3f444b
}

.elementor-widget-blockquote blockquote .e-q-footer:after,
.elementor-widget-blockquote blockquote .e-q-footer:before,
.elementor-widget-blockquote blockquote:after, .elementor-widget-blockquote
blockquote:before, .elementor-widget-blockquote blockquote cite:after,
.elementor-widget-blockquote blockquote cite:before {
    content: none
}

.elementor-blockquote .e-q-footer {
    margin-top: 12px;
    display: flex;
    justify-content: space-between
}

.elementor-blockquote__tweet-button span {
    font-weight: 600
}

.elementor-blockquote__tweet-button i, .elementor-blockquote__tweet-button
span {
    vertical-align: middle
}

.elementor-blockquote__tweet-button i + span,
.elementor-blockquote__tweet-button svg + span {
    margin-left: .5em
}

.elementor-blockquote__tweet-button svg {
    fill: #1da1f2;
    height: 1em;
    width: 1em
}

.elementor-blockquote--button-skin-bubble
.elementor-blockquote__tweet-button
svg, .elementor-blockquote--button-skin-classic
.elementor-blockquote__tweet-button svg {
    fill: #fff;
    height: 1em;
    width: 1em
}

.elementor-blockquote--button-skin-bubble.elementor-blockquote--button-view-icon
.elementor-blockquote__tweet-button i,
.elementor-blockquote--button-skin-classic.elementor-blockquote--button-view-icon
.elementor-blockquote__tweet-button i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%)
}

.elementor-blockquote--skin-boxed .elementor-blockquote {
    background-color: #f9fafa;
    padding: 30px
}

.elementor-blockquote--skin-border .elementor-blockquote {
    border-color: #f9fafa;
    border-left: 7px #f9fafa;
    border-style: solid;
    padding-left: 20px
}

.elementor-blockquote--skin-quotation .elementor-blockquote:before {
    content: "“";
    font-size: 100px;
    color: #f9fafa;
    font-family: Times New Roman, Times, serif;
    font-weight: 900;
    line-height: 1;
    display: block;
    height: .6em
}

.elementor-blockquote--skin-quotation .elementor-blockquote__content {
    margin-top: 15px
}

.elementor-blockquote--align-left .elementor-blockquote__content {
    text-align: left
}

.elementor-blockquote--align-left .elementor-blockquote .e-q-footer {
    flex-direction: row
}

.elementor-blockquote--align-right .elementor-blockquote__content {
    text-align: right
}

.elementor-blockquote--align-right .elementor-blockquote .e-q-footer {
    flex-direction: row-reverse
}

.elementor-blockquote--align-center .elementor-blockquote {
    text-align: center
}

.elementor-blockquote--align-center .elementor-blockquote .e-q-footer,
.elementor-blockquote--align-center .elementor-blockquote__author {
    display: block
}

.elementor-blockquote--align-center .elementor-blockquote__tweet-button {
    margin-right: auto;
    margin-left: auto
}
