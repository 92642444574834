a[href*='https://elfsight.com/all-in-one-reviews-widget/']::after {
    cursor: default;
    pointer-events: none;
}

a[href*='https://elfsight.com/all-in-one-reviews-widget/']::after {
    background-color: #FFFFFF;
    content: "";
    height: 50px;
    left: 0;
    position: absolute;
    width: 185px;
    z-index: 10000;
}

#feedbackComponent {
    width: 100%;
}
